import React from 'react';
import ServerReports from './fourthSection/ServerReports';
import VendorReportSection from './fourthSection/VendorReportSection';
import RecentActivitiesReport from './fourthSection/RecentActivitiesReport';

const FourthLayerReport = ({ dashboardResult }) => {
    return (
        <div className="my-4">
            <div className="flex lg:flex-row flex-col">
                <div className="w-2/5">
                    <VendorReportSection dashboardResult={dashboardResult} />
                </div>
                <div className="w-1/3 pl-3">
                    <ServerReports dashboardResult={dashboardResult} />
                </div>
                <div className="w-1/3 pl-3">
                    <RecentActivitiesReport dashboardResult={dashboardResult} />
                </div>
            </div>
        </div>
    );
};

export default FourthLayerReport;
