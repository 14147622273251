import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Upload, GroupAdd, Groups } from '@mui/icons-material';

//core components
import AppTable from 'components/new_components/app-table';
import AppButton from 'components/new_components/AppButton';
import AppTag from 'components/new_components/AppTags';
import EmployeeTableActions from './EmployeeTableActions';
import EmployeeModal from './EmployeeModal';
import AppUploadCSVModal from 'components/new_components/AppUploadCSVModal';
import EmployeeViewModal from './EmployeeViewModal';
import EmployeeDeleteModal from './EmployeeDeleteModal';
import useSearch from 'hooks/useSearch';
import FormTooltip from 'components/new_components/FormTooltip';
//redux
import { connect } from 'react-redux';
import { GetMerchantPeople, UploadMerchantPeopleFile } from 'store/actions/merchantActions';
//utils
import { truncateText } from 'utils';
import { initialEmployeeValues } from './utils/constants';
import PageHeader from 'components/new_components/PageHeader';
import SubscriptionAlert from '../../../../components/Pricing/SubscriptionAlert';
import { GetRemaining } from '../../../../components/Pricing/usePricingHook';

//userguide
import AppUserGuide from 'components/new_components/AppUserGuide';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { steps } from './steps';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 8;

const Employee = ({
    GetMerchantPeople,
    UploadMerchantPeopleFile,
    all_employees,
    pricing_plan,
    userguide_info,
    all_article_header,
}) => {
    //state
    const [loading, setloading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('');
    const [modalPayload, setModalPayload] = useState(initialEmployeeValues);
    const [modalPayloadId, setModalPayloadId] = useState();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState();
    const [viewPayload, setViewPayload] = useState();
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const stepsTargetRef = useRef([]);
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    //translation
    const { t } = useTranslation('inventory');

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Employee'),
        [all_article_header]
    );

    //functions
    const openModal = (mode, payload = initialEmployeeValues, id) => {
        setModalOpen(true);
        setModalMode(mode);
        setModalPayload(payload);
        setModalPayloadId(id);
    };
    const closeModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalPayload({}), 1000);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ employee: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, employee: false });
        setOpenArticle(false);
    };

    //Upload CSV Modal
    const openUploadCSVModal = () => {
        setUploadModalOpen(true);
    };
    const closeUploadCSVModal = () => {
        setUploadModalOpen(false);
    };

    //View Modal
    const openViewModal = (payload = initialEmployeeValues) => {
        setViewModalOpen(true);
        setViewPayload(payload);
    };
    const closeViewModal = () => {
        setViewModalOpen(false);
        setTimeout(() => setViewPayload({}, 1000));
    };
    //Delete Modal
    const openDeleteModal = (payload = initialEmployeeValues, id) => {
        setDeleteModalOpen(true);
        setDeletePayload(payload);
        setModalPayloadId(id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setTimeout(() => setDeletePayload({}), 1000);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };
    // hooks
    const { data, handleSearch } = useSearch(all_employees, [
        'first_name',
        'last_name',
        'email',
        'position',
        'phone',
        'department',
        'address',
    ]);

    //function
    const columns = [
        {
            title: t('employeeTable.column1'),
            render: (row) => <>{row?.first_name + ' ' + row?.last_name}</>,
        },
        {
            title: t('employeeTable.column2'),
            key: 'email',
            render: (row) => <>{row?.email}</>,
        },
        {
            title: t('employeeTable.column3'),
            key: 'role',
            render: (row) => <>{row?.position || 'N/A'}</>,
        },
        {
            title: t('employeeTable.column4'),
            key: 'department',
            render: (row) => <>{row?.department || 'N/A'}</>,
        },
        {
            title: t('employeeTable.column5'),
            key: 'location',
            render: (row) => <>{truncateText(row?.address) || 'N/A'}</>,
        },
        {
            title: t('employeeTable.column6'),
            key: 'background_check',
            render: (row) => (
                <>
                    <AppTag
                        text={row?.background_check ? t('employeeTable.pass') : t('employeeTable.failed')}
                        type={row?.background_check ? 'success' : 'failed'}
                        hasIcon={true}
                    />
                </>
            ),
        },
        {
            title: (
                <>
                    {t('employeeTable.column7')}
                    <FormTooltip
                        placement="top-end"
                        text="This metric gauges an employee's operational risk, considering system health, department, card data scan, and background check, to preemptively tackle potential threats."
                    />
                </>
            ),
            key: 'risk_score',
            render: (row) => <>{`${row?.personal_risk_score || 0}%`}</>,
        },
        {
            title: t('employeeTable.column9'),
            align: 'center',
            key: 'action',
            render: (row) => (
                <EmployeeTableActions
                    row={row}
                    openModal={openModal}
                    openViewModal={openViewModal}
                    openDeleteModal={openDeleteModal}
                />
            ),
        },
    ];

    useEffect(() => {
        const getMerchantPeople = async () => {
            setloading(true);
            const res = await GetMerchantPeople();
            if (res?.success) {
                setloading(false);
            } else {
                setloading(false);
                toast.error(res?.message);
            }
        };
        getMerchantPeople();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.employee ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.employee]);

    const { reminderCount, showReminder } = GetRemaining(pricing_plan?.background_check, all_employees?.length);
    return (
        <>
            <PageHeader browserTitle="Employee | Smartcomply" />
            {showReminder && <SubscriptionAlert isDashboardPage={false} limitedNumber={`${reminderCount} employees`} />}
            <AppTable
                columns={columns}
                data={pageData}
                loading={loading}
                sorter={(a, b) => b?.id - a?.id}
                title={t('title')}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <AppButton
                        icon={<GroupAdd />}
                        name={t('addEmployee')}
                        color="primary"
                        variant="contained"
                        disabled={pricing_plan?.background_check === all_employees?.length}
                        disableElevation
                        onClick={() => openModal('add')}
                        key={1}
                        ref={(el) => (stepsTargetRef.current[0] = el)}
                    />,
                    <AppButton
                        icon={<Upload />}
                        name={t('uploadFromCSV')}
                        color="primary"
                        variant="contained"
                        disabled={pricing_plan?.background_check === all_employees?.length}
                        disableElevation
                        onClick={() => openUploadCSVModal()}
                        key={2}
                        ref={(el) => (stepsTargetRef.current[1] = el)}
                    />,
                ]}
                search={true}
                onSearch={handleSearch}
            />

            <EmployeeModal
                open={modalOpen}
                handleClose={closeModal}
                payload={modalPayload}
                isAddModal={modalMode === 'add'}
                id={modalPayloadId}
            />
            <AppUploadCSVModal
                open={uploadModalOpen}
                handleClose={closeUploadCSVModal}
                title={t('uploadCSVModal.title')}
                subheader={t('uploadCSVModal.subtitle')}
                templateLink="https://smartcomply-prod.s3.amazonaws.com/employee_template.xlsx"
                uploadCSVAction={UploadMerchantPeopleFile}
            />
            <EmployeeViewModal
                open={viewModalOpen}
                handleClose={closeViewModal}
                title={t('viewEmployeeModal.title')}
                width={500}
                icon={<Groups />}
                payload={viewPayload}
            />
            <EmployeeDeleteModal
                open={deleteModalOpen}
                handleClose={closeDeleteModal}
                payload={deletePayload}
                id={modalPayloadId}
            />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Employee"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_employees: state?.merchantReducers?.all_merchant_people,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.employee,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {
    GetMerchantPeople,
    UploadMerchantPeopleFile,
})(Employee);
