import { INTEGRATIONS_GROUPS, MERCHANT_INTEGRATIONS, ONE_INTEGRATION, AUTHORIZATION_URL } from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case INTEGRATIONS_GROUPS:
            return {
                ...state,
                integrations_groups: action.payload,
            };
        case MERCHANT_INTEGRATIONS:
            return {
                ...state,
                merchant_integrations: action.payload,
            };
        case ONE_INTEGRATION:
            return {
                ...state,
                one_integration: action.payload,
            };
        case AUTHORIZATION_URL:
            return {
                ...state,
                authorization_url: action.payload,
            };
        default:
            return state;
    }
};
