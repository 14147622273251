import React, { useState } from 'react';
import { useTheme } from '@mui/styles';
//components
import { Grid, Stack, Typography, Button, CircularProgress } from '@mui/material';
import AppModal from 'components/new_components/AppModal';
import { ErrorOutline, Close } from '@mui/icons-material';
// import AppChip from 'components/new_components/AppChip';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import { ReactComponent as DoubleDownIcon } from 'assets/img/icons/doubledown.svg';
import { ReactComponent as RecycleIcon } from 'assets/img/icons/recycle.svg';
import { RegenerateComplianceAIAnswers } from 'store/actions/complianceActions';

//formik
import { useFormikContext } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { GetComplianceAIAnswersBySubReqAndStandard } from 'store/actions/complianceActions';

// import AppTags from 'components/new_components/AppTags';

const ComplianceAISuggestions = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose, question, answers, fieldId, credentials, recordId, resetAutoFilledState } = props;
    const [showMore, setShowMore] = useState({});
    const [loading, setLoading] = useState(false);

    // const getTags = (index) => {
    //     if (index === 0) {
    //         return <AppChip text="Best Suggested" background={theme.palette.success[700]} />;
    //     } else if (index === 1) {
    //         return <AppChip text="Better Suggested" background={theme.palette.warning[800]} />;
    //     } else {
    //         return <AppChip text="Least Suggested" background={theme.palette.neutral[90]} noBg={true} />;
    //     }
    // };

    const { setFieldValue } = useFormikContext();
    const theme = useTheme();

    const handleToggleShowMore = (index) => {
        setShowMore((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const MAX_ANSWER_LENGTH = 200;

    const RegenerateAnswers = async () => {
        setLoading(true);
        resetAutoFilledState();
        const data = {
            data: {
                [recordId.toString()]: {
                    field_id: fieldId.toString(),
                    answers: [],
                    context: answers[0].context,
                    question: answers[0].question,
                },
            },
        };

        const res = await RegenerateComplianceAIAnswers(data)();
        if (res.success) {
            const refetch_res = await dispatch(GetComplianceAIAnswersBySubReqAndStandard(credentials));
            if (refetch_res.success) {
                setLoading(false);
                toast.success('AI answers have been successfully regenerated');
            } else {
                setLoading(false);
                toast.error('An error occurred while regenerating the compliance AI answers ');
            }
        } else {
            setLoading(false);
            toast.error('An error occurred while fetching the compliance AI answers ');
        }
    };

    return (
        <AppModal open={open} handleClose={handleClose} width={500}>
            <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ mb: 2 }}>
                <Stack alignItems="start" direction="row" sx={{}}>
                    <Typography sx={{ fontWeight: 700, fontSize: 24, color: theme.palette.gray[800] }}>
                        Suggestions
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, color: theme.palette.primary.main }}>
                        With Vifly
                    </Typography>
                </Stack>
                <Button
                    sx={{
                        display: 'flex',
                        backgroundColor: 'transparent',
                        color: '#858999',
                        fontSize: '12px',
                        width: '30px',
                        minWidth: 'unset',
                        border: 'none',
                        height: '30px',
                        justifyContent: 'center',
                        alignitems: 'center',
                        '&:hover': {
                            border: 'none',
                            background: 'transparent',
                        },
                    }}
                    variant="outlined"
                    onClick={handleClose}
                >
                    <Close />
                </Button>
            </Stack>

            <Stack
                direction="row"
                justifyContent="space-between"
                gap={3}
                sx={{ borderBottom: '2px solid #F2F0F4', pb: 3, pt: 1.5 }}
            >
                <Stack direction="row">
                    <ErrorOutline sx={{ fontSize: '15px', color: theme.palette.gray[40], mb: 1 }} />
                    <Typography sx={{ fontSize: 12, color: theme.palette.gray[40], ml: 1 }}>
                        It is easier to complete your audit questions with suggested answers
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap={0.5}
                    sx={{
                        backgroundColor: '#F1F5F9',
                        borderRadius: '6px',
                        width: 'max-content',
                        height: 'fit-content',
                        px: 1,
                        py: 0.5,
                        cursor: loading ? 'not-allowed' : 'pointer',
                        opacity: loading ? '0.5' : '1',
                    }}
                    onClick={() => !loading && RegenerateAnswers()}
                >
                    {loading ? <CircularProgress color="primary" size="1rem" /> : <RecycleIcon className="w-sm h-sm" />}

                    <Typography
                        sx={{
                            fontSize: 14,
                            color: theme.palette.primary[40],
                        }}
                    >
                        Regenerate
                    </Typography>
                </Stack>
            </Stack>

            <ul className="list-disc list-inside ml-1 text-[12px] mt-[24px] font-medium">
                <li>{question}</li>
            </ul>

            <Grid container sx={{ my: 2 }}>
                {Array.isArray(answers) && answers?.length ? (
                    answers?.[0]?.answers.map((answer, index) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                sx={{
                                    p: 1.5,
                                    my: 2,
                                    border: '1.2px solid #F2F0F4',
                                    fontSize: 12,
                                    color: theme.palette.gray[30],
                                    '&:hover': {
                                        background: '#fafafa',
                                    },
                                }}
                                key={index}
                                onClick={() => {
                                    setFieldValue(fieldId?.toString(), answer);
                                    handleClose();
                                }}
                            >
                                {answer.length > MAX_ANSWER_LENGTH ? (
                                    <>
                                        <Typography sx={{ color: '#46464a', fontSize: 12 }}>
                                            {showMore[index]
                                                ? answer
                                                : answer.substring(0, MAX_ANSWER_LENGTH) +
                                                  `${answer[MAX_ANSWER_LENGTH - 1] !== '.' && '...'}`}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography sx={{ color: '#46464a', fontSize: 12 }}>{answer}</Typography>
                                )}
                                {answer.length > MAX_ANSWER_LENGTH && (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ width: '100%', mt: 2 }}
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="start"
                                            gap={0.5}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggleShowMore(index);
                                            }}
                                            className="cursor-pointer"
                                        >
                                            {showMore[index] ? (
                                                <>
                                                    <Typography
                                                        sx={{ color: theme.palette.primary[900], fontSize: 11 }}
                                                    >
                                                        Show less
                                                    </Typography>
                                                    <DoubleDownIcon className="rotate-180" />
                                                </>
                                            ) : (
                                                <>
                                                    <Typography
                                                        sx={{ color: theme.palette.primary[900], fontSize: 12 }}
                                                    >
                                                        Show more
                                                    </Typography>
                                                    <DoubleDownIcon />
                                                </>
                                            )}
                                        </Stack>
                                    </Stack>
                                )}
                            </Grid>
                        );
                    })
                ) : (
                    <EmptyComponent text="No answers available for this question yet" />
                )}
            </Grid>
            <div className="mb-3" />
        </AppModal>
    );
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { RegenerateComplianceAIAnswers })(ComplianceAISuggestions);
