import introduction from 'assets/img/userguide/introduction.svg';
import onboarding from 'assets/img/userguide/onboarding.svg';
import aml from 'assets/img/userguide/aml.svg';
import integration from 'assets/img/userguide/integration.svg';
import compliance from 'assets/img/userguide/compliance.svg';
import risk_management from 'assets/img/userguide/risk_management.svg';
import scans from 'assets/img/userguide/scans.svg';
import settings from 'assets/img/userguide/setting.svg';
import setup from 'assets/img/userguide/setup.svg';

export const getData = (name) => [
    {
        id: 0,
        image: introduction,
        title: 'We have restructured our Menu!',
        description: `For improved compliance process and a more efficient user experience, we've redesigned our app menu, making it simpler 
        to access features and content.`,
    },
    {
        id: 1,
        image: onboarding,
        title: 'Onboarding',
        title_fr: 'Intégration',
        description: `We have grouped <span class='text-[#395BA9]'>employee onboarding, people and computers, employee training</span> 
        and <span class='text-[#395BA9]'>policies</span> into the onboarding module.`,
    },
    {
        id: 2,
        image: compliance,
        title: 'Compliance',
        title_fr: 'Conformité',
        description: `We have grouped <span class='text-[#395BA9]'>frameworks, audit and data room</span> into the compliance module`,
    },
    {
        id: 3,
        image: risk_management,
        title: 'Risk Management',
        title_fr: 'Gestion des risques',
        description: `We have <span class='text-[#395BA9]'>grouped risk overview, run assessment and risk register</span> into the risk assessment module.`,
    },
    {
        id: 4,
        image: scans,
        title: 'Security Scans',
        title_fr: 'Scans de sécurité',
        description: `We have grouped <span class='text-[#395BA9]'>security scans, codebase security scan, penetration testing, vulnerability scan, 
        PCI scan, and API compliance</span> into the infra security scan module.`,
    },
    {
        id: 5,
        image: aml,
        title: 'AML/KYC',
        title_fr: 'AML/KYC',
        description: `We have grouped <span class='text-[#395BA9]'>risk assessment, transaction fraud monitoring, vendor management 
        and AML</span> into the risk assessment module.`,
    },
    {
        id: 6,
        image: integration,
        title: 'Integrations',
        title_fr: 'Intégration',
        description: `This feature integrates SmartComply with other software systems, tools, or platforms within
        your organization's IT ecosystem seamlessly. We have also added infrastructure management under integrations.`,
    },
    {
        id: 7,
        image: settings,
        title: 'Settings',
        title_fr: 'Paramètres',
        description: `We have <span class='text-[#395BA9]'>profile information, company information, subscription, users,</span> and <span class='text-[#395BA9]'>2FA</span>.`,
    },
    {
        id: 8,
        image: setup,
        title: 'Setup Task',
        description: `Hello ${name}, to make the most of your experience, let's ensure your setup is complete.`,
    },
];
