import React, { useState, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Stack, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';

//core components
import AppCustomCheckbox from 'components/new_components/AppCustomCheckbox';
import AppButton from 'components/new_components/AppButton';
// import AppInput from 'components/new_components/AppInput';
import { AppForm } from 'components/new_components/forms';
import { questionnaireValidate } from 'views/merchant/VendorManagement/utils/utils';
import { AppSubmitButton } from 'components/new_components/forms';
import { AppFormInput } from 'components/new_components/forms';

const Fields = (props) => {
    const {
        field,
        predictions,
        fieldValues,
        acceptedAnswers,
        // selectedQuestionnaire,
        questionsIndex,
        // handleAnswerInput,
        // handleCommentInput,
        handleAnswerSelect,
        handleCommentSelect,
        handleShowPredictionScore,
        handleHidePredictionScore,
        showPredictionScore,
        percentageCorrectness,
        handleSingleApproveAction,
        handleDisapproveAction,
        handleDeleteQuestion,
        completed,
        commentPredictions,
    } = props;
    const theme = useTheme();
    // states
    const [showFooterOptions, setFooterShowOptions] = useState(false);
    const [loading, setLoading] = useState(false);

    // // memos
    const approved = useMemo(() => acceptedAnswers?.[field]?.answer, [acceptedAnswers]);
    const payload = useMemo(
        () => ({
            answer: fieldValues?.[field]?.answer || '',
            comment: fieldValues?.[field]?.comment || '',
        }),
        [fieldValues?.[field]]
    );
    // functions
    const handleCheckBox = (e, value) => {
        setFooterShowOptions(value);
    };
    const handleSubmit = async (values) => {
        setLoading(true);
        if (approved) {
            await handleDisapproveAction(field);
        } else {
            await handleSingleApproveAction(field, values);
        }
        setLoading(false);
    };
    return (
        <Stack direction="row" alignItems="flex-start" sx={{ mb: 3 }}>
            <AppCustomCheckbox
                // CHECK: for unapproved field, this should be warning, for answered it should be success
                color={approved ? 'success' : 'warning'}
                sx={{ mt: -1 }}
                checked={showFooterOptions}
                onChange={handleCheckBox}
            />
            <Box>
                <Typography>{field}</Typography>
                <Box sx={{ py: 1, mt: 1 }}>
                    <Typography
                        sx={{
                            fontSize: 14,
                            backgroundColor: '#F2F0F4',
                            color: theme.palette.gray[40],
                            width: 'fit-content',
                            p: 0.5,
                            borderRadius: '4px',
                        }}
                    >
                        Possible Answers
                    </Typography>
                    {/* answer options */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        sx={{ mt: 0.5, flexWrap: 'wrap', minWidth: 500 }}
                    >
                        {Object?.keys(predictions)?.map((prediction) => {
                            return (
                                <FieldOption
                                    label={predictions[prediction]}
                                    onClick={() => handleAnswerSelect(predictions[prediction], field)}
                                    selected={fieldValues?.[field]?.answer}
                                    key={predictions[prediction]}
                                    handleShowPredictionScore={handleShowPredictionScore}
                                    handleHidePredictionScore={handleHidePredictionScore}
                                    correctness={percentageCorrectness?.prediction}
                                    showPredictionScore={showPredictionScore}
                                    disabled={completed}
                                />
                            );
                        })}
                    </Stack>
                </Box>
                {/* answer field */}
                {/* CHECK: still contemplating if to use app form inputs here or this app input for flexibility */}
                <AppForm initialValues={payload} onSubmit={handleSubmit} validate={questionnaireValidate}>
                    <AppFormInput
                        placeholder="Select an option above or type in a custom answer"
                        type="text"
                        name="answer"
                        sx={{ maxWidth: 500 }}
                        disabled={completed}
                    />
                    <Box sx={{ py: 1 }}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                backgroundColor: '#F2F0F4',
                                color: theme.palette.gray[40],
                                width: 'fit-content',
                                p: 0.5,
                                borderRadius: '4px',
                            }}
                        >
                            Possible comment
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            sx={{ mt: 0.5, flexWrap: 'wrap', minWidth: 500 }}
                        >
                            {Object?.keys(commentPredictions)?.map((prediction) => {
                                return (
                                    <FieldOption
                                        label={commentPredictions[prediction]}
                                        onClick={() => handleCommentSelect(commentPredictions[prediction], field)}
                                        selected={fieldValues?.[field]?.comment}
                                        key={commentPredictions[prediction]}
                                        disabled={completed}
                                    />
                                );
                            })}
                        </Stack>
                        {/* comment field */}
                        <AppFormInput
                            placeholder="Comment"
                            type="text"
                            multiline
                            name="comment"
                            sx={{ maxWidth: 500 }}
                            minRows={4}
                            disabled={completed}
                        />
                    </Box>

                    {/* footer */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        component="footer"
                        sx={{ mt: 1.5 }}
                        spacing={5}
                    >
                        <AppSubmitButton
                            sx={{
                                p: '0.5rem 0.8rem',
                                lineHeight: 1,
                                '&.Mui-disabled': {
                                    color: theme.palette.gray[90],
                                    border: `2px solid ${theme.palette.gray[95]}`,
                                },
                            }}
                            color="primary"
                            variant="contained"
                            text={approved ? 'Unapprove' : 'Approve'}
                            disabled={completed}
                            loading={loading}
                            loadingPosition="center"
                        />

                        {showFooterOptions && (
                            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
                                <FooterOptionButton
                                    label="Delete Question"
                                    icon={<Delete />}
                                    onClick={() => handleDeleteQuestion({ [questionsIndex]: field })}
                                    disabled={completed}
                                />
                                {/* <FooterOptionButton label="Assign Question" icon={<ContentPasteGoOutlined />} /> */}
                            </Stack>
                        )}
                    </Stack>
                </AppForm>
            </Box>
        </Stack>
    );
};

const FieldOption = (props) => {
    const { label, onClick, selected, correctness, disabled } = props;
    const theme = useTheme();
    const isCurrentAnswer = selected === label;
    return correctness ? (
        <Tooltip title={`${correctness?.toFixed(2)}%`} arrow placement="top">
            <div>
                <AppButton
                    variant="outlined"
                    color="primary"
                    name={label}
                    onClick={onClick}
                    sx={{
                        fontWeight: 400,
                        m: 1,
                        ml: 0,
                        py: 0.5,
                        px: 1.2,
                        backgroundColor: isCurrentAnswer ? theme.palette.primary[100] + '20' : theme.palette.white.main,
                        '&.Mui-disabled': {
                            color: theme.palette.gray[90],
                            border: `2px solid ${theme.palette.gray[95]}`,
                        },
                    }}
                    disabled={disabled}
                />
            </div>
        </Tooltip>
    ) : (
        <div>
            <AppButton
                variant="outlined"
                color="primary"
                name={label}
                onClick={onClick}
                sx={{
                    fontWeight: 400,
                    m: 1,
                    ml: 0,
                    py: 0.5,
                    px: 1.2,
                    backgroundColor: isCurrentAnswer ? theme.palette.primary[100] + '20' : theme.palette.white.main,
                    '&.Mui-disabled': {
                        color: theme.palette.gray[90],
                        border: `2px solid ${theme.palette.gray[95]}`,
                    },
                }}
                disabled={disabled}
            />
        </div>
    );
};

const FooterOptionButton = (props) => {
    const { label, ...otherProps } = props;
    const theme = useTheme();

    return (
        <AppButton
            sx={{
                py: 0.6,
                px: 1,
                color: theme.palette.primary[900],
                '&, &:hover': {
                    backgroundColor: theme.palette.gray[95],
                },
                '&.Mui-disabled': {
                    color: theme.palette.gray[90],
                    border: `2px solid ${theme.palette.gray[95]}`,
                },
            }}
            variant="contained"
            name={label}
            disableElevation
            {...otherProps}
        />
    );
};

export default Fields;
