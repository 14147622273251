import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Paper, Box, Slide, Stack, Typography, IconButton } from '@mui/material';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import CookieIcon from 'assets/img/icons/emojis_cookie.svg';
import AppButton from 'components/new_components/AppButton';
import { Close } from '@mui/icons-material';
import Cookies from 'js-cookie';

const CookiesModal = (props) => {
    const theme = useTheme();
    const { open, handleClose, openCookieNoticeModal, openPrivacyNoticeModal } = props;
    return (
        <TrapFocus open disableAutoFocus disableEnforceFocus>
            <Slide appear={false} in={open}>
                <Paper
                    role="dialog"
                    aria-modal="false"
                    aria-label="Cookie banner"
                    square
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                        position: 'fixed',
                        bottom: '3%',
                        left: { md: '16%', lg: '1%' },
                        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                        right: 0,
                        m: 0,
                        p: 2,
                        borderWidth: 0,
                        borderTopWidth: 1,
                        width: '576px',
                        borderRadius: '8px',
                        zIndex: '1000',
                    }}
                >
                    <Box
                        sx={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme.palette.gray[900],
                            marginBottom: 0,
                            paddingBottom: '8px',
                            pl: '10px',
                            pr: '10px',
                        }}
                    >
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" alignItems="center" gap={2}>
                                <img src={CookieIcon} className="w-[40px] h-[40px]" alt="Cookie icon" />
                                <Typography sx={{ color: theme.palette.primary[20], fontSize: 16, fontWeight: 700 }}>
                                    Hey. Just another cookie disclaimer
                                </Typography>
                            </Stack>
                            <IconButton onClick={handleClose} sx={{ backgroundColor: '#EEF0FF4D' }}>
                                <Close />
                            </IconButton>
                        </Stack>
                    </Box>

                    <Box sx={{ padding: '0px 10px', pb: 0.2 }}>
                        <Typography sx={{ py: 3, fontSize: 12, fontWeight: 400, color: theme.palette.gray[40] }}>
                            Essential cookies are needed for our website to function. We may also employ non-essential
                            cookies with your permission in order to enhance user experience and track website traffic.
                            We might provide our social media and analytics partners with your site usage information
                            for these reasons. You consent to the usage of cookies on our website as outlined in our{' '}
                            <AppButton
                                name="Privacy notice"
                                variant="text"
                                sx={{
                                    minWidth: 'unset',
                                    p: 0,
                                    fontSize: 12,
                                    fontWeight: 400,
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        backgroundColor: 'transparent !important',
                                        textDecoration: 'underline',
                                    },
                                }}
                                onClick={openPrivacyNoticeModal}
                            />{' '}
                            and Cookie notice by selecting "Accept All."
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', px: 3, width: '100%', gap: 2 }}>
                        <AppButton
                            name="Accept all"
                            variant="contained"
                            color="primary"
                            sx={{ width: '100%' }}
                            onClick={() => {
                                Cookies.set('accepted', 'true', { expires: 90 });
                                handleClose();
                            }}
                        />
                        <AppButton
                            name="Cookie notice"
                            onClick={openCookieNoticeModal}
                            variant="outlined"
                            color="primary"
                            sx={{ width: '100%' }}
                        />
                    </Box>
                </Paper>
            </Slide>
        </TrapFocus>
    );
};

export default CookiesModal;
