import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import React from 'react';

const QAReviewerAction = (props) => {
    // props
    const { record, openModal, deleteModal } = props;

    // constant
    const items = [
        {
            label: 'Edit',
            key: '1',
            icon: <EditOutlined />,
            onClick: () => openModal('Edit', record),
        },
        {
            label: 'Delete',
            key: '2',
            icon: <DeleteOutlined />,
            onClick: () => deleteModal(record),
        },
    ];
    return (
        <Dropdown trigger={['click']} menu={{ items }}>
            <MoreOutlined />
        </Dropdown>
    );
};

export default QAReviewerAction;
