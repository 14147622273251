import React, { useState, useMemo, useEffect } from 'react';

// core components
import DescriptionLayer from '../../common/DescriptionLayer';
import SubgroupView from '../../common/SubgroupView';
import DocumentItem from '../../common/document/DocumentItem';
import QuestionItem from '../../common/audit_questions/QuestionItem';
import LoadingState from 'components/new_components/LoadingState';

// redux
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';
import DescriptionPreviewDrawer from '../../common/DescriptionPreviewDrawer';

const ResourceView = (props) => {
    const {
        goBack,
        resource,
        categoryID,
        all_categories,
        all_categories_metrics,
        categoryResources,
        merchantResources,
        loading,
        setFormPayload,
        touchedFields,
        submitting,
        handleResourceExempted,
        openTaskAssignModal,
        // all_compliance_assigned_tasks,
        assigned_task_documents_info,
        assigned_task_questions_info,
    } = props;

    // state
    const [page, setPage] = useState(1);
    const [overallMetric, setOverallMetric] = useState('');
    const [categoryPercent, setCategoryPercent] = useState(0);
    const [descriptionDrawer, setDescriptionDrawer] = useState(false);

    //translation
    const { t } = useTranslation('compliance');
    // const language = localStorage.getItem('i18nextLng');

    // memos
    // get the selected category by the given category id
    const selectedCategory = useMemo(
        () => all_categories?.find((cat) => cat?.id === categoryID),
        [categoryID, all_categories]
    );
    // get the status of the resource in the category
    const resourcesHaveBeenAttendedTo = useMemo(() => {
        return categoryResources?.map((catRes) => {
            return merchantResources?.some((merchRes) =>
                resource === 'doc' ? merchRes?.document_id === catRes?.id : merchRes?.form_id === catRes?.id
            );
        });
    }, [categoryResources, merchantResources]);
    // get the resource for the current page
    const selectedResource = useMemo(() => {
        return categoryResources?.[page - 1] || categoryResources?.page - 2;
    }, [categoryResources, page]);

    // functions
    // logic functions
    const goToResource = (page) => {
        if (page > categoryResources?.length) {
            setPage(categoryResources?.length);
        } else if (page < 1) {
            setPage(1);
        } else {
            setPage(page);
        }
    };
    // whenever a resource is exempted
    const onResourceExempted = () => {
        handleResourceExempted(selectedResource?.gdpa_category);
    };

    //open description preview modal
    const openDescriptionDrawer = () => {
        setDescriptionDrawer(true);
    };
    const closeDescriptionDrawer = () => {
        setDescriptionDrawer(false);
    };

    // useEffect
    // whenever the selected resource chnages and the current one is undefined, then go back.
    useEffect(() => {
        if (!selectedResource) {
            if (categoryResources?.length) {
                return goToResource(categoryResources?.length);
            }
            goBack();
        }
    }, [selectedResource, categoryResources]);
    // to calculate the percentage and mtrics for each category
    useEffect(() => {
        const { total_form, total_doc, user_form, user_doc } = all_categories_metrics[selectedCategory?.title];
        let percentage, metric;
        if (resource === 'doc') {
            metric = `${user_doc}/${total_doc} ${t('documentsUploaded')}`;
            percentage = (user_doc / total_doc) * 100;
        } else {
            metric = `${user_form}/${total_form} ${t('questionsAnswered')}`;
            percentage = (user_form / total_form) * 100;
        }
        setOverallMetric(metric);
        setCategoryPercent(percentage);
    }, [all_categories_metrics, selectedCategory]);
    // to set the current question's answer to the appform initial value.
    useEffect(() => {
        if (resource === 'form') {
            // get the admin resource for that page
            const adminResource = selectedResource;
            // get the merchant resource corresponding to that page
            const merchantResource = merchantResources
                ?.sort((a, b) => b?.id - a?.id)
                ?.find((resource) => resource?.form_id === adminResource?.id);
            // set the answers
            let initialValues = {},
                requiredValues = {};
            adminResource?.form_fields?.forEach((field) => {
                initialValues[field?.id] = merchantResource?.answer?.[field?.id] || '';
                requiredValues[field?.id] = field?.required;
            });
            setFormPayload({ values: initialValues, form_id: adminResource?.id, requiredValues });
        }
    }, [page, merchantResources, selectedResource]);

    return (
        <>
            <SubgroupView
                goBack={goBack}
                title={selectedCategory?.title}
                groupMetric={overallMetric}
                goToNextResource={goToResource}
                currentResourcePage={page}
                data={resourcesHaveBeenAttendedTo}
                resource={resource}
                touchedFields={touchedFields}
                submitting={submitting}
                categoryResources={categoryResources}
                assigned_task_documents_info={assigned_task_documents_info}
                assigned_task_questions_info={assigned_task_questions_info}
            >
                {loading ? (
                    <LoadingState />
                ) : (
                    <>
                        <DescriptionLayer
                            percentCompletion={categoryPercent}
                            description={selectedCategory?.description}
                            openDescriptionDrawer={openDescriptionDrawer}
                            standard="gdpa"
                        />
                        {resource === 'doc' ? (
                            <DocumentItem
                                document={selectedResource}
                                title_key="gdpa_title"
                                // title={
                                //     language?.includes('fr')
                                //         ? 'gdpa_title_fr'
                                //         : language?.includes('en')
                                //         ? 'gdpa_title_en'
                                //         : 'gdpa_title'
                                // }
                                standard="gdpa"
                                onResourceChange={onResourceExempted}
                                resource="doc"
                                openTaskAssignModal={openTaskAssignModal}
                                taskInfo={{
                                    gdpa_category: selectedCategory?.id,
                                }}
                            />
                        ) : (
                            <QuestionItem
                                form={selectedResource}
                                title="gdpa_title"
                                // title={
                                //     language?.includes('fr')
                                //         ? 'gdpa_title_fr'
                                //         : language?.includes('en')
                                //         ? 'gdpa_title_en'
                                //         : 'gdpa_title'
                                // }
                                standard="gdpa"
                                onResourceChange={onResourceExempted}
                                resource="form"
                                openTaskAssignModal={openTaskAssignModal}
                                taskInfo={{
                                    gdpa_category: selectedCategory?.id,
                                }}
                                category={selectedCategory}
                            />
                        )}
                    </>
                )}
            </SubgroupView>

            <DescriptionPreviewDrawer
                open={descriptionDrawer}
                handleClose={closeDescriptionDrawer}
                category={selectedCategory}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_categories: state?.adminReducers?.all_gdpa_categories,
        all_categories_metrics: state?.complianceReducers?.all_gdpa_categories_metrics,
        assigned_task_documents_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.documents?.forms?.gdpa,
        assigned_task_questions_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.questions?.forms?.gdpa,
    };
};
export default connect(mapStateToProps, {})(ResourceView);
