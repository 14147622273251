import { Descriptions, Typography } from 'antd';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

const { Title } = Typography;

const CategoryAnswer = (props) => {
    const { all_audit_categories, answer } = props;

    // memos
    const category = useMemo(() => {
        return all_audit_categories?.find((category) => category?.id === answer?.category);
    }, [all_audit_categories, answer]);
    const answers = useMemo(() => {
        try {
            return JSON.parse(answer?.answers);
        } catch (err) {
            return {};
        }
    }, [answer]);

    return (
        <div>
            <Title level={3}>Category: {category?.name}</Title>
            <div className="my-5">
                {Object.keys(answers)?.map((section, index) => {
                    const entries = answers[section];
                    return (
                        <section key={index} className="mb-5">
                            {section}
                            {entries.map((entry, index) => {
                                return (
                                    <Descriptions
                                        title={`Entry ${index + 1}`}
                                        className="mb-5"
                                        key={index}
                                        size="medium"
                                        layout="vertical"
                                        bordered
                                    >
                                        {Object.keys(entry).map((question, index) => {
                                            return (
                                                <Descriptions.Item label={question} key={index}>
                                                    {entry[question]}
                                                </Descriptions.Item>
                                            );
                                        })}
                                    </Descriptions>
                                );
                            })}
                        </section>
                    );
                })}
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_audit_categories: state?.auditReducers?.all_audit_categories,
});
export default connect(mapStateToProps, {})(CategoryAnswer);
