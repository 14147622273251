import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
// import { Link } from 'react-router-dom';

//translations
import { useTranslation } from 'react-i18next';

const ResponsesTableAction = (props) => {
    const theme = useTheme();
    const { row, openScoreModal, handleChangeView } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('vendorManagement');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const handleOpenScoreModal = () => {
        openScoreModal(row?.vendor);
        closeMenu();
    };

    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} sx={{ borderRadius: '16px' }}>
                <MenuItem onClick={handleOpenScoreModal}>{t('vendorResponseTable.tableAction.viewScoreCard')}</MenuItem>
                <MenuItem onClick={() => handleChangeView(row?.merchant, row?.vendor_details?.id)}>
                    {/* <MenuItem>
                    <Link
                        target="_blank"
                        rel="noreferrer noopener"
                        to={{
                            pathname: `/vendors/responses/${row?.merchant}/${row?.vendor_details?.id}`,
                            state: { readonly: true },
                        }}
                    > */}
                    {t('vendorResponseTable.tableAction.viewResponse')}
                    {/* </Link>
                </MenuItem> */}
                </MenuItem>
            </Menu>
        </>
    );
};

export default ResponsesTableAction;
