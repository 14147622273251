import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularLoader from 'components/Loaders/CircularLoader';

import ViewResponse from './ViewResponse';

const ResponseBody = (props) => {
    const theme = useTheme();
    const { question, title, answers } = props;

    //translation
    const language = localStorage.getItem('i18nextLng');

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.white.main,
                // width: 'calc(100vh - 125px)',
                height: 'calc(100% - 65px)',
                pr: '20px',
                // position: 'fixed',
                // left: '500px',
                overflow: 'hidden',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary[900] + '30',
                    borderRadius: '10px',
                },
            }}
        >
            {title === question?.title && (
                <Box sx={{ pt: '15px', pb: '70px', pr: '20px' }}>
                    {question ? (
                        question?.form_fields
                            ?.sort((a, b) => a.id - b.id)
                            ?.map((field) => {
                                const answer = answers[field.id];
                                return (
                                    <ViewResponse
                                        key={field.id}
                                        type={field.type}
                                        name={
                                            language?.includes('fr')
                                                ? field.name_fr
                                                : language?.includes('en')
                                                ? field.name_en
                                                : field.name
                                        }
                                        answer={answer}
                                    />
                                );
                            })
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularLoader />
                        </div>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ResponseBody;
