import React, { useMemo, useEffect, useState, useContext } from 'react';
import { Stack } from '@mui/material';

// core components
import ExceptionsTabs from '../../common/exceptions/ExceptionsTabs';
import LoadingState from 'components/new_components/LoadingState';
import EmptyState from 'components/new_components/EmptyState';

// redux
import { connect } from 'react-redux';
import { GetAllExemptedDocuments, GetAllExemptedForms } from 'store/actions/complianceActions';
import ExceptionGroups from '../../common/exceptions/ExceptionGroups';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const SubcontrolSelectionAside = (props) => {
    const {
        all_controls,
        GetAllExemptedDocuments,
        GetAllExemptedForms,
        exempted_documents,
        exempted_forms,
        resource,
        handleResourceChange,
        handleSubgroupIdChange,
        selectedSubgroup,
    } = props;

    // state
    const [loading, setLoading] = useState({ content: false });

    //translation
    const { t } = useTranslation('compliance');

    const { activeMerchant } = useContext(SelectedMerchantContext);

    // logic functions
    //To filter controls that has exempted documents
    const getFilteredControls = (items) => {
        const filteredControls = JSON.parse(JSON.stringify(all_controls))?.filter((control) => {
            const control_id = control?.id;
            const controlHasExemption = items?.some((exemption) => {
                const exemptionControlID =
                    resource === 'doc'
                        ? exemption?.document_detail?.common_control
                        : exemption?.form_detail?.common_control;
                return exemptionControlID === control_id;
            });
            return controlHasExemption;
        });
        const controlsWithFilteredSubcontrols = filteredControls?.map((control) => {
            const filteredSubcontrols = [...control.sub_controls].filter((sub_control) => {
                const subcontrolTitle = sub_control?.title;
                const subcontrolHasExemption = items?.some((exemption) => {
                    const exemptionSubcontrol =
                        resource === 'doc'
                            ? exemption?.document_detail?.sub_control
                            : exemption?.form_detail?.sub_control;
                    return exemptionSubcontrol === subcontrolTitle;
                });
                return subcontrolHasExemption;
            });

            control.sub_controls = filteredSubcontrols;
            return control;
        });

        const formattedControls = controlsWithFilteredSubcontrols.map((control) => {
            return {
                id: control?.id,
                title: control?.title,
                description: control?.service_category,
                subgroups: control?.sub_controls?.map((subcontrol) => ({
                    label: `${t('sub')} ${subcontrol?.title}`,
                    id: subcontrol?.title,
                })),
            };
        });
        return formattedControls;
    };

    // async functions
    // get all excempted document for this standard
    const getAllExemptedDocuments = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllExemptedDocuments('soc2', activeMerchant);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };
    const getAllExemptedQuestions = async () => {
        setLoading((current) => ({ ...current, content: true }));
        const res = await GetAllExemptedForms('soc2', activeMerchant);
        setLoading((current) => ({ ...current, content: false }));
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };

    // memos
    const controlsWithExceptions = useMemo(() => {
        if (resource === 'doc') {
            return getFilteredControls(exempted_documents || []);
        } else {
            return getFilteredControls(exempted_forms || []);
        }
    }, [resource, exempted_documents, exempted_forms]);

    useEffect(() => {
        if (resource === 'doc') {
            getAllExemptedDocuments();
        } else {
            getAllExemptedQuestions();
        }
    }, [resource, activeMerchant]);

    return (
        <Stack sx={{ width: '100%' }}>
            <ExceptionsTabs onTabChange={handleResourceChange} currentTab={resource} />
            {!loading?.content ? (
                !controlsWithExceptions?.length ? (
                    <EmptyState description={t('noException')} />
                ) : (
                    <ExceptionGroups
                        hasSubgroup={true}
                        groups={controlsWithExceptions}
                        handleSubgroupIdChange={handleSubgroupIdChange}
                        selectedSubgroup={selectedSubgroup}
                        standard="soc2"
                    />
                )
            ) : (
                <LoadingState />
            )}
        </Stack>
    );
};

const mapStateToProps = (state) => {
    return {
        all_controls: state?.adminReducers?.all_controls,
        exempted_documents: state?.complianceReducers?.all_exempted_documents,
        exempted_forms: state?.complianceReducers?.all_exempted_forms,
    };
};

export default connect(mapStateToProps, {
    GetAllExemptedDocuments,
    GetAllExemptedForms,
})(SubcontrolSelectionAside);
