import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { CardContainer } from '../../dashboard-utils/DashboardUtils';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { isFullFunctionPlan } from 'utils';

//translations
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const RiskAssessmentSection = ({ pricing_plan, dashboardResult }) => {
    const theme = useTheme();
    const [indexValue, setIndexValue] = useState(1);
    const { risk_assessment } = dashboardResult || {};

    //translation
    const { t } = useTranslation('overview');

    const user_type = useSelector((state) => state?.authReducers?.user_type);

    //function
    const onChangeTab = async (val) => {
        setIndexValue(val);
    };
    const tabList = [
        {
            id: 1,
            name: t('riskAssessmentSection.5By5'),
        },
        {
            id: 2,
            name: t('riskAssessmentSection.3By3'),
        },
    ];

    //charts paremeters
    const data = {
        labels:
            indexValue === 1
                ? [
                      t('riskAssessmentSection.extreme'),
                      t('riskAssessmentSection.veryHigh'),
                      t('riskAssessmentSection.high'),
                      t('riskAssessmentSection.medium'),
                      t('riskAssessmentSection.low'),
                      t('riskAssessmentSection.veryLow'),
                  ]
                : [t('riskAssessmentSection.high'), t('riskAssessmentSection.medium'), t('riskAssessmentSection.low')],
        datasets: [
            {
                label: '',
                data: indexValue === 1 ? risk_assessment?.['5x5']?.inherent : risk_assessment?.['3x3']?.inherent,
                backgroundColor: `${theme.palette.primary.main}`,
                barThickness: 30,
            },
        ],
    };
    const data2 = {
        labels:
            indexValue === 1
                ? [
                      t('riskAssessmentSection.extreme'),
                      t('riskAssessmentSection.veryHigh'),
                      t('riskAssessmentSection.high'),
                      t('riskAssessmentSection.medium'),
                      t('riskAssessmentSection.low'),
                      t('riskAssessmentSection.veryLow'),
                  ]
                : [t('riskAssessmentSection.high'), t('riskAssessmentSection.medium'), t('riskAssessmentSection.low')],
        datasets: [
            {
                label: '',
                data: indexValue === 1 ? risk_assessment?.['5x5']?.residual : risk_assessment?.['3x3']?.residual,
                backgroundColor: `${theme.palette.primary.main}`,
                barThickness: 30,
            },
        ],
    };
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

    const option = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                display: true,
                color: 'black',
                formatter: Math.round,
                anchor: 'end',
                offset: -20,
                align: 'start',
            },
        },
        aspectRatio: user_type === 'auditor' ? 1 / 2 : 4 / 5,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                min: 0,
                max: 100,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                barThickness: 6,
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <CardContainer cardStyle="w-full md:h-[400px]">
            <div className="flex flex-row border-b gap-3 border-b-gray-300 whitespace-nowrap">
                <div className="pl-4 py-3  lg:text-[20px] text-lg textbrandColor font-bold">
                    {t('riskAssessmentSection.title')}
                </div>
                <div className="py-2 flex items-center gap-1 textbrandColor text-[12px]">
                    {tabList?.map((res, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`flex items-center cursor-pointer px-1 transition tab ${
                                    indexValue === res.id && 'active border-b-2 border-b-[#002C72]'
                                }`}
                                onClick={() => onChangeTab(res.id)}
                            >
                                <span className={`${indexValue === res.id ? 'textbrandColor' : 'text-[#919094]'}`}>
                                    {res.name}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="p-4">
                {isFullFunctionPlan(pricing_plan?.tag) || user_type === 'auditor' ? (
                    <Stack direction="row" spacing={1} sx={{ height: '100%', mt: 3 }}>
                        <div className="w-[48%] h-[fit-content]">
                            <Typography
                                sx={{
                                    color: theme.palette.primary[20],
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    letterSpacing: '0.1px',
                                    mb: 2,
                                }}
                            >
                                {t('riskAssessmentSection.inherent')}
                            </Typography>
                            <Bar options={option} data={data} width={100} height={140} />
                        </div>
                        <div className="w-1/2 border-l-[0.7px] border-l-[#E1E2EC] pl-4 h-[fit-content]">
                            <Typography
                                sx={{
                                    color: theme.palette.primary[20],
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    letterSpacing: '0.1px',
                                    // ml: 1.5,
                                    mb: 2,
                                }}
                            >
                                {t('riskAssessmentSection.residual')}
                            </Typography>
                            <Bar options={option} data={data2} width={100} height={140} />
                        </div>
                    </Stack>
                ) : (
                    <>
                        <div className="flex items-center mt-3 mb-1">
                            <div className="mr-3">
                                <h3 className="font-semibold text-[#77777A] text-[14px]">
                                    {t('riskAssessmentSection.notAvailable')}
                                </h3>
                            </div>
                        </div>
                        <div className="flex items-center mb-3">
                            <div className="text-[11px] text-[#77777A] font-medium mr-2">
                                {t('riskAssessmentSection.upgrade')}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </CardContainer>
    );
};

export default RiskAssessmentSection;
