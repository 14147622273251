import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';

// core components
import ComplianceLayoutNew from '../common/NewComplianceLayout';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import Documents from './Documents';
import AuditQuestions from './AuditQuestions';
import Exceptions from './Exceptions';
import PageHeader from 'components/new_components/PageHeader';

// redux
import { connect } from 'react-redux';
import { GetAllControls, GetAllFormsByTags, GetAllDocumentsByTags } from 'store/actions/adminActions';
import {
    GetControlMetrics,
    GetAllMerchantDocuments,
    GetAllMerchantAnswers,
    GetAllExemptedControls,
} from 'store/actions/complianceActions';
import {
    GetUserDetails,
    UpdateUserDetails,
    GetModuleGuides,
    UpdateModuleGuide,
    CreateModuleGuide,
} from 'store/actions/generalActions';
import { GetCompanyDetails } from 'store/actions/merchantActions';
import TaskIntroductionModal from '../common/TaskIntroductionModal';
import AssignTaskModal from '../common/AssignTaskModal';
import AllTaskModal from '../common/AllTaskModal';
import PolicyApprovalModal from '../common/PolicyApprovalModal';
import DataRoomModal from '../common/DataRoomModal';
import { GetAllMerchantEvidences } from 'store/actions/complianceActions';
import { GetEvidenceMetrics } from 'store/actions/complianceActions';
// import ComplianceAITipsModal from '../common/ComplianceAITipsModal';

//Userguide
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const SOC2 = (props) => {
    const {
        GetControlMetrics,
        GetAllMerchantDocuments,
        GetAllMerchantAnswers,
        GetAllControls,
        GetAllExemptedControls,
        GetAllFormsByTags,
        GetAllDocumentsByTags,
        GetUserDetails,
        GetCompanyDetails,
        // UpdateUserDetails,
        GetModuleGuides,
        CreateModuleGuide,
        UpdateModuleGuide,
        controls_fetched,
        controls_metrics_fetched,
        guideModal,
        all_controls,
        controls_metrics,
        GetAllMerchantEvidences,
        GetEvidenceMetrics,
        evidences_metrics,
        company_details,
        userguide_info,
        all_article_header,
    } = props;

    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState({ controls: false });
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [tasksModalOpen, setTaskModalOpen] = useState(false);
    const [policyModalOpen, setPolicyModalOpen] = useState(false);
    const [dataRoomModalOpen, setDataRoomModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [taskInfo, setTaskInfo] = useState({});
    const standards = company_details?.standards?.split(',');
    const [type, setType] = useState(standards?.includes('soc2') ? 'soc2' : 'soc2type2');
    const [view, setView] = useState(0);
    // const [openAiModal, setOpenAiModal] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const STEPS_LENGTH = 3;

    const steps = [
        {
            title: <UserGuideHeader title="Documents" id={1} length={STEPS_LENGTH} />,
            description: 'Here you can Upload compliance doc, evidences and policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Audit Questions" id={2} length={STEPS_LENGTH} />,
            description: 'Respond to audit-related queries here.',
            target: () => ref2.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Exceptions" id={3} length={STEPS_LENGTH} />,
            description: 'This tab displays questions and documents that are exempted',
            target: () => ref3.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // functions
    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    // const handleOpenAiModal = () => {
    //     setOpenAiModal(true);
    // };
    // const handleCloseAiModal = () => {
    //     setOpenAiModal(false);
    // };

    //task introduction/explanation modal
    const closeIntroModal = () => {
        setIntroModalOpen(false);
    };

    const openTaskAssignModal = (type, info) => {
        setModalOpen(true);
        setModalType(type);
        setTaskInfo(info);
    };
    const closeTaskAssignModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalType(null), 500);
        setTaskInfo({});
    };

    const openTasksModal = () => {
        setTaskModalOpen(true);
    };
    const closeTasksModal = () => {
        setTaskModalOpen(false);
    };

    const openPolicyModal = () => {
        setPolicyModalOpen(true);
    };
    const closePolicyModal = () => {
        setPolicyModalOpen(false);
    };

    const openDataRoomModal = () => {
        setDataRoomModalOpen(true);
    };
    const closeDataRoomModal = () => {
        setDataRoomModalOpen(false);
    };
    // async functions
    // get all the controls
    const getAllControls = async () => {
        setLoading((current) => ({
            ...current,
            controls: !controls_fetched,
        }));
        const res = await GetAllControls();
        setLoading((current) => ({ ...current, controls: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };
    // get all requirement metric for this merchant
    const getControlMetrics = async () => {
        setLoading((current) => ({
            ...current,
            controls: !controls_metrics_fetched,
        }));
        const res = await GetControlMetrics();
        setLoading((current) => ({ ...current, controls: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };

    const getEvidenceMetrics = async () => {
        setLoading((current) => ({
            ...current,
            controls: !controls_metrics_fetched,
        }));
        const res = await GetEvidenceMetrics('soc2');
        setLoading((current) => ({ ...current, controls: false }));
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch requirements.");
        }
    };
    const getAllMerchantDocuments = async () => {
        const res = await GetAllMerchantDocuments();
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch your documents.");
        }
    };

    const getAllMerchantEvidences = async () => {
        const res = await GetAllMerchantEvidences();
        if (!res.success) {
            toast.error("Something went wrong! Couldn't fetch your documents.");
        }
    };
    const getMerchantAnswers = async () => {
        const res = await GetAllMerchantAnswers();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch previous answers.");
        }
    };
    //get all exempted controls
    const getAllExemptedControls = async () => {
        const res = await GetAllExemptedControls();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exempted controls.");
        }
    };
    const getUserDetails = async () => {
        const res = await GetUserDetails();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch user details");
        }
    };

    const getCompanyDetails = async () => {
        const res = await GetCompanyDetails();
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch company details");
        }
    };

    const getModuleGuides = async () => {
        const res = await GetModuleGuides();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllFormsByTags = async () => {
        const res = await GetAllFormsByTags('soc2');
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllDocumentByTags = async () => {
        const res = await GetAllDocumentsByTags('soc2');
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ compliance_task: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ compliance_task: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllControls();
        getControlMetrics();
        getMerchantAnswers();
        getAllMerchantDocuments();
        getAllExemptedControls();
        getCompanyDetails();
        getUserDetails();
        getModuleGuides();
        getAllFormsByTags();
        getAllDocumentByTags();
        getAllMerchantEvidences();
        getEvidenceMetrics();
    }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    return (
        <>
            <PageHeader browserTitle="SOC2 - Compliance | Smartcomply" />
            <Box sx={{ px: { xs: 0, md: 0.5 }, py: { xs: 0, md: 3 } }}>
                <ComplianceLayoutNew
                    onTabChange={handleTabChange}
                    tab={currentTab}
                    openTasksModal={openTasksModal}
                    openPolicyModal={openPolicyModal}
                    openDataRoomModal={openDataRoomModal}
                    standard="soc2"
                    type={type}
                    setType={setType}
                    standards={standards}
                    view={view}
                    ref1={ref1}
                    ref2={ref2}
                    ref3={ref3}
                >
                    <AppTabPanel value={currentTab} index={0}>
                        <Documents
                            loading={loading.controls}
                            getControls={getAllControls}
                            openTaskAssignModal={openTaskAssignModal}
                            all_metrics={controls_metrics}
                            view={view}
                            setView={setView}
                            type={type}
                        />
                    </AppTabPanel>
                    <AppTabPanel value={currentTab} index={1}>
                        <AuditQuestions
                            loading={loading.controls}
                            getControls={getAllControls}
                            openTaskAssignModal={openTaskAssignModal}
                        />
                    </AppTabPanel>
                    <AppTabPanel value={currentTab} index={2}>
                        <Exceptions getControls={getAllControls} />
                    </AppTabPanel>
                </ComplianceLayoutNew>
                {/* <ComplianceAITipsModal open={openAiModal} handleClose={handleCloseAiModal} width="700px" minWidth={900} /> */}
            </Box>
            <TaskIntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            />

            <AssignTaskModal open={modalOpen} onClose={closeTaskAssignModal} type={modalType} taskInfo={taskInfo} />
            <AllTaskModal open={tasksModalOpen} handleClose={closeTasksModal} standard="soc2" />
            <PolicyApprovalModal open={policyModalOpen} handleClose={closePolicyModal} standard="soc2" />
            <DataRoomModal
                open={dataRoomModalOpen}
                handleClose={closeDataRoomModal}
                standard="soc2"
                all_requirements={all_controls}
                all_metrics={controls_metrics}
                all_evidences_metrics={evidences_metrics}
                evidence_type={type}
            />

            {!loading.controls && <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Compliance"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        controls_fetched: state?.adminReducers?.all_controls?.length,
        all_controls: state?.adminReducers?.all_controls,
        controls_metrics_fetched: state?.complianceReducers?.all_controls_metrics?.length,
        controls_metrics: state?.complianceReducers?.all_controls_metrics,
        evidences_metrics: state?.complianceReducers?.all_evidences_metrics,
        guideModal: state?.generalReducers?.module,
        company_details: state?.authReducers?.company_details,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.compliance,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {
    GetControlMetrics,
    GetAllMerchantDocuments,
    GetAllMerchantAnswers,
    GetAllControls,
    GetAllExemptedControls,
    GetUserDetails,
    GetCompanyDetails,
    UpdateUserDetails,
    GetModuleGuides,
    CreateModuleGuide,
    UpdateModuleGuide,
    GetAllFormsByTags,
    GetAllDocumentsByTags,
    GetAllMerchantEvidences,
    GetEvidenceMetrics,
})(SOC2);
