import {
    USER_INFO,
    APPROVED_AUDITORS,
    AUDITOR_POOL_CERTIFICATIONS,
    AUDITOR_POOL_PORTFOLIOS,
    MODULE_GUIDE,
    GET_ALL_EMPLOYEES,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                user_info: action?.payload,
            };
        case APPROVED_AUDITORS:
            return {
                ...state,
                approved_auditors: action?.payload,
            };
        case AUDITOR_POOL_CERTIFICATIONS:
            return {
                ...state,
                auditor_pool_certifications: action?.payload,
            };
        case AUDITOR_POOL_PORTFOLIOS:
            return {
                ...state,
                auditor_pool_portfolio: action?.payload,
            };
        case MODULE_GUIDE:
            return {
                ...state,
                module: action?.payload,
            };
        case GET_ALL_EMPLOYEES:
            return {
                ...state,
                all_employees: action?.payload,
            };
        default:
            return state;
    }
};
