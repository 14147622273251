import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
//components
import { Box, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import AppCustomSwitch from 'components/new_components/AppCustomSwitch';

//redux
import { connect } from 'react-redux';
import { GetCompanyDetails, UpdateCompanyDetails } from 'store/actions/merchantActions';
//translation
import { useTranslation } from 'react-i18next';

const Compliance = (props) => {
    const theme = useTheme();
    const [autoFill, setToggleAutoFill] = useState(false);

    //translation hook
    const { t } = useTranslation('accounts');

    const { GetCompanyDetails, UpdateCompanyDetails, merchant_info } = props;
    const handleToggleAutoFill = async () => {
        setToggleAutoFill(!autoFill);
        const res = await UpdateCompanyDetails({ compliance_ai: !autoFill });
        if (res?.success) {
            // toast.success(`Compliance AI turned ${!autoFill === true ? 'on' : 'off'}`);
        } else {
            toast.error(res?.message);
            setToggleAutoFill(autoFill);
        }
    };

    useEffect(() => {
        const getCompanyDetails = async () => {
            const res = await GetCompanyDetails();
            if (!res?.success) {
                toast.success(res?.message);
            }
        };
        getCompanyDetails();
    }, []);

    useEffect(() => {
        if (merchant_info?.compliance_ai) {
            setToggleAutoFill(true);
        } else {
            setToggleAutoFill(false);
        }
    }, [merchant_info?.compliance_ai]);
    return (
        <Box sx={{ p: 4 }}>
            <Typography sx={{ fontSize: 24, fontWeight: 700, color: theme.palette.primary.main }}>
                {t('compliance.header')}
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: theme.palette.primary.main }}>
                {t('compliance.subtitle')}
            </Typography>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 4, pl: 3 }}>
                <Box sx={{ width: '60%' }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 600, color: theme.palette.primary.main }}>
                        {t('compliance.controlSwitchSection.header')}
                    </Typography>
                    <Typography sx={{ fontSize: 12, color: theme.palette.primary.main }}>
                        {t('compliance.controlSwitchSection.subtitle.pre')}{' '}
                        <Box
                            sx={{
                                color: theme.palette.primary.main,
                                textTransform: 'none',
                                fontSize: 12,
                                minWidth: 'unset',
                                fontWeight: 600,
                                px: 0,
                                py: 0,
                                pr: 0.4,
                                '&:hover': {
                                    background: 'transparent',
                                },
                            }}
                            variant="text"
                            component="a"
                            target="_blank"
                            href="https://www.smartcomplyapp.com/terms"
                        >
                            {t('compliance.controlSwitchSection.subtitle.post.one')}{' '}
                            {t('compliance.controlSwitchSection.subtitle.post.two')}{' '}
                            {t('compliance.controlSwitchSection.subtitle.post.three')}
                        </Box>
                        {t('compliance.controlSwitchSection.subtitle.post.four')}
                    </Typography>
                </Box>
                <AppCustomSwitch checked={autoFill} onChange={handleToggleAutoFill} />
            </Stack>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, { GetCompanyDetails, UpdateCompanyDetails })(Compliance);
