import { validation } from 'validate';

export const runScanValidation = (values) => {
    const errors = {};
    const endpointValidate = validation(values.link, 'Endpoint', true);

    if (!endpointValidate.isValid) {
        errors.link = endpointValidate.errorMessage;
    }

    return errors;
};
