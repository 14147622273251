import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// core components
import AppAvatar from 'components/new_components/AppAvatar';
import InfoListItem from './components/InfoListItem';

// images
import pfp from 'assets/img/avatar.jpg';

// redux
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';

const OwnerInfo = (props) => {
    const theme = useTheme();
    const { owner_info } = props;

    const { t } = useTranslation('company');
    return (
        <Box sx={{ p: 3 }}>
            <Typography
                variant="h2"
                component="h2"
                sx={{
                    color: theme.palette.primary[900],
                    fontSize: 18,
                    fontWeight: 700,
                    mb: 2.5,
                }}
            >
                {t('ownerInfo.header')}
            </Typography>
            <Box sx={{ maxWidth: '500px' }}>
                <AppAvatar label={t('ownerInfo.avatarLabel')} src={owner_info?.picture || pfp} id="owner_image" />
                <InfoListItem
                    label={t('ownerInfo.infoListItem1')}
                    value={`${owner_info?.first_name} ${owner_info?.last_name}`}
                />
                <InfoListItem label={t('ownerInfo.infoListItem2')} value={owner_info?.email} />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        owner_info: state?.merchantReducers?.merchant_info?.owner_detail,
    };
};
export default connect(mapStateToProps, {})(OwnerInfo);
