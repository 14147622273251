import { validation } from 'validate';

export const infrastructureValidation = (values) => {
    const errors = {};
    const locationValidate = validation(values.location, 'Location', true);
    const departmentValidate = validation(values.department, 'Department', true);
    const sensitivityValidate = validation(values.sensitivity, 'Sensitivity', true);
    const ip_typeValidate = validation(values.ip_type, 'Infrastructure Type', true);
    const ip_addressValidate = validation(values.ip_address, 'Ip Address', true);
    const assetNumberValidate = validation(values.asset_number, 'Asset Number', true);

    if (!locationValidate.isValid) {
        errors.location = locationValidate.errorMessage;
    }
    if (!departmentValidate.isValid) {
        errors.department = departmentValidate.errorMessage;
    }
    if (!sensitivityValidate.isValid) {
        errors.sensitivity = sensitivityValidate.errorMessage;
    }
    if (!ip_typeValidate.isValid) {
        errors.ip_type = ip_typeValidate.errorMessage;
    }
    if (!ip_addressValidate.isValid) {
        errors.ip_address = ip_addressValidate.errorMessage;
    }
    if (!assetNumberValidate.isValid) {
        errors.asset_number = assetNumberValidate.errorMessage;
    }

    return errors;
};
