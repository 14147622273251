import React, { useState } from 'react';
//core Components
import { Box } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppForm, AppFormInput, AppSubmitButton, AppFormContext } from 'components/new_components/forms';
import NotificationModal from './NotificationModal';
import { AppFormAssetInput } from 'components/new_components/forms';
import { AppFormSelect } from 'components/new_components/forms';
import { Save } from '@mui/icons-material';
//utils
import { infrastructureValidation } from './utils/validation';
import { infrastructureTypesOption, sensitivityOptions } from './utils/constants';
//redux
import { connect } from 'react-redux';
import { AddInfrastructure, UpdateInfrastructure } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';
import { pattern } from 'validate';

//translations
import { useTranslation } from 'react-i18next';

const InfrastructuresModal = ({
    open,
    handleClose,
    payload,
    isAddModal,
    AddInfrastructure,
    UpdateInfrastructure,
    id,
}) => {
    const [loadingState, setloadingState] = useState(false);
    const [ModalOpen, setModalOpen] = useState(false);
    const [values, setValues] = useState({});

    //translation
    const { t } = useTranslation('inventory');

    // logic functions
    const getCurrentValues = (values) => setValues(values);

    const openNotificationModal = () => {
        setModalOpen(true);
    };

    const closeNotificationModal = () => {
        setModalOpen(false);
    };
    const handleSubmit = async (values) => {
        setloadingState(true);
        const res = isAddModal ? await AddInfrastructure(values) : await UpdateInfrastructure(values, id);
        setloadingState(false);
        if (res?.success) {
            handleClose();
            toast.success(isAddModal ? 'Infrastructure Added Successfully' : 'Infrastructure Updated Successfully');
            if (values.ip_type === 'public' && isAddModal) {
                openNotificationModal();
            }
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error(res?.message);
            }
        }
    };
    return (
        <>
            <AppForm initialValues={payload} onSubmit={handleSubmit} validate={infrastructureValidation}>
                <AppCenteredModal
                    open={open}
                    handleClose={handleClose}
                    title={t('addInfrastructure')}
                    width={450}
                    actions={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                my: 3,
                            }}
                        >
                            <AppSubmitButton
                                text={t('addInfrastructureModal.submit')}
                                startIcon={<Save />}
                                variant="contained"
                                color="primary"
                                loading={loadingState}
                                loadingPosition="center"
                                sx={{ width: '30%' }}
                            />
                        </Box>
                    }
                >
                    <AppFormContext getValues={getCurrentValues}>
                        <AppFormInput
                            type="text"
                            label={t('addInfrastructureModal.location')}
                            name="location"
                            placeholder={t('addInfrastructureModal.location')}
                        />
                        <AppFormInput
                            type="text"
                            label={t('addInfrastructureModal.department')}
                            name="department"
                            placeholder={t('addInfrastructureModal.department')}
                        />
                        <AppFormAssetInput
                            name="asset_number"
                            label={t('addInfrastructureModal.asset')}
                            type="text"
                            placeholder={t('addInfrastructureModal.generateAssetNo')}
                            isAddModal={isAddModal}
                        />
                        <AppFormSelect
                            name="sensitivity"
                            options={sensitivityOptions}
                            defaultValue={t('addInfrastructureModal.selectSensitivity')}
                            label={t('addInfrastructureModal.sensitivity')}
                        />
                        <AppFormSelect
                            name="ip_type"
                            options={infrastructureTypesOption}
                            defaultValue={t('addInfrastructureModal.selectInfrastructureType')}
                            label={t('addInfrastructureModal.infrastructureType')}
                        />
                        {values.ip_type !== '' && (
                            <AppFormInput
                                type="text"
                                name="ip_address"
                                regex={values.ip_type === 'local' ? pattern.ip_address : ''}
                                label={values.ip_type === 'local' ? 'IP Address' : 'Domain Name'}
                                placeholder={values.ip_type === 'local' ? 'IP Address' : 'Domain Name'}
                            />
                        )}
                    </AppFormContext>
                </AppCenteredModal>
            </AppForm>

            <NotificationModal open={ModalOpen} handleClose={closeNotificationModal} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, {
    AddInfrastructure,
    UpdateInfrastructure,
})(InfrastructuresModal);
