import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import AppAvatar from 'components/new_components/AppAvatar';
import pfp from 'assets/img/avatar.jpg';
// redux
import { connect } from 'react-redux';
import { getType, truncateText } from 'utils';

const UserDropdownTrigger = (props) => {
    const { showDropdown, company_info, employee_details } = props;
    const theme = useTheme();
    const userType = useMemo(() => getType('type'), []);
    return (
        <Box
            component="button"
            onClick={showDropdown}
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                // backgroundColor: 'transparent',
                width: 'fit-content',
                border: 'none',
                backgroundColor: 'inherit',
                color: theme.palette.primary[900],
            }}
        >
            <AppAvatar src={company_info?.company_img || pfp} size={40} />
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                <Typography
                    component="span"
                    variant="subtitle2"
                    sx={{ textTransform: 'capitalize', pl: 1, fontWeight: 700 }}
                >
                    {userType === 'employee'
                        ? employee_details?.first_name
                            ? `${employee_details?.first_name} ${employee_details?.last_name}`
                            : truncateText(employee_details?.email, '15')
                        : company_info?.name}
                </Typography>
                <ExpandMore color="primary" />
            </Box>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        company_info: state?.generalReducers?.user_info?.organization,
        employee_details: state?.generalReducers?.user_info?.employee?.user_detail,
    };
};
export default connect(mapStateToProps, {})(UserDropdownTrigger);
