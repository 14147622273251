import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Card, Fade, Typography } from '@mui/material';
import { getData } from './utils/constants';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { useTheme } from '@mui/styles';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useSelector, connect } from 'react-redux';
import defaultPolygon from 'assets/img/userguide/DefaultPolygon.svg';
import { CreateModuleGuide } from 'store/actions/generalActions';
import { toast } from 'react-toastify';

const DefaultModal = (props) => {
    const {
        open,
        handleClose,
        routes,
        CreateModuleGuide,
        // updateModuleIntroModal,
    } = props;
    const theme = useTheme();
    const language = localStorage.getItem('i18nextLng');

    const [page, setPage] = useState(0);
    const [title, setTitle] = useState('');

    const name = useSelector((state) => state?.generalReducers?.user_info?.first_name);
    const tag = useSelector((state) => state?.generalReducers?.user_info?.organization?.merchant_plan?.tag);
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const user_id = useSelector((state) => state?.authReducers?.user_id);

    const filtered_routes = routes?.map((route) => route?.name);
    const filtered_datas = getData(name)?.filter((dat) => filtered_routes?.includes(dat?.title || dat?.title_fr));
    const filtered_data = [
        getData(name)[0],
        ...filtered_datas,
        user_info?.complete_setup?.percentage < 100 ? getData(name)[8] : null,
    ]?.filter((item) => item !== null);
    return (
        <>
            <Modal open={open} closeAfterTransition>
                <Fade in={open}>
                    <Box>
                        {filtered_data?.map((detail, index) => {
                            return (
                                <Box key={detail?.id}>
                                    {index === page && (
                                        <Box key={index}>
                                            {index > 0 && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top:
                                                            index === 1
                                                                ? '14%'
                                                                : index === 2
                                                                ? tag === 'demo'
                                                                    ? '15%'
                                                                    : '19%'
                                                                : index === 3
                                                                ? '24%'
                                                                : index === 4
                                                                ? tag === 'demo'
                                                                    ? '24%'
                                                                    : '28%'
                                                                : index === 5
                                                                ? title === ('Integrations' || 'Intégration')
                                                                    ? '74%'
                                                                    : title === 'AML/KYC'
                                                                    ? '33%'
                                                                    : '68%'
                                                                : index === 6
                                                                ? title === ('Settings' || 'Paramètres')
                                                                    ? '78%'
                                                                    : user_info?.complete_setup?.percentage === 100
                                                                    ? '86%'
                                                                    : '74%'
                                                                : index === 7
                                                                ? title === 'Setup Task'
                                                                    ? '87%'
                                                                    : title === ('Settings' || 'Paramètres') &&
                                                                      user_info?.complete_setup?.percentage < 100
                                                                    ? '78%'
                                                                    : user_info?.complete_setup?.percentage === 100
                                                                    ? '90%'
                                                                    : '73%'
                                                                : index === 8
                                                                ? '90%'
                                                                : '',
                                                        left: index > 0 ? '16.5%' : '',
                                                        color: '#FFFFFF',
                                                        [theme.breakpoints.down('xl')]: {
                                                            left: index > 0 ? '22%' : '',
                                                            top:
                                                                index === 1
                                                                    ? '17%'
                                                                    : index === 2
                                                                    ? tag === 'demo'
                                                                        ? '18%'
                                                                        : '22%'
                                                                    : index === 3
                                                                    ? '28%'
                                                                    : index === 4
                                                                    ? '32.5%'
                                                                    : index === 5
                                                                    ? title === ('Integrations' || 'Intégration')
                                                                        ? '69%'
                                                                        : '38%'
                                                                    : index === 6
                                                                    ? title === ('Settings' || 'Paramètres')
                                                                        ? '74%'
                                                                        : title === ('Integrations' || 'Intégration') &&
                                                                          user_info?.complete_setup?.percentage < 100
                                                                        ? '70%'
                                                                        : user_info?.complete_setup?.percentage === 100
                                                                        ? '84%'
                                                                        : '72%'
                                                                    : index === 7
                                                                    ? title === 'Setup Task'
                                                                        ? '87%'
                                                                        : user_info?.complete_setup?.percentage === 100
                                                                        ? '88%'
                                                                        : '75%'
                                                                    : index === 8
                                                                    ? '90%'
                                                                    : '',
                                                        },
                                                        [theme.breakpoints.down('lg')]: {
                                                            left: index > 0 ? '27.3%' : '',
                                                            top:
                                                                index === 1
                                                                    ? '22%'
                                                                    : index === 2
                                                                    ? tag === 'demo'
                                                                        ? '25%'
                                                                        : '29%'
                                                                    : index === 3
                                                                    ? '36.5%'
                                                                    : index === 4
                                                                    ? '44%'
                                                                    : index === 5
                                                                    ? title === ('Integrations' || 'Intégration')
                                                                        ? '59%'
                                                                        : '52%'
                                                                    : index === 6
                                                                    ? title === ('Settings' || 'Paramètres')
                                                                        ? '65%'
                                                                        : user_info?.complete_setup?.percentage === 100
                                                                        ? '78%'
                                                                        : '61%'
                                                                    : index === 7
                                                                    ? title === 'Setup Task'
                                                                        ? '80%'
                                                                        : user_info?.complete_setup?.percentage === 100
                                                                        ? '80%'
                                                                        : '67.5%'
                                                                    : index === 8
                                                                    ? '85%'
                                                                    : '',
                                                        },
                                                        [theme.breakpoints.down('md')]: {
                                                            left: index > 0 ? '10.1%' : '',
                                                            top:
                                                                index === 1
                                                                    ? '15%'
                                                                    : index === 2
                                                                    ? '29%'
                                                                    : index === 3
                                                                    ? '36.5%'
                                                                    : index === 4
                                                                    ? '43%'
                                                                    : index === 5
                                                                    ? title === ('Integrations' || 'Intégration')
                                                                        ? '57%'
                                                                        : '50%'
                                                                    : index === 6
                                                                    ? title === ('Settings' || 'Paramètres')
                                                                        ? '65%'
                                                                        : '61%'
                                                                    : index === 7
                                                                    ? title === 'Setup Task'
                                                                        ? '80%'
                                                                        : '67.5%'
                                                                    : index === 8
                                                                    ? '90%'
                                                                    : '',
                                                        },
                                                    }}
                                                >
                                                    <img src={defaultPolygon} alt="defaultPolygon" />
                                                </Box>
                                            )}
                                            <Card
                                                sx={{
                                                    position: 'absolute',
                                                    top:
                                                        index === 1
                                                            ? '10%'
                                                            : index === 2
                                                            ? tag === 'demo'
                                                                ? '13%'
                                                                : '16%'
                                                            : index === 3
                                                            ? tag === 'demo'
                                                                ? '17%'
                                                                : '20%'
                                                            : index === 4
                                                            ? tag === 'demo'
                                                                ? '20%'
                                                                : '24%'
                                                            : index === 5
                                                            ? title === ('Integrations' || 'Intégration')
                                                                ? '35%'
                                                                : '28%'
                                                            : index === 6
                                                            ? '43%'
                                                            : index === 7
                                                            ? title === 'Setup Task'
                                                                ? '52%'
                                                                : user_info?.complete_setup?.percentage === 100
                                                                ? '52%'
                                                                : '40%'
                                                            : index === 8
                                                            ? '52%'
                                                            : '30%',
                                                    left: index > 0 ? '17.2%' : '40%',
                                                    [theme.breakpoints.down('xl')]: {
                                                        left: index > 0 ? '22.8%' : '',
                                                        top:
                                                            index === 1
                                                                ? '10%'
                                                                : index === 2
                                                                ? '16%'
                                                                : index === 3
                                                                ? '20%'
                                                                : index === 4
                                                                ? tag === 'demo'
                                                                    ? '20%'
                                                                    : '24%'
                                                                : index === 5
                                                                ? '28%'
                                                                : index === 6
                                                                ? user_info?.complete_setup?.percentage === 100
                                                                    ? '33%'
                                                                    : '30%'
                                                                : index === 7
                                                                ? title === 'Setup Task'
                                                                    ? '45%'
                                                                    : user_info?.complete_setup?.percentage === 100
                                                                    ? '43%'
                                                                    : '40%'
                                                                : index === 8
                                                                ? '45%'
                                                                : '30%',
                                                    },
                                                    [theme.breakpoints.down('lg')]: {
                                                        left: index?.id > 0 ? '28.5%' : '',
                                                        top:
                                                            index?.id === 1
                                                                ? '10%'
                                                                : index === 2
                                                                ? '16%'
                                                                : index === 3
                                                                ? tag === 'demo'
                                                                    ? '17%'
                                                                    : '20%'
                                                                : index === 4
                                                                ? '15%'
                                                                : index === 5
                                                                ? title === ('Integrations' || 'Intégration')
                                                                    ? '15%'
                                                                    : '13%'
                                                                : index === 6
                                                                ? '10%'
                                                                : index === 7
                                                                ? tag == 'demo'
                                                                    ? '32%'
                                                                    : user_info?.complete_setup?.percentage === 100
                                                                    ? '20%'
                                                                    : '22%'
                                                                : index === 8
                                                                ? '27%'
                                                                : '20%',
                                                    },
                                                    [theme.breakpoints.down('md')]: {
                                                        left: index > 0 ? '28.5%' : '',
                                                        top:
                                                            index === 1
                                                                ? '10%'
                                                                : index === 2
                                                                ? '16%'
                                                                : index === 3
                                                                ? tag === 'demo'
                                                                    ? '17%'
                                                                    : '20%'
                                                                : index === 4
                                                                ? '24%'
                                                                : index === 5
                                                                ? '28%'
                                                                : index === 6
                                                                ? '10%'
                                                                : index === 7
                                                                ? '22%'
                                                                : index === 8
                                                                ? '80%'
                                                                : '20%',
                                                    },
                                                }}
                                            >
                                                <img src={detail.image} alt={detail.title} className="" />
                                                <Box sx={{ p: '15px' }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '18px',
                                                            fontWeight: 700,
                                                            color: '#334155',
                                                            textAlign: 'left',
                                                            marginBottom: '8px',
                                                        }}
                                                    >
                                                        {language?.includes('fr') ? detail.title_fr : detail.title}
                                                    </Typography>

                                                    <Typography
                                                        sx={{
                                                            fontSize: '13px',
                                                            fontWeight: 400,
                                                            color: '#64748B',
                                                            width: '350px',
                                                            wordBreak: 'break-word',
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: detail.description }}
                                                    ></Typography>
                                                    <Box
                                                        sx={{
                                                            mt: 3,
                                                            display: 'flex',
                                                            justifyContent:
                                                                detail?.id === 0 ? 'right' : 'space-between',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {index > 0 && (
                                                            <Typography
                                                                sx={{
                                                                    color: theme.palette.neutral[50],
                                                                    fontWeight: 600,
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                {index}/{filtered_data?.length - 1}
                                                            </Typography>
                                                        )}
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {index > 1 && (
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{
                                                                        textTransform: 'inherit',
                                                                        border: '1px solid #E2E8F0',
                                                                        marginRight: '1rem',
                                                                        '&:hover': {
                                                                            border: '1px solid #E2E8F0',
                                                                            background: 'transparent',
                                                                        },
                                                                    }}
                                                                    startIcon={<WestIcon />}
                                                                    onClick={() => {
                                                                        setPage(page - 1);
                                                                        setTitle(
                                                                            language?.includes('fr')
                                                                                ? filtered_data[index - 1].title_fr
                                                                                : filtered_data[index - 1].title
                                                                        );
                                                                    }}
                                                                >
                                                                    Back
                                                                </Button>
                                                            )}
                                                            <Button
                                                                variant="contained"
                                                                sx={{ textTransform: 'inherit' }}
                                                                endIcon={<EastIcon />}
                                                                onClick={async () => {
                                                                    if (index === filtered_data.length - 1) {
                                                                        const res =
                                                                            user_id &&
                                                                            (await CreateModuleGuide({
                                                                                sidebar: true,
                                                                                user: user_id,
                                                                            }));
                                                                        if (res?.success) {
                                                                            handleClose();
                                                                        } else {
                                                                            toast.error(res?.message);
                                                                        }
                                                                        // handleClose();
                                                                    } else {
                                                                        setPage(page + 1);
                                                                        setTitle(
                                                                            language?.includes('fr')
                                                                                ? filtered_data[index + 1].title_fr
                                                                                : filtered_data[index + 1].title
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {index === 0
                                                                    ? 'Lets Go'
                                                                    : index === filtered_data?.length - 1
                                                                    ? 'Finish'
                                                                    : 'Next'}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </Box>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        guideModal: state?.generalReducers?.module,
    };
};

export default connect(mapStateToProps, {
    CreateModuleGuide,
})(DefaultModal);
