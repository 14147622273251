import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Card, Chip, Grid, Stack, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { DoNotDisturb } from '@mui/icons-material';

const GroupCard = forwardRef((props, ref) => {
    const theme = useTheme();
    const {
        children,
        onCardClick,
        subcriteria,
        empty,
        all_criteria_summary,
        exception,
        id,
        module_exception,
        description,
    } = props;
    console.log({ module_exception, id });
    return (
        <Grid item xs={12} sm={6} xl={6} ref={ref}>
            <Card
                variant={'outlined'}
                sx={{
                    p: 1,
                    borderRadius: '10px',
                    height: '100%',
                    display: 'grid',
                    alignContent: 'space-between',
                    cursor: empty
                        ? 'not-allowed'
                        : module_exception?.id === id
                        ? 'not-allowed'
                        : exception && description
                        ? 'not-allowed'
                        : 'pointer',

                    padding: theme.spacing(2, 3, 4, 3),
                    boxShadow:
                        '-111px 221px 99px rgba(230, 230, 230, 0.01), -62px 124px 83px rgba(230, 230, 230, 0.05), -28px 55px 62px rgba(230, 230, 230, 0.09), -7px 14px 34px rgba(230, 230, 230, 0.1), 0px 0px 0px rgba(230, 230, 230, 0.1)',
                }}
                onClick={() => {
                    if (module_exception?.id !== id) {
                        onCardClick();
                    }
                }}
            >
                <Box>{children}</Box>
                <Stack direction="row" sx={{ flexWrap: 'wrap', marginRight: 1, mt: 2 }} onClick={onCardClick}>
                    {subcriteria?.map((chip) => {
                        const one_summary = all_criteria_summary?.find((summary) => summary?.subsector === chip?.id);
                        const true_tools = one_summary?.tools?.every((item) => item?.status === true);

                        // forms and documents uploaded
                        const completed =
                            one_summary?.data?.user_doc === one_summary?.data?.total_doc &&
                            one_summary?.data?.user_form === one_summary?.data?.total_form &&
                            one_summary?.data?.user_conditional === one_summary?.data?.conditional_count &&
                            true_tools;
                        const isEmpty = chip?.exceptions?.some(
                            (exc) => exc?.document_detail || exc?.form_detail || exc?.conditional || exc?.tool_detail
                        );
                        const checkTool = one_summary?.total === 0 && one_summary?.tools?.length === 0;
                        return exception && isEmpty ? (
                            <Chip
                                key={chip?.id}
                                sx={{ marginRight: 1, marginBottom: 1 }}
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            color: theme.palette.gray[600],
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {chip.name}
                                        <FiberManualRecordIcon
                                            sx={{
                                                width: 12,
                                                height: 12,
                                                marginLeft: theme.spacing(0.5),
                                            }}
                                            color={'warning'}
                                        />
                                    </Typography>
                                }
                                variant="outlined"
                                size="small"
                            />
                        ) : (
                            <Chip
                                key={chip?.id}
                                sx={{ marginRight: 1, marginBottom: 1 }}
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            color: theme.palette.gray[600],
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {chip?.name}
                                        {checkTool === undefined || checkTool === true ? null : (
                                            <FiberManualRecordIcon
                                                sx={{
                                                    width: 12,
                                                    height: 12,
                                                    marginLeft: theme.spacing(0.5),
                                                }}
                                                color={completed ? 'success' : 'error'}
                                            />
                                        )}
                                    </Typography>
                                }
                                variant="outlined"
                                size="small"
                            />
                        );
                    })}
                    {module_exception?.id === id && (
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: theme.palette.error.main,
                                position: 'absolute',
                                right: 60,
                            }}
                        >
                            <DoNotDisturb sx={{ color: theme.palette.neutral[50] }} fontSize={'12px'} /> Exempted
                        </Typography>
                    )}
                </Stack>
            </Card>
        </Grid>
    );
});

const mapStateToProps = (state) => {
    return {
        all_criteria_summary: state?.complianceReducers?.all_cii_summary,
    };
};

export default connect(mapStateToProps, {})(GroupCard);
