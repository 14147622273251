import {
    ALL_PORTFOLIOS,
    AUDITOR_CERTIFICATIONS,
    AUDITOR_MERCHANTS,
    ONE_AUDITOR,
    AUDITOR_MERCHANT_DOC_MESSAGES,
    AUDITOR_MERCHANT_DOCUMENTS,
    AUDITOR_MERCHANT_DOCUMENTS_VERSIONS,
    AUDITOR_MERCHANT_COMPLIANCE_SUMMARY,
    AUDITOR_MERCHANT_PCIDSS4POINT0_SUMMARY,
    AUDITOR_MERCHANT_FORMS,
    MERCHANT_PENTEST_REQUESTS,
    MERCHANT_ASV_SCANS,
    MERCHANT_SERVER_RESULT_SCAN,
    MERCHANT_PENTEST_UPLOADS,
    AUDITOR_MERCHANT_ASVSCANS_UPLOADS,
    ALL_MERCHANT_SCANS_EXCEPTIONS,
    ALL_MERCHANT_EXEMPTED_SCANS,
    AUDITOR_MERCHANT_NDPR_GENERAL_SUMMARY,
    AUDITOR_MERCHANT_VULNERABILITY_SCANS,
    AUDITOR_MERCHANT_ISO_GENERAL_SUMMARY,
    AUDITOR_MERCHANT_SOC2_GENERAL_SUMMARY,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_PORTFOLIOS:
            return {
                ...state,
                all_portfolios: action.payload,
            };
        case AUDITOR_MERCHANTS:
            return {
                ...state,
                auditor_merchants: action.payload,
            };
        case AUDITOR_CERTIFICATIONS:
            return {
                ...state,
                auditor_certifications: action.payload,
            };
        case ONE_AUDITOR:
            return {
                ...state,
                one_auditor: action.payload,
            };

        case AUDITOR_MERCHANT_DOC_MESSAGES:
            return {
                ...state,
                auditor_merchant_doc_messages: action.payload,
            };

        case AUDITOR_MERCHANT_DOCUMENTS:
            return {
                ...state,
                auditor_merchant_documents: action.payload,
            };
        case AUDITOR_MERCHANT_DOCUMENTS_VERSIONS:
            return {
                ...state,
                auditor_merchant_document_versions: action.payload,
            };
        case AUDITOR_MERCHANT_COMPLIANCE_SUMMARY:
            return {
                ...state,
                auditor_merchant_compliance_summary: action.payload,
            };
        case AUDITOR_MERCHANT_PCIDSS4POINT0_SUMMARY:
            return {
                ...state,
                auditor_merchant_pcidss4point0_summary: action.payload,
            };
        case AUDITOR_MERCHANT_FORMS:
            return {
                ...state,
                auditor_merchant_forms: action.payload,
            };
        case MERCHANT_PENTEST_REQUESTS:
            return {
                ...state,
                merchant_penetration_requests: action.payload,
            };
        case MERCHANT_ASV_SCANS:
            return {
                ...state,
                merchant_asv_scans: action.payload,
            };
        case MERCHANT_SERVER_RESULT_SCAN:
            return {
                ...state,
                merchant_server_scan_result: action.payload,
            };
        case ALL_MERCHANT_SCANS_EXCEPTIONS:
            return {
                ...state,
                all_scans_exceptions: action.payload,
            };
        case ALL_MERCHANT_EXEMPTED_SCANS:
            return {
                ...state,
                all_exempted_scans: action.payload,
            };
        case MERCHANT_PENTEST_UPLOADS:
            return {
                ...state,
                merchant_pentest_uploads: action.payload,
            };
        case AUDITOR_MERCHANT_ASVSCANS_UPLOADS:
            return {
                ...state,
                merchant_asvscans_uploads: action.payload,
            };
        case AUDITOR_MERCHANT_NDPR_GENERAL_SUMMARY:
            return {
                ...state,
                auditor_merchant_ndpr_general_summary: action.payload,
            };
        case AUDITOR_MERCHANT_VULNERABILITY_SCANS:
            return {
                ...state,
                merchant_vulnerability_scans: action.payload,
            };
        case AUDITOR_MERCHANT_ISO_GENERAL_SUMMARY:
            return {
                ...state,
                auditor_merchant_iso_general_summary: action.payload,
            };
        case AUDITOR_MERCHANT_SOC2_GENERAL_SUMMARY:
            return {
                ...state,
                auditor_merchant_soc2_general_summary: action.payload,
            };
        default:
            return state;
    }
};
