import React from 'react';
import { IconButton, Typography, Stack, Box } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useTheme } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import ContentBody from './ContentBody';
import CookiesTable from './CookiesTable';

const CookieNoticeModal = (props) => {
    const { open, handleClose } = props;
    const theme = useTheme();
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            title={
                <Stack direction="row" alignItems="center" gap={1}>
                    <IconButton sx={{ backgroundColor: '#EEF0FF4D' }}>
                        <HiOutlineArrowNarrowLeft />
                    </IconButton>
                    <Typography sx={{ color: theme.palette.primary[20], fontSize: 20, fontWeight: 700 }}>
                        Cookie notice
                    </Typography>
                </Stack>
            }
            headerAction={
                <IconButton onClick={handleClose} sx={{ backgroundColor: '#EEF0FF4D' }}>
                    <Close />
                </IconButton>
            }
            width="720px"
            minWidth="400px"
            maxWidth="unset"
            height="90%"
        >
            <Box sx={{ my: 3 }}>
                <Box>
                    <Typography
                        component="h4"
                        sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            letterSpacing: '0.1px',
                            color: theme.palette.gray[30],
                            mb: 3,
                        }}
                    >
                        Last updated: 25 October, 2023
                    </Typography>
                    <ContentBody title="Who we are">
                        We are SmartComply Technology Solutions Limited (hereinafter referred to as ‘Smartcomply’). Our
                        address is 47, Karimu Ikotun close, Sabo, Yaba, Lagos, Nigeria. You can contact us at the above
                        address, by email at{' '}
                        <a href="mailto:support@smartcomplyapp.com" target="_blank" rel="noreferrer">
                            support@smartcomplyapp.com
                        </a>{' '}
                        or by telephone on +234 813 326 2024.
                    </ContentBody>
                    <ContentBody title="Cookie Notice Overview">
                        This Cookie Notice explains what Cookies are and how we use them. Cookies do not typically
                        contain any information that personally identifies a user. However, personal information that we
                        store about you may be linked to the information stored in and obtained from Cookies. For more
                        details on how We use, store and keep your data secure, see our Privacy Notice.
                    </ContentBody>
                    <ContentBody title="We use the following cookies on our Website">
                        <p className="mb-3">
                            <strong> I. Necessary cookies</strong>: These cookies are essential for the webapp to
                            function properly and provide basic services.{' '}
                        </p>
                        <p className="mb-3">
                            <strong>II. Analytics cookies</strong>: They are used to understand how visitors interact
                            with the Website. These cookies help provide information on metrics the number of visitors,
                            bounce rate, traffic source, etc.
                        </p>{' '}
                        <p className="mb-3">
                            <strong>III. Functional cookies</strong>: They help to perform certain functionalities like
                            sharing the Website's content on social media platforms, collecting feedback, and other
                            third-party features.
                        </p>
                        <p>We use cookie on our Web-app as described below:</p>
                    </ContentBody>
                    <CookiesTable />
                    <ContentBody title="How to Manage and Delete Your Cookie">
                        <p>
                            Unless you have adjusted your browser settings to refuse cookies, our system will issue
                            cookies when you direct your browser to our Website/Webapp. You can refuse to accept cookies
                            by activating the appropriate setting on your browser. If you do not wish to accept cookies
                            connected with your use of our Website/Web-app, you may delete and block or disable cookies
                            via your browser settings; see below for more information on how to do this.:
                        </p>
                        <ul className="ml-3 list-disc mb-4">
                            <li className="underline">
                                <a
                                    href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Firefox
                                </a>
                            </li>
                            <li className="underline">
                                <a
                                    href="https://privacy.microsoft.com/en-us/privacystatement"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Internet explorer
                                </a>
                            </li>
                            <li className="underline">
                                <a
                                    href="https://support.google.com/chrome/answer/95647?hl=en"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Google chrome
                                </a>
                            </li>
                        </ul>
                        <p>
                            If you have any questions or complaints about this Cookie Notice, you can contact us by
                            email at{' '}
                            <a className="underline" href="mailto:ope@smartcomplyapp.com.">
                                ope@smartcomplyapp.com.
                            </a>
                        </p>
                        <p>
                            For this Notice, the supervisory authority is the Nigeria Data Protection Commission (NDPC),
                            and the complaint can be sent via email at{' '}
                            <a className="underline" href="mailto:info@ndpc.gov.ng" target="_blank" rel="noreferrer">
                                info@ndpc.gov.ng
                            </a>
                        </p>
                    </ContentBody>
                </Box>
            </Box>
        </AppCenteredModal>
    );
};

export default CookieNoticeModal;
