import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

//redux
import { connect } from 'react-redux';

//translations
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import CloseIcon from '@mui/icons-material/Close';
import { accept } from 'validate';
// import { toast } from 'react-toastify';
import FolderIcon from 'assets/img/folder.svg';
import { ExemptMerchantDocument } from 'store/actions/complianceActions';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import Img from 'assets/img/img.svg';
import LightIcon from 'assets/img/Light.svg';
import { UploadMerchantEvidence } from 'store/actions/complianceActions';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { UploadReportFile } from 'store/actions/auditActions';
// import AppCircularProgress from 'components/new_components/AppCircularProgress';
import { UpdateMerchantEvidence } from 'store/actions/complianceActions';
import { DeleteMerchantEvidence } from 'store/actions/complianceActions';

const UploadDocumentModal = (props) => {
    const {
        open,
        handleClose,
        UploadMerchantEvidence,
        control,
        doc_id,
        UploadReportFile,
        segment,
        all_merchant_evidences,
        UpdateMerchantEvidence,
        segmentNumber,
        DeleteMerchantEvidence,
    } = props;
    // state
    const [documents, setDocuments] = useState([]);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [deleting, setDeleting] = useState(false);

    // get one evidence
    const one_evidence = all_merchant_evidences?.find((evidence) => evidence?.evidence_id === doc_id);

    const dragEnter = () => {
        const target = document.getElementById('drop-zones');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zones');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                setDocuments([...documents, reader.result]);
            };
            reader.readAsDataURL(file);
            convertedBase64(file).then(() => {});
        } else {
            // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
        }
        dragLeave();
    };

    const uploadImage = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        const fileSize = file.size / 1000 / 1000;
        if (fileSize > 2) {
            toast.info('File too large, minimum of 5MB');
            setDocuments('');
        } else {
            const formData = new FormData();
            formData.append('report', event.target.files[0]);
            setUploading(true);
            const res = await UploadReportFile(formData);
            setUploading(false);
            if (res?.success) {
                setFiles([...files, res?.report]);
                setDocuments([...documents, event.target.files[0]]);
                event.target.value = null;
            }
            // const base64 = await convertedBase64(file);
        }
    };
    const convertedBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const filReader = new FileReader();
            filReader.readAsDataURL(file);
            filReader.onload = () => {
                resolve(filReader.result);
            };
            filReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onClose = () => {
        setFiles([]);
        setDocuments([]);
        handleClose();
    };

    const handleSubmit = async () => {
        const data = [{ [segment]: files }];
        const doc = new FormData();
        doc.append('data', data);
        doc.append('evidence_id', doc_id);

        const body = {
            data,
            evidence_id: doc_id,
        };

        setLoading(true);
        const res = one_evidence?.id
            ? await UpdateMerchantEvidence(body, control, one_evidence?.id)
            : await UploadMerchantEvidence(body, control);
        setLoading(false);
        //handling response
        if (res?.success) {
            toast.success('Evidence uploaded succesfully');
            // setDocument(null);
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    const removeDocument = async (index, document) => {
        const filesCopy = [...files];
        const documentsCopy = [...documents];
        filesCopy?.splice(index, 1);
        documentsCopy?.splice(index, 1);
        if (document?.name) {
            setFiles(filesCopy);
            setDocuments(documentsCopy);
        } else {
            setDeleting(true);
            const data = [{ [segment]: filesCopy }];
            const doc = new FormData();
            doc.append('data', data);
            doc.append('evidence_id', doc_id);

            const body = {
                data,
                evidence_id: doc_id,
            };
            const res =
                files?.length === 1
                    ? await DeleteMerchantEvidence(one_evidence?.id, control)
                    : await UpdateMerchantEvidence(body, control, one_evidence?.id);
            setDeleting(false);
            if (res?.success) {
                setFiles(filesCopy);
                setDocuments(documentsCopy);
                toast.success('Document deleted successfully!');
            } else {
                toast.error(res?.message);
            }
        }
    };

    useEffect(() => {
        const one_evidence = all_merchant_evidences?.find((evidence) => evidence?.evidence_id === doc_id);
        if (one_evidence && open) {
            setFiles(one_evidence?.data[segment === 'segment_1' ? 0 : segment === 'segment_2' ? 1 : 2]?.[segment]);
            setDocuments(one_evidence?.data[segment === 'segment_1' ? 0 : segment === 'segment_2' ? 1 : 2]?.[segment]);
        }
    }, [open]);
    return (
        <AppCenteredModal
            open={open}
            handleClose={onClose}
            title={
                <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>Upload evidence</Typography>
            }
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        backgroundColor: '#F8FAFC',
                        py: 2,
                        pr: 3,
                        mx: '-2rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="center"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading || uploading || deleting}
                    >
                        Submit
                    </LoadingButton>
                </Box>
            }
            sx={{ borderRadius: 1 }}
            headerAction={
                <Box
                    sx={{
                        border: '1px solid #E2E8F0',
                        borderRadius: 30,
                        width: 20,
                        height: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    <CloseIcon sx={{ width: 12, height: 12, color: '#334155' }} />
                </Box>
            }
            width="550px"
            height="550px"
            maxHeight={'550px'}
        >
            <Box sx={{ my: 2 }}>
                {/* {evidence_type !== 'soc2' && ( */}
                <Box
                    sx={{
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #F0F0FA',
                        display: 'flex',
                        alignItems: 'flex-start',
                        my: 1.5,
                        py: 1.5,
                        px: 2,
                        borderRadius: 1,
                    }}
                >
                    <img src={LightIcon} alt={'LightIcon'} />
                    <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '13px', ml: 1 }}>
                        {/* You can upload multiple evidence at once for a segment */}
                        You can upload up to {segmentNumber} evidences at once
                    </Typography>
                </Box>
                {/* )} */}
                <Box
                    sx={{
                        // border: `3px dashed ${errors[name] ? '#D91B1B' : theme.palette.primary[900]}`,
                        border: '1px dashed #CBD5E1',
                        borderRadius: 2,
                        padding: 1,
                        minHeight: 150,
                        position: 'relative',
                        mt: 4,
                    }}
                >
                    <Box
                        id="drop-zones"
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={handleDrop}
                        onDragOver={dragOver}
                        sx={{ height: '100%', p: 2 }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <img src={FolderIcon} alt={FolderIcon} />
                            <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 500, mt: 2 }}>
                                Drop your files or click to upload
                            </Typography>
                            <Typography sx={{ color: '#94A3B8', fontSize: '12px', fontWeight: 400 }}>
                                Supported file types: ALL
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                            <label htmlFor="files-inputs">
                                <Box
                                    sx={{
                                        border: '1px solid #E2E8F0',
                                        cursor: 'pointer',
                                        padding: 0.5,
                                    }}
                                >
                                    <Typography sx={{ color: '#475569', fontWeight: 400, fontSize: 12 }}>
                                        Browse
                                    </Typography>
                                </Box>
                            </label>
                            <input
                                type="file"
                                id="files-inputs"
                                accept={accept}
                                onChange={(e) => uploadImage(e)}
                                hidden
                            />
                        </Box>
                        <input type="file" accept={accept} hidden id="files-inputs" />
                    </Box>
                </Box>

                {documents?.length > 0 && (
                    <Box>
                        {documents.map((document, index) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 3,
                                    }}
                                    key={index}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        key={index}
                                    >
                                        <img
                                            src={
                                                (document.name || document)?.split('.').pop() === 'xlsx'
                                                    ? Xsl
                                                    : (document.name || document)?.split('.').pop() === 'csv'
                                                    ? Xsl
                                                    : (document.name || document)?.split('.').pop() === 'docx'
                                                    ? Docx
                                                    : (document.name || document)?.split('.').pop() === 'png'
                                                    ? Png
                                                    : (document.name || document)?.split('.').pop() === 'img'
                                                    ? Img
                                                    : FileTypes
                                            }
                                            alt="PdfIcon"
                                        />
                                        <Box sx={{ ml: 1 }}>
                                            <Typography
                                                sx={{
                                                    color: '#475569',
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    maxWidth: '300px !important',
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                {document.name || document?.split('/')?.pop()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* {(new_index === index && uploading) || deleting ? (
                                        <AppCircularProgress sx={{ color: '#3B82F6' }} />
                                    ) : ( */}
                                    <CloseIcon
                                        onClick={() => removeDocument(index, document)}
                                        sx={{ cursor: 'pointer' }}
                                    />
                                    {/* )} */}
                                </Box>
                            );
                        })}
                    </Box>
                )}
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        employee_id: state?.authReducers?.user_info?.employee?.id,
        user: state?.authReducers?.user_info,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
    };
};

export default connect(mapStateToProps, {
    UploadMerchantEvidence,
    ExemptMerchantDocument,
    UploadReportFile,
    UpdateMerchantEvidence,
    DeleteMerchantEvidence,
})(UploadDocumentModal);
