import React from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import PentestCard from './components/PentestCard';
import AwarenessTrainingCard from './components/AwarenessTrainingCard';

const FourthPage = ({ dashboardResult }) => {
    return (
        <Grid container direction="column" sx={{ mb: 2, mt: 4 }} spacing={2}>
            <Grid item xs={12} md={6} lg={7}>
                <PentestCard dashboardResult={dashboardResult} />
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
                <AwarenessTrainingCard />
            </Grid>
        </Grid>
    );
};
const mapStateToProps = (state) => {
    return {
        dashboardResult: state?.dashboardReducers?.dashboardResult,
    };
};
export default connect(mapStateToProps, {})(FourthPage);
