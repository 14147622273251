import React, { useEffect } from 'react';
import { LabelTag } from '../../dashboard-utils/DashboardUtils';
// import DashboardASVModal from '../../OtherReports/DashboardASVModal';
// import DashboardPenTestModal from '../../OtherReports/DashboardPenTestModal';
import { formatDateObjectHandler, isFullFunctionPlan } from '../../../../utils';
import DisabledFeatureMsg from '../../OtherReports/DisabledFeatureMsg';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppColorTag from 'components/new_components/AppColorTag';
//redux
import { connect } from 'react-redux';
import { GetMerchantASVscanRequests } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const PenetrationResult = ({
    pricing_plan,
    dashboardResult,
    GetMerchantASVscanRequests,
    merchant_asvscans_requests,
}) => {
    const router = useHistory();
    const gotoPage = (link) => {
        router.push(link);
    };

    //translation
    const { t } = useTranslation('overview');

    // useEffects
    useEffect(() => {
        const getMerchantASVscanRequests = async () => {
            const res = await GetMerchantASVscanRequests();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getMerchantASVscanRequests();
    }, []);

    const passed_pciscans = merchant_asvscans_requests?.filter((request) => request?.status === true);
    const failed_pciscans = merchant_asvscans_requests?.filter((request) => !request?.status);

    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonthForPentest = date.getMonth() <= 6 ? 'Jan-Jun' : 'Jul-Dec';
    const currentMonthForPciscans =
        date.getMonth() <= 3
            ? 'Jan-Mar'
            : date.getMonth() > 3 && date.getMonth() <= 6
            ? 'Apr-June'
            : date.getMonth() > 6 && date.getMonth() <= 9
            ? 'July-Sept'
            : date.getMonth() > 9 && date.getMonth() <= 12
            ? 'Oct-Dec'
            : null;
    return (
        <>
            <div className={`border-b-4 border-b-[#EEF0FF] ${pricing_plan?.tag !== 'main' && 'h-[160px]'}`}>
                <div className="flex flex-col py-3 px-3">
                    <div className="flex items-center justify-between">
                        <div className="text-[13px] textbrandColor font-bold">{t('pentestResult.pentest')}</div>
                        {isFullFunctionPlan(pricing_plan?.tag) && (
                            <>
                                <div className="text-[12px]">
                                    <select
                                        className="border border-[#0E2C66] rounded-md"
                                        disabled={true}
                                        value={currentMonthForPentest}
                                    >
                                        <option value="Jan-Jun">Jan - Jun</option>
                                        <option value="Jul-Dec">Jul - Dec</option>
                                    </select>
                                </div>
                                <div className="text-[12px]">
                                    <select
                                        className="border border-[#0E2C66] rounded-md"
                                        value={parseInt(currentYear)}
                                        disabled={true}
                                    >
                                        <option value="2024">2024</option>
                                        <option value="2023">2023</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                    {isFullFunctionPlan(pricing_plan?.tag) ? (
                        <>
                            <div className="flex flex-row items-center flex-wrap my-3 w-full">
                                <div className="mr-2 w-[fit-content]">
                                    <span className="text-[12px] text-[#0E2C66] font-bold">
                                        {t('pentestResult.severity')}
                                    </span>
                                </div>
                                <div className="flex flex-row items-center pt-2">
                                    <AppColorTag
                                        text={t('pentestResult.low')}
                                        type="success"
                                        frequency={dashboardResult?.pentest?.low}
                                        fontSize={10}
                                        fontWeight={500}
                                        margin={0}
                                    />
                                    <AppColorTag
                                        text={t('pentestResult.medium')}
                                        type="warning"
                                        frequency={dashboardResult?.pentest?.medium}
                                        fontSize={10}
                                        fontWeight={500}
                                        margin={0}
                                    />
                                    <AppColorTag
                                        text={t('pentestResult.high')}
                                        type="high"
                                        frequency={dashboardResult?.pentest?.high}
                                        fontSize={10}
                                        fontWeight={500}
                                        margin={0}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center my-3">
                                <div className="text-[11px] text-[#77777A] font-medium mr-2">
                                    {t('pentestResult.nextPentest')}
                                </div>
                                <div className="bg-[#EEF0FF] text-[12px] rounded-md p-1 px-2">
                                    <span className="textbrandColor font-medium">
                                        {formatDateObjectHandler(
                                            dashboardResult?.pentest?.next_scan_date,
                                            'MMMM Do, YYYY'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="flex justify-end cursor-pointer textbrandColor"
                                onClick={() => gotoPage('/merchant/pentest')}
                            >
                                <span className="underline font-[600] text-[12px]">{t('pentestResult.viewTest')}</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <DisabledFeatureMsg />
                        </>
                    )}
                </div>
            </div>
            <div className="">
                <div className="py-3 px-3">
                    <div className="flex items-center justify-between">
                        <div className="text-[13px] textbrandColor font-bold">{t('pentestResult.pciScan')}</div>
                        {isFullFunctionPlan(pricing_plan?.tag) && (
                            <>
                                <div className="text-[12px]">
                                    <select
                                        className="border border-[#0E2C66] rounded-md"
                                        value={currentMonthForPciscans}
                                        disabled={true}
                                    >
                                        <option value="Jan-Mar">Jan - Mar</option>
                                        <option value="Apr-June">Apr - June</option>
                                        <option value="July-Sept">July - Sept</option>
                                        <option value="Oct-Dec">Oct - Dec</option>
                                    </select>
                                </div>
                                <div className="text-[12px]">
                                    <select
                                        className="border border-[#0E2C66] rounded-md"
                                        value={parseInt(currentYear)}
                                        disabled={true}
                                    >
                                        <option value="2024">2024</option>
                                        <option value="2023">2023</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                    {isFullFunctionPlan(pricing_plan?.tag) ? (
                        <>
                            <div className="flex items-center mt-3 mb-2">
                                <div className="mr-3 ">
                                    <span className="text-[12px] text-[#0E2C66] font-bold">
                                        {t('pentestResult.status')}
                                    </span>
                                </div>
                                <LabelTag
                                    label={`${passed_pciscans?.length} ${t('pentestResult.passed')}`}
                                    labelStyle="mr-2"
                                    type="primary"
                                />
                                <LabelTag label={`${failed_pciscans?.length} ${t('pentestResult.failed')}`} />
                            </div>
                            <div className="flex items-center my-2">
                                <div className="text-[12px] text-[#77777A] font-medium mr-2">
                                    {t('pentestResult.nextScan')}
                                </div>
                                <div className="bg-[#EEF0FF] text-[12px] rounded-md p-1 px-2">
                                    <span className="textbrandColor font-medium">
                                        {formatDateObjectHandler(
                                            dashboardResult?.asv?.next_scan_date,
                                            'MMMM Do, YYYY'
                                        ) || t('pentestResult.viewScan')}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="flex justify-end cursor-pointer textbrandColor"
                                onClick={() => gotoPage('/merchant/pciscans')}
                            >
                                <span className="underline font-[600] text-[12px]">{t('pentestResult.viewScan')}</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <DisabledFeatureMsg />
                        </>
                    )}
                </div>
            </div>

            {/* <DashboardPenTestModal isOpen={visible} closeDrawer={closeDrawer} /> */}
            {/* <DashboardASVModal isScanVisible={isScanVisible} closeScanDrawer={closeScanDrawer} /> */}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_asvscans_requests: state?.merchantReducers?.merchant_asvscans_requests,
    };
};
export default connect(mapStateToProps, { GetMerchantASVscanRequests })(PenetrationResult);
