import {
    USER_DETAILS,
    USER_TYPE,
    USER_ID,
    COMPANY_ID,
    USER_PRICING_PLANS,
    USER_INFO,
    COMPANY_DETAILS,
    FIRST_LOGIN,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                user_info: action.payload,
            };
        case FIRST_LOGIN:
            return {
                ...state,
                first_login: action.payload,
            };
        case USER_DETAILS:
            return {
                ...state,
                user_details: action.payload,
            };
        case USER_TYPE:
            return {
                ...state,
                user_type: action.payload,
            };
        case USER_ID:
            return {
                ...state,
                user_id: action.payload,
            };
        case COMPANY_ID:
            return {
                ...state,
                company_id: action.payload,
            };
        case USER_PRICING_PLANS:
            return {
                ...state,
                pricing_plan: action.payload,
            };
        case COMPANY_DETAILS:
            return {
                ...state,
                company_details: action.payload,
            };
        default:
            return state;
    }
};
