import React from 'react';
import { FormHelperText } from '@mui/material';
// formik
import { useFormikContext } from 'formik';
// core components
import { Box } from '@mui/material';
import AppInput from '../AppInput';
import { useTheme } from '@mui/material/styles';
//utils
import { formatSentence } from 'utils';
import AppButton from '../AppButton';

const AppAssetInput = (props) => {
    const { name, isAddModal, ...restProps } = props;

    const theme = useTheme();

    // get the formik context values
    const { setFieldTouched, setFieldValue, values, touched, errors } = useFormikContext();

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AppInput
                    disabled
                    value={values[name] || ''}
                    onBlur={() => setFieldTouched(name)}
                    error={touched[name] && errors[name]}
                    {...restProps}
                />
                <div style={{ marginTop: '44px', marginLeft: '0.9rem' }}>
                    <AppButton
                        onClick={() => {
                            let id = Math.floor(1000 + Math.random() * 9000);
                            setFieldValue(name, id);
                        }}
                        name="Generate"
                        sx={{
                            color: theme.palette.white.main,
                            backgroundColor: theme.palette.primary[700],
                            '&:hover': {
                                backgroundColor: theme.palette.primary[700],
                            },
                        }}
                        disabled={!isAddModal}
                        color="primary"
                        type="button"
                    />
                </div>
            </Box>
            {touched[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};

export default AppAssetInput;
