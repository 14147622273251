import React, { useMemo } from 'react';
import { Stack, Box } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import ResponseItemContainer from './Components/ResponseItemContainer';
import ResponseBody from './Components/components/ResponseBody';
import ResponseHeader from './Components/components/ResponseHeader';

const VendorResponseView = (props) => {
    const theme = useTheme();
    const {
        questionnaire_forms,
        all_responses,
        activeTab,
        setActiveTab,
        openDrawer,
        vendor,
        handleChangeView,
        goBack,
    } = props;

    const currentResponse = useMemo(() => {
        return all_responses?.find((response) => response?.id === activeTab);
    }, [activeTab]);
    const currentQuestion = useMemo(() => {
        return questionnaire_forms?.find((question) => question?.title === currentResponse.form_title);
    }, [currentResponse]);

    return (
        <>
            <Box
                sx={{
                    height: 'calc(100vh - 114px)',
                    width: 'calc(100% + 48px)',
                    display: 'flex',
                    alignItems: 'center',
                    ml: '-24px',
                    mt: '-24px',
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        width: '100%',
                        position: 'relative',
                        height: '100%',
                    }}
                >
                    <Box
                        component="nav"
                        sx={{
                            width: { md: 290 },
                            flexShrink: { md: 0 },
                            height: 'calc(100% + 24px)',
                            overflow: 'hidden',
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary[900] + '30',
                                borderRadius: '10px',
                            },
                        }}
                    >
                        <ResponseItemContainer
                            largeScreen={true}
                            all_responses={all_responses}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            handleChangeView={handleChangeView}
                            goBack={goBack}
                            vendor={vendor}
                        />
                    </Box>
                    <Stack
                        sx={{
                            height: 'calc(100% + 24px)',
                            width: '100%',
                        }}
                    >
                        <ResponseHeader
                            id={currentResponse?.id}
                            key={currentResponse?.id}
                            activeTab={activeTab}
                            title={currentResponse?.form_title}
                            value={currentResponse?.score_percent}
                            openDrawer={openDrawer}
                            score_percent={currentResponse?.score_percent}
                            risk_level={currentResponse?.risk_level}
                        />
                        <ResponseBody
                            key={currentQuestion?.id}
                            activeTab={activeTab}
                            title={currentResponse?.form_title}
                            questionID={currentQuestion?.id}
                            question={currentQuestion}
                            answers={currentResponse?.answer}
                        />
                    </Stack>
                </Stack>
            </Box>
        </>
    );
};

export default VendorResponseView;
