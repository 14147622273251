import {
    USER_INFO,
    APPROVED_AUDITORS,
    AUDITOR_POOL_CERTIFICATIONS,
    AUDITOR_POOL_PORTFOLIOS,
    MODULE_GUIDE,
    USER_TYPE,
    USER_ID,
    COMPANY_ID,
    COMPANY_DETAILS,
    USER_PRICING_PLANS,
    ALL_MERCHANT_EMPLOYEES,
    GET_ALL_EMPLOYEES,
} from '../constants';
import axiosInstance from '../../utils/https';
import { getType } from 'utils';

export const GetUserDetails = () => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id || getType('id');
    try {
        const res = await axiosInstance().get(`/user_mgt/users/${user_id}/`, {});
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: USER_INFO, payload: res?.data });
        dispatch({ type: USER_PRICING_PLANS, payload: res?.data?.organization?.merchant_plan });
        dispatch({
            type: USER_TYPE,
            payload: res?.data?.user_type,
        });
        dispatch({ type: USER_ID, payload: res?.data?.id });
        dispatch({
            type: COMPANY_ID,
            payload: res?.data?.organization?.id,
        });
        dispatch({
            type: COMPANY_DETAILS,
            payload: res?.data?.organization,
        });
        return {
            success: true,
            message: res?.message,
            user_info: res?.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateUserDetails = (credentials) => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/users/${user_id}/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserDetails());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ChangePhoto = (credentials) => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/users/${user_id}/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserDetails());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdatePassword = (credentials) => async (_, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().put(`/setup/change_password/${user_id}/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ConfirmPassword = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`/user_mgt/confirm_password/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const Initiate2FA = (credentials) => async () => {
    try {
        const res = await axiosInstance().post(`/user_mgt/mfa_connect/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        return credentials?.mfa_type === 'authenticator'
            ? {
                  success: true,
                  message: res?.message,
                  url: res?.data?.url,
                  secret: res?.data?.secret,
              }
            : { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const Finalize2FASetup = (credentials) => async (dispatch, getState) => {
    const user_id = getState()?.authReducers?.user_id;
    try {
        const res = await axiosInstance().put(`/user_mgt/finalize_mfa_connect/${user_id}/`, credentials);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetUserDetails());
        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
// auditor pool
export const GetApprovedAuditors = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/auditors/?is_approved=true`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: APPROVED_AUDITORS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorCertifications = (auditor_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/certificates/?auditor=${auditor_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUDITOR_POOL_CERTIFICATIONS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorPortfolio = (auditor_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/portfolios/?auditor=${auditor_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUDITOR_POOL_PORTFOLIOS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const RequestAuditor = (credentials) => async (dispatch, getState) => {
    const merchant_id = getState()?.generalReducers?.user_info?.organization?.id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/merchants/${merchant_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetApprovedAuditors());
        dispatch(GetUserDetails());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//M O D U L E   G U I D E   M O D A L S
export const GetModuleGuides = () => async (dispatch, getState) => {
    const user_id = getState()?.generalReducers?.user_info?.id;
    try {
        const res = await axiosInstance().get(`/user_mgt/user_modal/?user=${user_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MODULE_GUIDE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateModuleGuide = (credentials) => async (dispatch, getState) => {
    const user = getState()?.generalReducers?.user_info?.id;
    try {
        const res = await axiosInstance().post(`/user_mgt/user_modal/`, { ...credentials, user });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetModuleGuides());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UpdateModuleGuide = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/user_modal/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetModuleGuides());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAllMerchantEmployees = (id) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id || id;
    try {
        const res = await axiosInstance().get(`/user_mgt/get_employees/?organization_id=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_EMPLOYEES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};
export const GetAllCompanyEmployees = () => async (dispatch, getState) => {
    const company_id = getState()?.authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/user_mgt/get_employees/?organization_id=${company_id}`);
        if (res?.status.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: GET_ALL_EMPLOYEES, payload: res?.data });
        return { success: true, message: res?.message };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
