import { SELECT_GUIDE_STEPS, ENABLE_GUIDE_STEPS, USERGUIDE_INFO } from '../constants';

const initialState = {
    all_guides: {
        overview: [
            {
                target: '.test',
                content: 'We just added a  new feature to guide you through when using the app!',
                disableBeacon: true,
                placement: 'top',
            },
            {
                target: '.test-2',
                content: 'Click on this tabs to navigate between standards',
                placement: 'top',
            },
        ],
        users: [
            {
                target: '.test',
                content: 'We just added a  new feature to guide you through when using the app!',
                disableBeacon: true,
                placement: 'top',
            },
            {
                target: '.test-2',
                content: 'Click on this tabs to navigate between standards',
                placement: 'top',
            },
        ],
        activities: [
            {
                target: '.test',
                content: 'We just added a  new feature to guide you through when using the app!',
                disableBeacon: true,
                placement: 'top',
            },
            {
                target: '.test-2',
                content: 'Click on this tabs to navigate between standards',
                placement: 'top',
            },
        ],
        welcome: [
            {
                target: '.guide_trigger_button',
                content: 'Click this Icon to view the guides for each page',
                disableBeacon: true,
                placement: 'bottom',
            },
        ],
    },
    selected_guides: [],
    show_guide: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ENABLE_GUIDE_STEPS:
            return {
                ...state,
                show_guide: action?.payload,
            };
        case SELECT_GUIDE_STEPS:
            return {
                ...state,
                selected_guides: state.all_guides[action.payload],
            };
        case USERGUIDE_INFO:
            return {
                ...state,
                userguide_info: action?.payload,
            };
        default:
            return state;
    }
};
