import React, { useState } from 'react';
// core components
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import PageHeader from 'components/new_components/PageHeader';
import IntegrationsLayout from '../IntegrationsLayout/IntegrationsLayout';
import Integrations from '../Integrations';
import Infrastructures from 'views/merchant/Inventory/Infrastructures';
import { Button, useTheme } from '@mui/material';

//translation
import { PlusSquareOutlined } from '@ant-design/icons';

const Setup = () => {
    const theme = useTheme();
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [modal, setModal] = useState(false);

    // functions
    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    const RequestToolButton = () => {
        return (
            <Button
                sx={{
                    background: 'transparent',
                    textTransform: 'capitalize',
                    color: theme.palette.primary[20],
                    display: 'flex',
                    gap: 0.5,
                    alignItems: 'center',
                }}
                onClick={openModal}
            >
                <PlusSquareOutlined className="text-[#202d66]" />
                Request Integration
            </Button>
        );
    };

    return (
        <>
            <PageHeader browserTitle="Setup | Smartcomply" />
            <IntegrationsLayout
                onTabChange={handleTabChange}
                tab={currentTab}
                tabAction={currentTab == 0 && <RequestToolButton />}
                open={modal}
                closeToolModal={closeModal}
            >
                <AppTabPanel value={currentTab} index={0}>
                    <Integrations />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <Infrastructures />
                </AppTabPanel>
            </IntegrationsLayout>
        </>
    );
};

export default Setup;
