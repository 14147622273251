import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';

const InfrastructureTableActions = (props) => {
    const theme = useTheme();
    const { row, openViewModal, openDeleteModal, openEditModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('inventory');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const handleOpenViewModal = () => {
        openViewModal(row);
        closeMenu();
    };

    const handleOpenEditModal = () => {
        const payload = {
            asset_number: row?.asset_number,
            department: row?.department,
            ip_address: row?.ip_address,
            ip_type: row?.ip_type,
            location: row?.location,
            sensitivity: row?.sensitivity.toLowerCase(),
        };
        openEditModal('edit', payload, row?.id);
        closeMenu();
    };
    const handleOpenDeleteModal = () => {
        openDeleteModal(row?.id);
        closeMenu();
    };

    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem onClick={handleOpenViewModal}>{t('infrastructureTable.tableAction.view')}</MenuItem>
                <MenuItem onClick={handleOpenEditModal}>{t('infrastructureTable.tableAction.edit')}</MenuItem>
                <MenuItem onClick={handleOpenDeleteModal}>{t('infrastructureTable.tableAction.delete')}</MenuItem>
            </Menu>
        </>
    );
};

export default InfrastructureTableActions;
