import React from 'react';

// mui components
import { Box, Typography } from '@mui/material';

// core component
import FieldItem from './FieldItem';

const Questionnaire = (props) => {
    // props
    const { questionnaire, readonly, handleFileUpload, uploading, files } = props;

    //translation
    const language = localStorage.getItem('i18nextLng');

    return (
        <Box
            sx={{
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                paddingBottom: '2rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    boxShadow: 'none',
                    borderBottom: '2px solid #B8BCCC',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: '18px',
                        color: '#0E2C66',
                        py: '17px',
                        px: '20px',
                        borderBottom: '4px solid #0E2C66',
                    }}
                >
                    {language?.includes('fr')
                        ? questionnaire?.title_fr
                        : language?.includes('en')
                        ? questionnaire?.title_en
                        : questionnaire?.title}
                </Typography>
            </Box>
            <Box sx={{ p: '15px 30px' }}>
                {questionnaire?.form_fields
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((field) => {
                        return (
                            <Box key={field.id}>
                                <FieldItem
                                    type={field?.type}
                                    required={field?.required}
                                    name={field.id?.toString()}
                                    options={field?.options}
                                    label={
                                        language?.includes('fr')
                                            ? field.name_fr
                                            : language?.includes('en')
                                            ? field.name_en
                                            : field.name
                                    }
                                    disabled={readonly}
                                    uploading={uploading[field?.id]}
                                    handleFileUpload={handleFileUpload}
                                    files={files}
                                />
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default Questionnaire;
