import React, { useState } from 'react';
import { Select, MenuItem, Button, Box, Typography } from '@mui/material';
import { AppFormInput, AppFormSelect, AppFormContext } from 'components/new_components/forms';
import { FileUpload, Download, CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

//translation
import { useTranslation } from 'react-i18next';

const FieldItem = (props) => {
    const {
        type,
        required,
        name,
        id,
        options,
        value,
        onTextChange,
        label,
        disabled,
        handleFileUpload,
        uploading,
        files,
    } = props;

    // TODO: This is a quick fix to get the value of the file input from Formik, fix - createa file input components that integrates with formik.
    const [values, setValues] = useState({});

    //translation
    const { t } = useTranslation('vendorManagement');

    return (
        <AppFormContext getValues={setValues}>
            {type === 'text' ? (
                <AppFormInput
                    name={name}
                    required={required}
                    label={label}
                    disabled={disabled}
                    placeholder={label}
                    labelStyle={{
                        fontWeight: '500 !important',
                        fontSize: '16px !important',
                        color: '#46464A !important',
                    }}
                    sx={{
                        width: '500px',
                    }}
                />
            ) : type === 'file' ? (
                <>
                    {disabled ? (
                        <Button
                            variant="contained"
                            style={{
                                margin: '.5rem',
                                marginLeft: '0',
                                padding: '.6rem',
                                width: '100%',
                                marginTop: '1rem',
                            }}
                            href={value}
                            download
                            startIcon={<Download />}
                            target="_blank"
                            rel="noreferer"
                        >
                            {t('vendorQuestionnaire.download')}
                        </Button>
                    ) : (
                        <>
                            <Box sx={{ fontWeight: 500, fontSize: '16px', color: '#46464A', mt: '15px' }}>{label}</Box>
                            <label htmlFor={id}>
                                <input
                                    id={id}
                                    name={name}
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(e.target.files[0], name)}
                                />
                                <LoadingButton
                                    component="span"
                                    loading={uploading}
                                    style={{ padding: '.6rem', marginTop: '1rem' }}
                                    startIcon={
                                        files?.[name] || values?.[name] ? (
                                            <CheckCircle color="success" />
                                        ) : (
                                            <FileUpload />
                                        )
                                    }
                                    loadingPosition="start"
                                    variant="outlined"
                                    sx={{ width: '200px', borderRadius: '10px' }}
                                    onChange={(e) => handleFileUpload(e.target.files[0], name)}
                                >
                                    {files?.[name] || values?.[name]
                                        ? t('vendorQuestionnaire.uploaded')
                                        : t('vendorQuestionnaire.uploadDocument')}
                                </LoadingButton>
                                <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#0E2C66', mt: '15px' }}>
                                    {files?.[name] || values?.[name]}
                                </Typography>
                            </label>
                        </>
                    )}
                </>
            ) : type === 'number' ? (
                <AppFormInput
                    name={name}
                    required={required}
                    label={label}
                    type="number"
                    placeholder={label}
                    disabled={disabled}
                    labelStyle={{ fontWeight: 500, fontSize: '16px', color: '#46464A' }}
                    sx={{ width: '500px' }}
                />
            ) : type === 'textarea' ? (
                <AppFormInput
                    name={name}
                    required={required}
                    label={label}
                    multiline
                    placeholder={label}
                    disabled={disabled}
                    labelStyle={{ fontWeight: 500, fontSize: '16px', color: '#46464A' }}
                    sx={{
                        width: '500px',
                    }}
                />
            ) : type === 'date' ? (
                <AppFormInput
                    name={name}
                    required={required}
                    label={label}
                    type="date"
                    disabled={disabled}
                    labelStyle={{ fontWeight: 500, fontSize: '16px', color: '#46464A' }}
                    sx={{
                        width: '500px',
                    }}
                />
            ) : type === 'time' ? (
                <AppFormInput
                    name={name}
                    required={required}
                    label={label}
                    type="time"
                    disabled={disabled}
                    labelStyle={{
                        fontWeight: '500 !important',
                        fontSize: '16px !important',
                        color: '#46464A !important',
                    }}
                    sx={{
                        width: '500px',
                    }}
                />
            ) : type === 'checkboxes' ? (
                <>
                    <AppFormSelect
                        name={name}
                        label={label}
                        options={options?.split(',')}
                        defaultValue={t('vendorQuestionnaire.value')}
                        multiple
                        disabled={disabled}
                        labelStyle={{
                            fontWeight: '500 !important',
                            fontSize: '16px !important',
                            color: '#46464A !important',
                        }}
                        sx={{
                            width: '500px',
                        }}
                    />
                </>
            ) : type === 'radio' ||
              type === 'select' ||
              type.toLowerCase() === 'dropdown' ||
              type.toLowerCase() === 'multi-field' ? (
                <AppFormSelect
                    name={name}
                    label={label}
                    options={options?.split(',')}
                    defaultValue={t('vendorQuestionnaire.value')}
                    disabled={disabled}
                    multiple={type.toLowerCase() === 'multi-field'}
                    labelStyle={{
                        fontWeight: '500 !important',
                        fontSize: '16px !important',
                        color: '#46464A !important',
                    }}
                    sx={{
                        width: '500px',
                    }}
                />
            ) : (
                <Select
                    id={id}
                    label={label}
                    required={required}
                    variant="outlined"
                    onChange={onTextChange}
                    value={value}
                    name={name}
                    displayEmpty
                    notched
                    disabled={disabled}
                    labelStyle={{ fontWeight: 500, fontSize: '16px', color: '#46464A' }}
                    sx={{
                        fontWeight: 500,
                        fontSize: '16px',
                        color: '#46464A',
                        width: '500px',
                    }}
                >
                    <MenuItem value="" disabled>
                        <span style={{ color: '#ccc' }}>{t('vendorQuestionnaire.select')}</span>
                    </MenuItem>
                    {options?.split(',')?.map((option) => (
                        <MenuItem value={option} key={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </AppFormContext>
    );
};

export default FieldItem;
