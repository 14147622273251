import React from 'react';
import { ArrowBackIosNew, HorizontalRuleRounded } from '@mui/icons-material';
import AppViewModal from 'components/new_components/AppViewModal';
import { useTheme } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

const TransactionCheckModal = (props) => {
    const theme = useTheme();
    const { open, handleClose, payload } = props;

    return (
        <AppViewModal
            width={600}
            open={open}
            handleClose={handleClose}
            noClose
            title={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIosNew
                        sx={{
                            width: 20,
                            height: 20,
                            color: theme.palette.gray[30],
                            mr: 3,
                            borderRadius: '50%',
                            cursor: 'pointer',
                        }}
                        onClick={handleClose}
                    />
                    <Typography
                        variant="h2"
                        sx={{
                            color: '#46464A',
                            fontWeight: 700,
                            fontSize: '16px',
                            marginTop: 1,
                        }}
                    >
                        {payload?.name}
                    </Typography>
                </Box>
            }
            anchor="right"
        >
            <Box sx={{ mx: 3, my: 3 }}>
                <Box>
                    <Typography sx={{ fontWeight: 600, fontSize: '12px', color: theme.palette.gray[40], mb: 2 }}>
                        Entity details
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Name
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {payload?.name}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Country
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {payload?.country}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Date created
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {moment(payload?.date_created).format('MMMM Do, YYYY, (h:mm a)')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Date updated
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {moment(payload?.date_updated).format('MMMM Do, YYYY, (h:mm a)')}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #F0F0FA',
                        pb: 1,
                        mb: 1,
                    }}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                        Continuous monitoring
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: '10px', color: theme.palette.gray[40] }}>
                        {payload?.request_details?.continous_monitoring === false ? 'Not available' : 'Available'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #F0F0FA',
                        pb: 1,
                        mb: 1,
                    }}
                >
                    <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                        Fraudulent transaction
                    </Typography>
                    {payload?.request_details?.fraudcentry === false ? (
                        <HorizontalRuleRounded color="disabled" sx={{ fontSize: 50 }} />
                    ) : (
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '10px',
                                backgroundColor:
                                    payload?.request_details?.fraudcentry === false
                                        ? theme.palette.error[300] + '20'
                                        : theme.palette.success[500] + '20',
                                border: '1px',
                                borderColor:
                                    payload?.request_details?.fraudcentry === false
                                        ? theme.palette.error[300] + '20'
                                        : theme.palette.success[500] + '20',
                                color:
                                    payload?.request_details?.fraudcentry === false
                                        ? theme.palette.error[500]
                                        : theme.palette.success[500],
                                p: 0.7,
                            }}
                        >
                            {payload?.request_details?.fraudcentry === false ? 'Fraudulent' : 'Clean'}
                        </Typography>
                    )}
                </Box>
            </Box>
        </AppViewModal>
    );
};

export default TransactionCheckModal;
