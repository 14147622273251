import React from 'react';
import { Stack } from '@mui/material';
import AppButton from 'components/new_components/AppButton';
import { GoLightBulb } from 'react-icons/go';
import { AddOutlined } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const NotificationMessage = (props) => {
    const { currentTab, openAddModal } = props;

    //translation
    const { t } = useTranslation('dataroom');

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);

    return (
        <Stack flexDirection="row" justifyContent="space-between" sx={{ width: '100%', mt: 2, mb: 3 }}>
            <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap={1}
                sx={{
                    width: currentTab && user_type !== 'auditor' ? '70%' : '100%',
                    border: '1px solid #395BA9',
                    borderRadius: '4px',
                    p: '8px',
                }}
            >
                <GoLightBulb size="16px" color="#395BA9" />
                <p
                    className="text-[12px] text-[#5E5E62] font-medium"
                    dangerouslySetInnerHTML={{
                        __html: currentTab
                            ? `${t('uploadDocument')} <strong>${t('additionalDocument')}</strong> ${t('tab')}`
                            : t('documentsListed'),
                    }}
                />
            </Stack>
            {currentTab && user_type !== 'auditor' ? (
                <AppButton
                    icon={<AddOutlined />}
                    name={t('addDoc')}
                    // color="primary"
                    variant="contained"
                    disableElevation
                    sx={{
                        backgroundColor: '#395BA9',
                        '&:hover': {
                            backgroundColor: '#395BA980',
                        },
                    }}
                    onClick={openAddModal}
                />
            ) : null}
        </Stack>
    );
};

export default NotificationMessage;
