import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Stack } from '@mui/material';
import { Download, MoreVert } from '@mui/icons-material';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import RequestMenuItem from 'components/new_components/RequestMenuItem';

//translations
import { useTranslation } from 'react-i18next';

const PentestTableActions = (props) => {
    const { row, openDownloadPentestReportModal, tableTab, openViewModal, user_type, role } = props;

    const theme = useTheme();

    // state
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('pentest');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const openReportModal = () => {
        closeMenu();
        openDownloadPentestReportModal(row?.id);
    };

    const handleOpenViewModal = () => {
        closeMenu();
        openViewModal('view', row);
    };
    const handleOpenRerunModal = () => {
        closeMenu();
        openViewModal('rerun', row);
    };
    const menus = [
        {
            name: t('table.tableAction.viewDetails'),
            component: (
                <RequestMenuItem
                    header={t('table.tableAction.viewDetails')}
                    onClick={handleOpenViewModal}
                    disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                />
            ),
            isAvailable: ['requested'],
        },
        user_type === 'auditor' || role === 'audior'
            ? {
                  name: t('table.tableAction.rerunTest'),
                  component: (
                      <RequestMenuItem
                          header={t('table.tableAction.rerunTest')}
                          onClick={handleOpenRerunModal}
                          disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                      />
                  ),
                  isAvailable: ['requested'],
              }
            : null,
        {
            name: t('table.tableAction.downloadReport'),
            component: (
                <RequestMenuItem
                    header={t('table.tableAction.downloadReport')}
                    onClick={openReportModal}
                    disabled={row?.progress === 'running'}
                />
            ),
            isAvailable: ['uploaded', 'requested'],
        },
        // {
        //     name: 'Delete',
        //     component: (
        //         <RequestMenuItem
        //             header="Delete"
        //             // onClick={handleOpenRerunModal}
        //             // disabled={
        //             //     row?.progress === 'running' ||
        //             //     row?.progress === 'running' ||
        //             //     row?.uploaded_by?.toLowerCase() !== 'clone systems'
        //             // }
        //         />
        //     ),
        //     isAvailable: ['uploaded', 'requested'],
        // },
    ];

    return (
        <Stack direction="row">
            {tableTab === 'requested' && (
                <IconButton
                    sx={{ color: theme.palette.gray[900] }}
                    component="a"
                    href={row?.pentestscan}
                    target="_blank"
                    disabled={!row?.pentestscan}
                >
                    <Download />
                </IconButton>
            )}

            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <AppTableActionMenu anchor={anchor} closeMenu={closeMenu}>
                {menus?.map((menu) => {
                    return menu?.isAvailable?.includes(tableTab) && <div key={menu.name}>{menu.component}</div>;
                })}
            </AppTableActionMenu>
        </Stack>
    );
};

export default PentestTableActions;
