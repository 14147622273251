import { API_SCANS, API_SCAN_RESULT_BY_SCAN, SOURCE_CODE, CODE_REVIEW_REPORT_BY_SCAN } from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case API_SCANS:
            return {
                ...state,
                api_scans: action.payload,
            };
        case API_SCAN_RESULT_BY_SCAN:
            return {
                ...state,
                api_scan_result_by_scan: action.payload,
            };
        case SOURCE_CODE:
            return {
                ...state,
                source_code: action.payload,
            };
        case CODE_REVIEW_REPORT_BY_SCAN:
            return {
                ...state,
                code_review_report: action.payload,
            };
        default:
            return state;
    }
};
