import { Stack, InputAdornment, Box, Typography } from '@mui/material';
import React from 'react';
import AppInput from 'components/new_components/AppInput';
import { useTheme } from '@mui/material/styles';
import { EntityTypeOption, StatusOption, DATE_OPTIONS } from '../../utils/constants';
import AppSelectInput from 'components/new_components/AppSelectInput';
import AppFilterSelect from 'components/new_components/AppFilterSelect';
import { connect } from 'react-redux';
import { GetAllMerchantRiskAssessments } from 'store/actions/merchantActions';
import SearchIcon from '../../../../../assets/img/Searchicon.svg';
import DateIcon from '../../../../../assets/img/icons/dateIcon.svg';

const KycHead = (props) => {
    const {
        onSearch,
        match_status,
        date_created,
        entity_type,
        setEntityType,
        setMatch_Status,
        handleFilter,
        setDateModal,
    } = props;
    const theme = useTheme();

    const handleStatusChange = (e) => {
        setMatch_Status(e.target.value);
    };
    const handleEntityChange = (e) => {
        setEntityType(e.target.value);
    };

    return (
        <Stack direction="row" alignItems="center" sx={{ marginLeft: '-1rem', mt: '-1rem' }}>
            <AppInput
                placeholder="Search..."
                fullWidth
                sx={{
                    mr: 3,
                    borderColor: theme.palette.gray[30],
                    height: 38,
                    '& input::placeholder': {
                        color: theme.palette.gray[40],
                        fontWeight: 600,
                    },
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <img src={SearchIcon} alt="SearchIcon" width="25px" />
                    </InputAdornment>
                }
                onChange={onSearch}
            />
            <AppFilterSelect
                options={DATE_OPTIONS}
                name="date"
                defaultValue="Date Created"
                value={date_created}
                onChange={handleFilter}
                type="checkbox"
                sx={{ mx: 2 }}
                custom={[
                    {
                        name: (
                            <Box onClick={() => setDateModal(true)} sx={{ display: 'flex', ml: 2, py: 1 }}>
                                <img src={DateIcon} alt="date icon" className="mr-2" />
                                <Typography sx={{ color: '#77777A', fontSize: '14px', fontWeight: 500 }}>
                                    Custom date range{' '}
                                </Typography>
                            </Box>
                        ),
                        value: 'custom',
                    },
                ]}
            />
            <AppSelectInput
                name="Entity type"
                options={EntityTypeOption}
                defaultValue="Entity Type"
                sx={{ mr: 1, borderRadius: '8px', height: 38, fontSize: 12 }}
                value={entity_type}
                onChange={handleEntityChange}
                size="small"
            />
            <AppSelectInput
                name="status"
                options={StatusOption}
                defaultValue="Status"
                sx={{ mr: 1, ml: 1, borderRadius: '8px', height: 38, fontSize: 12 }}
                value={match_status}
                onChange={handleStatusChange}
                size="small"
            />
        </Stack>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_risk_assessments: state?.merchantReducers?.all_merchant_risk_assessments,
    };
};
export default connect(mapStateToProps, { GetAllMerchantRiskAssessments })(KycHead);
