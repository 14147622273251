import { ALL_MERCHANT_NOTIFICATIONS, ADD_MERCHANT_NOTIFICATION } from '../constants';

const initialState = {
    all_merchant_notifications: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ALL_MERCHANT_NOTIFICATIONS:
            return {
                ...state,
                all_merchant_notifications: action?.payload,
            };
        case ADD_MERCHANT_NOTIFICATION:
            return {
                ...state,
                all_merchant_notifications: [...state.all_merchant_notifications, action.payload],
            };
        default:
            return state;
    }
};
