import React, { useState } from 'react';
import {
    Typography,
    Button,
    Box,
    MenuItem,
    Menu,
    // Menu, MenuItem
} from '@mui/material';

import { truncateText } from 'utils';
import { Progress } from 'antd';
import { MoreHoriz } from '@mui/icons-material';
import EvidencesDetailsModal from './EvidencesDetailsModal';

const DescriptionLayerNew = (props) => {
    const {
        description,
        percentCompletion,
        // type,
        // subCategory,
        standard,
        openDescriptionDrawer,
        // resource,
        category,
        isTaskAssignedToSelectSubRequirement,
        openTaskAssignModal,
        title,
        subgroup_evidences,
        user_subgroup_evidences,
        type_,
    } = props;
    const [anchor, setAnchor] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const totalEvidences = subgroup_evidences
        ?.map((item) => item?.segments)
        ?.flat(1)
        ?.map((itm) =>
            Object?.keys(itm)?.reduce((prev, current) => {
                const reduced_items = prev + itm[current];
                return reduced_items;
            }, 0)
        )
        ?.reduce((prev, current) => {
            const reduced_items = prev + current;
            return reduced_items;
        }, 0);

    const user_docs = user_subgroup_evidences
        ?.map((item) => {
            return item?.data?.map((itm, idx) => {
                return itm?.[`segment_${idx + 1}`]?.map((dat) => dat);
            });
        })
        ?.flat(1)
        .flat(1);

    const expectedEvidences = subgroup_evidences
        ?.map((item) => (type_ === 'soc2' ? item?.segments[0] : item?.segments))
        ?.flat(1)
        ?.map((itm) =>
            Object?.keys(itm)?.reduce((prev, current) => {
                const reduced_items = prev + itm[current];
                return reduced_items;
            }, 0)
        )
        ?.reduce((prev, current) => {
            const reduced_items = prev + current;
            return reduced_items;
        }, 0);
    return (
        <Box
            sx={{
                p: 2,
                backgroundColor: '#F8FAFC',
                borderRadius: 1,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '70%' }} gap={1}>
                <Progress
                    type="circle"
                    strokeLinecap="butt"
                    width={33}
                    percent={Math.round(percentCompletion)}
                    strokeWidth={12}
                    strokeColor={'#0E2C66'}
                />
                <Box sx={{ ml: 1 }}>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: 14,
                            color: '#64748B',
                        }}
                    >
                        {title}
                    </Typography>

                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: 14,
                            color: '#64748B',
                        }}
                    >
                        {description?.length > 300 ? (
                            <>
                                {truncateText(description, '250')}{' '}
                                <Button
                                    variant="text"
                                    sx={{
                                        textDecoration: 'underline',
                                        color: '#5474C4',
                                        textTransform: 'none',
                                        minWidth: 'unset',
                                        p: 0,
                                    }}
                                    onClick={openDescriptionDrawer}
                                >
                                    see more...
                                </Button>{' '}
                            </>
                        ) : (
                            description
                        )}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: 'inherit',
                        px: 0.8,
                        py: 0.5,
                        color: '#395BA9',
                        fontWeight: 600,
                        fontSize: '13px',
                    }}
                    onClick={handleOpenModal}
                >
                    Evidences:: {user_docs?.length}/{type_ === 'soc2' ? expectedEvidences : totalEvidences}
                </Button>
                {!(standard === 'ndpr' || standard === 'kdpa' || standard === 'gdpa' || standard?.includes('iso')) ? (
                    <Box>
                        <Box
                            sx={{ border: '1px solid #E1E2EC', borderRadius: 0.5, ml: 2, p: 0.3, cursor: 'pointer' }}
                            onClick={openMenu}
                        >
                            <MoreHoriz />
                        </Box>
                        <Menu
                            open={Boolean(anchor)}
                            anchorEl={anchor}
                            onClose={closeMenu}
                            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                        >
                            <MenuItem
                                disabled={isTaskAssignedToSelectSubRequirement}
                                sx={{ fontSize: '14px', fontWeight: 500, color: '#64748B' }}
                                onClick={() => {
                                    openTaskAssignModal();
                                    closeMenu();
                                }}
                            >
                                Assign to
                            </MenuItem>
                        </Menu>
                    </Box>
                ) : null}
            </Box>
            <EvidencesDetailsModal
                open={openModal}
                handleClose={handleCloseModal}
                title={title}
                subgroup_evidences={subgroup_evidences}
                evidence_type={type_}
                category={category}
            />
        </Box>
    );
};

export default DescriptionLayerNew;
