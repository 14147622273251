import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Table } from 'antd';
import { Box, ButtonGroup, Button } from '@mui/material';
import BreadCrumb from 'components/Breadcrumb';

//redux
import { connect } from 'react-redux';
import { GetAllPentestRequests } from 'store/actions/adminActions';
import AppColorTag from 'components/new_components/AppColorTag';
import { PentestOperations } from './PentestOperations/PentestOperations';
import UploadModal from './PentestOperations/UploadModal';

const Pentests = ({ GetAllPentestRequests, all_pentests }) => {
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [record, setRecord] = useState({});
    const [type, setType] = useState('386konsult');

    const handleOpenModal = (data) => {
        setOpenModal(true);
        setRecord(data);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setRecord({});
    };

    const columns = [
        {
            title: 'Merchant Name',
            render: (record) => record?.merchant_name,
        },
        {
            title: 'Test Name',
            render: (record) => record?.name,
        },
        {
            title: 'Severity Level',
            render: (record) => {
                return !record?.severity ? (
                    <Box sx={{ height: '6px', width: '80px', background: ' #D9D9D9', borderRadius: '8px' }}></Box>
                ) : (
                    <AppColorTag
                        text={record?.severity}
                        type={
                            record?.severity === 'high'
                                ? 'error'
                                : record?.severity === 'medium'
                                ? 'warning'
                                : 'success'
                        }
                    />
                );
            },
        },
        {
            title: 'Status',
            render: (record) => <span className="capitalize">{record?.progress}</span>,
        },
        {
            title: 'Pentest by',
            render: (record) => <span className="capitalize">{record?.uploaded_by}</span>,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => <PentestOperations record={record} handleOpenModal={handleOpenModal} />,
        },
    ];

    const wiredAssuranceRequest = useMemo(() => {
        return all_pentests?.filter((pentest) => pentest?.uploaded_by === 'wired assurance');
    }, [all_pentests]);
    const konsultPentests = useMemo(() => {
        return all_pentests?.filter((pentest) => pentest?.uploaded_by === '386konsult');
    }, [all_pentests]);

    useEffect(() => {
        const getAllPentestRequests = async () => {
            setLoading(true);
            const res = await GetAllPentestRequests();
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllPentestRequests();
    }, []);
    const pentest_results = type === '386konsult' ? konsultPentests : wiredAssuranceRequest;
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'Penetration Test' }]} />
                </Col>
                <Col span={6} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={console.log}
                        className="flex-1"
                        size="large"
                    />
                </Col>
            </Row>
            <Row>
                <ButtonGroup>
                    <Button
                        variant={type === '386konsult' ? 'contained' : 'outlined'}
                        sx={{
                            backgroundColor: type === 's386konsult' ? '#395BA9' : 'outlined',
                            border: type === '386konsult' ? 'none' : '1px solid #F1F5F9',
                            textTransform: 'inherit',
                            '&:hover': {
                                backgroundColor: type === '386konsult' ? '#395BA9' : 'outlined',
                            },
                        }}
                        onClick={() => setType('386konsult')}
                    >
                        386Konsult
                    </Button>
                    <Button
                        variant={type === 'wired_assurance' ? 'contained' : 'outlined'}
                        sx={{
                            backgroundColor: type === 'wired_assurance' ? '#395BA9' : 'outlined',
                            border: type === 'wired_assurance' ? 'none' : '1px solid #F1F5F9',
                            textTransform: 'inherit',
                            '&:hover': {
                                backgroundColor: type === 'wired_assurance' ? '#395BA9' : 'outlined',
                            },
                        }}
                        onClick={() => setType('wired_assurance')}
                    >
                        Wired Assurance
                    </Button>
                </ButtonGroup>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(pentest_results) => pentest_results?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={pentest_results}
                    pagination={{
                        total: pentest_results?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>

            <UploadModal open={openModal} handleClose={handleCloseModal} record={record} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_pentests: state?.adminReducers?.all_pentests,
    };
};

export default connect(mapStateToProps, { GetAllPentestRequests })(Pentests);
