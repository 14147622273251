import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FiHelpCircle } from 'react-icons/fi';
import { Box } from '@mui/material';
import { Edit, Add } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppButton from 'components/new_components/AppButton';
import { AppForm, AppFormInput, AppFormSelect, AppSubmitButton } from 'components/new_components/forms';

//redux
import { connect } from 'react-redux';
import { AddVendor, EditVendor } from 'store/actions/vendorActions';

//validation
import { vendorsValidation } from '../../utils/validation';
import { riskLevelOptions } from '../../utils/constants';

//translations
import { useTranslation } from 'react-i18next';

const VendorModal = (props) => {
    const { open, handleClose, isAddModal, id, payload, openRiskLevelsModal, AddVendor, EditVendor } = props;

    const [loadingState, setLoadingState] = useState(false);

    const theme = useTheme();

    //translation
    const { t } = useTranslation('vendorManagement');

    const handleSubmit = async (values) => {
        setLoadingState(true);
        const res = isAddModal ? await AddVendor(values) : await EditVendor(values, id);
        setLoadingState(false);
        if (res?.success) {
            toast.success(isAddModal ? 'Vendor Added Successfully' : 'Vendor Credentials Updated Successfully');
            handleClose();
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error(res?.message);
            }
        }
    };
    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={vendorsValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={t('vendorModal.addVendor')}
                headerAction={
                    <>
                        <AppButton
                            name={t('vendorModal.riskLevel')}
                            sx={{
                                fontSize: '12px',
                                fontStyle: 'italic',
                                fontWeight: '400',
                                color: theme.palette.primary[200],
                                '&:hover': {
                                    background: 'transparent !important',
                                },
                            }}
                            icon={<FiHelpCircle size="13px" />}
                            onClick={openRiskLevelsModal}
                        />
                    </>
                }
                width="500px"
                height="520px"
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            my: 3,
                        }}
                    >
                        <AppSubmitButton
                            text={isAddModal ? t('vendorModal.addVendor') : t('vendorModal.updateVendor')}
                            startIcon={isAddModal ? <Add /> : <Edit />}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '50%' }}
                        />
                    </Box>
                }
            >
                <AppFormInput
                    type="text"
                    label={t('vendorModal.vendorName')}
                    name="name"
                    placeholder={t('vendorModal.vendorName')}
                />
                <AppFormInput
                    type="text"
                    label={t('vendorModal.emailAddress')}
                    name="email"
                    placeholder={t('vendorModal.emailAddress')}
                    disabled={!isAddModal}
                />
                <AppFormInput
                    type="text"
                    label={t('vendorModal.descriptionOS')}
                    name="description"
                    placeholder={t('vendorModal.description')}
                />
                <AppFormInput
                    type="text"
                    label={t('vendorModal.dataShared')}
                    name="data"
                    placeholder={t('vendorModal.dataShared')}
                />
                <AppFormSelect
                    name="risk_level"
                    options={riskLevelOptions}
                    defaultValue={t('vendorModal.riskLevelValue')}
                    label={t('vendorModal.riskLevel')}
                />
                <AppFormInput
                    type="text"
                    label={t('vendorModal.product')}
                    name="products"
                    placeholder={t('vendorModal.product')}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { AddVendor, EditVendor })(VendorModal);
