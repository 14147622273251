import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import RiskAssessmentCard from './components/RiskAssessmentCard';
import EmployeeAndVendorsCard from './components/EmployeeAndVendorsCard';
import AutomatedScanCategoryCard from '../commons/AutomatedScanCategoryCard';

//redux
import { GetAutomatedScanGroups } from 'store/actions/automatedScansActions';
import { GetMerchantPeople, GetAllMerchantRiskAssessments } from 'store/actions/merchantActions';
import { GetAllVendors } from 'store/actions/vendorActions';
import { connect } from 'react-redux';
import { riskLevelEvaluation } from 'utils';

const FifthPage = ({
    GetAutomatedScanGroups,
    GetMerchantPeople,
    all_automated_scan_groups,
    all_employees,
    GetAllVendors,
    all_vendors,
    GetAllMerchantRiskAssessments,
    all_merchant_risk_assessments,
    dashboardResult,
}) => {
    const [scanResultGroups, setScanResultGroups] = useState({});

    // functions
    const getAllAutomatedScanGroups = async () => {
        // setLoading(true);
        const res = await GetAutomatedScanGroups();
        // setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllAutomatedScanGroups();
    }, []);
    useEffect(() => {
        const getMerchantPeople = async () => {
            const res = await GetMerchantPeople();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getMerchantPeople();
    }, []);
    useEffect(() => {
        const getAllVendors = async () => {
            const res = await GetAllVendors();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getAllVendors();
    }, []);
    useEffect(() => {
        const getAllMerchantRiskAssessments = async () => {
            const res = await GetAllMerchantRiskAssessments();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getAllMerchantRiskAssessments();
    }, []);
    useEffect(() => {
        if (all_automated_scan_groups) {
            const { ...otherGroup } = all_automated_scan_groups;
            setScanResultGroups(otherGroup);
        }
    }, [all_automated_scan_groups]);

    const employee_with_agent = all_employees?.filter((employee) => employee?.ran_agent === true);
    const employee_with_no_background = all_employees?.filter((employee) => employee?.background_check === false);
    const low_vendor = all_vendors?.filter((vendor) => vendor?.risk_level === 'low');
    const medium_vendor = all_vendors?.filter((vendor) => vendor?.risk_level === 'medium');
    const high_vendor = all_vendors?.filter((vendor) => vendor?.risk_level === 'high');
    const critical_vendor = all_vendors?.filter((vendor) => vendor?.risk_level === 'critical');
    const low_risk = all_merchant_risk_assessments?.filter(
        (risk) => riskLevelEvaluation(risk?.post_riskscore) === 'Low'
    );
    const medium_risk = all_merchant_risk_assessments?.filter(
        (risk) => riskLevelEvaluation(risk?.post_riskscore) === 'Medium'
    );
    const high_risk = all_merchant_risk_assessments?.filter(
        (risk) => riskLevelEvaluation(risk?.post_riskscore) === 'High'
    );

    return (
        <Box sx={{ py: 2 }}>
            <Typography
                sx={{
                    color: '#0E2C66',
                    fontWeight: 700,
                    py: 2,
                    fontSize: '13.3333px',
                    lineHeight: '16px',
                    textTransform: 'capitalize',
                }}
            >
                Automated scans
            </Typography>
            <Grid container spacing={1}>
                {Object.keys(scanResultGroups).map((group) => {
                    const { failed, passed, name } = scanResultGroups[group];
                    return group === 'Access_errors' ? null : (
                        <Grid item xs={12} md={6} lg={6} key={group}>
                            <AutomatedScanCategoryCard category={name} passed={passed} failed={failed} />
                        </Grid>
                    );
                })}
            </Grid>
            <RiskAssessmentCard
                dashboardResult={dashboardResult}
                low_risk={low_risk}
                medium_risk={medium_risk}
                high_risk={high_risk}
            />
            <EmployeeAndVendorsCard
                dashboardResult={dashboardResult}
                all_employees={all_employees}
                employee_with_agent={employee_with_agent}
                employee_with_no_background={employee_with_no_background}
                low_vendor={low_vendor}
                medium_vendor={medium_vendor}
                high_vendor={high_vendor}
                critical_vendor={critical_vendor}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_automated_scan_groups: state?.automatedScansReducers?.all_automated_scan_group,
        all_employees: state?.merchantReducers?.all_merchant_people,
        all_vendors: state?.vendorReducers?.all_vendors,
        all_merchant_risk_assessments: state?.merchantReducers?.all_merchant_risk_assessments?.results,
        dashboardResult: state?.dashboardReducers?.dashboardResult,
    };
};
export default connect(mapStateToProps, {
    GetAutomatedScanGroups,
    GetMerchantPeople,
    GetAllVendors,
    GetAllMerchantRiskAssessments,
})(FifthPage);
