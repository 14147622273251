import { Box, ToggleButton, ToggleButtonGroup, Typography, Tooltip, Chip, Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Warning from 'assets/img/Warning.svg';
import Check from 'assets/img/CheckIcon.svg';
import { GetScans, RemoveScanException } from 'store/actions/automatedScansActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import CircularLoader from 'components/Loaders/CircularLoader';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import './style.css';
import ExemptScanModal from '../ExemptScanModal';
import HowToFixModal from '../HowToFix';
import AssignmentAdd from '../../../../../../assets/img/AssignmentAdd.svg';
import AssignTaskModal from '../AssignTaskModal';

const Vulnerabilities = (props) => {
    const { scan_details, GetScans, all_scan_items, sub, isScan, RemoveScanException, category } = props;
    const [alignment, setAlignment] = useState('');
    const [accountName, setAcccountName] = useState('');
    const [openExemptModal, setOpenExemptModal] = useState(false);
    const [openFixModal, setOpenFixModal] = useState(false);
    const [scan, setScan] = useState({});
    const [loading, setLoading] = useState(false);
    const [all_scans, setAll_scans] = useState([]);
    const [assignTask, setAssignTask] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [subs, setSubs] = useState(sub?.account);
    const theme = useTheme();

    const find_scan =
        (scan_details?.accounts || sub?.account) !== null &&
        (scan_details?.accounts || sub?.account) !== undefined &&
        Object?.keys(scan_details?.accounts || sub?.account)?.length > 0 &&
        Object?.keys(scan_details?.accounts || sub?.account)?.find((scan) => scan);

    // functions
    const getScans = async (subcategory, account_name) => {
        setLoading(true);
        const res = await GetScans(subcategory, account_name);
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        const filtered_scans = all_scan_items?.filter((item) => item?.result === newAlignment);
        setAll_scans(filtered_scans);
    };

    const openExemptScanModal = (data = {}) => {
        setOpenFixModal(false);
        setScan(data);
        setOpenExemptModal(true);
    };

    const closeExemptScanModal = () => {
        setOpenExemptModal(false);
    };

    const openFixScanModal = (data) => {
        setScan(data);
        setOpenFixModal(true);
    };

    const closeFixcanModal = () => {
        setOpenFixModal(false);
    };

    // useEffect
    useEffect(() => {
        if (typeof find_scan === 'string') {
            setAcccountName(find_scan);
        }
        return () => {
            setLoading(false);
        };
    }, [find_scan]);

    useEffect(() => {
        if (accountName && isScan) {
            getScans(scan_details?.id, accountName);
        }
    }, [accountName, isScan]);

    useEffect(() => {
        if (accountName) {
            setAll_scans(isScan ? all_scan_items : sub?.account[accountName]);
            return () => {
                setLoading(false);
            };
        }
    }, [accountName, isScan, all_scan_items]);

    const handleDelete = async (id) => {
        const res = await RemoveScanException(id);
        if (res?.success) {
            toast.success('Scan successfully reverted');
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Box sx={{ mx: 2, my: 3, border: `1px solid ${theme.palette.neutral[95]}`, borderRadius: '5px', p: 2 }}>
            {isScan ? (
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: '12px',
                        color: '#303034',
                        pb: 1,
                        borderBottom: `1px solid ${theme.palette.neutral[95]}`,
                    }}
                >
                    Vulnerabilities
                </Typography>
            ) : null}
            {isScan ? (
                <Box sx={{ mt: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '10px', fontWeight: 500, color: theme.palette.neutral.main }}>
                                Instances:
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignitems: 'center',
                                    ml: 1,
                                    mt: 1,
                                    // border: '2px solid red',
                                }}
                            >
                                {scan_details?.accounts !== null &&
                                    scan_details?.accounts !== undefined &&
                                    Object?.keys(scan_details?.accounts)?.length > 0 &&
                                    Object?.keys(scan_details?.accounts)?.map((scan, index) => {
                                        return scan === 'scan_time' ? null : (
                                            <Typography
                                                sx={{
                                                    backgroundColor: accountName === scan ? '#EEF0FF' : '',
                                                    color: '#011947',
                                                    fontSize: '10px',
                                                    fontWeight: 500,
                                                    mx: 0.5,
                                                    display: 'flex',
                                                    px: 0.9,
                                                    py: 0.4,
                                                    mb: 1,
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                key={index}
                                                onClick={() => {
                                                    setAcccountName(scan);
                                                }}
                                            >
                                                {scan}{' '}
                                                {scan_details?.accounts[scan] === 'failed' && (
                                                    <span className="text-[#FF5449] ml-[2px]">!</span>
                                                )}
                                            </Typography>
                                        );
                                    })}
                            </Box>
                        </Box>
                        <Tooltip title="Toggle between pass and failed scans" arrow>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="success">
                                    {' '}
                                    <img src={Check} alt="Check" />
                                </ToggleButton>
                                <ToggleButton value="failed">
                                    {' '}
                                    <img src={Warning} alt="Check" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Tooltip>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '10px', fontWeight: 500, color: theme.palette.neutral.main }}>
                                Instances:
                            </Typography>
                            <Box sx={{ display: 'flex', alignitems: 'center', ml: 1 }}>
                                {sub?.account !== null &&
                                    sub?.account !== undefined &&
                                    Object?.keys(sub?.account)?.length > 0 &&
                                    Object?.keys(sub?.account)?.map((scan, index) => (
                                        <Typography
                                            sx={{
                                                backgroundColor: accountName === scan ? '#EEF0FF' : '',
                                                color: '#011947',
                                                fontSize: '10px',
                                                fontWeight: 500,
                                                mx: 0.5,
                                                display: 'flex',
                                                px: 0.9,
                                                py: 0.4,
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                            }}
                                            key={index}
                                            onClick={() => {
                                                setAcccountName(scan);
                                            }}
                                        >
                                            {scan}{' '}
                                        </Typography>
                                    ))}
                            </Box>
                        </Box>
                        <Tooltip title="Toggle between pass and failed scans" arrow>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="success">
                                    {' '}
                                    <img src={Check} alt="Check" />
                                </ToggleButton>
                                <ToggleButton value="failed">
                                    {' '}
                                    <img src={Warning} alt="Check" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Tooltip>
                    </Box>
                </Box>
            )}
            {isScan ? (
                <>
                    {loading ? (
                        <CircularLoader />
                    ) : (
                        <Box sx={{ maxHeight: '300px', overflow: 'auto', pt: 2 }}>
                            {all_scans?.length ? (
                                all_scans?.map((scan) => (
                                    <Box
                                        key={scan?.id}
                                        className="parent_card"
                                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <Typography
                                            onClick={() => openFixScanModal(scan)}
                                            sx={{
                                                marginBottom: 1,
                                                borderTop: `1px solid ${theme.palette.neutral[95]}`,
                                                py: 0.5,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {scan?.result === 'failed' ? (
                                                <ErrorIcon sx={{ color: '#FF5449', width: '15px', height: '15px' }} />
                                            ) : (
                                                <CheckCircleIcon
                                                    sx={{ color: '#37A372', width: '15px', height: '15px' }}
                                                />
                                            )}
                                            <Tooltip
                                                title={scan?.result === 'failed' ? 'Click to see how to fix' : ''}
                                                arrow
                                            >
                                                <Typography
                                                    component="span"
                                                    sx={{ fontSize: '11px', fontWeight: 400, color: '#5E5E62', ml: 1 }}
                                                >
                                                    {scan?.scan_name}
                                                </Typography>
                                            </Tooltip>
                                        </Typography>

                                        <Box className="second-child">
                                            <Box onClick={() => openExemptScanModal(scan)}>
                                                <ScanStatus
                                                    icon={<BlockIcon sx={{ width: 15, height: 15, marginRight: 1 }} />}
                                                    text={'Exempt'}
                                                    color={'#DE3730'}
                                                />
                                            </Box>
                                        </Box>

                                        {scan?.result === 'failed' &&
                                            !category?.is_assigned_to_current_user &&
                                            !sub?.is_assigned_to_current_user &&
                                            !scan.is_assigned_to_current_user && (
                                                <Box
                                                    className="third-child"
                                                    onClick={() => {
                                                        setScan(scan);
                                                        setAssignTask(true);
                                                    }}
                                                >
                                                    <img src={AssignmentAdd} alt="AssignmentAdd" />
                                                </Box>
                                            )}
                                        {scan.is_assigned_to_current_user && (
                                            <Typography
                                                sx={{
                                                    backgroundColor: '#D2A500',
                                                    color: '#FFFFFF',
                                                    fontWeight: 900,
                                                    fontSize: '9px',
                                                    float: 'right',
                                                    px: 1,
                                                    py: 0.5,
                                                    borderRadius: '2px',
                                                }}
                                            >
                                                To you
                                            </Typography>
                                        )}
                                    </Box>
                                ))
                            ) : (
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mt: 5,
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        color: '#5E5E62',
                                    }}
                                >
                                    {' '}
                                    No Scan Found
                                </Typography>
                            )}
                        </Box>
                    )}
                </>
            ) : (
                <Box sx={{ maxHeight: '300px', overflow: 'auto', pt: 2 }}>
                    {all_scans?.length ? (
                        all_scans?.map((scan) => (
                            <Box
                                key={scan?.id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderTop: `1px solid ${theme.palette.neutral[95]}`,
                                }}
                            >
                                <Box>
                                    <Typography>
                                        {scan?.status === 'failed' ? (
                                            <ErrorIcon sx={{ color: '#FF5449', width: '15px', height: '15px' }} />
                                        ) : (
                                            <CheckCircleIcon sx={{ color: '#37A372', width: '15px', height: '15px' }} />
                                        )}
                                        <Typography
                                            component="span"
                                            sx={{ fontSize: '11px', fontWeight: 400, color: '#5E5E62', ml: 1 }}
                                        >
                                            {scan?.scan_name}
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{ fontSize: '11px', fontWeight: 400, color: '#5E5E62', ml: 3 }}
                                    >
                                        {scan?.reason}
                                    </Typography>
                                </Box>

                                <Button
                                    startIcon={<TurnLeftIcon />}
                                    onClick={() => {
                                        handleDelete(scan?.x_id);
                                    }}
                                    sx={{ fontSize: '11px', fontWeight: 500, textTransform: 'inherit' }}
                                >
                                    Revert
                                </Button>
                            </Box>
                        ))
                    ) : (
                        <Typography
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 5,
                                fontWeight: 600,
                                fontSize: '16px',
                                color: '#5E5E62',
                            }}
                        >
                            {' '}
                            No Scan Found
                        </Typography>
                    )}
                </Box>
            )}
            <ExemptScanModal
                open={openExemptModal}
                onClose={closeExemptScanModal}
                sub={sub}
                accountName={accountName}
                scan={scan}
            />
            <HowToFixModal open={openFixModal} onClose={closeFixcanModal} scan={scan} setScan={setScan} />
            <AssignTaskModal
                open={assignTask}
                onClose={() => setAssignTask(false)}
                type="Scan"
                scans={scan}
                category={category}
                sub_category={sub}
            />
        </Box>
    );
};

const ScanStatus = ({ icon, text, color, theme }) => {
    return (
        <Chip
            sx={{ marginRight: 1, marginBottom: 1, marginLeft: 1, border: 'none' }}
            onClick={() => {
                ('ehh');
            }}
            label={
                <Typography
                    sx={{
                        fontSize: 10,
                        fontWeight: 600,
                        color: color ? color : theme.palette.primary[500],
                    }}
                >
                    {icon}
                    {text}
                </Typography>
            }
            variant="outlined"
            size="small"
        />
    );
};

const mapStateToProps = (state) => {
    return {
        all_scan_items: state?.automatedScansReducers?.all_scan_items,
        all_assigned_task: state?.automatedScansReducers?.all_assigned_task,
        user: state?.generalReducers?.user_info,
    };
};

export default connect(mapStateToProps, { GetScans, RemoveScanException })(Vulnerabilities);
