import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';

const VendorsTableActions = (props) => {
    const theme = useTheme();
    const { row, openModal, openDeleteModal } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('vendorManagement');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const openEditModal = () => {
        closeMenu();
        const payload = {
            name: row?.name,
            description: row?.description,
            data: row?.data,
            risk_level: row?.risk_level?.toLowerCase(),
            products: row?.products,
            email: row?.email,
        };
        openModal('edit', payload, row?.id);
    };

    const handleOpenDeleteModal = () => {
        closeMenu();
        openDeleteModal(row?.id);
    };

    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem onClick={openEditModal}>{t('table.tableAction.edit')}</MenuItem>
                <MenuItem onClick={handleOpenDeleteModal}>{t('table.tableAction.delete')}</MenuItem>
            </Menu>
        </>
    );
};

export default VendorsTableActions;
