import React from 'react';
import { connect } from 'react-redux';
import { DeleteMerchantPeople } from 'store/actions/merchantActions';
import AppViewModal from 'components/new_components/AppViewModal';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ArrowBackIosNewRounded, DownloadRounded } from '@mui/icons-material';
import { viewFile } from 'utils';
import { ReactComponent as ReportIcon } from 'assets/img/icons/ROC.svg';
import { ReactComponent as Certification } from 'assets/img/icons/certification.svg';
import { ReactComponent as OtherReports } from 'assets/img/icons/other-report-doc.svg';

const AuditDownloadModal = (props) => {
    const theme = useTheme();
    const { open, handleClose, data } = props;

    const documentsName = {
        attestation_doc: 'Attestation',
        thank_you_doc: 'Thank you card',
    };

    const uploadedDocs = Array.isArray(data?.uploaded_docs) ? data?.uploaded_docs : [];
    return (
        <AppViewModal
            open={open}
            handleClose={handleClose}
            title={
                <>
                    <IconButton onClick={handleClose}>
                        <ArrowBackIosNewRounded sx={{ color: theme.palette.gray[30] }} />
                    </IconButton>
                    <Box sx={{ ml: 2.5 }}>Downloads</Box>
                </>
            }
            width={540}
            noClose
        >
            <Stack sx={{ mx: 2.5, my: 4, gap: 1 }}>
                {uploadedDocs?.map((docs) => {
                    return docs?.doc_name?.toLowerCase()?.includes('roc') ? (
                        <DownloadCard Icon={ReportIcon} title={docs?.doc_name} url={docs?.doc} key={docs?.id} />
                    ) : docs?.doc_name?.toLowerCase()?.includes('certification') ? (
                        <DownloadCard Icon={Certification} title={docs?.doc_name} url={docs?.doc} key={docs?.id} />
                    ) : (
                        <DownloadCard Icon={OtherReports} title={docs?.doc_name} url={docs?.doc} key={docs?.id} />
                    );
                })}
                {data?.certification && (
                    <DownloadCard Icon={Certification} title="Certification" url={data?.certification} />
                )}
                {data?.extra_docs &&
                    Object.entries(data?.extra_docs).map(([key, value]) => (
                        <DownloadCard Icon={OtherReports} title={documentsName[key]} url={value} />
                    ))}
            </Stack>
        </AppViewModal>
    );
};
const DownloadCard = (props) => {
    const { Icon, title, url } = props;
    const theme = useTheme();
    return (
        <Box
            sx={{
                border: `1.5px solid ${theme.palette.gray[95]}`,
                p: 2,
                py: 1,
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Icon />
            <Typography sx={{ fontSize: 16, fontWeight: 600, color: theme.palette.primary[20], flex: 1, ml: 3 }}>
                {title}
            </Typography>
            <IconButton sx={{ color: theme.palette.primary[900] }} onClick={() => viewFile(url)}>
                <DownloadRounded />
            </IconButton>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, {
    DeleteMerchantPeople,
})(AuditDownloadModal);
