import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CisoReportCard from './CisoReportCard';
import { GetMerchantOverviewSummary, GetMerchantComplianceSummary } from 'store/actions/dashboardActions';
import { connect } from 'react-redux';

const PCIDSSReport = ({ GetMerchantOverviewSummary, GetMerchantComplianceSummary, pciSummary }) => {
    const toListEntry = Object.entries(pciSummary || {});
    const newList = toListEntry
        ?.sort((a, b) => a[0] - b[0])
        ?.map((res) => {
            const { user_doc, user_form, total_form, user_scan, total_scan, total_doc } = res?.[1] || {};
            const steps = ((user_doc + user_form + user_scan) * 100) / (total_doc + total_form + total_scan) || 0;
            return {
                title: `Requirement ${parseInt(res?.[0])} - ${res?.[1]?.title}`,
                total_doc: res?.[1]?.total_doc,
                total_form: res?.[1]?.total_form,
                total_scan: res?.[1]?.total_scan,
                user_doc: res?.[1]?.user_doc,
                user_form: res?.[1]?.user_form,
                user_scan: res?.[1]?.user_scan,
                stepCount: parseInt(steps.toString()),
            };
        });
    useEffect(() => {
        const getAllPcidssSummary = async () => {
            await GetMerchantOverviewSummary();
            await GetMerchantComplianceSummary();
        };
        getAllPcidssSummary();
    }, []);
    return (
        <>
            <Box sx={{ mt: '20px', mb: '20px', display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#0E2C66', mr: '10px' }}>PCIDSS</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#46464A' }}>
                    Implementation Phase Completion
                </Typography>
            </Box>
            <Grid container spacing={1}>
                {newList?.map((res, index) => (
                    <Grid key={index} item xs={12} md={6} lg={6}>
                        <CisoReportCard details={res} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        pciSummary: state?.dashboardReducers?.pciSummary,
    };
};
export default connect(mapStateToProps, { GetMerchantOverviewSummary, GetMerchantComplianceSummary })(PCIDSSReport);
