import { MoreOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { AiOutlineCheck } from 'react-icons/ai';
import { Dropdown, Menu } from 'antd';
import { TiCancelOutline } from 'react-icons/ti';
import React from 'react';

const { Item } = Menu;

const PlanRequestAction = (props) => {
    const { record, openViewDrawer, openRequestModal, openCustomPlanModal } = props;

    const menu = (
        <Menu>
            <Item key="0" onClick={() => openViewDrawer(record)}>
                <div className="flex items-center">
                    <EyeOutlined className="mr-1" /> View
                </div>
            </Item>
            <Item
                key="3"
                onClick={() => openCustomPlanModal('Edit', record)}
                disabled={record?.status === 'approved' || record?.tag !== 'custom'}
            >
                <div className="flex items-center">
                    <EditOutlined className="mr-1" /> Edit
                </div>
            </Item>
            <Item key="1" onClick={() => openRequestModal(record)} disabled={record?.status === 'approved'}>
                <div className="flex items-center">
                    <AiOutlineCheck className="mr-1" />
                    Approve
                </div>
            </Item>
            <Item key="2" onClick={console.log}>
                <div className="flex items-center">
                    <TiCancelOutline className="mr-1" />
                    Cancel
                </div>
            </Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined />
        </Dropdown>
    );
};

export default PlanRequestAction;
