import React, { useEffect } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import ContainerWithHeader from '../commons/ContainerWithHeader';
// import LabeledCircularTag from '../commons/LabeledCircularTag';
import { connect } from 'react-redux';
import { GetAllInfrastructures, GetAllComputers } from 'store/actions/merchantActions';
import { GetAllIntegrationsGroups } from 'store/actions/integrationsActions';
import { toast } from 'react-toastify';

const SixthPage = (props) => {
    const {
        dashboardResult,
        GetAllInfrastructures,
        all_merchant_infrastructures,
        GetAllComputers,
        all_computers,
        GetAllIntegrationsGroups,
    } = props;
    const {
        total_integration,
        total_computer_count,
        total_server_count,
        critical_servers,
        integrations,
        // critical_servers_without_agent,
    } = dashboardResult || {};

    useEffect(() => {
        const getMerchantInfrastructures = async () => {
            const res = await GetAllInfrastructures();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getMerchantInfrastructures();
    }, []);
    useEffect(() => {
        const getMerchantComputers = async () => {
            const res = await GetAllComputers();
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getMerchantComputers();
    }, []);
    useEffect(() => {
        const getAllIntegrationsGroups = async () => {
            const res = await GetAllIntegrationsGroups();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllIntegrationsGroups();
    }, []);

    const criticalServers = all_merchant_infrastructures?.filter(
        (infrastructure) => infrastructure?.sensitivity === 'critical'
    );
    const server_with_no_agent = all_merchant_infrastructures?.filter(
        (infrastructure) => infrastructure?.is_executed === false
    );

    return (
        <Stack sx={{ pb: 1 }}>
            <ContainerWithHeader header="server" sx={{ minHeight: '270px', mb: 2 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                    <Box sx={{ width: '50%', m: 2 }}>
                        <Typography
                            sx={{ color: '#0E2C66', fontWeight: 700, lineHeight: '14.52px', fontSize: 12, my: 1 }}
                        >
                            Critical Servers ({critical_servers})
                        </Typography>
                        <table
                            style={{
                                border: '0.5px solid #C7C6CA',
                                marginTop: '10px',
                                width: '100%',
                            }}
                        >
                            <thead
                                style={{
                                    border: '0.5px solid #C7C6CA',
                                }}
                            >
                                <tr style={{ border: '0.5px solid #C7C6CA', borderRadius: '6px 6px 0px 0px' }}>
                                    <td
                                        style={{
                                            padding: '5px',
                                            width: '50%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                            Server
                                        </Typography>
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',
                                            width: '50%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                            Type
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {criticalServers?.map((server) => {
                                    return (
                                        <tr style={{ width: '100%' }}>
                                            <td
                                                style={{
                                                    padding: '5px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#0E2C66',
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        mb: 1,
                                                    }}
                                                >
                                                    {server?.asset_number}
                                                </Typography>
                                            </td>
                                            <td
                                                style={{
                                                    padding: '5px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#0E2C66',
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        mb: 1,
                                                    }}
                                                >
                                                    {server?.ip_type}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Box>
                    <Box sx={{ width: '50%', m: 2 }}>
                        <Typography
                            sx={{ color: '#0E2C66', fontWeight: 700, lineHeight: '14.52px', fontSize: 12, my: 1 }}
                        >
                            Servers with no agents installed ({server_with_no_agent?.length})
                        </Typography>
                        <table
                            style={{
                                border: '0.5px solid #C7C6CA',
                                marginTop: '10px',
                                width: '100%',
                            }}
                        >
                            <thead
                                style={{
                                    border: '0.5px solid #C7C6CA',
                                }}
                            >
                                <tr style={{ border: '0.5px solid #C7C6CA', borderRadius: '6px 6px 0px 0px' }}>
                                    <td
                                        style={{
                                            padding: '5px',
                                            fontSize: '13px',
                                            fontWeight: 700,
                                            color: '#46464A',
                                            borderRadius: '6px 6px 0px 0px',
                                            width: '50%',
                                        }}
                                    >
                                        Server
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',
                                            fontSize: '13px',
                                            fontWeight: 700,
                                            color: '#46464A',
                                            width: '50%',
                                        }}
                                    >
                                        Type
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {server_with_no_agent?.map((server) => {
                                    return (
                                        <tr style={{ width: '100%' }}>
                                            <td
                                                style={{
                                                    padding: '5px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#0E2C66',
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        my: 1,
                                                    }}
                                                >
                                                    {server?.asset_number}
                                                </Typography>
                                            </td>
                                            <td
                                                style={{
                                                    padding: '5px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#0E2C66',
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        my: 1,
                                                    }}
                                                >
                                                    {server?.ip_type}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </ContainerWithHeader>
            <ContainerWithHeader header="infrastructures" sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Box sx={{ width: '33%', m: 2 }}>
                        <Typography
                            sx={{ color: '#0E2C66', fontWeight: 700, lineHeight: '14.52px', fontSize: 12, my: 1 }}
                        >
                            Number of Computers ({total_computer_count})
                        </Typography>
                        <table
                            style={{
                                border: '0.5px solid #C7C6CA',
                                marginTop: '10px',
                                width: '100%',
                            }}
                        >
                            <thead
                                style={{
                                    border: '0.5px solid #C7C6CA',
                                }}
                            >
                                <tr style={{ border: '0.5px solid #C7C6CA', borderRadius: '6px 6px 0px 0px' }}>
                                    <td
                                        style={{
                                            padding: '5px',
                                            fontSize: '13px',
                                            fontWeight: 700,
                                            color: '#46464A',
                                            borderRadius: '6px 6px 0px 0px',
                                            width: '50%',
                                        }}
                                    >
                                        Computer
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {all_computers?.map((computer) => {
                                    return (
                                        <tr style={{ width: '100%' }}>
                                            <td
                                                style={{
                                                    padding: '5px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#0E2C66',
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        mb: 1,
                                                    }}
                                                >
                                                    {computer?.serialnumber}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Box>
                    <Box sx={{ width: '33%', m: 2 }}>
                        <Typography
                            sx={{ color: '#0E2C66', fontWeight: 700, lineHeight: '14.52px', fontSize: 12, my: 1 }}
                        >
                            Total number of Servers ({total_server_count})
                        </Typography>
                        <table
                            style={{
                                border: '0.5px solid #C7C6CA',
                                marginTop: '10px',
                                width: '100%',
                            }}
                        >
                            <thead
                                style={{
                                    border: '0.5px solid #C7C6CA',
                                }}
                            >
                                <tr style={{ border: '0.5px solid #C7C6CA', borderRadius: '6px 6px 0px 0px' }}>
                                    <td
                                        style={{
                                            padding: '5px',
                                            borderRadius: '6px 6px 0px 0px',
                                            width: '50%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                            Server
                                        </Typography>
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',
                                            width: '50%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                            Type
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {all_merchant_infrastructures?.map((infrastructure) => {
                                    return (
                                        <tr style={{ width: '100%' }}>
                                            <td
                                                style={{
                                                    padding: '5px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#0E2C66',
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        mb: 1,
                                                    }}
                                                >
                                                    {infrastructure?.asset_number}
                                                </Typography>
                                            </td>
                                            <td
                                                style={{
                                                    padding: '5px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#0E2C66',
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        mb: 1,
                                                    }}
                                                >
                                                    {infrastructure?.ip_type}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Box>
                    <Box sx={{ width: '33%', m: 2 }}>
                        <Typography
                            sx={{ color: '#0E2C66', fontWeight: 700, lineHeight: '14.52px', fontSize: 12, my: 1 }}
                        >
                            Integrations ({total_integration})
                        </Typography>
                        <table
                            style={{
                                border: '0.5px solid #C7C6CA',
                                marginTop: '10px',
                                width: '100%',
                            }}
                        >
                            <thead
                                style={{
                                    border: '0.5px solid #C7C6CA',
                                }}
                            >
                                <tr style={{ border: '0.5px solid #C7C6CA', borderRadius: '6px 6px 0px 0px' }}>
                                    <td
                                        style={{
                                            padding: '5px',
                                            fontSize: '13px',
                                            fontWeight: 700,
                                            color: '#46464A',
                                            borderRadius: '6px 6px 0px 0px',
                                            width: '50%',
                                        }}
                                    >
                                        Server
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {integrations?.map((integration) => {
                                    return (
                                        <tr style={{ width: '100%' }}>
                                            <td
                                                style={{
                                                    padding: '5px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#0E2C66',
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        mb: 1,
                                                    }}
                                                >
                                                    {integration?.toUpperCase()}
                                                </Typography>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Box>
                    {/* <LabeledCircularTag label="Number of Computers" value={total_computer_count} />
                    <LabeledCircularTag label="Total number of Servers" value={total_server_count} />
                    <LabeledCircularTag label="Integrations" value={total_integration} /> */}
                </Box>
                {/* <Box sx={{ display: 'flex', py: 2, width: '100%' }}>
                    <Box sx={{ borderRight: '1px solid #0E2C66', width: '33%' }}>
                        {all_computers?.map((computer) => {
                            return (
                                <Typography
                                    key={computer?.id}
                                    sx={{ fontWeight: 500, fontSize: '10px', mb: '4px', ml: 3 }}
                                >
                                    {computer?.serialnumber}
                                </Typography>
                            );
                        })}
                    </Box>
                    <Box sx={{ borderRight: '1px solid #0E2C66', width: '33%' }}>
                        {all_merchant_infrastructures?.map((infrastructure) => {
                            return (
                                <Box key={infrastructure?.id} sx={{ display: 'flex', alignItems: 'center', mb: '8px' }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: '10px', mr: '8px', ml: 3 }}>
                                        {infrastructure?.asset_number}
                                    </Typography>
                                    <Box
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '10px',
                                            color: '#5E5E62',
                                            border: '0.8px solid #B8BCCC',
                                            borderRadius: '90px',
                                            px: '4px',
                                            py: '1px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {infrastructure?.ip_type}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box>
                        {integrations?.map((integration, index) => {
                            return (
                                <Typography key={index} sx={{ fontWeight: 500, fontSize: '10px', mb: '4px', ml: 3 }}>
                                    {integration?.toUpperCase()}
                                </Typography>
                            );
                        })}
                    </Box> 
                </Box> */}
            </ContainerWithHeader>
        </Stack>
    );
};

const mapStateToProps = (state) => {
    return {
        dashboardResult: state?.dashboardReducers?.dashboardResult,
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        all_computers: state?.merchantReducers?.all_computers,
        integrations_groups: state?.integrationsReducers?.integrations_groups,
    };
};
export default connect(mapStateToProps, { GetAllInfrastructures, GetAllComputers, GetAllIntegrationsGroups })(
    SixthPage
);
