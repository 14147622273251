import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';

//Redux
import { connect } from 'react-redux';
import { EditThreat } from 'store/actions/adminActions';

const EditModal = (props) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState({});
    const { open, handleClose, EditThreat, record } = props;

    const handleTextChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const onClose = () => {
        setData({});
        handleClose();
    };

    useEffect(() => {
        if (record) {
            setData(record);
        }
    }, [record]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const payload = { name: data?.name, sub_category: data?.sub_category, description: data?.description };

        const res = await EditThreat(payload, data?.id);
        setloading(false);
        if (res?.success) {
            toast.success('Threat Added Successfully!');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Modal title="Edit Threat" open={open} onCancel={handleClose} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="name">Threat Category</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            onChange={handleTextChange}
                            value={data.name || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="sub_category">Threat</label>
                        <Input
                            type="text"
                            size="large"
                            name="sub_category"
                            id="sub_category"
                            onChange={handleTextChange}
                            value={data.sub_category || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="description">Description</label>
                        <Input.TextArea
                            type="text"
                            size="large"
                            name="description"
                            id="description"
                            rows={4}
                            onChange={handleTextChange}
                            value={data.description || ''}
                            required
                        />
                    </Col>
                    <Col style={{ marginTop: '5rem' }}>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { EditThreat })(EditModal);
