import {
    ALL_GROUP_AUTOMATED_SCANS,
    ALL_SCAN_EXCEPTIONS,
    ALL_ASSIGNED_TASK,
    ALL_EXEMPTED_SCANS,
    ALL_AUTOMATED_SCAN_GROUPS,
    ALL_GROUP_AUTOMATED_SCANS_ITEM,
    ALL_SCANS,
    USER_ASSIGNED_TASK,
    SCAN_AI,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_GROUP_AUTOMATED_SCANS:
            return {
                ...state,
                all_group_automated_scans: action.payload,
            };
        case ALL_AUTOMATED_SCAN_GROUPS:
            return {
                ...state,
                all_automated_scan_group: action.payload,
            };
        case ALL_SCAN_EXCEPTIONS:
            return {
                ...state,
                all_scan_exceptions: action.payload,
            };
        case ALL_EXEMPTED_SCANS:
            return {
                ...state,
                all_exempted_scans: action.payload,
            };
        case ALL_GROUP_AUTOMATED_SCANS_ITEM:
            return {
                ...state,
                scan_items: action.payload,
            };
        case ALL_SCANS:
            return {
                ...state,
                all_scan_items: action.payload,
            };

        case SCAN_AI:
            return {
                ...state,
                scan_ai: action.payload,
            };
        case ALL_ASSIGNED_TASK:
            return {
                ...state,
                all_assigned_task: action.payload,
            };

        case USER_ASSIGNED_TASK:
            return {
                ...state,
                user_assigned_tasks: action.payload,
            };

        default:
            return state;
    }
};
