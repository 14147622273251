import { validation } from 'validate';
export const loginValidation = (values) => {
    const errors = {};
    const emailValidate = validation(values.email, 'email', true);
    const passwordValidate = validation(values.password, 'password', true);
    if (!emailValidate.isValid) {
        errors.email = emailValidate.errorMessage;
    }
    if (!passwordValidate.isValid) {
        errors.password = passwordValidate.errorMessage;
    }
    return errors;
};
export const registerValidation = (values) => {
    const errors = {};
    const emailValidate = validation(values.work_email, 'work_email', true);
    const FNValidate = validation(values.first_name, 'first name', true);
    const LNValidate = validation(values.last_name, 'last name', true);
    const companyValidate = validation(values.company, 'Company name', true);
    const passwordValidate = validation(values.password, 'New password', true);
    const typeValidate = validation(values.user_type, 'select', true);
    const phone = validation(values.phone_number, 'phone number', true);

    if (!emailValidate.isValid) {
        errors.work_email = emailValidate.errorMessage;
    }
    if (!FNValidate.isValid) {
        errors.first_name = FNValidate.errorMessage;
    }
    if (!LNValidate.isValid) {
        errors.last_name = LNValidate.errorMessage;
    }
    if (!phone.isValid) {
        errors.phone_number = phone.errorMessage;
    }
    if (!passwordValidate.isValid) {
        errors.password = passwordValidate.errorMessage;
    }
    if (!typeValidate.isValid) {
        errors.user_type = typeValidate.errorMessage;
    }
    if (!companyValidate.isValid) {
        errors.company = companyValidate.errorMessage;
    }
    return errors;
};

export const forgotPasswordValidate = (values) => {
    const errors = {};
    const emailValidate = validation(values.email, 'email', true);
    if (!emailValidate.isValid) {
        errors.email = emailValidate.errorMessage;
    }

    return errors;
};

export const passwordValidation = (values) => {
    const errors = {};
    const oldPass = validation(values.old_password, 'Password', true);
    const newPass = validation(values.new_password, 'New password', true);
    const confirmPass = validation(values.confirm_password, 'Confirm password', true);

    if (!oldPass.isValid) {
        errors.old_password = oldPass.errorMessage;
    }
    if (!newPass.isValid) {
        errors.new_password = newPass.errorMessage;
    }
    if (!confirmPass.isValid) {
        errors.confirm_password = confirmPass.errorMessage;
    }
    return errors;
};
