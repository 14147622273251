import React, { useState, useEffect } from 'react';
import { Button, Col, Input, Modal, Row, Select } from 'antd';
import { FEATURE_CATEGORIES, FEATURE_KEYS, VALUE_TYPES } from '../util';

// redux
import { connect } from 'react-redux';
import { AddFeature, EditFeature } from 'store/actions/subscriptionActions';
import { toast } from 'react-toastify';

const { Option } = Select;

const FeatureModal = (props) => {
    const { mode, onClose, modalData, AddFeature, EditFeature, all_features } = props;

    // state
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // functions
    const closeAll = () => {
        setData({});
        onClose();
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSelectChange = (value, name) => setData({ ...data, [name]: value });
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { id, suffix, name, category, value_type, key } = data;
        console.log(data);
        const payload = { suffix, name, category, value_type, key };
        const res = mode.toLowerCase() === 'add' ? await AddFeature(payload) : await EditFeature(payload, id);
        setLoading(false);
        if (res?.success) {
            toast.success(`You have ${mode.toLowerCase() === 'add' ? 'added a new' : 'editted a'} feature`);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        setData(modalData);
    }, [modalData]);
    return (
        <Modal
            visible={Boolean(mode)}
            onCancel={closeAll}
            title={`${mode} a Feature`}
            footer={null}
            destroyOnClose={true}
        >
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col span={24} style={{ margin: '1rem 0' }}>
                        <label htmlFor="name">Label of Feature</label>
                        <Input
                            type="text"
                            size="large"
                            name="name"
                            id="name"
                            value={data.name || ''}
                            onChange={handleTextChange}
                            required
                        />
                    </Col>
                    <Col span={24} style={{ margin: '1rem 0' }}>
                        <label htmlFor="name">Name/Key of Feature</label>
                        <Select
                            size="large"
                            name="key"
                            id="key"
                            onChange={(value) => handleSelectChange(value, 'key')}
                            value={data.key || ''}
                            required
                            style={{ width: '100%' }}
                        >
                            {FEATURE_KEYS.filter((key) => {
                                console.log(key);
                                return !all_features?.find((feature) => feature?.key === key);
                            }).map((key) => {
                                return (
                                    <Option value={key} key={key}>
                                        {key}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col span={24} style={{ margin: '1rem 0' }}>
                        <label htmlFor="category">Category</label>
                        <Select
                            size="large"
                            name="category"
                            id="category"
                            onChange={(value) => handleSelectChange(value, 'category')}
                            value={data.category || ''}
                            required
                            style={{ width: '100%' }}
                        >
                            {FEATURE_CATEGORIES.map((category) => {
                                return (
                                    <Option value={category} key={category}>
                                        {category}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                    <Col span={24} style={{ margin: '1rem 0' }}>
                        <label htmlFor="category">Type</label>
                        <Select
                            size="large"
                            name="value_type"
                            id="value_type"
                            onChange={(value) => handleSelectChange(value, 'value_type')}
                            value={data.value_type || ''}
                            required
                            style={{ width: '100%' }}
                        >
                            {VALUE_TYPES.map((type) => {
                                return (
                                    <Option value={type.key} key={type.key}>
                                        {type.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                    {data.value_type !== 'check' && (
                        <Col span={24} style={{ margin: '1rem 0' }}>
                            <label htmlFor="name">Suffix / Items</label>
                            <Input
                                type="text"
                                size="large"
                                name="suffix"
                                id="suffix"
                                value={data.suffix || ''}
                                onChange={handleTextChange}
                                required
                            />
                        </Col>
                    )}
                </Row>
                <Row justify="end">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return { all_features: state?.subscriptionReducers?.all_features };
};
export default connect(mapStateToProps, { AddFeature, EditFeature })(FeatureModal);
