import { Button, Col, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';

// redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateQAReviwer, UpdateQAReviwer } from 'store/actions/auditActions';

const QAReviewerModal = (props) => {
    const { open, mode, modalData, handleClose, CreateQAReviwer, UpdateQAReviwer } = props;

    // state
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    // functions
    const closeAll = () => {
        setData({});
        handleClose();
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = mode === 'Add' ? await CreateQAReviwer(data) : await UpdateQAReviwer(data, modalData?.id);
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            setData(modalData);
        }
    }, [modalData, open]);

    return (
        <Modal title={`${mode} QA Reviewers`} open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-1">
                        <label htmlFor="qa_reviewer_name">Name</label>
                        <Input
                            type="text"
                            size="large"
                            name="qa_reviewer_name"
                            id="qa_reviewer_name"
                            onChange={handleTextChange}
                            value={data?.qa_reviewer_name || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-1">
                        <label htmlFor="qa_reviewer_phone_no">Phone Number</label>
                        <Input
                            type="text"
                            size="large"
                            name="qa_reviewer_phone_no"
                            id="qa_reviewer_phone_no"
                            onChange={handleTextChange}
                            value={data?.qa_reviewer_phone_no || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-1">
                        <label htmlFor="qa_reviewer_email">Email</label>
                        <Input
                            type="text"
                            size="large"
                            name="qa_reviewer_email"
                            id="qa_reviewer_email"
                            onChange={handleTextChange}
                            value={data?.qa_reviewer_email || ''}
                            required
                        />
                    </Col>
                    <Col span={24} className="my-1">
                        <label htmlFor="qa_reviewer_pci_credentials">PCI credential</label>
                        <Input
                            type="text"
                            size="large"
                            name="qa_reviewer_pci_credentials"
                            id="qa_reviewer_pci_credentials"
                            onChange={handleTextChange}
                            value={data?.qa_reviewer_pci_credentials || ''}
                            required
                        />
                    </Col>
                    <Col className="mt-3">
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, { CreateQAReviwer, UpdateQAReviwer })(QAReviewerModal);
