import React, { useMemo, useState, useEffect } from 'react';

// mui components
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
// import { ArrowBack } from '@mui/icons-material';

// core component
import VendorInformation from './vendorViewSections/VendorInformation';
import QuestionnaireFormsView from './vendorViewSections/QuestionnaireFormsView';

// redux
import { connect } from 'react-redux';
import { GetOneVendor } from 'store/actions/vendorActions';
// import ExitModal from './vendorViewSections/ExitModal';
import { toast } from 'react-toastify';
import AppSideBar from 'components/new_components/AppSideBar';

import logo from 'assets/img/brand/logo.svg';
import Success from './vendorViewSections/Success';

const VendorsView = (props) => {
    const theme = useTheme();
    const midColour = '#F8F9FE';
    // props
    const { location, match, GetOneVendor, one_vendor } = props;
    const readonly = useMemo(() => location?.state?.readonly, [props]);
    const merchant = useMemo(() => match.params.merchant, [props]);
    const vendor = useMemo(() => match.params.vendor, [props]);

    // state
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    // const [exitModal, setExitModal] = useState(false);

    // functions
    const getOneVendor = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetOneVendor(vendor);
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    // const openExitModal = () => setExitModal(true);
    // const closeExitModal = () => setExitModal(false);

    useEffect(() => {
        if (readonly || one_vendor?.has_confirmed_details) {
            setPage(1);
        }
    }, [readonly]);
    useEffect(() => {
        getOneVendor();
    }, []);
    return (
        <>
            {one_vendor?.has_responded ? (
                <Success />
            ) : (
                <Box sx={{ display: 'flex', backgroundColor: '#F8F9FE', height: '100vh', overflowY: 'scroll' }}>
                    {' '}
                    <AppSideBar midColour={midColour} />
                    <Stack sx={{ width: '97.3%' }}>
                        {!page && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: '45px',
                                    mb: '45px',
                                    ml: '75px',
                                }}
                            >
                                <img src={logo} className="logo" alt="SmartComply logo" />
                            </Box>
                        )}
                        {page > 0 ? (
                            <Stack
                                alignItem="center"
                                direction="row"
                                flex={0.5}
                                alignItems="center"
                                sx={{ pl: 8.5, mt: 2, background: '#F8F9FE' }}
                            >
                                <Box sx={{ width: 340 }}>
                                    <img src={logo} className="w-[140px]" alt="SmartComply logo" />
                                </Box>
                                <Box sx={{ width: 'calc(100% - 270px)' }}>
                                    <Typography
                                        sx={{
                                            cursor: 'pointer',
                                            color: theme.palette.primary.main,
                                            fontWeight: 700,
                                            fontSize: '34px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Vendor Questionnaire
                                    </Typography>
                                </Box>
                            </Stack>
                        ) : null}
                        {page > 0 ? (
                            <QuestionnaireFormsView
                                readonly={readonly}
                                vendor={vendor}
                                merchant={merchant}
                                page={page}
                                setPage={setPage}
                            />
                        ) : (
                            <VendorInformation
                                vendor={vendor}
                                setPage={setPage}
                                one_vendor={one_vendor}
                                contentLoading={loading.content}
                            />
                        )}
                    </Stack>
                </Box>
            )}
            {/* <header
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '50%',
                    backgroundColor: '#fff',
                    zIndex: 100,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        overflow: 'hidden',
                        lineHeight: 0,
                        transform: 'rotate(180deg)',
                    }}
                >
                    <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1200 120"
                        preserveAspectRatio="none"
                        style={{
                            position: 'relative',
                            display: 'block',
                            width: 'calc(100% + 1.3px)',
                            height: '57px',
                            transform: 'rotateY(180deg)',
                        }}
                    >
                        <path
                            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                            className="shape-fill"
                            style={{ fill: '#FFFFFF' }}
                        ></path>
                    </svg>
                </div>
            </header> */}
            {/* {readonly && (
                    <Box
                        sx={{
                            pt: '4rem',
                            pl: '2rem',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 101,
                        }}
                    >
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBack />}
                            color="primary"
                            sx={{ textTransform: 'capitalize' }}
                            onClick={openExitModal}
                        >
                            Dashboard
                        </Button>
                    </Box>
                )} */}
            {/* <ExitModal open={exitModal} handleClose={closeExitModal} /> */}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        one_vendor: state?.vendorReducers?.one_vendor,
    };
};
export default connect(mapStateToProps, { GetOneVendor })(VendorsView);
