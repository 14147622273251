import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { Add } from '@mui/icons-material';

//core components
import AppTable from 'components/new_components/app-table';
import AppButton from 'components/new_components/AppButton';
import AppTag from 'components/new_components/AppTags';
import VendorsTableActions from './VendorsTableActions';
import RiskLevelsModal from './RiskLevelsModal';
import VendorDeleteModal from './VendorDeleteModal';
import VendorModal from './VendorModal';
//redux
import { connect } from 'react-redux';
import { GetAllVendors } from 'store/actions/vendorActions';

//utils
import { vendorsInitialValues } from '../../utils/constants';
import { GetRemaining } from '../../../../../components/Pricing/usePricingHook';
// import AuthNotificationBar from "../../../AccountSetup/TwoFactorAuth/AuthNotificationBar";

//Userguide
import AppUserGuide from 'components/new_components/AppUserGuide';
import { steps } from './steps';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';

//translations
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const NO_PER_PAGE = 8;

const Vendors = (props) => {
    const { GetAllVendors, all_vendors, pricing_plan, userguide_info, all_article_header, reff } = props;
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('');
    const [modalPayload, setModalPayload] = useState(vendorsInitialValues);
    const [modalPayloadId, setModalPayloadId] = useState(0);
    const [riskModalOpen, setRiskModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //translation
    const { t } = useTranslation('vendorManagement');

    const stepsTargetRef = useRef([]);
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Vendor Management'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ vendors: true });
        setToggleUserGuide({ ...toggleUserGuide, vendors: false, actingasavendor: false });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, vendors: false });
        setOpenArticle(false);
    };

    const columns = [
        {
            title: t('table.column1'),
            render: (row) => <>{row?.name}</>,
        },
        {
            title: t('table.column2'),
            key: 'email',
            render: (row) => <>{row?.email}</>,
        },
        {
            title: t('table.column3'),
            key: 'risk_level',
            render: (row) => (
                <>
                    {row?.risk_level?.toLowerCase() || row?.sensitivity?.toLowerCase() === 'low' ? (
                        <AppTag text="Low" type="success" />
                    ) : row?.risk_level?.toLowerCase() || row?.sensitivity?.toLowerCase() === 'medium' ? (
                        <AppTag text="Medium" type="pending" />
                    ) : row?.risk_level?.toLowerCase() || row?.sensitivity?.toLowerCase() === 'high' ? (
                        <AppTag text="High" type="high" />
                    ) : row?.risk_level?.toLowerCase() || row?.sensitivity?.toLowerCase() === 'critical' ? (
                        <AppTag text="Critical" type="failed" />
                    ) : (
                        <AppTag text={row?.risk_level?.toUpperCase()} type="default" />
                    )}
                </>
            ),
        },
        {
            title: t('table.column4'),
            key: 'product',
            render: (row) => <>{row?.products}</>,
        },
        {
            title: t('table.column5'),
            key: 'has_responded',
            render: (row) => (
                <>
                    {row?.has_responded ? (
                        <AppTag text={t('table.responded')} type="success" />
                    ) : (
                        <AppTag text={t('table.notResponded')} type="failed" />
                    )}
                </>
            ),
        },
        {
            title: t('table.column6'),
            key: 'date_created',
            render: (row) => <>{moment(row?.date_created).format('MMM Do, YYYY')}</>,
        },
        {
            title: t('table.column7'),
            align: 'center',
            key: 'action',
            render: (row) => (
                <>
                    <VendorsTableActions row={row} openModal={openModal} openDeleteModal={openDeleteModal} />
                </>
            ),
        },
    ];

    //functions
    const handlePageChange = (page) => {
        setPage(page);
    };

    const openModal = (mode, payload = vendorsInitialValues, id) => {
        setModalOpen(true);
        setModalMode(mode);
        setModalPayload(payload);
        setModalPayloadId(id);
    };
    const closeModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalPayload({}), 1000);
    };
    const openRiskLevelsModal = () => {
        setRiskModalOpen(true);
    };
    const closeRiskLevelsModal = () => {
        setRiskModalOpen(false);
    };

    const openDeleteModal = (id) => {
        setModalPayloadId(id);
        setDeleteModalOpen(true);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setModalPayloadId(0);
    };

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.vendors ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.vendors]);

    useEffect(() => {
        const getAllVendors = async () => {
            setloading(true);
            const res = await GetAllVendors();
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllVendors();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(all_vendors?.length ? all_vendors?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return () => setPageData([]);
    }, [page, all_vendors]);

    const { reminderCount } = GetRemaining(pricing_plan?.vendor_mgt, all_vendors?.length);

    return (
        <Box sx={{ mt: '3rem' }}>
            {/*<div>
        {pricing_plan?.vendor_mgt !== all_vendors?.length && <AuthNotificationBar text="You have exceeded your number of users" />}
      </div>*/}
            <AppTable
                columns={columns}
                data={pageData}
                loading={loading}
                sorter={(a, b) => b?.id - a?.id}
                title={t('table.title')}
                dataLength={all_vendors?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <AppButton
                        icon={<Add />}
                        name={t('table.addVendor')}
                        color="primary"
                        variant="contained"
                        disabled={reminderCount === 0}
                        disableElevation
                        onClick={() => openModal('add')}
                        key={1}
                        ref={reff}
                    />,
                ]}
            />

            <VendorModal
                open={modalOpen}
                handleClose={closeModal}
                isAddModal={modalMode === 'add'}
                payload={modalPayload}
                id={modalPayloadId}
                openRiskLevelsModal={openRiskLevelsModal}
            />
            <RiskLevelsModal open={riskModalOpen} handleClose={closeRiskLevelsModal} />

            <VendorDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={modalPayloadId} />

            <AppUserGuide
                open={false ?? openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Vendors"
                headerId={oneArticle?.id}
                icon={true}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_vendors: state?.vendorReducers?.all_vendors,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.vendors,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps, { GetAllVendors })(Vendors);
