import React from 'react';
import { Box, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';

const ResponseItem = ({ id, title, activeTab, setActiveTab }) => {
    const theme = useTheme();

    const handleClick = () => {
        setActiveTab(id);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                textAlign: 'left',
                width: '100%',
                py: '10px',
                px: '15px',
                mb: '10px',
                backgroundColor: activeTab === id ? '#FEFBFF' : '#EEF0FF',
            }}
            component="button"
            onClick={handleClick}
        >
            <Typography
                sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    color: activeTab === id ? theme.palette.primary[900] : '#919094',
                    fontWeight: activeTab === id ? 600 : 500,
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default ResponseItem;
