import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import AppTable from 'components/new_components/app-table';
import useSearch from 'hooks/useSearch';
import KycHeading from './kycHeading';
import KycCheckModal from '../../components/KycCheckModal';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePickerModal from './DatePickerModal';

const NO_PER_PAGE = 10;

const KycChecks = (props) => {
    const {
        all_kyc_result,
        match_status,
        setMatch_Status,
        entity_type,
        setEntityType,
        loading,
        date_created,
        setDate_created,
        dateModal,
        setDateModal,
        to,
        setTo,
        from,
        setFrom,
        handleFilter,
        closeDatePickerModal,
    } = props;
    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [payload, setPayload] = useState(null);
    const [open, setOpen] = useState(false);

    const openModal = (data) => {
        setOpen(true);
        setPayload(data);
    };
    const CloseModal = () => {
        setOpen(false);
        setPayload(null);
    };

    const { data, handleSearch } = useSearch(all_kyc_result, ['name', 'match_status', 'entity_type']);

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px', cursor: 'pointer' }}>{row?.name}</Typography>
            ),
        },
        {
            title: 'Match status',
            key: 'match_status',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {row?.match_status?.replace(/_/g, ' ')}
                </Typography>
            ),
        },
        {
            title: 'Entity type',
            key: 'entity_type',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {row?.entity_type?.replace(/_/g, ' ')}
                </Typography>
            ),
        },
        {
            title: 'Date created',
            key: 'date_created',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {moment(row?.date_created).format('MMMM Do, YYYY (h:mm a)')}
                </Typography>
            ),
        },
        {
            title: 'Date updated',
            key: 'date_updated',
            render: (row) => (
                <Typography
                    component="span"
                    sx={{
                        color: '#5E5E62',
                        fontSize: '14px',
                    }}
                >
                    {moment(row?.date_updated).format('MMMM Do, YYYY (h:mm a)')}
                </Typography>
            ),
        },
        {
            title: 'Total search hit',
            key: 'total_hits',
            render: (row) => (
                <Typography
                    component={'span'}
                    sx={{
                        color: '#5E5E62',
                        fontSize: '11px',
                        fontWeight: 500,
                        border: '1px solid #E1E2EC',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '30px',
                        height: '30px',
                    }}
                >
                    {row?.total_hits}
                </Typography>
            ),
        },
    ];

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);

    const handlePageChange = (page) => {
        setPage(page);
    };
    // const
    return (
        <div className="mt-2">
            <>
                <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#46464A', mt: 2 }}>
                    Entity Kyc Check
                </Typography>
                <AppTable
                    columns={columns}
                    data={pageData}
                    sorter={(a, b) => b?.id - a?.id}
                    loading={loading}
                    clickableRow={() => true}
                    onRowClick={openModal}
                    title={
                        <KycHeading
                            onSearch={handleSearch}
                            date_created={date_created}
                            entity_type={entity_type}
                            setEntityType={setEntityType}
                            match_status={match_status}
                            setMatch_Status={setMatch_Status}
                            handleFilter={handleFilter}
                            setDateModal={setDateModal}
                        />
                    }
                    dataLength={all_kyc_result?.length}
                    noPerPage={NO_PER_PAGE}
                    page={page}
                    onPageChange={handlePageChange}
                    // actions={data && data?.length > 0 ? <RiskAction /> : ''}
                />
                <KycCheckModal open={open} handleClose={CloseModal} payload={payload} />
                <DatePickerModal
                    open={dateModal}
                    handleClose={closeDatePickerModal}
                    from={from}
                    to={to}
                    setFrom={setFrom}
                    setTo={setTo}
                    dateModal={dateModal}
                    setDateModal={setDateModal}
                    date_created={date_created}
                    setDate_created={setDate_created}
                />
            </>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_kyc_result: state?.fraudGuardianReducers?.all_kyc_result,
    };
};

export default connect(mapStateToProps, {})(KycChecks);
