import React from 'react';
import { Box, Typography, ListItem, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
//core components
import AppProgressBar from 'components/new_components/AppProgressBar';
//utils

import { Menu } from '@mui/icons-material';

const ResponseHeader = (props) => {
    const theme = useTheme();
    const { title, id, activeTab, value, risk_level, setOpenDrawer, openDrawer, score_percent } = props;

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: theme.palette.white.main,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    boxShadow: 'none',
                    borderBottom: '3px solid ' + theme.palette.primary[100],
                    zIndex: 200,
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ pl: '5px', display: { xs: 'block', lg: 'none' } }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => setOpenDrawer(!openDrawer)}
                        >
                            <Menu color="primary" sx={{ fontSize: '2rem', pb: '5px' }} />
                        </IconButton>
                    </Box>

                    <Box>
                        {activeTab === id ? (
                            <Typography
                                variant="h3"
                                noWrap
                                component="h3"
                                sx={{
                                    m: 0,
                                    py: '17.5px',
                                    px: '20px',
                                    fontSize: { xs: '14px', lg: '18px' },
                                    color: theme.palette.primary[900],
                                    fontWeight: '700',
                                    borderBottom: '4px solid ' + theme.palette.primary[900],
                                }}
                            >
                                {title}
                            </Typography>
                        ) : (
                            <div></div>
                        )}
                    </Box>
                </Box>
                <Box>
                    {activeTab === id ? (
                        <Box
                            sx={{
                                pr: '40px',
                                pb: { xs: '3px' },
                            }}
                        >
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                        color: theme.palette.gray[900],
                                        whiteSpace: 'pre',
                                        fontSize: { xs: '8px', md: '10px' },
                                    }}
                                >
                                    Score Card
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: '400',
                                        color: theme.palette.gray[900],
                                        pl: { xs: '3px', md: 3, lg: 5 },
                                        fontSize: { xs: '7px', md: '10px' },
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {score_percent}% ({risk_level})
                                </Typography>
                            </ListItem>
                            <Box>
                                <AppProgressBar
                                    value={value}
                                    sx={{ height: '4.89px' }}
                                    color={
                                        risk_level === 'medium' ? 'warning' : risk_level === 'low' ? 'success' : 'error'
                                    }
                                />
                            </Box>
                        </Box>
                    ) : (
                        <div></div>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default ResponseHeader;
