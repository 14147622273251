import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
//core components
import { List, ListItem, ListItemText, ListItemAvatar, Typography, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingState from 'components/new_components/LoadingState';
import AppReportModal from 'components/new_components/AppReportModal';
import { Download, CalendarTodayOutlined, FiberManualRecord } from '@mui/icons-material';
import FileIcon from '../../../assets/img/FileIcon.svg';

//redux
import { connect } from 'react-redux';
import { GetMerchantAsvscanReportById } from 'store/actions/merchantActions';
import { formatDateObjectHandler } from 'utils';

//translations
import { useTranslation } from 'react-i18next';

const PCIReportModal = (props) => {
    //props
    const { open, handleClose, title, subtitle, GetMerchantAsvscanReportById, report, asv_reports_by_id } = props;

    //state
    const [getReportsLoading, setGetReportLoading] = useState(false);

    //theme
    const theme = useTheme();

    //translation
    const { t } = useTranslation('pciscans');

    const getScanReports = async (id) => {
        setGetReportLoading(true);
        const res = await GetMerchantAsvscanReportById(id);
        setGetReportLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (report) {
            getScanReports(report);
        }
    }, [report]);
    return (
        <AppReportModal open={open} handleClose={handleClose} title={title} width={580} subtitle={subtitle}>
            <List sx={{ paddingTop: 0, mb: 3 }}>
                {getReportsLoading ? (
                    <LoadingState />
                ) : (
                    asv_reports_by_id
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((report) => {
                            return (
                                <ListItem sx={{ alignItems: 'flex-start' }} key={report?.id}>
                                    <ListItemAvatar>
                                        <img src={FileIcon} alt="file icon" />
                                    </ListItemAvatar>
                                    <ListItemText sx={{ fontSize: '16px', color: theme.palette.gray[800], ml: 1 }}>
                                        <Typography
                                            color={theme.palette.gray[900]}
                                            sx={{ fontWeight: 500, fontSize: '18px', marginTop: '-8px' }}
                                        >
                                            {`${report?.name} ${report?.version}`}
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ fontSize: '12px', color: theme.palette.gray[40], my: 0.4 }}
                                        >
                                            <CalendarTodayOutlined
                                                sx={{ fontSize: '12px', color: theme.palette.gray[40], mr: 1 }}
                                            />
                                            {formatDateObjectHandler(report?.date_created, 'MMMM Do, YYYY')}
                                            <FiberManualRecord
                                                sx={{
                                                    width: 10,
                                                    height: 10,
                                                    marginRight: theme.spacing(0.5),
                                                    ml: 1.5,
                                                }}
                                                color={report?.status ? 'success' : 'error'}
                                            />
                                            {report?.status ? t('pciReportModal.passed') : t('pciReportModal.failed')}
                                        </Stack>
                                        <Stack direction="row">
                                            <Box
                                                component="a"
                                                href={report?.executive}
                                                target="_blank"
                                                rel="noreferer noreferrer"
                                                sx={{
                                                    color: theme.palette.neutral[50],
                                                    border: `0.4px solid ${theme.palette.gray[300]}`,
                                                    width: 'fit-content',
                                                    px: 1.5,
                                                    py: 0.6,
                                                    fontSize: 12,
                                                    borderRadius: '90px',
                                                    textDecoration: 'none',
                                                    '&:hover': {
                                                        color: theme.palette.neutral[50],
                                                        textDecoration: 'none',
                                                    },
                                                }}
                                            >
                                                <Download sx={{ fontSize: 12 }} /> {t('pciReportModal.executive')}
                                            </Box>
                                            {report?.detailed && (
                                                <Box
                                                    component="a"
                                                    href={report?.detailed}
                                                    target="_blank"
                                                    rel="noreferer noreferrer"
                                                    sx={{
                                                        color: theme.palette.neutral[50],
                                                        border: `0.4px solid ${theme.palette.gray[300]}`,
                                                        width: 'fit-content',
                                                        px: 1.5,
                                                        py: 0.6,
                                                        ml: 1,
                                                        fontSize: 12,
                                                        borderRadius: '90px',
                                                        textDecoration: 'none',
                                                        '&:hover': {
                                                            color: theme.palette.neutral[50],
                                                            textDecoration: 'none',
                                                        },
                                                    }}
                                                >
                                                    <Download sx={{ fontSize: 12 }} /> {t('pciReportModal.detailed')}
                                                </Box>
                                            )}
                                        </Stack>
                                    </ListItemText>
                                </ListItem>
                            );
                        })
                )}
                {/* <ListItem sx={{ alignItems: 'flex-start' }}>
                    <ListItemAvatar>
                        <img src={FileIcon} alt="file icon" />
                    </ListItemAvatar>
                    <ListItemText sx={{ fontSize: '16px', color: theme.palette.gray[800], ml: 1 }}>
                        <Typography
                            color={theme.palette.gray[900]}
                            sx={{ fontWeight: 500, fontSize: '18px', marginTop: '-8px' }}
                        >
                            Download Executive Report
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ fontSize: '12px', color: theme.palette.gray[40], my: 0.4 }}
                        >
                            <CalendarTodayOutlined sx={{ fontSize: '12px', color: theme.palette.gray[40], mr: 1 }} />
                            September 18th, 2023
                            <FiberManualRecord
                                sx={{
                                    width: 10,
                                    height: 10,
                                    marginRight: theme.spacing(0.5),
                                    ml: 1.5,
                                }}
                                color="success"
                            />
                            Passed
                        </Stack>
                        <Stack direction="row">
                            <Box
                                component="a"
                                href={report?.executive}
                                target="_blank"
                                rel="noreferer noreferrer"
                                sx={{
                                    color: theme.palette.neutral[50],
                                    border: `0.4px solid ${theme.palette.gray[300]}`,
                                    width: 'fit-content',
                                    px: 1.5,
                                    py: 0.6,
                                    fontSize: 12,
                                    borderRadius: '90px',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: theme.palette.neutral[50],
                                        textDecoration: 'none',
                                    },
                                }}
                            >
                                <Download sx={{ fontSize: 12 }} /> Download Executive Report
                            </Box>
                            <Box
                                component="a"
                                href={report?.executive}
                                target="_blank"
                                rel="noreferer noreferrer"
                                sx={{
                                    color: theme.palette.neutral[50],
                                    border: `0.4px solid ${theme.palette.gray[300]}`,
                                    width: 'fit-content',
                                    px: 1.5,
                                    py: 0.6,
                                    ml: 1,
                                    fontSize: 12,
                                    borderRadius: '90px',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: theme.palette.neutral[50],
                                        textDecoration: 'none',
                                    },
                                }}
                            >
                                <Download sx={{ fontSize: 12 }} /> Download Detailed Report
                            </Box>
                        </Stack>
                    </ListItemText>
                </ListItem>
                {report.uploaded_by !== 'merchant' ? (
                    <ListItem sx={{ alignItems: 'flex-start' }}>
                        <ListItemAvatar>
                            <Avatar sx={{ background: theme.palette.primary[100] }}>
                                <Download />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{ fontSize: '16px', color: theme.palette.gray[800] }}>
                            <Typography
                                color={theme.palette.gray[900]}
                                sx={{ fontWeight: 500, fontSize: '18px', marginTop: '-8px' }}
                            >
                                Download Detailed Report
                            </Typography>
                            You can download your detailed report by click this link.
                            <a
                                href={report?.detailed}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    color: theme.palette.primary[900],
                                    textDecoration: 'underline',
                                }}
                            >
                                Click here to download.
                            </a>
                        </ListItemText>
                    </ListItem>
                ) : null} */}
            </List>
        </AppReportModal>
    );
};

const mapStateToProps = (state) => {
    return {
        asv_reports_by_id: state?.merchantReducers?.asv_reports_by_id,
    };
};
export default connect(mapStateToProps, { GetMerchantAsvscanReportById })(PCIReportModal);
