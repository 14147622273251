import React from 'react';

const TaskName = (props) => {
    const { data } = props;
    return data?.tag === 'compliance' ? (
        <div>
            <span className="capitalize">{data?.standard} </span>
            <span>{data?.document ? <>&gt; Document </> : data?.form ? <> &gt; Form </> : ''}</span>
            <span>{data?.compliance_tasks?.category ? <>&gt; {data?.compliance_tasks?.category} </> : ''}</span>
            <span>
                {data?.compliance_tasks?.subcategory ? (
                    <>
                        &gt;{' '}
                        {data?.standard === 'pcidss'
                            ? `Sub ${data?.compliance_tasks?.subcategory}`
                            : data?.compliance_tasks?.subcategory}{' '}
                    </>
                ) : (
                    ''
                )}
            </span>
            <span>
                {data?.document ? (
                    <>&gt; {data?.compliance_tasks?.document_title} </>
                ) : data?.form ? (
                    <>&gt; {data?.compliance_tasks?.form_title} </>
                ) : (
                    ''
                )}
            </span>
        </div>
    ) : (
        <div>Automated scan</div>
    );
};

export default TaskName;
