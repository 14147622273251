import React from 'react';
import AppDrawer from 'components/new_components/AppDrawer';
import { useTheme } from '@mui/styles';
import { ArrowBackIosRounded, FiberManualRecord } from '@mui/icons-material';
import { Typography, Chip, Stack, Tooltip } from '@mui/material';
import CheckDone from 'assets/img/dashboard/CheckDone.svg';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import { Progress } from 'antd';

const ComplianceHealthSuggestions = (props) => {
    const { open, handleClose, smartComplianceReport, ai_recommendations, complianceHealthPercentage } = props;

    const getTagType = (scan) => {
        if (scan?.toLowerCase()?.includes('scan')) {
            return 'failing scan(s)';
        } else if (scan?.toLowerCase()?.includes('infrastructure')) {
            return 'infra';
        } else if (scan?.toLowerCase()?.includes('testing')) {
            return 'failed test';
        } else if (scan?.toLowerCase()?.includes('check')) {
            return 'employees left';
        } else if (scan?.toLowerCase()?.includes('computer') || scan?.toLowerCase()?.includes('installation')) {
            return 'employees(s)';
        } else if (scan?.toLowerCase()?.includes('vendor')) {
            return 'vendor(s)';
        } else if (['PCI DSS', 'NDPR', 'ISO 27001', 'SOC 2', 'BLOCKCHAIN', 'ISO 22301', 'PCIDSS 4']?.includes(scan)) {
            return 'unattended';
        } else {
            return 'none';
        }
    };
    return (
        <AppDrawer
            open={open}
            icon={<ArrowBackIosRounded sx={{ color: '#46464A', fontSize: '18px' }} />}
            handleClose={handleClose}
            title={
                <div>
                    <p className="text-[16px] font-bold text-[#191B23]">Compliance health summary</p>
                    <div className="flex flex-row items-center gap-5">
                        <ComplianceTag
                            text="Needs attention"
                            type="high"
                            fontSize={10}
                            margin={0}
                            frequency={smartComplianceReport?.total_failed}
                        />
                        <ComplianceTag
                            text="Looks okay"
                            type="success"
                            fontSize={10}
                            margin={0}
                            frequency={smartComplianceReport?.total_passed}
                        />
                    </div>
                </div>
            }
            closeicon={
                <>
                    <Progress
                        type="dashboard"
                        percent={Math.round(100 - complianceHealthPercentage)}
                        success={{
                            percent: Math.round(100 - complianceHealthPercentage),
                        }}
                        trailColor="#FF5449"
                        width={50}
                    />
                </>
            }
        >
            <Stack direction="column" sx={{ mt: 3, mx: 1, gap: 1.5 }}>
                {smartComplianceReport?.scans?.length ? (
                    smartComplianceReport?.scans?.map((scan, index) => {
                        const reportRecommendation = ai_recommendations?.find(
                            (recommendation) => recommendation?.id === scan?.id
                        );
                        return (
                            <React.Fragment key={index}>
                                <SummaryItem
                                    type={!scan?.failed ? 'success' : 'error'}
                                    text={scan?.issue}
                                    tagText={scan?.failed ? getTagType(scan?.issue) : 'None'}
                                    frequency={scan?.failed ? scan?.failed : ''}
                                    reportRecommendation={reportRecommendation}
                                />
                            </React.Fragment>
                        );
                    })
                ) : (
                    <EmptyComponent />
                )}
            </Stack>
        </AppDrawer>
    );
};

const SummaryItem = (props) => {
    const { type, text, tagText, frequency, reportRecommendation } = props;
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 1, border: '1px solid #F0F0FA', borderRadius: '4px', pointerEvents: 'auto' }}
        >
            <Stack direction="row" alignItems="center" gap={1}>
                <img
                    src={type === 'success' ? CheckDone : '/img/exclamation_circle.svg'}
                    className="w-[16px] w-[16px]"
                    alt="status"
                />
                {type !== 'success' ? (
                    <Tooltip
                        title={reportRecommendation?.Recommendation || 'No recommendations at the moment'}
                        placement="top"
                        arrow
                    >
                        <div>
                            <Typography sx={{ fontSize: 11, pt: 0.5, color: '#5E5E62' }}>{text}</Typography>
                        </div>
                    </Tooltip>
                ) : (
                    <Typography sx={{ fontSize: 11, pt: 0.5, color: '#5E5E62' }}>{text}</Typography>
                )}
            </Stack>
            <ComplianceTag
                text={tagText}
                type={type === 'error' ? 'secondary' : type}
                fontSize={10}
                margin={0}
                frequency={frequency}
            />
        </Stack>
    );
};
const ComplianceTag = (props) => {
    const { text, type, frequency, fontSize } = props;
    const theme = useTheme();
    return (
        <div>
            <Chip
                sx={{
                    margin: 0,
                    border: 'none',
                    '& .MuiChip-label': { p: fontSize ? 0.2 : 'inital' },
                }}
                label={
                    <Typography
                        sx={{
                            fontSize: fontSize ? fontSize : 12,
                            fontWeight: 600,
                            color: theme.palette.gray[600],
                            textTransform: 'capitalize',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                        component="span"
                    >
                        <FiberManualRecord
                            sx={{
                                width: 8,
                                height: 8,
                                marginRight: theme.spacing(0.2),
                                '&.MuiSvgIcon-colorSecondary': {
                                    color: '#ACAAAF !important',
                                },
                            }}
                            color={type}
                        />
                        {frequency || frequency === 0 ? (
                            <span className="text-[10px] ml-[1px] mr-[3px]">{frequency}</span>
                        ) : null}
                        {text}
                    </Typography>
                }
                variant="outlined"
                size="small"
            />
        </div>
    );
};
export default ComplianceHealthSuggestions;
