import { Button, Col, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { AUDIT_STATUS } from 'views/merchant/audit/utils';

const DisapproveRequestModal = (props) => {
    const { open, handleClose, onProceed } = props;

    // states
    const [loading, setLoading] = useState(false);

    // functions
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        onProceed(AUDIT_STATUS.DISAPPROVED, e.target.reason.value);
        setLoading(false);
    };

    return (
        <Modal title="Disapprove Audit Request" open={open} onCancel={handleClose} footer={null} destroyOnClose>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="mb-2">
                            <label htmlFor="reason">Reason</label>
                            <Input.TextArea type="text" size="large" name="reason" id="reason" required />
                        </div>
                    </Col>
                    <Col>
                        <Button htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

export default DisapproveRequestModal;
