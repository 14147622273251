import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Drawer, Pagination, Row, Space, Spin } from 'antd';
import CategoryAnswer from './CategoryAnswer';
import DisapproveRequestModal from './DisapproveRequestModal';
import SignModal from './SignModal';
import { AUDIT_STATUS } from 'views/merchant/audit/utils';

// redux
import { connect } from 'react-redux';
import {
    GetAllAuditAnswers,
    GetAllAuditCategories,
    UpdateAuditRequest,
    GetAllQAReviwers,
} from 'store/actions/auditActions';
import { GetAllAuditors } from 'store/actions/adminActions';

const ViewRequestModal = (props) => {
    const {
        open,
        handleClose,
        GetAllAuditAnswers,
        GetAllAuditCategories,
        modalData,
        all_merchant_audit_answers,
        all_merchants,
        UpdateAuditRequest,
        GetAllAuditors,
        GetAllQAReviwers,
    } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [disapproveModal, setDisapprovedModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);

    // memo
    const merchant = useMemo(() => {
        return all_merchants?.find((merchant) => merchant?.id === modalData?.merchant);
    }, [all_merchants, modalData]);
    const categoryAnswers = useMemo(() => {
        return all_merchant_audit_answers
            ?.filter((answer) => answer?.merchant === merchant?.id)
            ?.sort((a, b) => a?.id - b?.id);
    }, [all_merchant_audit_answers]);

    // functions
    const openDisapproveModal = () => setDisapprovedModal(true);
    const closeDisapproveModal = () => setDisapprovedModal(false);
    const openApproveModal = () => setApproveModal(true);
    const closeApproveModal = () => setApproveModal(false);
    const handlePageChange = (page) => {
        setPage(page);
    };
    const getAllMerchantAuditAnswers = async () => {
        setLoading(true);
        const res = await GetAllAuditAnswers(modalData?.merchant);
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllAuditCategories = async () => {
        setLoading(true);
        const res = await GetAllAuditCategories(modalData?.compliance);
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const updateReviewStatus = async (status, disapprove_reason) => {
        const res = await UpdateAuditRequest(
            { status, disapprove_reason, merchant: modalData?.merchant },
            modalData?.id
        );
        if (res?.success) {
            setDisapprovedModal(false);
            return toast.success(
                `You have ${status} this review request successfully, the merchant will get a notification immediately.`
            );
        } else {
            return toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (modalData && open) {
            GetAllAuditors();
            GetAllQAReviwers();
            getAllMerchantAuditAnswers();
            getAllAuditCategories();
        }
    }, [open, modalData]);

    return (
        <>
            <Drawer
                title={`Review ${merchant?.name}'s Audit Request`}
                open={open}
                width="900px"
                getContainer={false}
                onClose={handleClose}
                destroyOnClose={true}
                extra={
                    <Space>
                        <Button
                            danger
                            onClick={openDisapproveModal}
                            disabled={loading || modalData?.status === AUDIT_STATUS.AUDITING}
                        >
                            Disapprove
                        </Button>
                        <Button
                            type="primary"
                            disabled={loading || modalData?.status === AUDIT_STATUS.AUDITING}
                            onClick={openApproveModal}
                        >
                            Approved Review
                        </Button>
                    </Space>
                }
            >
                {loading ? (
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                ) : (
                    <div className="flex flex-col h-full">
                        <div className="flex-1 mb-4">
                            <CategoryAnswer answer={categoryAnswers?.[page - 1]} />
                        </div>
                        <div className="self-end">
                            <Pagination
                                total={categoryAnswers?.length}
                                onChange={handlePageChange}
                                current={page}
                                pageSize={1}
                            />
                        </div>
                    </div>
                )}
            </Drawer>
            <DisapproveRequestModal
                open={disapproveModal}
                handleClose={closeDisapproveModal}
                onProceed={updateReviewStatus}
            />
            <SignModal open={approveModal} closeModal={closeApproveModal} record={modalData} />
        </>
    );
};
const mapStateToProps = (state) => ({
    all_merchant_audit_answers: state?.auditReducers?.all_audit_answers,
    all_audit_categories: state?.auditReducers?.all_audit_categories,
    all_merchants: state?.adminReducers?.all_merchants,
});
export default connect(mapStateToProps, {
    GetAllAuditAnswers,
    GetAllAuditCategories,
    UpdateAuditRequest,
    GetAllAuditors,
    GetAllQAReviwers,
})(ViewRequestModal);
