import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Row, Col, Tag, Modal } from 'antd';
import { MdCancel } from 'react-icons/md';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAllAuditors, AssignAuditorToMerchant } from 'store/actions/adminActions';

import LoadingState from 'components/new_components/LoadingState';
import { toast } from 'react-toastify';

const ViewAssignedAuditors = (props) => {
    const { visible, closeDrawer, record, standard } = props;

    const [loading, setLoading] = useState({ content: false });

    const dispatch = useDispatch();

    //accessing values from the redux store
    const all_auditors = useSelector((state) => state?.adminReducers?.all_auditors);

    //functions
    const getAuditorName = (id) => {
        const auditor_details = all_auditors?.find((auditor) => auditor?.id === id);
        console.log(auditor_details);

        return `${auditor_details?.owner_detail?.first_name} ${auditor_details?.owner_detail?.last_name}`;
    };

    const getAllAuditors = async () => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetAllAuditors());
        setLoading({ ...loading, content: false });
        if (!res.success) {
            toast.error(res.message);
        }
    };

    const auditorsByStandard = useMemo(() => {
        const filteredAuditors = record?.smart_auditors?.filter((auditor) =>
            auditor?.standard?.includes(standard?.toLowerCase())
        );
        console.log({ filteredAuditors });
        return filteredAuditors?.length ? filteredAuditors : [];
    }, [standard, record?.smart_auditors]);
    // useEffect
    useEffect(() => {
        getAllAuditors();
    }, []);

    console.log(record);
    return (
        <div>
            <Drawer
                title={`All ${record?.owner_detail?.first_name || ''} ${
                    record?.owner_detail?.last_name || ''
                } Auditors for ${standard}`}
                open={visible}
                width="800px"
                getContainer={false}
                onClose={closeDrawer}
                destroyOnClose={true}
                contentWrapperStyle={{
                    overflow: 'auto',
                }}
            >
                {!loading?.content ? (
                    <>
                        {auditorsByStandard?.length > 0 ? (
                            <div className="userdataContainer">
                                {auditorsByStandard?.map((user) => (
                                    <div className="item" key={user?.id}>
                                        <Row gutter={28} align="middle">
                                            <Col xs={12} xl={8} lg={8}>
                                                <span>Name:</span>
                                            </Col>
                                            <Col xs={12} xl={12} lg={12}>
                                                <div className="flex flex-row gap-4">
                                                    {getAuditorName(user?.id)}{' '}
                                                    <Tag
                                                        color="red"
                                                        onClick={() => {
                                                            Modal.confirm({
                                                                title: 'Confirm Action',
                                                                content:
                                                                    'Do you want to remove this auditor from this list? If yes, click "Ok"',
                                                                okText: 'Yes',
                                                                okType: 'danger',
                                                                cancelText: 'No',
                                                                async onOk() {
                                                                    const payload = record.smart_auditors?.filter(
                                                                        (aud) => aud?.id !== user?.id
                                                                    );
                                                                    const res = await dispatch(
                                                                        AssignAuditorToMerchant(record?.id, {
                                                                            smart_auditors: payload,
                                                                        })
                                                                    );
                                                                    if (res?.success) {
                                                                        toast.success(
                                                                            'Auditor has been removed from merchant'
                                                                        );
                                                                        closeDrawer();
                                                                    } else {
                                                                        toast.error(res?.message);
                                                                    }
                                                                },
                                                                onCancel() {
                                                                    console.log('Cancel');
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        Remove
                                                    </Tag>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row gutter={28} align="middle">
                                            <Col xs={12} xl={8} lg={8}>
                                                <span>Standard:</span>
                                            </Col>
                                            <Col xs={12} xl={12} lg={12}>
                                                <div className="flex flex-row gap-2" items-center>
                                                    {user?.standard?.map((standard, index) => {
                                                        return (
                                                            <Tag
                                                                key={index}
                                                                className="flex flex-row items-center gap-2 uppercase w-fit"
                                                            >
                                                                {standard}{' '}
                                                                {user?.standard?.length > 1 ? (
                                                                    <MdCancel
                                                                        className="cursor-pointer"
                                                                        onClick={() => () => {
                                                                            Modal.confirm({
                                                                                title: 'Confirm Action',
                                                                                content:
                                                                                    'Do you want to remove this auditor from this list? If yes, click "Ok"',
                                                                                okText: 'Yes',
                                                                                okType: 'danger',
                                                                                cancelText: 'No',
                                                                                async onOk() {
                                                                                    const payload =
                                                                                        user?.standard?.filter(
                                                                                            (std) => std !== standard
                                                                                        );

                                                                                    const res = await dispatch(
                                                                                        AssignAuditorToMerchant(
                                                                                            record?.id,
                                                                                            {
                                                                                                smart_auditors: [
                                                                                                    ...(record?.smart_auditors ||
                                                                                                        []),
                                                                                                    {
                                                                                                        ...(record
                                                                                                            ?.smart_auditors?.[
                                                                                                            index
                                                                                                        ] || {}),
                                                                                                        standard:
                                                                                                            payload,
                                                                                                    },
                                                                                                ],
                                                                                            }
                                                                                        )
                                                                                    );
                                                                                    if (res?.success) {
                                                                                        toast.success(
                                                                                            "This standard has been withdrawn from auditors' view."
                                                                                        );
                                                                                        closeDrawer();
                                                                                    } else {
                                                                                        toast.error(res?.message);
                                                                                    }
                                                                                },
                                                                                onCancel() {
                                                                                    console.log('Cancel');
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                ) : null}
                                                            </Tag>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex justify-center items-center">
                                <h1>No Auditor has been assigned to this merchant for this standard</h1>
                            </div>
                        )}
                    </>
                ) : (
                    <LoadingState />
                )}
            </Drawer>
        </div>
    );
};

export default ViewAssignedAuditors;
