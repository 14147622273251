import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Paper, MenuList } from '@mui/material';
import { Settings, Login, TextSnippet, LightbulbOutlined, Language } from '@mui/icons-material';

// core components
import AppMenuItem from 'components/new_components/AppMenuItem';

//
import { connect } from 'react-redux';
import { LogoutUser } from 'store/actions/authActions';
import { getUserToken } from 'utils';
import { getType } from 'utils';
import { endSession } from 'utils';

const UserMenu = (props) => {
    const theme = useTheme();
    const history = useHistory();
    const { handleClose, LogoutUser, openLanguageMenu, closeLanguageMenu } = props;

    const logout = async () => {
        await LogoutUser();
        handleClose();
        endSession(getUserToken('token'), getType('type'), getType('role'));
        history.push('/auth/login');
    };

    return (
        <>
            <Paper
                sx={{
                    maxWidth: '100%',
                    borderRadius: '24px',
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    overflow: 'hidden',
                    width: 270,
                }}
            >
                <MenuList sx={{ p: 0 }}>
                    <AppMenuItem icon={<Language fontSize="small" />} text="Language" onClick={openLanguageMenu} />
                    <AppMenuItem
                        icon={<Settings fontSize="small" />}
                        text="Settings"
                        component={Link}
                        to="/merchant/settings/account"
                        onClick={() => {
                            handleClose;
                            closeLanguageMenu();
                        }}
                    />
                    <AppMenuItem
                        icon={<LightbulbOutlined fontSize="small" />}
                        text="Page Tips"
                        onClick={handleClose}
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                    />
                    <AppMenuItem
                        icon={<TextSnippet fontSize="small" />}
                        text="Release notes"
                        to="/merchant/release-notes"
                        component={Link}
                        onClick={() => {
                            handleClose;
                            closeLanguageMenu();
                        }}
                    />
                    <AppMenuItem
                        icon={<Login fontSize="small" />}
                        text="Log out"
                        color={theme.palette.error[700]}
                        sx={{
                            borderTop: '1px solid' + theme.palette.gray[600],
                            '&, &:hover': {
                                backgroundColor: theme.palette.error[100] + '80',
                            },
                        }}
                        onClick={logout}
                    />
                </MenuList>
            </Paper>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { LogoutUser })(UserMenu);
