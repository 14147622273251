import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import { PersonAddAlt1 } from '@mui/icons-material';

// core components
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
import AppButton from 'components/new_components/AppButton';
import UserTableActions from './UserTableActions';
import UserModal from './UserModal';
import UserDeleteModal from './UserDeleteModal';

// images
import avatar from 'assets/img/avatar.jpg';

// others
import { initialUserValues } from './utils/constants';

// redux
import { connect } from 'react-redux';
import { GetAllMerchantUsers } from 'store/actions/merchantActions';
import PageHeader from 'components/new_components/PageHeader';
// import AuthNotificationBar from "../AccountSetup/TwoFactorAuth/AuthNotificationBar";
import SubscriptionAlert from '../../../components/Pricing/SubscriptionAlert';
import { GetRemaining } from '../../../components/Pricing/usePricingHook';

//Userguide
import AppUserGuide from 'components/new_components/AppUserGuide';
import { steps } from './steps';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';

//translation
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 8;

const Users = (props) => {
    const { all_users, GetAllMerchantUsers, pricing_plan, userguide_info, all_article_header } = props;
    // state
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState();
    const [modalPayload, setModalPayload] = useState(initialUserValues);
    const [loading, setLoading] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState({});
    const [page, setPage] = useState(1);
    // const [filteredUsers, setFilteredUsers] = useState(all_users);
    const [pageData, setPageData] = useState([]);
    const [allowedUser, setAllowedUser] = useState(0);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //Userguide
    const stepsTargetRef = useRef([]);
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    //transaltion
    const { t } = useTranslation('user');

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Users'),
        [all_article_header]
    );

    // functions
    // logic
    const openModal = (mode, payload = initialUserValues) => {
        setModalOpen(true);
        setModalMode(mode);
        setModalPayload(payload);
    };
    const closeModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalPayload({}), 1000);
    };
    const openDeleteModal = (payload = initialUserValues) => {
        setDeleteModalOpen(true);
        setDeletePayload(payload);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setTimeout(() => setDeletePayload({}), 1000);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };
    // const handleSearch = (e) => {
    //     const value = e.target.value;
    //     const filteredUsers = all_users?.filter((user) => user?.user_detail?.email?.includes(value));
    //     setFilteredUsers(filteredUsers);
    // };
    // async functions
    const getAllMerchantUsers = async () => {
        setLoading(true);
        const res = await GetAllMerchantUsers();
        setLoading(false);
        if (!res.message) {
            toast.error(res.message);
        }
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ users: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, users: false });
        setOpenArticle(false);
    };

    // useEffect
    useEffect(() => {
        getAllMerchantUsers();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        const filteredUsers = all_users ? all_users?.filter((user) => user?.user_detail?.user_type !== 'support') : [];
        setPageData(filteredUsers ? filteredUsers?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return () => setPageData([]);
    }, [page, all_users]);
    // constants
    const columns = [
        {
            title: t('table.columns.column1'),
            align: 'center',
            render: (row) => {
                const { first_name, last_name, picture } = row?.user_detail || {};
                return <Avatar alt={`${first_name} ${last_name}`} src={picture || avatar} sx={{ mx: 'auto' }} />;
            },
        },
        {
            title: t('table.columns.column2'),
            render: (row) => <>{row?.user_detail?.first_name + ' ' + row?.user_detail?.last_name}</>,
        },
        {
            title: t('table.columns.column3'),
            key: 'email',
            render: (row) => <>{row?.user_detail?.email}</>,
        },
        {
            title: t('table.columns.column4'),
            key: 'role',
            render: (row) => <>{row?.role?.toUpperCase()}</>,
        },
        {
            title: t('table.columns.column5'),
            key: 'status',
            render: (row) => {
                const isActive = Boolean(row?.user_detail?.first_name || row?.user_detail?.last_name);
                return (
                    <AppTag
                        text={isActive ? t('table.columns.active') : t('table.columns.inactive')}
                        type={isActive ? 'success' : 'failed'}
                    />
                );
            },
        },
        {
            title: t('table.columns.column6'),
            align: 'center',
            key: 'action',
            render: (row) => <UserTableActions row={row} openModal={openModal} openDeleteModal={openDeleteModal} />,
        },
    ];

    // set number of users allowed based on plans
    useEffect(() => {
        setAllowedUser(pricing_plan?.users);
    }, [pricing_plan]);

    const { reminderCount, showReminder } = GetRemaining(
        pricing_plan?.users,
        all_users?.filter((user) => user?.user_detail?.user_type !== 'support')?.length
    );

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.users ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.users]);

    return (
        <>
            <PageHeader browserTitle="Users | Smartcomply" />
            {showReminder && <SubscriptionAlert isDashboardPage={false} limitedNumber={`${reminderCount} users`} />}
            <AppTable
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                title={t('table.title')}
                dataLength={all_users?.filter((user) => user?.user_detail?.user_type !== 'support')?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                loading={loading}
                actions={[
                    <AppButton
                        icon={<PersonAddAlt1 />}
                        name={t('createUser')}
                        color="primary"
                        variant="contained"
                        disabled={
                            allowedUser ===
                            all_users?.filter((user) => user?.user_detail?.user_type !== 'support')?.length
                        }
                        disableElevation
                        onClick={() => openModal('add')}
                        ref={(el) => (stepsTargetRef.current[0] = el)}
                        key={1}
                    />,
                ]}
                // search={true}
                // onSearch={handleSearch}
            />
            <UserModal
                open={modalOpen}
                handleClose={closeModal}
                payload={modalPayload}
                isAddModal={modalMode === 'add'}
            />
            <UserDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} payload={deletePayload} />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Users"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_users: state?.merchantReducers?.all_merchant_users,
        pricing_plan: state?.authReducers?.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.users,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, { GetAllMerchantUsers })(Users);
