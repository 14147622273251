import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CisoReportCard from './CisoReportCard';
import { connect } from 'react-redux';
import { GetMerchantContinuityGeneralSummary } from 'store/actions/dashboardActions';
import { GetContinuityGroupMetrics } from 'store/actions/complianceActions';

const CONTINUITYReport = ({ GetMerchantContinuityGeneralSummary, GetContinuityGroupMetrics, continuitySummary }) => {
    const toListEntry = Object.entries(continuitySummary || {});
    const newList = toListEntry
        ?.sort((a, b) => a - b)
        .map((res) => {
            const { user_doc, user_form, total_form, total_doc } = res?.[1] || {};
            const steps = ((user_doc + user_form) * 100) / (total_doc + total_form) || 0;
            return {
                title: res?.[0],
                ...res?.[1],
                stepCount: parseInt(steps.toString()),
            };
        });

    const sortedList = newList?.sort((a, b) => {
        const clauseNumberA = parseInt(a?.title?.split(' ')?.[1]);
        const clauseNumberB = parseInt(b?.title?.split(' ')?.[1]);

        return clauseNumberA - clauseNumberB;
    });
    useEffect(() => {
        const getAllIsoData = async () => {
            await GetMerchantContinuityGeneralSummary();
            await GetContinuityGroupMetrics();
        };
        getAllIsoData();
    }, []);
    return (
        <>
            <Box sx={{ my: '25px', display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#0E2C66', mr: '10px' }}>
                    ISO22301
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#46464A' }}>
                    Implementation Phase Completion
                </Typography>
            </Box>
            <Typography
                sx={{
                    fontSize: '10px',
                    fontWeight: 600,
                    color: '#0E2C66',
                    borderBottom: '1px solid #0E2C66',
                    width: 'fit-content',
                    mt: '20px',
                    mb: '12px',
                    pb: '4px',
                }}
            >
                Clauses
            </Typography>
            <Grid container spacing={1}>
                {sortedList?.map((res, index) => (
                    <Grid key={index} item xs={12} md={6} lg={6}>
                        <CisoReportCard details={res} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        continuityGeneral: state?.dashboardReducers?.continuityGeneral,
        continuitySummary: state?.complianceReducers?.all_continuitygroups_metrics,
    };
};
export default connect(mapStateToProps, { GetMerchantContinuityGeneralSummary, GetContinuityGroupMetrics })(
    CONTINUITYReport
);
