import React, { useState, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Stack, IconButton, MenuItem, Divider } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';

// core component
import GroupCard from '../../common/GroupCard';
import { Tag } from 'antd';
import ControlExemptModal from './ControlExemptModal';

//redux
import { connect } from 'react-redux';

const ControlCard = (props) => {
    const theme = useTheme();
    const {
        id,
        title,
        service,
        metric,
        resourceLabel,
        onViewChange,
        exempted,
        all_exempted_controls,
        openTaskAssignModal,
        isControlAssigned,
        isSubControlAssigned,
        resourceAssigned,
    } = props;
    // const [activeHoverCard, setActiveHoverCard] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [activeTitle, setActiveTitle] = useState('');
    const [type, setType] = useState('');
    const [anchor, setAnchor] = useState(null);

    // const user_type = useSelector((state) => state?.authReducers?.user_type);
    const openMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.target);
    };
    const closeMenu = () => setAnchor(null);

    const handleOpenTaskModal = (e) => {
        e.stopPropagation();
        openTaskAssignModal('requirement', {
            common_control: id,
            is_document: resourceLabel !== 'Questions Answered',
            standard: 'soc2',
        });
        closeMenu();
    };

    const handleCardClick = () => {
        if (metric?.totalValue && !anchor) {
            onViewChange(id);
        }
    };
    // const onHover = (id) => {
    //     setActiveHoverCard(id);
    // };
    // const onHoverOut = () => {
    //     setActiveHoverCard(null);
    // };

    const openModal = (exempted, title) => {
        setModalOpen(true);
        setType(!exempted ? 'exempt' : 'revert');
        setActiveTitle(title);
        closeMenu();
    };
    const closeModal = () => {
        setModalOpen(false);
        setType(null);
        setActiveTitle(null);
    };

    const exemptedControlId = useMemo(() => {
        return exempted ? all_exempted_controls?.find((control) => control?.soc_detail?.title === activeTitle)?.id : id;
    }, [all_exempted_controls, activeTitle, exempted]);
    return (
        <>
            <GroupCard
                percentCompleted={
                    (metric?.totalValue === 0 ? 100 : (metric?.totalAttendedTo / metric?.totalValue) * 100) || 0
                }
                totalValue={metric?.totalValue}
                totalAttendedTo={metric?.totalAttendedTo}
                resourceLabel={resourceLabel}
                onCardClick={handleCardClick}
                empty={metric?.totalValue == 0}
                // onHover={onHover}
                // onHoverOut={onHoverOut}
                id={id}
                exempted={exempted}
                // activeHoverCard={activeHoverCard}
                isCategoryAssigned={isControlAssigned}
                isSubCatgoryAssigned={isSubControlAssigned}
                isResourceAssigned={resourceAssigned?.includes(title)}
                standard={'soc2'}
            >
                <Stack direction="row" justifyContent="space-between" className="relative">
                    <Typography sx={{ fontWeight: 600, mb: 0, fontSize: '15px', color: '#475569' }}>{title}</Typography>
                    {/* {activeHoverCard === id && ( */}
                    <>
                        {exempted ? (
                            <Tag
                                // color="#f50"
                                className="w-fit h-fit absolute -right-4 text-[11px] z-20"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openModal(exempted, title);
                                }}
                            >
                                Revert
                            </Tag>
                        ) : (
                            <>
                                <IconButton
                                    sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                    onClick={openMenu}
                                >
                                    <MoreVert />
                                </IconButton>
                                <AppTableActionMenu
                                    anchor={anchor}
                                    closeMenu={closeMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    {!isControlAssigned ? (
                                        <MenuItem
                                            sx={{ fontSize: 14, color: '#64748B', fontWeight: 500 }}
                                            onClick={handleOpenTaskModal}
                                        >
                                            Assign to:
                                        </MenuItem>
                                    ) : null}
                                    <Divider />
                                    <MenuItem
                                        sx={{ fontSize: 14, color: '#64748B', fontWeight: 500 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openModal(exempted, title);
                                        }}
                                    >
                                        Not applicable
                                    </MenuItem>
                                </AppTableActionMenu>
                            </>
                        )}
                    </>
                    {/* )} */}
                </Stack>
                {/* <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#64748B',
                        textTransform: 'capitalize',
                        mt: 1,
                    }}
                >
                    {description}
                </Typography> */}
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: '#64748B',
                        textTransform: 'capitalize',
                        mt: 1,
                    }}
                >
                    {service}
                </Typography>
            </GroupCard>
            <ControlExemptModal open={modalOpen} handleClose={closeModal} type={type} ControlId={exemptedControlId} />
        </>
    );
};

const mapStateToProps = (state) => ({
    all_exempted_controls: state?.complianceReducers?.all_exempted_controls,
});
export default connect(mapStateToProps, {})(ControlCard);
