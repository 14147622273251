import React, { useState } from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { Box, Drawer, Slide, Stack, Typography } from '@mui/material';
import OutlinedLayout from 'components/new_components/tabs/OutlinedLayout';
import moment from 'moment';
import { connect } from 'react-redux';
import AppSelectInput from 'components/new_components/AppSelectInput';
import EmptyRectangle from 'assets/img/icons/EmptyRectangle.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const KycCheckModal = (props) => {
    const theme = useTheme();
    const { open, handleClose, payload } = props;
    const [indexValue, setIndexValue] = useState(1);
    const [current_value, setCurrrent_value] = useState('');

    const handleDetailsChange = (e) => {
        setCurrrent_value(e.target.value);
    };

    const onChangeTab = async (val) => {
        setIndexValue(val);
    };

    const tabList = [
        {
            id: 1,
            name: 'Online Source',
        },
    ];

    const match_options = payload?.result?.map((data) => {
        return {
            name: data?.doc?.name,
            value: data?.doc?.id,
        };
    });
    const current_fraud_details = payload?.result?.find((current) => current?.doc?.id === current_value);
    return (
        <Drawer
            open={open}
            anchor={'right'}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    borderRadius: 4,
                    width: 600,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <Box
                sx={{
                    borderBottom: `1px solid ${theme.palette.primary[100]}`,
                }}
            >
                <Stack
                    sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.primary[900],
                        padding: 2,
                        width: '100%',
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowBackIosNew
                            sx={{
                                width: 20,
                                height: 20,
                                color: theme.palette.gray[30],
                                mr: 3,
                                borderRadius: '50%',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                        />
                        <Typography
                            variant="h2"
                            sx={{
                                color: '#46464A',
                                fontWeight: 700,
                                fontSize: '16px',
                                marginTop: 1,
                            }}
                        >
                            {payload?.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{ fontWeight: 500, fontSize: '10px', color: theme.palette.gray[40], float: 'right' }}
                        >
                            {payload?.total_hits} search hits
                        </Typography>

                        <AppSelectInput
                            name="Search hits"
                            options={match_options}
                            defaultValue={payload?.name}
                            sx={{ borderRadius: '8px', fontSize: 12, mt: '-1rem' }}
                            value={current_value}
                            onChange={handleDetailsChange}
                            size="small"
                        />
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ mx: 3, my: 3, flex: 1 }}>
                <Box>
                    <Typography sx={{ fontWeight: 600, fontSize: '12px', color: theme.palette.gray[40], mb: 2 }}>
                        Entity details
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Name
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {payload?.name}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Entity type
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '12px',
                                color: theme.palette.gray[40],
                                textTransform: 'capitalize',
                            }}
                        >
                            {payload?.entity_type}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Birth year
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {payload?.request_details?.birthyear}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Country
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {payload?.request_details?.country ? (
                                payload?.request_details?.country
                            ) : (
                                <img src={EmptyRectangle} alt="EmptyRectangle" />
                            )}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Date created
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {moment(payload?.date_created).format('MMMM Do, YYYY, (h:mm a)')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Date updated
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {moment(payload?.last_updated_utc).format('MMMM Do, YYYY, (h:mm a)')}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '12px', color: theme.palette.gray[40], mb: 2 }}>
                        Entity search criteria
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Fuziness
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.gray[40] }}>
                            {payload?.request_details?.fuziness}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            KYC types
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-ebtween',
                                alignItems: 'center',
                                width: '100%',
                                flexWrap: 'wrap',
                                mt: 1,
                            }}
                        >
                            {(current_value
                                ? current_fraud_details?.doc?.types
                                : payload?.request_details?.kyc_types
                            )?.map((item, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        color: theme.palette.error[500],
                                        border: `1px solid ${theme.palette.error[500]}`,
                                        fontSize: '10px',
                                        borderRadius: '3px',
                                        fontWeight: 400,
                                        p: 0.5,
                                        mr: 0.8,
                                        mb: 1,
                                    }}
                                >
                                    {item[index]}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Continuous monitoring
                        </Typography>

                        {payload?.request_details?.continous_monitoring === false ? (
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '10px',
                                    color: theme.palette.gray[40],
                                    backgroundColor: '#FFEFCF',
                                    p: 0.5,
                                }}
                            >
                                Not available
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '10px',
                                    color: theme.palette.gray[40],
                                    backgroundColor: '#BFFFD9',
                                    p: 0.5,
                                }}
                            >
                                Available
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #F0F0FA',
                            pb: 1,
                            mb: 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: '12px', color: theme.palette.neutral[60] }}>
                            Fraudulent transaction
                        </Typography>
                        {payload?.request_details?.fraudcentry === false ? (
                            <img src={EmptyRectangle} alt="EmptyRectangle" />
                        ) : (
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '10px',
                                    backgroundColor:
                                        parseInt(payload?.fraud_response?.fraud_percent) > 100
                                            ? theme.palette.error[300] + '20'
                                            : theme.palette.success[500] + '20',
                                    border: '1px',
                                    borderColor:
                                        parseInt(payload?.fraud_response?.fraud_percent) > 100
                                            ? theme.palette.error[300] + '20'
                                            : theme.palette.success[500] + '20',
                                    color:
                                        parseInt(payload?.fraud_response?.fraud_percent) > 100
                                            ? theme.palette.error[500]
                                            : theme.palette.success[500],
                                    p: 0.7,
                                }}
                            >
                                {parseInt(payload?.fraud_response?.fraud_percent) > 100 ? 'Fraudulent' : 'Clean'}
                            </Typography>
                        )}
                    </Box>
                </Box>
                {current_fraud_details?.doc?.media?.length > 0 && (
                    <Box>
                        <OutlinedLayout tabList={tabList} indexValue={indexValue} onChangeTab={onChangeTab} />
                        {indexValue === 1 && (
                            <Box sx={{ mt: 3 }}>
                                {current_fraud_details?.doc?.media?.map((source, index) => (
                                    <Box key={index} sx={{ border: '1px solid #F0F0FA', p: 1, mb: 1.5 }}>
                                        <Typography
                                            sx={{ fontSize: '12px', fontWeight: 600, color: theme.palette.gray[40] }}
                                        >
                                            {source?.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '10px',
                                                fontWeight: 500,
                                                color: theme.palette.gray.new60,
                                                mt: 0.5,
                                            }}
                                        >
                                            Published {moment(source.date).format('Do MMMM, YYYY')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '10px',
                                                fontWeight: 500,
                                                color: theme.palette.gray[30],
                                                mt: 0.5,
                                            }}
                                        >
                                            <a href={source?.url} target="_blank" rel="noreferrer">
                                                {source?.url}
                                            </a>
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
        all_kyc_result: state?.fraudGuardianReducers?.all_kyc_result,
    };
};

export default connect(mapStateToProps, {})(KycCheckModal);
