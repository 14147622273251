import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
//core components
import { List, ListItem, ListItemText, ListItemAvatar, Typography, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingState from 'components/new_components/LoadingState';
import AppReportModal from 'components/new_components/AppReportModal';
import { Download, CalendarTodayOutlined } from '@mui/icons-material';
import FileIcon from '../../../assets/img/FileIcon.svg';

//redux
import { connect } from 'react-redux';
import { GetMerchantPentestReportById } from 'store/actions/merchantActions';
import { formatDateObjectHandler } from 'utils';
import AppColorTag from 'components/new_components/AppColorTag';

//translations
import { useTranslation } from 'react-i18next';

const PentestReportModal = (props) => {
    //props
    const { open, handleClose, title, subtitle, GetMerchantPentestReportById, report, pentest_report_by_id } = props;

    //state
    const [getReportsLoading, setGetReportLoading] = useState(false);

    //theme
    const theme = useTheme();

    //translation
    const { t } = useTranslation('pentest');

    const getPentestReports = async (id) => {
        setGetReportLoading(true);
        const res = await GetMerchantPentestReportById(id);
        setGetReportLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (report) {
            getPentestReports(report);
        }
    }, [report]);
    return (
        <AppReportModal open={open} handleClose={handleClose} title={title} width={580} subtitle={subtitle}>
            <List sx={{ paddingTop: 0, mb: 3 }}>
                {getReportsLoading ? (
                    <LoadingState />
                ) : (
                    pentest_report_by_id
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((report) => {
                            return (
                                <ListItem sx={{ alignItems: 'flex-start' }} key={report?.id}>
                                    <ListItemAvatar>
                                        <img src={FileIcon} alt="file icon" />
                                    </ListItemAvatar>
                                    <ListItemText sx={{ fontSize: '16px', color: theme.palette.gray[800], ml: 1 }}>
                                        <Typography
                                            color={theme.palette.gray[900]}
                                            sx={{ fontWeight: 500, fontSize: '18px', marginTop: '-8px' }}
                                        >
                                            {`${report?.name} ${report?.version}`}
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ fontSize: '12px', color: theme.palette.gray[40], my: 0.4 }}
                                        >
                                            <CalendarTodayOutlined
                                                sx={{ fontSize: '12px', color: theme.palette.gray[40], mr: 1 }}
                                            />
                                            <span className="mx-2">
                                                {formatDateObjectHandler(report?.date_created, 'MMMM Do, YYYY')}
                                            </span>
                                            <AppColorTag
                                                text={report?.severity}
                                                type={
                                                    report?.severity === 'high'
                                                        ? 'error'
                                                        : report?.severity === 'medium'
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                                fontWeight={500}
                                                margin="0px"
                                            />
                                            <Box
                                                component="a"
                                                href={report?.report}
                                                target="_blank"
                                                rel="noreferer noreferrer"
                                                sx={{
                                                    color: theme.palette.neutral[50],
                                                    border: `0.4px solid ${theme.palette.gray[300]}`,
                                                    width: 'fit-content',
                                                    px: 1.5,
                                                    py: 0.6,
                                                    ml: 1,
                                                    fontSize: 12,
                                                    borderRadius: '90px',
                                                    textDecoration: 'none',
                                                    '&:hover': {
                                                        color: theme.palette.neutral[50],
                                                        textDecoration: 'none',
                                                    },
                                                }}
                                            >
                                                <Download sx={{ fontSize: 12 }} />
                                                {t('pentestReportModal.download')}
                                            </Box>
                                        </Stack>
                                    </ListItemText>
                                </ListItem>
                            );
                        })
                )}
            </List>
        </AppReportModal>
    );
};

const mapStateToProps = (state) => {
    return {
        pentest_report_by_id: state?.merchantReducers?.pentest_report_by_id,
    };
};
export default connect(mapStateToProps, { GetMerchantPentestReportById })(PentestReportModal);
