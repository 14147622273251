import React from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';
import AppViewModal from 'components/new_components/AppViewModal';
import { useTheme } from '@mui/styles';
import { Box, Button, Divider, Typography } from '@mui/material';
import Hello from '../../../../assets/img/Hello.svg';
import SmilingFace from '../../../../assets/img/SmilingFace.svg';
import ReadDocumentation from '../components/ReadDocumentation';

const AboutFraudGuardian = (props) => {
    const theme = useTheme();
    const { open, handleClose, handlePagechange } = props;
    return (
        <AppViewModal
            width={600}
            open={open}
            handleClose={handleClose}
            noClose
            title={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIosNew
                        sx={{
                            width: 20,
                            height: 20,
                            color: theme.palette.gray[30],
                            mr: 3,
                            borderRadius: '50%',
                            cursor: 'pointer',
                        }}
                        onClick={handleClose}
                    />
                    <Typography
                        variant="h2"
                        sx={{
                            color: '#46464A',
                            fontWeight: 700,
                            fontSize: '16px',
                            marginTop: 1,
                        }}
                    >
                        About Fraud Guardian
                    </Typography>
                </Box>
            }
            anchor="right"
        >
            <Box sx={{ mx: 2, my: 5 }}>
                <Typography sx={{ color: '#395BA9', fontWeight: 600, fontSize: '16px' }}>
                    {' '}
                    Introducing
                    <Typography
                        component={'span'}
                        sx={{
                            color: '#395BA9',
                            fontWeight: 600,
                            fontSize: '16px',
                            ml: 1,
                            borderBottom: `3px solid ${theme.palette.warning[700]}`,
                        }}
                    >
                        Fraud guardian API !
                    </Typography>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Typography sx={{ width: '80%', color: theme.palette.gray[30], fontWeight: 400, fontSize: '13px' }}>
                        Fraud Guardian API is your trusted partner in the world of Know Your Customer (KYC) solutions.
                        Designed as a three-way KYC API, our platform is committed to guarding against fraudulent
                        activities and ensuring secure and reliable user verification.
                    </Typography>
                    <img src={Hello} alt="Hello" />
                </Box>
                <Divider />
                <Box sx={{ mt: 3, mb: 3 }}>
                    <Typography sx={{ color: '#395BA9', fontWeight: 600, fontSize: '16px', mb: 2 }}>
                        Ready to explore
                    </Typography>
                    <Typography
                        sx={{ width: '70%', color: theme.palette.gray[30], fontWeight: 400, fontSize: '13px', mb: 2.5 }}
                    >
                        For those seeking a deeper understanding of the capabilities and possibilities of our API, the
                        API Reference is your treasure trove of information. Discover the full potential of Fraud
                        Guardian API and unlock new horizons for your KYC needs.
                    </Typography>
                    <Button
                        variant="outlined"
                        sx={{ textTransform: 'inherit', width: '170px', height: '45px' }}
                        onClick={() => {
                            handlePagechange('token');
                            handleClose();
                        }}
                    >
                        View API TOKEN
                    </Button>
                </Box>
                <Divider />
                <Box sx={{ mt: 3 }}>
                    <Box sx={{ display: 'flex', mb: 2 }}>
                        <Typography sx={{ mr: 1 }}>Hey developer</Typography>
                        <img src={SmilingFace} alt="SmilingFace" />
                    </Box>
                    <Typography sx={{ color: theme.palette.gray[30], fontWeight: 400, fontSize: '13px', mb: 2.5 }}>
                        If you're raring to go, take a quick leap into the Quick Start Docs. They'll guide you through
                        the process of making your first request, ensuring a smooth onboarding experience.
                    </Typography>
                </Box>
                <ReadDocumentation />
            </Box>
        </AppViewModal>
    );
};

export default AboutFraudGuardian;
