import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ModelTraining } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import AppTable from 'components/new_components/app-table';
import AppButton from 'components/new_components/AppButton';
import AppInput from 'components/new_components/AppInput';
import AppSelectInput from 'components/new_components/AppSelectInput';
import { FiSearch } from 'react-icons/fi';
import TrainingActions from './TrainingActions';
import TrainingSummary from './TrainingSummary';
import AssignTrainingModal from './AssignTrainingModal';
import useSearch from 'hooks/useSearch';

// redux
import { connect } from 'react-redux';
import { GetTrainingByCourse, ResendTraining } from 'store/actions/trainingActions';
import { GetMerchantPeople } from 'store/actions/merchantActions';
import RemoveAssignment from './RemoveAssignment';
import LoadingState from 'components/new_components/LoadingState';
// import PreviewLink from 'views/merchant/OnboardingPolicy/components/PreviewLink';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 5;

const CourseView = (props) => {
    const {
        current,
        all_training_courses,
        all_training,
        GetTrainingByCourse,
        GetMerchantPeople,
        ResendTraining,
        avaliable_courses,
        ref2,
    } = props;
    const theme = useTheme();

    // state
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState(null);
    const [is_complete, setIs_completed] = useState('all');
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);

    const { data, handleSearch } = useSearch(all_training, ['enrollee_name', 'enrollee_email']);

    //translation
    const { t } = useTranslation('training');
    const language = localStorage.getItem('i18nextLng');

    // memos
    //This helps to filter out merchant paid courses
    const availableCourses = useMemo(() => {
        const paid_courses = avaliable_courses?.split(',');
        return all_training_courses?.filter((course) => paid_courses?.includes(course?.id));
    }, [avaliable_courses, all_training_courses]);
    const course = useMemo(() => {
        return availableCourses?.find((course) => course?.id === current);
    }, [current, availableCourses]);

    const trainingMetrics = useMemo(() => {
        return all_training?.reduce(
            (current, next) => {
                let type;
                if (next?.is_complete) {
                    type = 'complete';
                } else {
                    type = 'incomplete';
                }
                return { ...current, [type]: (current[type] || 0) + 1 };
            },
            { complete: 0, incomplete: 0 }
        );
    }, [all_training]);

    // functions
    const handleSortChange = (e) => {
        setIs_completed(e.target.value);
    };
    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);
    const openDeleteModal = (record) => {
        setRecord(record);
        setDeleteModal(true);
    };
    const closeDeleteModal = () => {
        setRecord(null);
        setDeleteModal(false);
    };
    const getAllEmployees = async () => {
        setLoading(true);
        const res = await GetMerchantPeople();
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't fetch employees' details");
        }
    };

    // const query_params = useMemo(() => {
    //     const params = {
    //         ...(is_complete.length && { is_complete: is_complete === 'All' ? '' : is_complete }),
    //     };
    //     return params;
    // }, [is_complete]);

    const getTrainingByCourse = async () => {
        setLoading(true);
        const res = await GetTrainingByCourse(current);
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't get training records.");
        }
    };
    const resendTraining = async (id) => {
        const res = await ResendTraining({ training_id: id });
        if (res?.success) {
            return toast.success('A mail containing the training link has been resent to this trainee.');
        } else {
            return toast.error("Something went wrong, couldn't resend training details.");
        }
    };

    // useEffect
    useEffect(() => {
        if (!current) return;
        getAllEmployees();
        getTrainingByCourse();
    }, [current]);

    // constants
    const columns = [
        {
            title: t('courseView.table.column1'),
            render: (row) => {
                return (
                    <>
                        <Typography sx={{ fontSize: 14, fontWeight: 500, color: theme.palette.gray[40] }}>
                            {row?.enrollee_name}
                        </Typography>
                        <Typography sx={{ fontSize: 12, fontWeight: 500, color: theme.palette.neutral[60] }}>
                            {row?.enrollee_email}
                        </Typography>
                    </>
                );
            },
        },
        {
            title: t('courseView.table.column2'),
            render: (row) => {
                return (
                    <Typography
                        sx={{
                            py: 0.5,
                            px: 1,
                            borderRadius: 23,
                            fontSize: 10,
                            fontWeight: 500,
                            border: row?.is_complete ? 'none' : `1px solid ${theme.palette.gray[300] + 'aa'}`,
                            backgroundColor: row?.is_complete ? 'rgba(4, 106, 69, 0.1)' : 'transparent',
                            color: row?.is_complete ? theme.palette.success[900] : theme.palette.gray[40],
                            width: 'fit-content',
                        }}
                    >
                        {row?.is_complete ? t('courseView.options.completed') : t('courseView.options.pending')}
                    </Typography>
                );
            },
        },
        {
            title: t('courseView.table.column3'),
            render: (row) => (
                <TrainingActions row={row} openDeleteModal={openDeleteModal} resendTraining={resendTraining} />
            ),
        },
    ];

    const filteredData = useMemo(() => {
        if (is_complete === 'all') {
            return data;
        } else if (is_complete === 'completed') {
            const completeddRecords = data?.length ? data?.filter?.((item) => item?.is_complete) : [];
            return completeddRecords;
        } else {
            const pendingRecords = data?.length ? data?.filter?.((item) => !item?.is_complete) : [];
            return pendingRecords;
        }
    }, [data, is_complete]);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(filteredData?.length > 0 ? filteredData?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, filteredData]);

    const handlePageChange = (page) => {
        setPage(page);
    };

    return (
        <Stack sx={{ pt: 3, pr: 3.5, pl: 5, flex: 1 }}>
            <Box component="header">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" sx={{ gap: 2 }}>
                        <Typography sx={{ fontWeight: 700, fontSize: 15, color: theme.palette.primary[900] }}>
                            {language?.includes('fr')
                                ? course?.title_fr
                                : language?.includes('en')
                                ? course?.title_en
                                : course?.title}
                        </Typography>
                        {/* <PreviewLink
                            text="Preview"
                            to="/training/preview"
                            sx={{
                                py: 0.4,
                                px: 1,
                                border: `1px solid ${theme.palette.gray[300]}50`,
                                backgroundColor: 'transparent',
                                fontSize: 12,
                                fontWeight: 500,
                                gap: 0.5,
                                '&, &:hover': {
                                    color: theme.palette.gray[40],
                                    textDecoration: 'none',
                                },
                                '& svg': {
                                    fontSize: 14,
                                },
                            }}
                        /> */}
                    </Stack>
                    <AppButton
                        name={t('courseView.addEmployee')}
                        icon={<ModelTraining />}
                        color="primary"
                        variant="contained"
                        onClick={openModal}
                        disabled={!availableCourses?.length}
                        ref={ref2}
                    />
                </Stack>
                <Typography
                    sx={{
                        fontSize: 10,
                        fontWeight: 500,
                        color: theme.palette.gray[40],
                        px: 1,
                        py: 0.8,
                        mt: 2,
                        border: `1px solid ${theme.palette.gray[300] + 50}`,
                        borderRadius: 1,
                    }}
                >
                    {language?.includes('fr')
                        ? course?.description_fr
                        : language?.includes('en')
                        ? course?.description_en
                        : course?.description}
                </Typography>
            </Box>
            {loading ? (
                <LoadingState />
            ) : (
                <>
                    <TrainingSummary metrics={trainingMetrics} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', py: 3, gap: 2.5 }}>
                        <AppInput
                            sx={{ gap: 0.7, height: 'unset', borderRadius: 2, '& input': { py: 1 } }}
                            controlStyle={{ width: 'fit-content' }}
                            onChange={handleSearch}
                            placeholder={t('courseView.search')}
                            startAdornment={<FiSearch style={{ color: theme.palette.neutral[70], fontSize: '16px' }} />}
                        />
                        <AppSelectInput
                            name="status"
                            options={[
                                { name: t('courseView.options.all'), value: 'all' },
                                { name: t('courseView.options.completed'), value: 'completed' },
                                { name: t('courseView.options.pending'), value: 'pending' },
                            ]}
                            defaultValue={t('courseView.status')}
                            value={is_complete}
                            onChange={handleSortChange}
                            sx={{ height: 30, borderRadius: 2, width: 'fit-content', minWidth: 80 }}
                            size="small"
                        />
                    </Box>
                    <Box sx={{ border: '1px solid #395ba924', borderRadius: 1 }}>
                        <AppTable
                            columns={columns}
                            data={pageData}
                            showTitleBar={false}
                            dataLength={filteredData?.length}
                            noPerPage={NO_PER_PAGE}
                            page={page}
                            onPageChange={handlePageChange}
                            sx={{ container: { minHeight: 'fit-content' } }}
                        />
                    </Box>
                    <AssignTrainingModal open={modal} onClose={closeModal} course={course?.id} />
                    <RemoveAssignment open={deleteModal} handleClose={closeDeleteModal} record={record} />
                </>
            )}
        </Stack>
    );
};

const mapStateToProps = (state) => ({
    all_training_courses: state?.trainingReducers?.all_training_courses,
    all_training: state?.trainingReducers?.all_training,
    avaliable_courses: state?.generalReducers?.user_info?.organization?.avaliable_courses,
});

export default connect(mapStateToProps, { GetTrainingByCourse, GetMerchantPeople, ResendTraining })(CourseView);
