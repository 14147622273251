import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Tabs, Row, Col, Spin } from 'antd';

// core component
import DocumentCardsview from './DocumentCardsView';
import BreadCrumb from 'components/Breadcrumb';
import FormsView from './FormsView';

// icons
import { FileOutlined, FormOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
    GetPcidss4Point0DocumentsBySubRequirement,
    GetAllPcidss4Point0DocumentsByAltTag,
} from 'store/actions/pcidssActions';

// sub compliance
const { TabPane } = Tabs;

const SubReqPage = (props) => {
    const { location } = props;
    const [loading, setLoading] = useState(false);

    // redux
    const dispatch = useDispatch();
    const all_documents_by_subreq = useSelector(
        (state) => state?.pcidssReducers?.all_pcidss4point0_documents_by_subreq
    );

    //use effect
    useEffect(() => {
        setLoading(true);
        getDocumentsBySubRequirement();
        getAllAdminDocuments();
        setLoading(false);
    }, []);

    //functions
    const getDocumentsBySubRequirement = async () => {
        const res = await dispatch(
            GetPcidss4Point0DocumentsBySubRequirement(location?.state?.sub_requirement?.requirement_number)
        );
        if (!res.success) {
            toast.error(res?.message);
        }
    };
    const getAllAdminDocuments = async () => {
        const res = await dispatch(GetAllPcidss4Point0DocumentsByAltTag('card4'));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb
                        views={[
                            { label: 'PCIDSS 4.0', link: '/pcidss4.0' },
                            {
                                label: `Requirement ${location?.state?.parent_req_num}`,
                                link: '/pcidss4.0/requirement',
                                state: {
                                    req_num: location?.state?.parent_req_num,
                                    req_id: location?.state?.sub_requirement?.parent_requirement,
                                },
                            },
                            {
                                label: `Sub Requirement ${location?.state?.sub_requirement?.requirement_number}`,
                            },
                        ]}
                    />
                </Col>
            </Row>
            <section className="my-3">
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                <FileOutlined />
                                Documents
                            </span>
                        }
                        key="1"
                    >
                        {loading ? (
                            <Row justify="center">
                                <Spin size="large" />
                            </Row>
                        ) : (
                            <DocumentCardsview
                                sub_requirement={location?.state?.sub_requirement}
                                all_documents_by_subreq={all_documents_by_subreq}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <FormOutlined />
                                Audit Questions
                            </span>
                        }
                        key="2"
                    >
                        {loading ? (
                            <Row justify="center">
                                <Spin size="large" />
                            </Row>
                        ) : (
                            <FormsView sub_requirement={location?.state?.sub_requirement} />
                        )}
                    </TabPane>
                </Tabs>
            </section>
        </div>
    );
};

export default SubReqPage;
