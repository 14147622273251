import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
//Components
import { Table, Button, Modal } from 'antd';
import { ThreatOperations } from './ThreatOperations/ThreatOperations';
import ThreatModal from './ThreatOperations/ThreatModal';
import EditModal from './ThreatOperations/EditModal';

//Icons
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

//Redux
import { connect } from 'react-redux';
import { GetAllThreats, DeleteThreat } from '../../../store/actions/adminActions';

const Threat = (props) => {
    const [loading, setloading] = useState(false);
    const [AddModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [record, setrecord] = useState(null);
    const { GetAllThreats, all_threats, DeleteThreat } = props;

    const columns = [
        {
            title: 'Threat Category',
            render: (record) => record?.name,
        },
        {
            title: 'Threat',
            render: (record) => record?.sub_category,
        },
        {
            title: 'Description',
            render: (record) => record?.description,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => (
                <ThreatOperations record={record} openUpdateModal={openEditModal} deleteModal={deleteModal} />
            ),
        },
    ];
    const openAddModal = () => {
        setAddModal(true);
    };
    const closeAddModal = () => {
        setAddModal(false);
    };

    //Edit Modal
    const openEditModal = (data) => {
        setrecord(data);
        setEditModal(true);
    };
    const closeEditModal = () => {
        setEditModal(false);
    };

    const deleteModal = (threat) => {
        Modal.confirm({
            title: 'Do you want to delete this threat?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteThreat(threat?.id);
                if (res?.success) {
                    toast.success('Threat Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };
    useEffect(() => {
        const getAllThreats = async () => {
            setloading(true);
            const res = await GetAllThreats();
            setloading(false);
            if (!res.success) {
                toast.error(res?.message);
            }
        };

        getAllThreats();
    }, []);
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={openAddModal}
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    Add Threat
                </Button>
            </div>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius"
                    style={{ overflowX: 'auto' }}
                    rowKey={(all_threats) => all_threats?.id}
                    columns={columns}
                    loading={loading}
                    dataSource={all_threats}
                    pagination={{
                        total: all_threats?.length,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                    }}
                />
            </section>
            <ThreatModal open={AddModal} handleClose={closeAddModal} />
            <EditModal open={editModal} handleClose={closeEditModal} record={record} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_threats: state?.adminReducers?.all_threats,
    };
};
export default connect(mapStateToProps, { GetAllThreats, DeleteThreat })(Threat);
