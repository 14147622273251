import React from 'react';
import PageHeader from 'components/new_components/PageHeader';
import AuditTableRecord from './AuditTableRecord';

const AuditIndex = () => {
    return (
        <>
            <PageHeader browserTitle="Audit | Smartcomply" />
            <AuditTableRecord />
        </>
    );
};

export default AuditIndex;
