import React, { useState } from 'react';
import { Box } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import ReporttDeleteModal from './ReportDeleteModal';

const TableAction = (props) => {
    const { row } = props;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const openDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    return (
        <Box>
            <a href={row?.report} target="_blank" rel="noopener noreferrer">
                <FileDownloadIcon color="primary" sx={{ width: '20px', height: '20px' }} />
            </a>
            <DeleteIcon
                color="primary"
                sx={{ width: '20px', height: '20px', cursor: 'pointer', ml: 2 }}
                onClick={openDeleteModal}
            />
            <ReporttDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={row?.id} />
        </Box>
    );
};

export default TableAction;
