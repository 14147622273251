import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import AppTable from 'components/new_components/app-table';
import useSearch from 'hooks/useSearch';
import KycHeading from './TransactionHeading';
import TransactionCheckModal from '../../components/TransactionCheckModal';
import { connect } from 'react-redux';
import EmptyRectangle from 'assets/img/icons/EmptyRectangle.svg';

const NO_PER_PAGE = 10;

const TransactionChecks = (props) => {
    const { all_fraud_transactions, loading } = props;
    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [payload, setPayload] = useState(null);

    const openModal = (data) => {
        setOpen(true);
        setPayload(data);
    };
    const CloseModal = () => {
        setOpen(false);
        setPayload(null);
    };

    const { data, handleSearch } = useSearch(all_fraud_transactions, ['name']);

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (row) => (
                <Typography
                    sx={{ color: '#5E5E62', fontSize: '14px', cursor: 'pointer' }}
                    onClick={() => openModal(row)}
                >
                    {row?.name}
                </Typography>
            ),
        },
        {
            title: 'Source Bank',
            key: 'source_bank',
            render: (row) => (
                <>
                    {row?.result?.source_bank ? (
                        <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>{row?.result?.source_bank}</Typography>
                    ) : (
                        <img src={EmptyRectangle} alt="EmptyRectangle" />
                    )}
                </>
            ),
        },
        {
            title: 'Fraud Percentage',
            key: 'fraud_percent',
            render: (row) => (
                <>
                    {row?.result?.fraud_percent ? (
                        <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                            {row?.result?.fraud_percent}%
                        </Typography>
                    ) : (
                        <img src={EmptyRectangle} alt="EmptyRectangle" />
                    )}
                </>
            ),
        },
        {
            title: 'Transaction Status',
            key: 'transaction_status',
            render: (row) => (
                <>
                    {row?.result?.transaction_status ? (
                        <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                            {row?.result?.transaction_status}
                        </Typography>
                    ) : (
                        <img src={EmptyRectangle} alt="EmptyRectangle" />
                    )}
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                <Typography sx={{ color: '#5E5E62', fontSize: '14px' }}>
                    {row?.result?.fraud_percent > 100 ? 'Fraudulent' : 'Clean'}
                </Typography>
            ),
        },
    ];

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);

    const handlePageChange = (page) => {
        setPage(page);
    };
    return (
        <div className="mt-2">
            <>
                <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#46464A', mt: 2 }}>
                    Entity Fraudulent Transaction Check
                </Typography>
                <AppTable
                    columns={columns}
                    data={pageData}
                    sorter={(a, b) => b?.id - a?.id}
                    loading={loading}
                    title={<KycHeading onSearch={handleSearch} />}
                    dataLength={all_fraud_transactions?.length}
                    noPerPage={NO_PER_PAGE}
                    page={page}
                    onPageChange={handlePageChange}
                    // actions={data && data?.length > 0 ? <RiskAction /> : ''}
                />
                <TransactionCheckModal open={open} handleClose={CloseModal} payload={payload} />
            </>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_fraud_transactions: state?.fraudGuardianReducers?.all_fraud_transactions,
    };
};

export default connect(mapStateToProps, {})(TransactionChecks);
