import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row, Table } from 'antd';
import BreadCrumb from 'components/Breadcrumb';
import useSearch from 'hooks/useSearch';
import React, { useEffect, useState } from 'react';
import QAReviewerAction from './components/QAReviewerAction';
import QAReviewerModal from './components/QAReviewerModal';

// redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllQAReviwers, DeleteQAReviwer } from 'store/actions/auditActions';

const QAReviewer = (props) => {
    // props
    const { GetAllQAReviwers, all_qa_reviewers, DeleteQAReviwer } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [record, setRecord] = useState({});

    // hooks
    const { data, handleSearch } = useSearch(all_qa_reviewers, [
        'qa_reviewer_name',
        'qa_reviewer_phone_no',
        'qa_reviewer_email',
        'qa_reviewer_pci_credentials',
    ]);

    // functions
    const openModal = (mode, data = {}) => {
        setModal(mode);
        setRecord(data);
    };
    const closeModal = () => {
        setModal(null);
        setRecord({});
    };
    const getAllQAReviewers = async () => {
        setLoading(true);
        const res = await GetAllQAReviwers();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteModal = (record) => {
        Modal.confirm({
            title: 'Do you want to delete this QA Reviewer?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteQAReviwer(record?.id);
                if (res?.success) {
                    toast.success('Support User Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllQAReviewers();
    }, []);

    // constants
    const columns = [
        {
            title: 'Name',
            render: (record) => record?.qa_reviewer_name,
        },
        {
            title: 'Email',
            render: (record) => record?.qa_reviewer_email,
        },
        {
            title: 'Phone',
            render: (record) => record?.qa_reviewer_phone_no,
        },
        {
            title: 'PCI credential',
            render: (record) => record?.qa_reviewer_pci_credentials,
        },
        {
            title: 'Action',
            align: 'right',
            render: (record) => <QAReviewerAction record={record} openModal={openModal} deleteModal={deleteModal} />,
        },
    ];

    return (
        <div>
            <Row justify="space-between">
                <Col>
                    <BreadCrumb views={[{ label: 'QA Reviewers' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={() => openModal('Add')}
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center ml-3"
                    >
                        Add Reviewers
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table columns={columns} dataSource={data} loading={loading} />
            </section>
            <QAReviewerModal open={Boolean(modal)} mode={modal} modalData={record} handleClose={closeModal} />
        </div>
    );
};
const mapStateToProps = (state) => ({
    all_qa_reviewers: state?.auditReducers?.all_qa_reviewers,
});
export default connect(mapStateToProps, { GetAllQAReviwers, DeleteQAReviwer })(QAReviewer);
