import React from 'react';
import PCIDSSFirstLayerReport from './PCIDSSFirstLayerReport';
import SummaryReportCard from '../SummaryReportCard';

//translations
import { useTranslation } from 'react-i18next';

const PCIDSSIndex = ({ percentage, pciGeneral = {}, pciSummary = {} }) => {
    //translation
    const { t } = useTranslation('overview');

    //function
    const toListEntry = Object.entries(pciSummary);
    const newList = toListEntry?.map((res) => {
        const { user_doc, user_form, total_form, user_scan, total_scan, total_doc } = res?.[1] || {};
        const steps = ((user_doc + user_form + user_scan) * 100) / (total_doc + total_form + total_scan) || 0;
        return {
            title: `${t('standardTab.requirement')} ${parseInt(res?.[0])} - ${res?.[1]?.title}`,
            // ...res?.[1],
            total_doc: res?.[1]?.total_doc,
            total_form: res?.[1]?.total_form,
            total_scan: res?.[1]?.total_scan,
            user_doc: res?.[1]?.user_doc,
            user_form: res?.[1]?.user_form,
            user_scan: res?.[1]?.user_scan,
            stepCount: parseInt(steps.toString()),
        };
    });
    return (
        <div className="">
            <PCIDSSFirstLayerReport percentage={percentage} pciGeneral={pciGeneral} />
            <div className="py-8">
                <h3 className="textbrandColor font-bold text-[23px]">{t('standardTab.summary')}</h3>
                <div className="pt-4">
                    {newList?.map((res, index) => (
                        <React.Fragment key={index}>
                            <SummaryReportCard details={res} />
                        </React.Fragment>
                    ))}
                    {/*<SummaryReportCard title="Requirement 1 - Install and maintain a firewall configuration to protect cardholder data" />*/}
                </div>
            </div>
        </div>
    );
};

export default PCIDSSIndex;
