import { ALL_ASSIGNED_TASKS, ALL_ASSIGNED_TASKS_BY_STANDARD } from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_ASSIGNED_TASKS:
            return {
                ...state,
                all_assigned_tasks: action?.payload,
            };
        case ALL_ASSIGNED_TASKS_BY_STANDARD:
            return {
                ...state,
                all_assigned_tasks_by_standard: action?.payload,
            };
        default:
            return state;
    }
};
