import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
// route components
import AuthLayout from './views/auth';
import Marketplace from './views/marketplace/index';
import AdminDashboard from './views/admin/index';
import VerifySecurityTraining from 'views/merchant/OnboardingPolicy/EmployeeView';
import MerchantLayout from 'layouts/MerchantLayout';
// import ReadOnlyVendor from 'views/merchant/VendorManagement/VendorsView/ReadOnlyVendor';
import { MerchantRoute, AuditorRoute, AdminRoute } from 'PrivateRoute';
import VendorsView from 'views/merchant/VendorManagement/VendorsView';
import MerchantSurvey from 'views/merchant/Survey/index';
import EmployeeTrainingView from 'views/merchant/training/employee-view';
import TrainingCertificate from 'views/merchant/training/employee-view/TrainingCertificate';
import AuditCertificateCountdown from 'views/merchant/audit/ViewCertificate/CertificateCountdown';
import AuditCertificateVerification from 'views/merchant/audit/ViewCertificate/CertificateVerification';
import AuditorLayout from 'layouts/AuditorLayout';
import Paywall from 'views/merchant/UpsellPaywall/AppPaywall';
import Upsell from 'views/merchant/UpsellPaywall/AppUpsell';

const App = () => {
    const [isRefreshing, setIsRefreshing] = useState(false);
    useEffect(() => {
        window.addEventListener('beforeunload', showRefreshLoader);

        return () => {
            window.removeEventListener('beforeunload', showRefreshLoader);
        };
    }, []);

    const showRefreshLoader = () => {
        setIsRefreshing(true);
    };
    if (isRefreshing) {
        return (
            <div className="flex items-center justify-center m-auto h-screen ">
                <CircularProgress disableShrink sx={{ '& .MuiCircularProgress-circle': { height: '300px' } }} />
            </div>
        );
    }

    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Route path="/verifystatus/:id" render={(props) => <VerifySecurityTraining {...props} />} />
                    <Route path="/training/:training_id" render={(props) => <EmployeeTrainingView {...props} />} />
                    <Route
                        path="/verification/:confirmation_token"
                        render={(props) => <AuditCertificateVerification {...props} />}
                    />
                    <Route path="/countdown" render={(props) => <AuditCertificateCountdown {...props} />} />
                    <Route path="/training-cert/:training_id" render={(props) => <TrainingCertificate {...props} />} />
                    <Route
                        path="/verification/:confirmation_token"
                        render={(props) => <AuditCertificateVerification {...props} />}
                    />
                    <Route path="/paywall" render={(props) => <Paywall {...props} />} />
                    <Route path="/upsell" render={(props) => <Upsell {...props} />} />
                    <Route path="/validate" render={(props) => <AuditCertificateCountdown {...props} />} />
                    <Route path="/vendors/:merchant/:vendor" render={(props) => <VendorsView {...props} />} />
                    <Route path="/survey/:merchant_id/:user" render={(props) => <MerchantSurvey {...props} />} />
                    <Route path="/auditor-pool" render={(props) => <Marketplace {...props} />} />
                    <AuditorRoute path="/auditor" render={(props) => <AuditorLayout {...props} />} />
                    <MerchantRoute path="/merchant" render={(props) => <MerchantLayout {...props} />} />
                    <AdminRoute path="/admin" render={(props) => <AdminDashboard {...props} />} />
                    <Redirect from="/" to="/auth/login" />
                </Switch>
            </BrowserRouter>
        </>
    );
};

export default App;
