import { Close } from '@mui/icons-material';
import { Box, Chip, IconButton, Typography, useTheme } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppSubmitButton } from 'components/new_components/forms';
import { AppFormSelect } from 'components/new_components/forms';
import { AppForm } from 'components/new_components/forms';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { assignTaskValidation } from '../validation';
import { AppFormInput } from 'components/new_components/forms';
import { GetAllCompanyEmployees } from 'store/actions/generalActions';
import { AssignComplianceTask } from 'store/actions/taskActions';
import { toast } from 'react-toastify';

const AssignTaskModal = (props) => {
    const {
        open,
        onClose,
        GetAllCompanyEmployees,
        AssignComplianceTask,
        all_employees,
        type,
        user_id,
        employee_id,
        taskInfo,
    } = props;
    const theme = useTheme();

    // state
    const [loading, setLoading] = useState({});
    const [payload, setPayload] = useState({ employees: [], comments: '' });

    // memos
    const employees = useMemo(() => {
        return all_employees
            ?.filter((emp) => emp?.id !== employee_id)
            ?.map((user) => ({
                name: `${user?.user_detail?.first_name} ${user?.user_detail?.last_name}`,
                value: user?.id,
            }));
    }, [all_employees]);
    // functions
    const handleClose = () => {
        onClose();
        setTimeout(() => setPayload({}), 500);
    };
    const handleSubmit = async (values) => {
        const users = values.employees?.map((employee) => parseInt(employee));
        setLoading({ ...loading, submit: true });
        console.log({ taskInfo });
        const res = await AssignComplianceTask({
            assigned_to: users,
            comments: values?.comments,
            assigned_by: user_id,
            tag: 'compliance',
            completion_status: 'not_completed',
            mark_as_completed: false,
            ...taskInfo,
        });
        setLoading({ ...loading, submit: false });
        if (res?.success) {
            toast.success('Task has been assigned to the employee(s)');
            handleClose();
        } else {
            toast.error("Something went wrong, couldn't assign task to employee(s)");
        }
    };
    //async action
    const getAllEmployees = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllCompanyEmployees();
        setLoading({ ...loading, content: false });
        if (!res?.status) {
            toast.error(res?.status);
        }
    };
    // useEffect
    useEffect(() => {
        if (open) {
            setPayload({ employees: '', comments: '' });
        }
    }, [open]);

    useEffect(() => {
        getAllEmployees();
    }, []);

    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={assignTaskValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[800] }}>
                        Assign this {type === 'doc' ? 'document' : type === 'form' ? 'question' : type} to:
                    </Typography>
                }
                headerAction={
                    <IconButton color="disabled" onClick={handleClose}>
                        <Close />
                    </IconButton>
                }
                titleStyle={{ borderBottom: `1px solid ${theme.palette.neutral[90]}` }}
                width={540}
                sx={{ top: '-10%' }}
                padding="0 32px"
            >
                <Box sx={{ py: 0.4, pb: 6 }}>
                    <Box>
                        <AppFormSelect
                            label="User (person(s))"
                            multiple
                            options={employees}
                            name="employees"
                            loading={loading.content}
                            noDataLabel="No employees at the moment"
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1.2,
                                    }}
                                >
                                    {selected?.map((value) => {
                                        const employee = employees?.find((employee) => employee?.value == value);
                                        return (
                                            <Chip
                                                sx={{
                                                    backgroundColor: theme.palette.gray[95],
                                                    borderRadius: 1,
                                                    p: 0.5,
                                                    color: theme.palette.gray[40],
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                                key={value}
                                                label={employee?.name}
                                            />
                                        );
                                    })}
                                </Box>
                            )}
                        />
                        <AppFormInput
                            type="text"
                            label="Comment"
                            name="comments"
                            multiline
                            rows={4}
                            placeholder="Comment"
                        />
                        <AppSubmitButton
                            text="Assign"
                            variant="contained"
                            color="primary"
                            loadingPosition="center"
                            loading={loading?.submit}
                            sx={{ width: '100%', mt: 4.5 }}
                        />
                    </Box>
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};
const mapStateToProps = (state) => ({
    all_employees: state?.generalReducers?.all_employees,
    user_id: state?.authReducers?.user_id,
    employee_id: state?.authReducers?.user_info?.employee?.id,
    user_type: state?.authReducers?.user_type,
});
export default connect(mapStateToProps, { GetAllCompanyEmployees, AssignComplianceTask })(AssignTaskModal);
