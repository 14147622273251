import React, { useMemo, useEffect, useState } from 'react';

// core components
import SubgroupView from '../../common/SubgroupView';
import SubgroupTabs from '../../common/SubgroupTabs';
import DescriptionLayer from '../../common/DescriptionLayer';
import CodeReviewItem from './CodeReviewItem';
import ConditionalItem from './ConditionalItem';
import DocumentItem from './DocumentItem';
import PentestItem from './PentestItem';
import PciScanItem from './PciScan';
import CodeRepositoryItem from './CodeRepositoryItem';
import VulnerabilityManagementItem from './VulnerabilityManagemnetItem';
import DocumentationLifeCycleItem from './DocumentationLifeCycleItem';
import IdentityManagementItem from './IdentityManagementItem';
import ServerConfigurationItem from './ServerConfigurationItem';
import NetworkOperationsItem from './NetworkOperationsItem';
import DataManagementItem from './DataManagementItem';
import SystemManagementItem from './SystemManagementItem';
import ConfigurationManagementItem from './ConfigManagementItem';
import { toast } from 'react-toastify';
import BackgroundCheckItem from './BackgroundCheckItem';
import AntiVirusItem from './AntiVirusItem';
import QuestionItem from './QuestionItem';
import RiskAssessmentItem from './RiskAssessmentItem';
import LoadingState from 'components/new_components/LoadingState';
import EmptyState from 'components/new_components/EmptyState';

// Redux
import { connect } from 'react-redux';
import { GetAllConditionalItems, GetAllToolsItems, GetUserTools } from 'store/actions/adminActions';

//translation
import { useTranslation } from 'react-i18next';

const SubCriterias = (props) => {
    const {
        goBack,
        criteria,
        getSubCriteriaDocumentsByTag,
        documents_by_subcriteria,
        all_forms_by_subcriteria,
        merchantResources,
        loading,
        touchedFields,
        setFormPayload,
        getSubCriteriaQuestion,
        all_merchant_documents,
        all_condition_answers,
        getAllExemptedBlockchain,
        all_criteria,
        handleResourceExempted,
        GetAllConditionalItems,
        all_conditional_forms,
        formPayload,
        all_criteria_summary,
        GetAllToolsItems,
        all_tools_items,
        GetUserTools,
        all_background_check_items,
        condtitionalPayload,
    } = props;
    const [page, setPage] = useState(1);
    const [selectedSubcriteriaNo, setselectedSubcriteriaNo] = useState(null);
    const [document_id, setDocument_id] = useState('');
    // const dispatch = useDispatch();

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    // get the selected Criteria obj from the criteria ID
    const selectedRequirement = useMemo(
        () => all_criteria?.find((onecriteria) => onecriteria?.id === criteria),
        [criteria, all_criteria]
    );

    // functions
    const getAllConditionalItems = async () => {
        const res = await GetAllConditionalItems();
        if (!res?.success) {
            console.log(res);
        }
    };

    const getAlltoolsItems = async () => {
        const res = await GetAllToolsItems();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getUserBackground_checkTools = async () => {
        const res = await GetUserTools();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllConditionalItems();
        getAlltoolsItems();
        getUserBackground_checkTools();
    }, []);

    // get the subquirements for the selected requirement
    const subCriterias = useMemo(() => {
        return selectedRequirement?.subcriterias?.length
            ? selectedRequirement?.subcriterias
                  ?.sort((a, b) => a?.id - b?.id)
                  ?.map((subRequirement) => ({
                      id: subRequirement?.id,
                      title: subRequirement?.name,
                      description: subRequirement?.description,
                      criteria: subRequirement?.criteria,
                      contain_tools: subRequirement?.contain_tools,
                      contain_risk: subRequirement?.contain_risk,
                  }))
            : [];
    }, [selectedRequirement]);

    // when the sub group is changed set the page to 1 (first stage)
    const handleSubgroupChange = (id) => {
        setselectedSubcriteriaNo(id);
        goToNextResource(1);
    };

    useEffect(() => {
        if (subCriterias) {
            const activeSubrequirement = subCriterias?.find((sub) => sub?.id);
            if (activeSubrequirement) {
                setselectedSubcriteriaNo(activeSubrequirement?.id);
            } else {
                goBack();
            }
        }
    }, [subCriterias]);

    const selectedCategory = all_criteria?.find((data) => data.id === criteria);
    const selectedSubCriteria = subCriterias?.find((data) => data.id === selectedSubcriteriaNo);

    // Documents and forms
    const conditional_forms = all_conditional_forms?.filter(
        (conditional) => conditional.subcriteria === selectedSubcriteriaNo
    );
    const document_only = documents_by_subcriteria?.filter((doc) => doc?.type === '' || doc.type === null);
    const codereview_documents = documents_by_subcriteria?.filter((doc) => doc?.type === 'codereview');
    const pentest_documents = documents_by_subcriteria?.filter((doc) => doc?.type === 'pentest');
    const pciscan_documents = documents_by_subcriteria?.filter((doc) => doc?.type === 'pciscan');
    const background_checks = all_tools_items?.filter(
        (category) => category?.category === 'background check' && category?.subcriteria === selectedSubcriteriaNo
    );
    const antivirus_checks = all_tools_items?.filter(
        (category) => category?.category === 'antivirus' && category?.subcriteria === selectedSubcriteriaNo
    );
    const risk_assessment = all_tools_items?.filter(
        (category) => category?.category === 'risk assessment' && category?.subcriteria === selectedSubcriteriaNo
    );
    const network_operations = all_tools_items?.filter(
        (category) => category?.category === 'network operations' && category?.subcriteria === selectedSubcriteriaNo
    );
    const data_management = all_tools_items?.filter(
        (category) => category?.category === 'data management' && category?.subcriteria === selectedSubcriteriaNo
    );

    const vulnerability_management = all_tools_items?.filter(
        (category) =>
            category?.category === 'vulnerability management' && category?.subcriteria === selectedSubcriteriaNo
    );
    const identity_and_access_management = all_tools_items?.filter(
        (category) =>
            category?.category === 'identity and access management' && category?.subcriteria === selectedSubcriteriaNo
    );
    const documentation_lifecycle = all_tools_items?.filter(
        (category) =>
            category?.category === 'documentation lifecycle' && category?.subcriteria === selectedSubcriteriaNo
    );
    const server_configuration = all_tools_items?.filter(
        (category) => category?.category === 'server configuration' && category?.subcriteria === selectedSubcriteriaNo
    );
    const code_repository = all_tools_items?.filter(
        (category) => category?.category === 'code repository' && category?.subcriteria === selectedSubcriteriaNo
    );
    const systems_and_security_monitoring = all_tools_items?.filter(
        (category) =>
            category?.category === 'systems and security monitoring' && category?.subcriteria === selectedSubcriteriaNo
    );
    const configuration_management = all_tools_items?.filter(
        (category) =>
            category?.category === 'configuration management' && category?.subcriteria === selectedSubcriteriaNo
    );

    // Data to render
    const data = document_only?.concat(
        all_forms_by_subcriteria,
        conditional_forms,
        pentest_documents,
        codereview_documents,
        background_checks,
        antivirus_checks,
        risk_assessment,
        network_operations,
        data_management,
        vulnerability_management,
        identity_and_access_management,
        documentation_lifecycle,
        server_configuration,
        code_repository,
        systems_and_security_monitoring,
        configuration_management,
        pciscan_documents
    );
    // Filtered Data
    const filteredData = data?.filter((dat) => dat != undefined);

    useEffect(() => {
        if (selectedSubcriteriaNo) {
            getSubCriteriaDocumentsByTag(selectedSubcriteriaNo);
            getSubCriteriaQuestion(selectedSubcriteriaNo);
        }
    }, [selectedSubcriteriaNo]);

    // get the selected document for the current page
    const selectedResource = useMemo(() => {
        return filteredData?.[page - 1];
    }, [filteredData, page]);

    // // functions
    const goToNextResource = (page) => {
        if (page <= filteredData?.length) {
            setPage(page);
        }
    };

    // get documents and forms that have been attended to
    const documentAttendedTo = useMemo(() => {
        return document_only?.length
            ? document_only?.map((subReqDoc) => {
                  return all_merchant_documents?.some(
                      (merchDoc) => merchDoc?.document_id === subReqDoc?.id && merchDoc?.tag === null
                  );
              })
            : null;
    }, [all_merchant_documents, document_only]);

    const document_codereviewAttendedTo = useMemo(() => {
        return codereview_documents?.length
            ? codereview_documents?.map((subReqDoc) => {
                  return all_merchant_documents?.some(
                      (merchDoc) => merchDoc?.document_id === subReqDoc?.id && merchDoc?.tag === 'codereview'
                  );
              })
            : null;
    }, [all_merchant_documents, codereview_documents]);

    const document_pentestAttendedTo = useMemo(() => {
        return pentest_documents?.length
            ? pentest_documents.map((subReqDoc) => {
                  return all_merchant_documents?.some(
                      (merchDoc) => merchDoc?.document_id === subReqDoc?.id && merchDoc?.tag === 'pentest'
                  );
              })
            : null;
    }, [all_merchant_documents, pentest_documents]);

    const formResourcesHaveBeenAttendedTo = useMemo(() => {
        return all_forms_by_subcriteria?.length
            ? all_forms_by_subcriteria?.map((subReqDoc) => {
                  return merchantResources?.some((merchDoc) => merchDoc?.form_id === subReqDoc?.id);
              })
            : null;
    }, [merchantResources, all_forms_by_subcriteria]);

    const conditionalResourcesHaveBeenAttendedTo = useMemo(() => {
        return conditional_forms?.length
            ? conditional_forms?.map((subReqDoc) => {
                  return all_condition_answers?.some((merchDoc) => merchDoc?.conditional === subReqDoc?.id);
              })
            : null;
    }, [all_condition_answers, conditional_forms]);

    const backgroundChecksResourcesHaveBeenAttendedTo = useMemo(() => {
        return background_checks?.length
            ? background_checks?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id && merchDoc?.category_details['people with antivirus'] > 0
                  );
              })
            : null;
    }, [all_background_check_items, background_checks]);

    const antivirusResourcesHaveBeenAttendedTo = useMemo(() => {
        return antivirus_checks?.length
            ? antivirus_checks?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id && merchDoc?.category_details?.people_with_background_check > 0
                  );
              })
            : null;
    }, [all_background_check_items, antivirus_checks]);

    const riskAssessmentHaveBeenAttendedTo = useMemo(() => {
        return risk_assessment?.length
            ? risk_assessment?.map((subReqDoc) => {
                  return all_background_check_items?.some((merchDoc) => merchDoc?.id === subReqDoc?.id);
              })
            : null;
    }, [all_background_check_items, risk_assessment]);

    const networkOperationsHaveBeenAttendedTo = useMemo(() => {
        return network_operations?.length
            ? network_operations?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id &&
                          (merchDoc?.category_details?.network_operations?.passed > 0) |
                              (merchDoc?.category_details?.network_operations?.failed > 0)
                  );
              })
            : null;
    }, [all_background_check_items, network_operations]);

    const dataManagemnetHaveBeenAttendedTo = useMemo(() => {
        return data_management?.length
            ? data_management?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id &&
                          (merchDoc?.category_details?.data_management?.passed > 0) |
                              (merchDoc?.category_details?.data_management?.failed > 0)
                  );
              })
            : null;
    }, [all_background_check_items, data_management]);

    const vulnerablilityManagementHaveBeenAttendedTo = useMemo(() => {
        return vulnerability_management?.length
            ? vulnerability_management?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id &&
                          (merchDoc?.category_details?.vulnerability_management?.passed > 0) |
                              (merchDoc?.category_details?.vulnerability_management?.failed > 0)
                  );
              })
            : null;
    }, [all_background_check_items, vulnerability_management]);

    const identityManagementHaveBeenAttendedTo = useMemo(() => {
        return identity_and_access_management?.length
            ? identity_and_access_management?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id &&
                          (merchDoc?.category_details?.identity_and_access_management?.passed > 0) |
                              (merchDoc?.category_details?.identity_and_access_management?.failed > 0)
                  );
              })
            : null;
    }, [all_background_check_items, identity_and_access_management]);

    const documentationLifecycleHaveBeenAttendedTo = useMemo(() => {
        return documentation_lifecycle?.length
            ? documentation_lifecycle?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id &&
                          (merchDoc?.category_details?.documentation_lifecycle?.passed > 0) |
                              (merchDoc?.category_details?.documentation_lifecycle?.failed > 0)
                  );
              })
            : null;
    }, [all_background_check_items, documentation_lifecycle]);

    const serverConfigurationHaveBeenAttendedTo = useMemo(() => {
        return server_configuration?.length
            ? server_configuration?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id &&
                          (merchDoc?.category_details?.server_configuration?.passed > 0) |
                              (merchDoc?.category_details?.server_configuration?.failed > 0)
                  );
              })
            : null;
    }, [all_background_check_items, server_configuration]);

    const codeRepositoryHaveBeenAttendedTo = useMemo(() => {
        return code_repository?.length
            ? code_repository?.map((subReqDoc) => {
                  return all_background_check_items?.some((merchDoc) => merchDoc?.id === subReqDoc?.id);
              })
            : null;
    }, [all_background_check_items, code_repository]);

    const systemManagementHaveBeenAttendedTo = useMemo(() => {
        return systems_and_security_monitoring?.length
            ? systems_and_security_monitoring?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id &&
                          (merchDoc?.category_details?.systems_and_security_monitoring?.passed > 0) |
                              (merchDoc?.category_details?.systems_and_security_monitoring?.failed > 0)
                  );
              })
            : null;
    }, [all_background_check_items, systems_and_security_monitoring]);

    const configManagementHaveBeenAttendedTo = useMemo(() => {
        return configuration_management?.length
            ? configuration_management?.map((subReqDoc) => {
                  return all_background_check_items?.some(
                      (merchDoc) =>
                          merchDoc?.id === subReqDoc?.id &&
                          (merchDoc?.category_details?.configuration_management?.passed > 0) |
                              (merchDoc?.category_details?.configuration_management?.failed > 0)
                  );
              })
            : null;
    }, [all_background_check_items, configuration_management]);

    const document_pciscansAttendedTo = useMemo(() => {
        return pciscan_documents?.length
            ? pciscan_documents.map((subReqDoc) => {
                  return all_merchant_documents?.some(
                      (merchDoc) => merchDoc?.document_id === subReqDoc?.id && merchDoc?.tag === 'pciscan'
                  );
              })
            : null;
    }, [all_merchant_documents, pciscan_documents]);

    // Array of documents and forms attended to
    const resourcesHaveBeenAttendedTo = (documentAttendedTo === null ? [] : documentAttendedTo)?.concat(
        formResourcesHaveBeenAttendedTo,
        conditionalResourcesHaveBeenAttendedTo,
        document_pentestAttendedTo,
        document_codereviewAttendedTo,
        backgroundChecksResourcesHaveBeenAttendedTo,
        antivirusResourcesHaveBeenAttendedTo,
        riskAssessmentHaveBeenAttendedTo,
        networkOperationsHaveBeenAttendedTo,
        dataManagemnetHaveBeenAttendedTo,
        vulnerablilityManagementHaveBeenAttendedTo,
        identityManagementHaveBeenAttendedTo,
        documentationLifecycleHaveBeenAttendedTo,
        serverConfigurationHaveBeenAttendedTo,
        codeRepositoryHaveBeenAttendedTo,
        systemManagementHaveBeenAttendedTo,
        configManagementHaveBeenAttendedTo,
        document_pciscansAttendedTo
    );

    const filteredResourcesHaveBeenAttendedTo = resourcesHaveBeenAttendedTo?.filter(
        (resource) => resource !== null && resource !== undefined
    );

    const subCriteriatCompletionPercent = useMemo(() => {
        return (
            (filteredResourcesHaveBeenAttendedTo?.reduce((prev, curr) => prev + (curr ? 1 : 0), 0) * 100) /
            filteredResourcesHaveBeenAttendedTo?.length
        );
    }, [filteredResourcesHaveBeenAttendedTo]);

    const resource = selectedResource?.no_label ? 'conditional' : 'form';

    // Form submit useeffect
    useEffect(() => {
        if (resource === 'form') {
            const adminResource = selectedResource;
            const merchantResource = merchantResources?.length
                ? merchantResources
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.find((resource) => resource?.form_id === adminResource?.id)
                : {};
            let initialValues = {},
                requiredValues = {};
            adminResource?.form_fields?.forEach((field) => {
                initialValues[field?.id] = merchantResource?.answer?.[field?.id] || '';
                requiredValues[field?.id] = field?.required;
            });
            setFormPayload({
                values: initialValues,
                form_id: adminResource?.id,
                subcriteria: selectedSubcriteriaNo,
                resource,
                requiredValues,
            });
        } else {
            const merchantResource = all_condition_answers?.length
                ? all_condition_answers
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.find((resource) => resource?.conditional === selectedResource?.id)
                : {};
            let initialValues = {};
            initialValues['answer'] = merchantResource?.answer || '';
            initialValues['no_label'] = merchantResource?.form_answer || '';

            setFormPayload({
                values: initialValues,
                subcriteria: selectedSubcriteriaNo,
                conditional: selectedResource?.id,
                resource,
                criteria,
                document_id: document_id,
            });
        }
    }, [selectedResource, merchantResources, resource]);
    // On resource change
    const onResourceChange = () => {
        handleResourceExempted(selectedSubcriteriaNo);
        if (page > 1) {
            setPage(page - 1);
        } else {
            setPage(1);
        }
    };

    const checkCompleted = (item) => {
        return item === true || item?.value === true;
    };
    const completed = filteredResourcesHaveBeenAttendedTo?.every(checkCompleted);
    const one_summary = all_criteria_summary?.find((summary) => summary?.subcriteria === selectedSubcriteriaNo);

    const activeSubCriterias = useMemo(() => {
        return all_criteria_summary?.length
            ? all_criteria_summary?.map((subReqDoc) => {
                  return subCriterias?.find(
                      (merchDoc) => subReqDoc?.total > 0 && merchDoc?.id === subReqDoc?.subcriteria
                  );
              })
            : null;
    }, [subCriterias, all_criteria_summary]);

    useEffect(() => {
        if (one_summary?.total === 0 && one_summary?.tools?.length === 0) {
            const activeSubCriteria = activeSubCriterias?.find((item) => item);
            setselectedSubcriteriaNo(activeSubCriteria?.id);
        }
    }, [one_summary?.total, one_summary?.tools?.length]);
    return (
        <SubgroupView
            goBack={goBack}
            title={selectedCategory.name || selectedResource?.id}
            goToNextResource={goToNextResource}
            currentResourcePage={page}
            touchedFields={touchedFields}
            data={filteredResourcesHaveBeenAttendedTo}
            submitting={loading.submit}
            resource={selectedResource?.question || selectedResource?.form_fields ? 'form' : 'doc'}
            selectedSubcriteriaNo={selectedSubcriteriaNo}
            standard="blockchain"
        >
            <SubgroupTabs
                subgroups={subCriterias}
                onSubgroupChange={handleSubgroupChange}
                selectedSubgroup={selectedSubcriteriaNo}
                selectedSubCriteria={selectedSubCriteria}
                selectedResource={selectedResource}
                selectedSubcriteriaNo={selectedSubcriteriaNo}
                criteria={criteria}
                completed={completed}
                isCriteria
            />
            {loading.content ? (
                <LoadingState />
            ) : (
                <>
                    <DescriptionLayer
                        percentCompletion={subCriteriatCompletionPercent}
                        label={`${t('sub')} ${selectedSubCriteria?.title} ${t('completion')}`}
                        description={selectedSubCriteria?.description}
                    />
                    {selectedResource?.type === 'codereview' ? (
                        <CodeReviewItem
                            title={selectedResource?.criteria_title}
                            id={selectedSubcriteriaNo}
                            document={selectedResource}
                            onResourceChange={onResourceChange}
                            criteria={criteria}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.default &&
                      (selectedResource?.type === '' || selectedResource?.type === null) ? (
                        <DocumentItem
                            title={
                                language?.includes('fr')
                                    ? selectedResource?.criteria_title_fr
                                    : language?.includes('en')
                                    ? selectedResource?.criteria_title_en
                                    : selectedResource?.criteria_title
                            }
                            document={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.form_fields ? (
                        <QuestionItem
                            form={selectedResource}
                            title="title"
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.question ? (
                        <ConditionalItem
                            form={selectedResource}
                            setDocument_id={setDocument_id}
                            formPayload={formPayload}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                            condtitionalPayload={condtitionalPayload}
                            documnetId={document_id}
                        />
                    ) : selectedResource?.type === 'pentest' ? (
                        <PentestItem
                            title={
                                language?.includes('fr')
                                    ? selectedResource?.criteria_title_fr
                                    : language?.includes('en')
                                    ? selectedResource?.criteria_title_en
                                    : selectedResource?.criteria_title
                            }
                            id={selectedSubcriteriaNo}
                            document={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'risk assessment' ? (
                        <RiskAssessmentItem
                            risk_assessment={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'background check' ? (
                        <BackgroundCheckItem
                            background_checks={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'antivirus' ? (
                        <AntiVirusItem
                            antivirus={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'network operations' ? (
                        <NetworkOperationsItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'data management' ? (
                        <DataManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'vulnerability management' ? (
                        <VulnerabilityManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'identity and access management' ? (
                        <IdentityManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'documentation lifecycle' ? (
                        <DocumentationLifeCycleItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'server configuration' ? (
                        <ServerConfigurationItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'code repository' ? (
                        <CodeRepositoryItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'systems and security monitoring' ? (
                        <SystemManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.category === 'configuration management' ? (
                        <ConfigurationManagementItem
                            category={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : selectedResource?.type === 'pciscan' ? (
                        <PciScanItem
                            title={
                                language?.includes('fr')
                                    ? selectedResource?.criteria_title_fr
                                    : language?.includes('en')
                                    ? selectedResource?.criteria_title_en
                                    : selectedResource?.criteria_title
                            }
                            id={selectedSubcriteriaNo}
                            document={selectedResource}
                            onResourceChange={onResourceChange}
                            getAllExemptedBlockchain={getAllExemptedBlockchain}
                        />
                    ) : (
                        <EmptyState description={t('blockchain.noData')} />
                    )}
                </>
            )}
        </SubgroupView>
    );
};

const mapStateToProps = (state) => {
    return {
        all_criteria: state?.adminReducers?.all_criteria,
        documents_by_subcriteria: state?.adminReducers?.documents_by_subcriteria,
        all_forms_by_subcriteria: state?.adminReducers.all_blockchain_forms,
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
        all_condition_answers: state?.complianceReducers?.all_condition_answers,
        all_conditional_forms: state?.adminReducers?.all_conditional_items,
        all_criteria_summary: state?.complianceReducers?.all_criteria_summary,
        all_tools_items: state?.adminReducers?.all_tools_items,
        all_background_check_items: state?.adminReducers?.all_background_check_items,
    };
};
export default connect(mapStateToProps, {
    GetAllConditionalItems,
    GetAllToolsItems,
    GetUserTools,
})(SubCriterias);
