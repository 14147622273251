import React, { useState } from 'react';
import { toast } from 'react-toastify';

import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import { DeleteInfrastucture } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const InfrastructureDeleteModal = (props) => {
    const { open, handleClose, DeleteInfrastucture, id } = props;

    const [deleting, setDeleting] = useState(false);

    //translation
    const { t } = useTranslation('inventory');

    //functions
    const handleDelete = async () => {
        setDeleting(true);
        const res = await DeleteInfrastucture(id);
        setDeleting(false);
        if (res?.success) {
            toast.success('Successfully deleted an infrastructure.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppDeleteModal
            open={open}
            handleClose={handleClose}
            title={t('infrastructureDeleteModal.title')}
            subtitle={t('infrastructureDeleteModal.subtitle')}
            deleting={deleting}
            onDelete={handleDelete}
        />
    );
};
const mapStateToProps = (state) => {
    return { ...state };
};
export default connect(mapStateToProps, {
    DeleteInfrastucture,
})(InfrastructureDeleteModal);
