import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton, List, Stack, Typography } from '@mui/material';

// core component
import Brand from '../../Brand';
import SidebarListItem from './SidebarListItem';
import ListIcon from './ListIcon';
import SidebarListGroup from './SidebarListGroup';
import SidebarDivider from './SidebarDivider';
import Hello from 'assets/img/Hello.svg';
//redux
import { useSelector } from 'react-redux';
import AppProgressBar from 'components/new_components/AppProgressBar';
import { Close } from '@mui/icons-material';

const SidebarContent = (props) => {
    // hooks
    const location = useLocation();
    const { routes, merchant_info, title, ref2 } = props;
    const [closeSetup, setCloseUp] = useState(true);
    const close_setup = localStorage.getItem('close_setup');
    const language = localStorage.getItem('i18nextLng');

    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const mfa = useSelector((state) => state?.generalReducers?.user_info?.mfa);

    const theme = useTheme();
    useEffect(() => {
        if (close_setup) {
            setCloseUp(false);
        }
    });

    const top_routes = routes?.filter(
        (route) =>
            route?.name !== (language?.includes('fr') ? 'Intégrations' : 'Integrations') &&
            route?.name !== (language?.includes('fr') ? 'Paramètres' : 'Settings') &&
            route?.name !== (language?.includes('fr') ? 'Activités' : 'Activities')
    );
    const bottom_routes = routes?.filter(
        (route) =>
            route?.name === (language?.includes('fr') ? 'Intégrations' : 'Integrations') ||
            route?.name === (language?.includes('fr') ? 'Paramètres' : 'Settings') ||
            route?.name === (language?.includes('fr') ? 'Activités' : 'Activities')
    );
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Brand />
            <Stack direction="column" justifyContent="space-between" sx={{ height: 'calc(100vh - 70px)' }}>
                <List
                    sx={{
                        mr: 0.8,
                        // height: '100%',
                        height: 'calc(100vh - 108px)',
                        // overflow: 'hidden',
                        // mt: 'auto',
                        // overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary[100] + '30',
                            borderRadius: '10px',
                        },
                    }}
                >
                    {top_routes
                        .filter((res) => !res.subRoute)
                        .map((route) => {
                            if (route.subMenus) {
                                const activeChild = route.subMenus.find(
                                    (submenu) => location.pathname === submenu.layout + submenu.path
                                );
                                return (
                                    <SidebarListGroup
                                        key={route.name}
                                        subMenus={route.subMenus}
                                        active={activeChild}
                                        text={route.name}
                                        icon={<ListIcon Component={route?.icon} />}
                                        icon_active={<ListIcon Component={route?.icon_active || route?.icon} />}
                                        merchant_info={merchant_info}
                                        route={route}
                                        isNew={route.isNew}
                                        disabled={!mfa && route.layout + route.path !== '/merchant/settings'}
                                        title={title}
                                        ref2={ref2}
                                    />
                                );
                            } else {
                                const isActive = location.pathname === route.layout + route.path;
                                return !route?.subRoute ? (
                                    <SidebarListItem
                                        active={isActive}
                                        icon={<ListIcon Component={route.icon} />}
                                        icon_active={<ListIcon Component={route?.icon_active || route.icon} />}
                                        text={route.name}
                                        link={route.layout + route.path}
                                        key={route.name + route.path}
                                        isNew={route.isNew}
                                        subMenus={route.subMenus}
                                        title={title}
                                        disabled={!mfa && route.layout + route.path !== '/merchant/settings'}
                                    />
                                ) : null;
                            }
                        })}
                </List>
                <Box>
                    <List
                        sx={{
                            mr: 0.8,
                            // height: '200px',
                            // overflow: 'hidden',
                            // overflowY: 'auto',
                            mb: user_info?.complete_setup?.percentage === 100 ? 2 : 0,
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary[100] + '30',
                                borderRadius: '10px',
                            },
                        }}
                    >
                        {bottom_routes
                            .filter((res) => !res.subRoute)
                            .map((route) => {
                                if (route.subMenus) {
                                    const activeChild = route.subMenus.find(
                                        (submenu) => location.pathname === submenu.layout + submenu.path
                                    );

                                    return (
                                        <SidebarListGroup
                                            key={route.name}
                                            subMenus={route.subMenus}
                                            active={activeChild}
                                            text={route.name}
                                            icon={<ListIcon Component={route.icon} />}
                                            merchant_info={merchant_info}
                                            route={route}
                                            isNew={route.isNew}
                                            title={title}
                                            disabled={!mfa && route.layout + route.path !== '/merchant/settings'}
                                            ref2={ref2}
                                        />
                                    );
                                } else if (route.divider) {
                                    return <SidebarDivider title={route.title} key={route.title} />;
                                } else {
                                    const isActive = location.pathname === route.layout + route.path;
                                    return (
                                        <SidebarListItem
                                            active={isActive}
                                            icon={<ListIcon Component={route.icon} />}
                                            icon_active={<ListIcon Component={route?.icon_active || route.icon} />}
                                            text={route.name}
                                            link={route.layout + route.path}
                                            key={route.name + route.path}
                                            isNew={route.isNew}
                                            subMenus={route.subMenus}
                                            title={title}
                                            disabled={!mfa && route.layout + route.path !== '/merchant/settings'}
                                        />
                                    );
                                }
                            })}
                    </List>
                    {user_info?.complete_setup?.percentage < 100 && (
                        <>
                            {user_info?.user_type !== 'auditor' && user_info?.user_type !== 'support' ? (
                                <>
                                    {closeSetup && (
                                        <div
                                            className={`px-3 pb-3 rounded ${
                                                title === 'Setup Task' ? 'z-index-10' : ''
                                            }`}
                                        >
                                            <SetupProgress setCloseUp={setCloseUp} />
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </>
                    )}
                </Box>
            </Stack>
        </Box>
    );
};

const SetupProgress = (props) => {
    const { setCloseUp } = props;
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const location = useLocation();
    const theme = useTheme();
    return (
        <Box
            sx={{
                pr: 1,
                pl: 2,
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#2D3F8F',
                width: '100%',
                borderRadius: '4px',
                marginTop: 0.5,
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    onClick={() => {
                        if (localStorage.getItem('setup_complete')) {
                            localStorage.removeItem('setup_complete');
                            if (location.pathname === '/merchant/index') {
                                window.location.reload();
                            } else {
                                window.location = '/merchant/index';
                            }
                        }
                    }}
                >
                    <img className="w-[28px] h-[28px] pt-1" src={Hello} alt="Hello" />
                    <Typography
                        sx={{ color: theme.palette.white.main, fontSize: 12, fontWeight: 500, letterSpacing: '0.1px' }}
                    >
                        Complete your setup
                    </Typography>
                </Stack>

                <IconButton
                    sx={{
                        backgroundColor: 'transparent',
                        color: theme.palette.white.main,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                    onClick={() => {
                        setCloseUp(false);
                        localStorage.setItem('close_setup', true);
                    }}
                >
                    <Close sx={{ fontSize: 14 }} />
                </IconButton>
            </Stack>
            <Box
                sx={{ mt: 2, width: '80%', pl: 1.5 }}
                onClick={() => {
                    if (localStorage.getItem('setup_complete')) {
                        localStorage.removeItem('setup_complete');
                    }
                    if (location.pathname === '/merchant/index') {
                        window.location.reload();
                    } else {
                        window.location = '/merchant/index';
                    }
                }}
            >
                <AppProgressBar
                    sx={{
                        '&.MuiLinearProgress-colorPrimary': {
                            backgroundColor: theme.palette.primary.lightNew,
                        },
                    }}
                    value={user_info?.complete_setup?.percentage}
                    color="primary"
                />
                <Typography sx={{ fontSize: 11, mt: 1, color: theme.palette.white.main }}>
                    <span>{user_info?.complete_setup?.percentage}%</span> done
                </Typography>
            </Box>
        </Box>
    );
};

export default SidebarContent;
