import React from 'react';
//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppListItems from 'components/new_components/AppListItems';
import { Add, Upload } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';

const AddInfrastructureAction = ({ open, handleClose, handleOpenModal, handleOpenUploadCSVModal }) => {
    //translation
    const { t } = useTranslation('inventory');

    //function
    const OpenAddModal = () => {
        handleOpenModal('add');
        handleClose();
    };
    const OpenCSVModal = () => {
        handleOpenUploadCSVModal();
        handleClose();
    };
    const items = [
        {
            id: 1,
            name: t('addInfrastructure'),
            icon: <Add />,
            onClick: OpenAddModal,
        },
        {
            id: 2,
            name: t('importFromCSV'),
            icon: <Upload />,
            onClick: OpenCSVModal,
        },
    ];
    return (
        <>
            <AppCenteredModal open={open} handleClose={handleClose}>
                <AppListItems items={items} />
            </AppCenteredModal>
        </>
    );
};

export default AddInfrastructureAction;
