import React from 'react';
import { Stack } from '@mui/material';
import AppModal from 'components/new_components/AppModal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import AppButton from 'components/new_components/AppButton';
import DatePicker from 'components/new_components/DatePicker';

const DatePickerModal = (props) => {
    const { open, handleClose, from, to, setFrom, setTo, dateModal, setDateModal } = props;

    const handleSave = () => {
        setDateModal(!dateModal);
    };

    const clearSelection = () => {
        setFrom(null);
        setTo(null);
    };
    return (
        <AppModal
            open={open}
            handleClose={handleClose}
            title="Custom date range"
            headerProps={{ enable: true }}
            hasCloseBtn
            sx={{ content: { px: 4, pt: 1 }, paper: { minWidth: 500, height: 550 } }}
            actions={
                <Stack direction="row" justifyContent="space-between" sx={{ ml: 2, mr: 4, mb: 3 }}>
                    <div>
                        <AppButton name="Clear selection" onClick={clearSelection} />
                    </div>
                    <div>
                        <AppButton name="Cancel" onClick={handleClose} />
                        <AppButton name="Save" onClick={handleSave} variant={'contained'} />
                    </div>
                </Stack>
            }
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" gap={6} sx={{ mb: 3, width: '100%' }}>
                    <DatePicker label="Start Date" value={from} disabled />
                    <DatePicker label="End Date" value={to} disabled />
                </Stack>
            </LocalizationProvider>
            <Stack direction="row" gap={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        value={from}
                        onChange={(newValue) => setFrom(newValue)}
                        disableFuture
                        sx={{ border: '0.6px solid #C7C6CA', borderRadius: '8px', height: 300, fontSize: 12 }}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        value={to}
                        onChange={(newValue) => setTo(newValue)}
                        disableFuture
                        sx={{ border: '0.6px solid #C7C6CA', borderRadius: '8px', height: 300, fontSize: 12 }}
                    />
                </LocalizationProvider>
            </Stack>
        </AppModal>
    );
};

export default DatePickerModal;
