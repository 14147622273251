import React, { useMemo } from 'react';
import { List, ListItem, Divider, Avatar, Stack, IconButton, Tooltip } from '@mui/material';
import { ArrowBackIosRounded, Topic } from '@mui/icons-material';
import { BsDownload } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import AppDrawer from 'components/new_components/AppDrawer';
import moment from 'moment';

//translations
import { useTranslation } from 'react-i18next';

const UploadHistoryDrawer = (props) => {
    const { open, handleClose, selectedDoc, all_merchant_docs_responses, openDeleteModal } = props;

    //translation
    const { t } = useTranslation('dataroom');

    const filteredMerchantResponses = useMemo(() => {
        return all_merchant_docs_responses?.filter((document) => document?.doc === selectedDoc?.id);
    }, [selectedDoc, all_merchant_docs_responses]);

    const getFileName = (url) => {
        const fileName = url?.split('/')?.[4];
        return fileName;
    };
    return (
        <AppDrawer
            open={open}
            icon={<ArrowBackIosRounded sx={{ color: '#46464A', fontSize: '18px' }} />}
            handleClose={handleClose}
            title={
                <div>
                    <p className="text-[16px] font-bold text-[#191B23]">{t('uploadHistory')}</p>
                    <p className="text-[11px] text-[#5E5E62] font-semibold">{selectedDoc?.name}</p>
                </div>
            }
            noClose
        >
            <List sx={{ px: 1.5, py: 0, mt: 3 }}>
                {filteredMerchantResponses?.map((file) => {
                    return (
                        <React.Fragment key={file?.id}>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    py: 2,
                                }}
                            >
                                <Stack flexDirection="row" alignItems="flex-start" gap={2}>
                                    <Avatar sx={{ bgcolor: '#F8F8F8' }} variant="square">
                                        <Topic sx={{ color: '#395BA9' }} />
                                    </Avatar>
                                    <div>
                                        <p className="text-[12px] font-medium text-[#395BA9]">
                                            {getFileName(file?.response_file)}
                                        </p>
                                        <p className="text-[10px] font-normal mt-1 text-[#5E5E62]">
                                            {t('uploaded')}{' '}
                                            {moment(file?.date_created).format('MMMM Do, YYYY (h:mm a)')}
                                        </p>
                                    </div>
                                </Stack>
                                <Stack flexDirection="row" alignItems="center">
                                    <Tooltip title={t('deleteDocument')} placement="top">
                                        <IconButton aria-label="delete" onClick={() => openDeleteModal(file?.id)}>
                                            <AiOutlineDelete color="#77777A" size="18px" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('downloadDocument')} placement="top">
                                        <IconButton
                                            aria-label="download"
                                            component="a"
                                            href={file?.response_file}
                                            download
                                        >
                                            <BsDownload color="#77777A" size="18px" />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </ListItem>
                            <Divider sx={{ color: '#F0F0FA' }} />
                        </React.Fragment>
                    );
                })}
            </List>
        </AppDrawer>
    );
};

export default UploadHistoryDrawer;
