import { Grid } from '@mui/material';
import EmptyState from 'components/new_components/EmptyState';
import LoadingState from 'components/new_components/LoadingState';
import React from 'react';
import { useSelector } from 'react-redux';
import CategoryCard from '../../common/CategoryCard';

//translations
import { useTranslation } from 'react-i18next';

const Isogroups = (props) => {
    const { resource, loading, onViewChange } = props;

    //translation
    const { t } = useTranslation('compliance');

    const all_techsecgroups = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_groups);
    const all_techsecgroup_metrics = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_metrics);

    console.log({ all_techsecgroups });

    return !loading ? (
        <Grid container spacing={2.5} sx={{ p: 4, pt: 6 }}>
            {all_techsecgroups && all_techsecgroups?.length ? (
                all_techsecgroups
                    ?.sort((a, b) => a?.id - b?.id)
                    ?.map((techsecgroup) => {
                        {
                            /* get and descructure the categories metrics */
                        }
                        // const { total_form, total_doc, user_form, user_doc } =
                        //   all_isogroup_metrics?.[isogroup?.title];

                        {
                            /* format metric and label based on resource type */
                        }
                        const metric =
                            resource === 'doc'
                                ? {
                                      totalValue: all_techsecgroup_metrics?.[techsecgroup?.title]?.total_doc,
                                      totalAttendedTo: all_techsecgroup_metrics?.[techsecgroup?.title]?.user_doc,
                                  }
                                : {
                                      totalValue: all_techsecgroup_metrics?.[techsecgroup?.title]?.total_form,
                                      totalAttendedTo: all_techsecgroup_metrics?.[techsecgroup?.title]?.user_form,
                                  };
                        const resourceLabel = resource === 'doc' ? t('fileUploaded') : t('questionAnswered');
                        return (
                            <CategoryCard
                                key={techsecgroup?.id}
                                id={techsecgroup?.id}
                                title={techsecgroup?.title}
                                metric={metric}
                                onViewChange={onViewChange}
                                resourceLabel={resourceLabel}
                            />
                        );
                    })
            ) : (
                <EmptyState description={t('noISO')} />
            )}
        </Grid>
    ) : (
        <LoadingState />
    );
};

export default Isogroups;
