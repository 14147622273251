import {
    ALL_VENDORS,
    ONE_VENDOR,
    QUESTIONNAIRE_FORMS,
    ALL_RESPONSES,
    VENDOR_UPLOADED_QUESTIONNAIRE,
    VENDOR_RESPONSES,
    ONE_VENDOR_UPLOADED_QUESTIONNAIRE,
} from '../constants';
import axiosInstance from '../../utils/https';

// merchant
export const GetAllVendors = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/vendor/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_VENDORS, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddVendor = (credentials) => async (dispatch, getState) => {
    const merchant = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().post(`/compliance/vendor/`, { ...credentials, merchant });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVendors());
        return {
            success: true,
            message: 'You have successfully invited this vendor! They will get a mail in few minutes.',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const EditVendor = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/vendor/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllVendors());
        return {
            success: true,
            message: 'Vendor updated successfully.',
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};
export const DeleteVendor = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/vendor/${id}/`);
        console.log(res);
        dispatch(GetAllVendors());
        return {
            success: true,
            message: 'Vendor deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// reponses
export const GetAllResponses = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.company_id;
    try {
        const res = await axiosInstance().get(`/compliance/user_vendor_forms/?merchant=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_RESPONSES, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
// vendors view
export const GetOneVendor = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/vendor/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ONE_VENDOR, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UpdateVendorDetails = (body, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/vendor/${id}/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetOneVendor(id));
        dispatch(GetAllResponses());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetVendorResponses = (vendor_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/user_vendor_forms/?vendor=${vendor_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: VENDOR_RESPONSES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateVendorResponse = (body) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/user_vendor_forms/`, body);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetVendorResponses(body?.vendor));
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditVendorResponse = (body, response_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/user_vendor_forms/${response_id}/`, {
            answer: body.answer,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetVendorResponses(body.vendor));
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetQuestionnaireForms = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/vendor_forms/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: QUESTIONNAIRE_FORMS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const CreateQuestonnaireForm = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/vendor_forms/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetQuestionnaireForms());
        return {
            success: true,
            message: res.message,
            form_id: res?.data?.id,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const EditQuestonnaireForm = (credentials, id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/vendor_forms/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetQuestionnaireForms());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteQuestonnaireForm = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance.delete(`/compliance/vendor_forms/${id}/`);
        console.log(res);
        dispatch(GetQuestionnaireForms());
        return {
            success: true,
            message: 'You have deleted this section successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateFormField = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/compliance/vendor_form_fields/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetQuestionnaireForms());
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const EditFormField = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/vendor_form_fields/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetQuestionnaireForms());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const DeleteFormField = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/vendor_form_fields/${id}/`);
        console.log(res);
        dispatch(GetQuestionnaireForms());
        return {
            success: true,
            message: 'Field deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// file upload
export const UploadFile = (formData) => async () => {
    try {
        const res = await axiosInstance().post(`/compliance/vendor_file_upload/`, formData);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res.message,
            file_url: res.data,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

// AI Vendor Responder
export const GetAllUploadedVendorQuestionnaire = () => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/questionnaire_predictions/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: VENDOR_UPLOADED_QUESTIONNAIRE, payload: res?.data });
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetOneUploadedVendorQuestionnareDetails = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/questionnaire_predictions/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ONE_VENDOR_UPLOADED_QUESTIONNAIRE, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const UploadVendorQuestionnaire = (formData) => async (dispatch, getState) => {
    const merchant_id = getState().authReducers?.company_id;
    // const user_id = getState()?.authReducers?.user_id;
    formData.append('merchant', merchant_id);
    try {
        const res = await axiosInstance().post(`/compliance/questionnaire_upload/`, formData);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        setTimeout(() => dispatch(GetAllUploadedVendorQuestionnaire()), 7000);
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err?.message };
    }
};

export const DeleteUploadedVendorQuestionnaire = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/compliance/questionnaire_predictions/${id}/`);
        console.log(res);
        dispatch(GetAllUploadedVendorQuestionnaire());
        return {
            success: true,
            message: 'Vendor Questionnaire deleted successfully.',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const SaveApprovedQuestionnaireAnswers = (id, credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/compliance/questionnaire_predictions/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllUploadedVendorQuestionnaire());
        dispatch(GetOneUploadedVendorQuestionnareDetails(id));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
