import { DeleteOutlined, EditOutlined, FolderViewOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RequestActions = (props) => {
    const history = useHistory();
    // props
    const { record, deleteModal, openEditModal, openReviewersModal } = props;

    const viewFile = (url) => {
        window.open(url, '_blank');
    };

    const filesArray = () => {
        const uploadedDocs = Array.isArray(record?.uploaded_docs) ? record?.uploaded_docs : [];
        return uploadedDocs
            ?.sort((a, b) => a.id - b.id)
            ?.map((docs, index) => ({
                key: `5-${index + 1}`,
                label: docs?.doc_name,
                onClick: () => viewFile(docs?.doc),
            }));
    };

    // constant
    const items1 = [
        {
            label: 'Edit ROC',
            key: '2',
            icon: <EditOutlined />,
            onClick: () => openEditModal('Edit', record),
        },
        {
            label: 'Delete',
            key: '4',
            icon: <DeleteOutlined />,
            onClick: () => deleteModal(record?.id),
        },
        {
            type: 'divider',
        },
        {
            label: 'View Files',
            key: '5',
            icon: <FolderViewOutlined />,
            children: filesArray(),
        },
    ];

    const items2 = [
        {
            label: 'Add Reviewers',
            key: '2',
            icon: <EditOutlined />,
            disabled: record?.auditor || record?.status === 'completed' || record?.status === 'draft',
            onClick: () => openReviewersModal(record),
        },
        {
            label: 'Review Report',
            key: '2',
            icon: <EditOutlined />,
            disabled: record?.status === 'inreview' || record?.status === 'draft' || !record?.merchant_signature,
            onClick: () => history.push(`/admin/audit-requests/${record?.id}`),
        },
    ];

    const items3 = [
        {
            label: 'View Files',
            key: '5',
            icon: <FolderViewOutlined />,
            children: filesArray(),
        },
    ];

    const items =
        record?.compliance === 'pcidss4.0'
            ? items2
            : record?.complaince === 'pcidss4.0' && record?.status === 'completed'
            ? items3
            : items1;
    return (
        <Dropdown trigger={['click']} menu={{ items }}>
            <MoreOutlined />
        </Dropdown>
    );
};

export default RequestActions;
