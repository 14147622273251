import React, { useState } from 'react';
import { Upload } from '@mui/icons-material';
//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppSubmitButton, AppFormFileInput, AppForm } from 'components/new_components/forms';
//validate
import { connect } from 'react-redux';
import { UploadVendorQuestionnaire } from 'store/actions/vendorActions';
import { toast } from 'react-toastify';
import { accept, validation } from 'validate';

//translations
import { useTranslation } from 'react-i18next';

const UploadQuestionnaireModal = ({ open, handleClose, UploadVendorQuestionnaire }) => {
    const [loadingState, setloadingState] = useState(false);

    //translation
    const { t } = useTranslation('vendorManagement');

    //accept props
    const { excel } = accept;

    const onClose = () => {
        resetFile();
        handleClose();
    };
    const resetFile = () => {
        const file = document.querySelector('.file');
        file.value = '';
    };
    //options

    const uploadModalValidation = (values) => {
        const errors = {};
        const reportValidate = validation(values.file_upload, 'Questionnaire', true);
        if (!reportValidate.isValid) {
            errors.report = reportValidate.errorMessage;
        }

        return errors;
    };
    const handleSubmit = async (values) => {
        const formdata = new FormData();
        formdata.append('file_upload', values?.file_upload);
        setloadingState(true);
        const res = await UploadVendorQuestionnaire(formdata);
        setloadingState(false);
        if (res?.success) {
            toast.success(res?.message);
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppForm initialValues={{ file_upload: '' }} onSubmit={handleSubmit} validate={uploadModalValidation}>
            <AppCenteredModal
                open={open}
                handleClose={onClose}
                title={t('uploadQuestionnaireModal.upload')}
                width={450}
                actions={
                    <>
                        <AppSubmitButton
                            text={t('uploadQuestionnaireModal.upload')}
                            startIcon={<Upload />}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '100%' }}
                        />
                    </>
                }
            >
                <AppFormFileInput
                    type="file"
                    label={t('uploadQuestionnaireModal.questionnaire')}
                    name="file_upload"
                    className="file"
                    inputProps={{
                        accept: `${excel}`,
                    }}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { UploadVendorQuestionnaire })(UploadQuestionnaireModal);
