import React from 'react';
import { useTheme } from '@mui/material/styles';
import { TableHead, TableRow } from '@mui/material';

// core components
import AppTableCell from './AppTableCell';

const TableHeader = (props) => {
    const theme = useTheme();
    const { columns, noBackground } = props;
    return (
        <TableHead
            sx={{
                backgroundColor: noBackground ? '' : '#F8F8F8',
                // borderCollapse: 'collapse',
                borderBottom: noBackground ? '1px solid #B8BCCC' : '',
            }}
        >
            <TableRow>
                {columns
                    ? columns.map((column, idx) => {
                          return (
                              <AppTableCell
                                  align={column.align}
                                  sx={{
                                      fontWeight: noBackground ? 400 : 500,
                                      color: noBackground ? theme.palette.gray[30] : theme.palette.neutral[600],
                                  }}
                                  key={column.title + 'Head' + idx}
                              >
                                  {column.title}
                              </AppTableCell>
                          );
                      })
                    : null}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
