import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import AppInactivityModal from 'components/new_components/AppInactivityModal';
import { LogoutUser } from 'store/actions/authActions';
import { connect } from 'react-redux';
import { TIME_OUT } from 'config';
import { useHistory } from 'react-router-dom';

let countdownInterval;
let timeout;

const SessionTimeout = ({ isAuthenticated, LogoutUser }) => {
    const [timeModal, setTimeModal] = useState(false);
    const [loading, setloading] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);

    const history = useHistory();
    const logOutSession = async () => {
        await LogoutUser();
    };

    const idleTimer = useRef(null);
    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };
    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };
    const handleCloseModal = () => {
        setTimeModal(false);
        clearSessionInterval();
        clearSessionTimeout();
    };
    const handleLogout = async () => {
        try {
            setTimeModal(false);
            clearSessionInterval();
            clearSessionTimeout();
            setloading(true);
            await logOutSession();
            setloading(false);
            history.push('/auth/login');
        } catch (err) {
            console.error(err);
        }
    };
    const handleContinue = async () => {
        clearSessionInterval();
        clearSessionTimeout();
        handleCloseModal();
    };
    const onActive = () => {
        if (!timeModal) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };
    const onIdle = () => {
        if (isAuthenticated && !timeModal) {
            const delay = 1000;
            timeout = setTimeout(() => {
                let countDown = 35;
                setTimeModal(true);
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout();
                    }
                }, 1000);
            }, delay);
        }
    };
    return (
        <>
            <IdleTimer ref={idleTimer} onActive={onActive} onIdle={onIdle} debounce={250} timeout={TIME_OUT} />
            <AppInactivityModal
                countdown={timeoutCountdown}
                onContinue={handleContinue}
                onLogout={handleLogout}
                open={timeModal}
                handleClose={handleCloseModal}
                loading={loading}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return state;
};
export default connect(mapStateToProps, { LogoutUser })(SessionTimeout);
