import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
//redux
import { connect } from 'react-redux';
import { UpdateMerchantRiskAssessments } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const TableAction = (props) => {
    const theme = useTheme();
    const { row, openDeleteModal, openViewModal, handleType } = props;

    // state
    const [anchor, setAnchor] = useState(null);

    //translation
    const { t } = useTranslation('riskAssessment');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const handleOpenDeleteModal = () => {
        closeMenu();
        openDeleteModal(row?.id);
    };

    const handleViewModal = () => {
        openViewModal(row);
        closeMenu();
    };

    const editPayload = {
        threat: row?.threat_detail?.id?.toString(),
        threat_description: row?.threat_description,
        risk_source: row?.risk_source,
        asset_type: row?.asset_type,
        asset: row?.asset,
        matrix: row?.matrix,
        risk_owner: row?.risk_owner,
        date_detected: row?.date_detected,
        inherent_likelihood: row?.inherent_likelihood?.toString(),
        inherent_impact: row?.inherent_impact?.toString(),
        existing_controls: row?.existing_controls,
        residual_likelihood: row?.residual_likelihood?.toString(),
        residual_impact: row?.residual_impact?.toString(),
        risk_status: row?.risk_status,
        comments: row?.comments,
        id: row?.id,
    };

    return (
        <>
            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                {' '}
                <MenuItem
                    onClick={handleViewModal}
                    sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: theme.palette.gray[20],
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {t('riskRegisterTab.viewDetails')}
                </MenuItem>
                <MenuItem
                    sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: theme.palette.gray[20],
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    onClick={() => handleType('intelligence', editPayload)}
                >
                    {t('riskRegisterTab.updateDetails')}
                </MenuItem>
                <MenuItem
                    onClick={handleOpenDeleteModal}
                    sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#46464A',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {t('riskRegisterTab.deleteAssessment')}
                </MenuItem>
            </Menu>
        </>
    );
};

const mapStateToProps = () => {
    return {};
};
export default connect(mapStateToProps, {
    UpdateMerchantRiskAssessments,
})(TableAction);
