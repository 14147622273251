import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//redux
import { connect } from 'react-redux';
import { GetAllTasksByStandard } from 'store/actions/taskActions';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import { toast } from 'react-toastify';
import { HiCheckCircle, HiOutlineAnnotation } from 'react-icons/hi';
import LogStatusTag from 'views/merchant/Log/taskLog/components/LogStatusTag';
import { AiFillExclamationCircle } from 'react-icons/ai';
import TaskName from 'views/merchant/Log/taskLog/components/TaskName';
import { formatDateObjectHandler } from 'utils';
import { MoreVert } from '@mui/icons-material';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import { UpdateTask } from 'store/actions/taskActions';

const AllTasksModal = (props) => {
    //props
    const { open, handleClose, GetAllTasksByStandard, standard, UpdateTask, all_assigned_task, user_id } = props;

    const [anchor, setAnchor] = useState(null);
    const [info, setInfo] = useState({});
    const [activeTask, setActiveTask] = useState(null);

    const theme = useTheme();
    //functions
    const openMenu = (e, data) => {
        setAnchor(e.target);
        setInfo(data);
    };
    const closeMenu = () => {
        setAnchor(null);
        setInfo({});
    };
    // async functions
    const getAllTasksByStandard = async () => {
        const res = await GetAllTasksByStandard(standard, user_id);
        if (!res.message) {
            toast.error(res.message);
        }
    };

    const updateTaskStatus = async () => {
        const payload = {
            assigned_by: info?.assigned_by,
            assigned_to: info?.assigned_to,
            mark_as_completed: !info?.mark_as_completed && info?.completion_status === 'not_completed' ? true : false,
            completion_status:
                !info?.mark_as_completed && info?.completion_status === 'not_completed'
                    ? 'waiting_approval'
                    : 'not_completed',
            user_completion_date:
                !info?.mark_as_completed && info?.completion_status === 'not_completed' ? new Date() : '',
        };
        const res = await UpdateTask(payload, info?.id, standard, user_id);
        if (res?.success) {
            toast.success('Task status has been updated');
            closeMenu();
        } else {
            toast.error(res?.message);
        }
    };
    // useEffect
    useEffect(() => {
        if (open) getAllTasksByStandard();
    }, [open]);

    return (
        <AppViewModal open={open} handleClose={handleClose} width={600}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2 }}>
                    <ArrowBackIosIcon
                        onClick={handleClose}
                        sx={{ width: 20, height: 20, color: theme.palette.gray[30], cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '13px', fontWeight: 600, color: '#46464A', ml: 2 }}>
                        All task assigned to you
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                <Box sx={{ mx: 2, my: 3, border: `1px solid ${theme.palette.neutral[95]}`, borderRadius: '5px', p: 2 }}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: 12,
                            color: '#303034',
                            pb: 1,
                            borderBottom: `1px solid ${theme.palette.neutral[95]}`,
                        }}
                    >
                        Tasks
                    </Typography>
                    {all_assigned_task?.length ? (
                        all_assigned_task
                            ?.sort((a, b) => b?.id - a?.id)
                            ?.map((task, index) => (
                                <div
                                    direction="row"
                                    gap="9px"
                                    alignItems="flex-start"
                                    key={index}
                                    className="flex flex-row items-start gap-[9px] mt-6 border border-0 border-b border-[#F0F0FA] pb-2 relative"
                                    onMouseEnter={() => setActiveTask(index)}
                                    onMouseLeave={() => setActiveTask(null)}
                                >
                                    {task?.completion_status === 'completed' ? (
                                        <HiCheckCircle color="#37A372" size={13} />
                                    ) : (
                                        <AiFillExclamationCircle
                                            size={13}
                                            color={task?.completion_status === 'not_completed' ? '#EB4141' : '#002C72'}
                                        />
                                    )}
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumn: 'repeat(1, minmax(0, 1fr));',
                                            gap: 1,
                                            width: '100%',
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: 12, fontWeight: 500, color: theme.palette.neutral[50] }}
                                        >
                                            <span className="text-sm font-semibold text-[#303034]">
                                                {task?.assigned_by_name}
                                            </span>{' '}
                                            assigned a task to you
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ color: theme.palette.neutral[50], fontSize: 14 }}
                                            gap={0.5}
                                        >
                                            <HiOutlineAnnotation />{' '}
                                            <Typography
                                                sx={{ color: theme.palette.neutral[50], fontSize: 10, fontWeight: 400 }}
                                            >
                                                {task?.comments}
                                            </Typography>
                                        </Stack>
                                        <Typography
                                            sx={{
                                                borderLeft: `2px solid ${theme.palette.gray[40]}`,
                                                p: 0.5,
                                                fontSize: 12,
                                                fontWeight: 500,
                                                color: theme.palette.gray[30],
                                                background: '#F8F8F8',
                                            }}
                                        >
                                            <TaskName data={task} />
                                        </Typography>

                                        <Stack direction="row" alignItems="end" gap={1}>
                                            <Typography sx={{ fontSize: 10, color: theme.palette.neutral.new60 }}>
                                                {formatDateObjectHandler(
                                                    task?.completion_status === 'completed'
                                                        ? task?.date_updated
                                                        : task?.completion_status === 'not_completed'
                                                        ? task?.date_created
                                                        : task?.user_completion_date,
                                                    'MMMM DD, YYYY:: h:mm a '
                                                )}
                                            </Typography>{' '}
                                            <LogStatusTag
                                                text={
                                                    task?.completion_status === 'waiting_approval'
                                                        ? 'Awaiting approval'
                                                        : task?.completion_status?.replaceAll('_', ' ')
                                                }
                                                type={
                                                    task?.completion_status === 'not_completed'
                                                        ? 'error'
                                                        : task?.completion_status === 'completed'
                                                        ? 'success'
                                                        : 'secondary'
                                                }
                                                sx={{ fontSize: 10, padding: '2px 3px', borderRadius: '2px' }}
                                            />
                                        </Stack>
                                    </Box>
                                    {index === activeTask ? (
                                        <Box sx={{ position: 'absolute', right: 0 }}>
                                            <IconButton
                                                sx={{ color: theme.palette.gray[600], minWidth: 'unset', p: 0 }}
                                                onClick={(e) => openMenu(e, task)}
                                            >
                                                <MoreVert />
                                            </IconButton>
                                            <AppTableActionMenu
                                                anchor={anchor}
                                                closeMenu={closeMenu}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <MenuItem
                                                    sx={{ fontSize: 12 }}
                                                    disabled={task?.completion_status === 'completed'}
                                                    onClick={updateTaskStatus}
                                                >
                                                    {!task?.mark_as_completed &&
                                                    task?.completion_status === 'not_completed'
                                                        ? 'Mark as completed'
                                                        : 'Mark as not completed'}
                                                </MenuItem>
                                            </AppTableActionMenu>
                                        </Box>
                                    ) : null}
                                </div>
                            ))
                    ) : (
                        <Stack alignItems="center" justifyContent="center" flex={1}>
                            <img src="/img/empty-task.svg" className="w-[200px] h-[200px]" alt="empty task" />
                            <p className="mt-8 text-xl font-medium text-[#8F9099]">No task at the moment</p>
                        </Stack>
                    )}
                </Box>
            </Box>
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
    };
};
export default connect(mapStateToProps, { GetAllTasksByStandard, UpdateTask })(AllTasksModal);
