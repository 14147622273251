import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// core components
import CircularLoader from 'components/Loaders/CircularLoader';
import VendorSectionLayout from './VendorSectionLayout';
import Questionnaire from './Questionnaire';
import { AppForm } from 'components/new_components/forms';

// redux
import { connect } from 'react-redux';
import {
    GetQuestionnaireForms,
    GetVendorResponses,
    CreateVendorResponse,
    EditVendorResponse,
    UpdateVendorDetails,
    UploadFile,
} from 'store/actions/vendorActions';

// validation
import { validation } from 'validate';

//translation
import { Box } from '@mui/material';
import { Language } from '@mui/icons-material';
import LanguageMenu from 'components/new_components/merchant-layout/header/menus/LanguageMenu';

const QuestionnaireFormsView = (props) => {
    // props
    const {
        vendor,
        readonly,
        merchant,
        page,
        setPage,
        GetQuestionnaireForms,
        GetVendorResponses,
        CreateVendorResponse,
        EditVendorResponse,
        UpdateVendorDetails,
        questionnaire_forms,
        vendor_responses,
        UploadFile,
    } = props;

    // state
    const [loading, setLoading] = useState({
        content: false,
        form: false,
    });
    const [uploading, setUploading] = useState({});
    const [sortedQuestionnaireForms, setSortedQuestionnaireForms] = useState([]);
    const [currentQuestionnaireSection, setCurrentQuestionnaireSection] = useState(null);
    const [currentResponse, setCurrentResponse] = useState(null);
    const [files, setFiles] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    //translation
    const language = localStorage.getItem('i18nextLng');

    const open = Boolean(anchorEl);
    const openLanguageMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeLanguageMenu = () => {
        setAnchorEl(null);
    };

    // functions
    // async functions
    const getQuestionnaire = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetQuestionnaireForms();
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getVendorResponse = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetVendorResponses(vendor);
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const handleSubmit = async (values) => {
        // creating a new instance of the values
        const answer = { ...JSON.parse(JSON.stringify(values)), ...files };
        // turning arrays to comma separated values
        Object.keys(answer).forEach((key) => {
            const value = answer[key];
            answer[key] = value && value.constructor === Array ? value.join(',') : value;
        });
        // create payload
        const payload = { answer, vendor };
        const isLastPage = page >= sortedQuestionnaireForms.length;

        if (currentResponse) {
            await handleEditAnswers(payload);
        } else {
            await handleSubmitAnswers(payload);
            if (isLastPage) {
                // send request to the patch the vendor details to response request to the merchant
                sendResponded();
            }
        }
        if (!isLastPage) {
            goToNextSection();
        }
    };
    const handleSubmitAnswers = async (payload) => {
        payload.form_id = currentQuestionnaireSection?.id;
        payload.merchant = merchant;

        setLoading((loading) => ({ ...loading, form: true }));
        const res = await CreateVendorResponse(payload);
        setLoading((loading) => ({ ...loading, form: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const handleEditAnswers = async (payload) => {
        setLoading((loading) => ({ ...loading, form: true }));
        const res = await EditVendorResponse(payload, currentResponse?.id);
        setLoading((loading) => ({ ...loading, form: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const sendResponded = async () => {
        setLoading((loading) => ({ ...loading, form: true }));
        const res = await UpdateVendorDetails({ has_responded: true }, vendor);
        setLoading((loading) => ({ ...loading, form: false }));
        if (res?.success) {
            return toast.success('Your response has been sent to the merchant.');
        } else {
            toast.error(res?.message);
        }
    };
    const handleFileUpload = async (file, name) => {
        const formData = new FormData();
        formData.append('file_upload', file);
        setUploading((uploading) => ({ ...uploading, [name]: true }));
        const res = await UploadFile(formData);
        setUploading((uploading) => ({ ...uploading, [name]: false }));
        if (res?.success) {
            setFiles((current) => ({ ...current, [name]: res?.file_url }));
            toast.success('File upload successfully!');
        } else {
            toast.error(res?.message);
        }
    };

    // navigation functions
    const goToPage = (page) => setPage(page);
    const goToNextSection = () => setPage((page) => page + 1);
    const goToPrevSection = () => setPage((page) => page - 1);

    const getInitialValues = (questionnaire, response) => {
        const initialValues = {};
        questionnaire?.form_fields?.forEach((field) => {
            const fieldValue = response?.answer?.[field?.id];
            const arrayFields = ['checkboxes'];
            initialValues[field?.id] = (arrayFields.includes(field?.type) ? fieldValue?.split(',') : fieldValue) || '';
        });
        return initialValues;
    };
    const validate = (values, questionnaire) => {
        const errors = {};
        questionnaire?.form_fields.forEach((field) => {
            if (field.type === 'file') return;
            const fieldValidate = validation(values[field?.id], 'Field', field?.required);
            if (!fieldValidate.isValid) {
                errors[field?.id] = fieldValidate.errorMessage;
            }
        });
        return errors;
    };

    // useEffect
    useEffect(() => {
        getQuestionnaire();
        getVendorResponse();
    }, [vendor, merchant, readonly]);
    useEffect(() => {
        if (questionnaire_forms) {
            const sortedQuestionnaire = questionnaire_forms?.sort((a, b) => a.id - b.id);
            setSortedQuestionnaireForms(sortedQuestionnaire);
        }
    }, [questionnaire_forms]);
    useEffect(() => {
        // find the section and response corresponding to that particular page
        const currentQuestionnaireSection = sortedQuestionnaireForms[page - 1];
        const currentResponse = vendor_responses?.find(
            (response) => response?.form_id === currentQuestionnaireSection?.id
        );

        setCurrentQuestionnaireSection(currentQuestionnaireSection);
        setCurrentResponse(currentResponse);
    }, [page, sortedQuestionnaireForms]);

    return (
        <AppForm
            initialValues={getInitialValues(currentQuestionnaireSection, currentResponse)}
            onSubmit={handleSubmit}
            validate={(value) => validate(value, currentQuestionnaireSection)}
        >
            <Box sx={{ display: 'flex', justifyContent: 'right', mx: '12.5%', mt: '-40px' }}>
                <Box className="text-[14px] font-medium" onClick={openLanguageMenu}>
                    <Language fontSize="small" className="mr-2" />
                    {window.localStorage.i18nextLng?.includes('en')
                        ? 'English'
                        : window.localStorage.i18nextLng?.includes('fr')
                        ? 'French'
                        : window.localStorage.i18nextLng}
                </Box>
                <LanguageMenu
                    open={open}
                    closeLanguageMenu={closeLanguageMenu}
                    anchorEl={anchorEl}
                    language={
                        window.localStorage.i18nextLng?.includes('en')
                            ? 'English'
                            : window.localStorage.i18nextLng?.includes('fr')
                            ? 'French'
                            : window.localStorage.i18nextLng
                    }
                    menuListStyles={{
                        parent: {
                            right: '15%',
                        },
                    }}
                />
            </Box>
            <VendorSectionLayout
                questionnaireFormsCount={sortedQuestionnaireForms?.length}
                categories={sortedQuestionnaireForms?.map((question) =>
                    language?.includes('fr')
                        ? question?.title_fr
                        : language?.includes('en')
                        ? question?.title_en
                        : question?.title
                )}
                setPage={setPage}
                page={page}
                showFooter={true}
                readonly={readonly}
                onNextClick={goToNextSection}
                onPrevClick={goToPrevSection}
                onPageChange={goToPage}
                loading={loading}
                responseCount={vendor_responses?.length}
            >
                {!loading.content ? (
                    <Questionnaire
                        questionnaire={currentQuestionnaireSection}
                        vendorResponse={currentResponse}
                        readonly={readonly}
                        vendor={vendor}
                        merchant={merchant}
                        CreateVendorResponse={CreateVendorResponse}
                        EditVendorResponse={EditVendorResponse}
                        handleFileUpload={handleFileUpload}
                        uploading={uploading}
                        files={files}
                    />
                ) : (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularLoader />
                    </div>
                )}
            </VendorSectionLayout>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        questionnaire_forms: state?.vendorReducers?.questionnaire_forms,
        vendor_responses: state?.vendorReducers?.vendor_responses,
    };
};
export default connect(mapStateToProps, {
    GetQuestionnaireForms,
    GetVendorResponses,
    CreateVendorResponse,
    EditVendorResponse,
    UpdateVendorDetails,
    UploadFile,
})(QuestionnaireFormsView);
