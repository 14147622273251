import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import Logo from 'assets/img/brand/logo.svg';
import PCIDSSReport from './PCIDSSReport';
import PCIDSS4Point0Report from './PCIDSS4Point0Report';
import NDPRReport from './NDPRReport';
import ISOReport from './ISOReport';
import ISOTWOReport from './ISOTWOReport';
import CONTINUITYReport from './CONTINUITYReport';
import SOCReport from './SOCReport';
import FourthPage from './fourthPage';
import FifthPage from './fifthPage';
import SixthPage from './sixthPage';
import KDPAReport from './KDPAReport';
import GDPAReport from './GDPAReport';
import TECHSECReport from './TECHSECReport';

const CisoReport = ({ standards }) => {
    const complianceList = [
        {
            id: 1,
            name: 'pcidss',
            component: <PCIDSSReport />,
        },
        { id: 2, name: 'ndpr', component: <NDPRReport /> },
        {
            id: 3,
            name: 'iso27001',
            component: <ISOReport />,
        },
        {
            id: 9,
            name: 'iso27001-2022',
            component: <ISOTWOReport />,
        },
        {
            id: 4,
            name: 'soc2',
            component: <SOCReport />,
        },
        {
            id: 5,
            name: 'iso22301',
            component: <CONTINUITYReport />,
        },
        {
            id: 6,
            name: 'pcidss4.0',
            component: <PCIDSS4Point0Report />,
        },
        { id: 7, name: 'kdpa', component: <KDPAReport /> },
        { id: 8, name: 'gdpa', component: <GDPAReport /> },
        {
            id: 9,
            name: 'iso27017+iso27032',
            component: <TECHSECReport />,
        },
    ];

    const accessList = complianceList?.filter((res) => {
        return standards?.includes(res?.name);
    });

    return (
        <>
            <Stack sx={{ display: 'flex' }}>
                <Box sx={{ mb: '24px' }}>
                    <Box>
                        <img src={Logo} alt="Smartcomply logo" className="w-[149px]" />
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '-40px' }}>
                            <Typography sx={{ fontSize: '24px', fontWeight: 700, color: '#0E2C66' }}>
                                CISO REPORT
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {accessList?.map((access) => {
                    return <React.Fragment key={access?.id}>{access?.component}</React.Fragment>;
                })}
                <FourthPage />
                <FifthPage />
                <SixthPage />
            </Stack>
        </>
    );
};

export default CisoReport;
