// components
import Dashboard from '../views/admin/Dashboard';
import Auditors from '../views/admin/auditors';
import Users from '../views/admin/merchants';
import PCIDSS from '../views/admin/compliance/Pcidss/Pcidss';
import Ndpr from '../views/admin/compliance/Ndpr';
import Iso27001 from '../views/admin/compliance/Iso27001';
import Iso27001_2022 from '../views/admin/compliance/Iso27001-2022';
import ISO27017_27032 from '../views/admin/compliance/ISO27017+27032';
import Iso22301 from '../views/admin/compliance/Iso22301';
import PCIDSS4POINT0 from '../views/admin/compliance/Pcidss4.0/Pcidss4.0';
import SOC2 from '../views/admin/compliance/soc2';
import Blockchain from 'views/admin/compliance/blockchain';
import CiiDirective from 'views/admin/compliance/CiiDirective';
import Onboarding from 'views/admin/Onboarding';
import Threat from 'views/admin/Threat/Threat';
import Support from 'views/admin/support/Support';
import Releases from 'views/admin/releases/Releases';
import QuestionaireDashboard from 'views/admin/questionaires/index';
import Integration from 'views/admin/integration';
import Plans from 'views/admin/Plans';
import Features from 'views/admin/features';
import StandardsAndPayments from 'views/admin/standards_payments';
import PlanRequests from 'views/admin/plan_requests';
import FAQs from 'views/admin/resources/FAQs';
import Glossary from 'views/admin/resources/Glossary';
import PushNotifications from 'views/admin/push_notifications';
import Pentests from 'views/admin/pentests';
import CodeReview from 'views/admin/CodeReview/components/index';
import Categories from 'views/admin/audit/cateories';
import AccessorCategories from 'views/admin/audit/accessor_category';
import Requests from 'views/admin/audit/requests/index';
import AccessorRequests from 'views/admin/audit/Accessors/index';
import QAReviewer from 'views/admin/qa_reviewer';
import Training from 'views/admin/training';
import DataRoom from 'views/admin/data_room';
import Article from 'views/admin/Article';

//icons
import { CreditScore, Security } from '@mui/icons-material';
import { AiOutlineDashboard, AiOutlineFileAdd, AiOutlineForm, AiOutlineNotification } from 'react-icons/ai';
import { BiUser, BiSupport, BiRocket } from 'react-icons/bi';
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { BsChatLeftQuote } from 'react-icons/bs';
import { FaUsers, FaQuestion, FaRegListAlt } from 'react-icons/fa';
import { GrThreats, GrIntegration, GrResources } from 'react-icons/gr';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { MdOutlineReviews, MdPayment, MdVerifiedUser, MdModelTraining, MdOutlineAssessment } from 'react-icons/md';
import { VscSymbolProperty } from 'react-icons/vsc';
import Testimonials from 'views/admin/testimonials';
import SurveyQuestions from 'views/admin/survery_report/questions/Index';
import SurveyResponses from 'views/admin/survery_report/responses/Index';

import {
    BookOutlined,
    CodeOutlined,
    GroupOutlined,
    MailOutlined,
    ScanOutlined,
    HighlightOutlined,
} from '@ant-design/icons';
import Kdpa from 'views/admin/compliance/kdpa';
import Gdpa from 'views/admin/compliance/gdpa';

const getRoutes = (role) => {
    const routes = [
        {
            path: '',
            name: 'Dashboard',
            icon: AiOutlineDashboard,
            Component: Dashboard,
            layout: '/admin',
            key: '1',
            hasAccess: ['admin', 'aud_admin'],
        },
        {
            name: 'Users',
            icon: FaUsers,
            layout: '/admin',
            key: '2',
            hasAccess: ['admin'],
            subMenus: [
                {
                    path: 'auditors',
                    name: 'Auditors',
                    icon: BiUser,
                    Component: Auditors,
                    layout: '/admin',
                    key: '2',
                    hasAccess: ['admin'],
                },
                {
                    path: 'merchants',
                    name: 'Merchants',
                    icon: FaUsers,
                    Component: Users,
                    layout: '/admin',
                    key: '3',
                    hasAccess: ['admin'],
                },
                {
                    path: 'support',
                    name: 'Support',
                    icon: BiSupport,
                    Component: Support,
                    layout: '/admin',
                    key: '4',
                    hasAccess: ['admin'],
                },
            ],
        },
        {
            path: 'compliance',
            name: 'Compliance',
            icon: MdVerifiedUser,
            layout: '/admin',
            key: '13',
            hasAccess: ['admin'],
            subMenus: [
                {
                    path: 'pcidss',
                    name: 'PCIDSS',
                    icon: CreditScore,
                    Component: PCIDSS,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.1',
                },
                {
                    path: 'ndpr',
                    name: 'NDPR',
                    icon: Security,
                    Component: Ndpr,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.2',
                },
                {
                    path: 'iso27001',
                    name: 'ISO27001',
                    icon: MdVerifiedUser,
                    Component: Iso27001,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.3',
                },
                {
                    path: 'iso27001_2022',
                    name: 'ISO27001_2022',
                    icon: MdVerifiedUser,
                    Component: Iso27001_2022,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.10',
                },
                {
                    path: 'iso27017+27032',
                    name: 'ISO27017+27032',
                    icon: MdVerifiedUser,
                    Component: ISO27017_27032,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.11',
                },
                {
                    path: 'soc2',
                    name: 'SOC2',
                    icon: MdVerifiedUser,
                    Component: SOC2,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.4',
                },
                {
                    path: 'cii-directive',
                    name: 'Cii Directive',
                    icon: MdVerifiedUser,
                    Component: CiiDirective,
                    hasAccess: ['admin'],
                    layout: '/admin',
                    key: '13.5',
                },
                {
                    path: 'blockchain',
                    name: 'Blockchain',
                    icon: MdVerifiedUser,
                    Component: Blockchain,
                    hasAccess: ['admin'],
                    layout: '/admin',
                    key: '13.6',
                },
                {
                    path: 'iso22301',
                    name: 'ISO22301',
                    icon: MdVerifiedUser,
                    Component: Iso22301,
                    hasAccess: ['admin'],
                    layout: '/admin',
                    key: '13.7',
                },
                {
                    path: 'pcidss4.0',
                    name: 'PCIDSS4.0',
                    icon: CreditScore,
                    Component: PCIDSS4POINT0,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.8',
                },
                {
                    path: 'kdpa',
                    name: 'Kenya DPA',
                    icon: Security,
                    Component: Kdpa,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.12',
                },
                {
                    path: 'gdpa',
                    name: 'Ghana DPA',
                    icon: Security,
                    Component: Gdpa,
                    layout: '/admin',
                    hasAccess: ['admin'],
                    key: '13.9',
                },
            ],
        },
        {
            path: 'audit',
            name: 'Audit',
            icon: BookOutlined,
            layout: '/admin',
            key: '18',
            hasAccess: ['admin'],
            subMenus: [
                {
                    path: 'audit-categories',
                    name: 'Audit Categories',
                    icon: GroupOutlined,
                    Component: Categories,
                    layout: '/admin',
                    key: '18.1',
                    hasAccess: ['admin'],
                },
                {
                    path: 'accessor-categories',
                    name: 'Accessor Categories',
                    icon: GroupOutlined,
                    Component: AccessorCategories,
                    layout: '/admin',
                    key: '18.2',
                    hasAccess: ['admin'],
                },
                {
                    path: 'audit-requests',
                    name: 'Audit Requests',
                    icon: MailOutlined,
                    Component: Requests,
                    layout: '/admin',
                    key: '18.3',
                    hasAccess: ['admin'],
                },
                {
                    path: 'qa-reviewers',
                    name: 'QA Reviewers',
                    icon: MdOutlineReviews,
                    Component: QAReviewer,
                    layout: '/admin',
                    key: '19',
                    hasAccess: ['admin'],
                },
            ],
        },
        {
            path: 'subscriptions & plans',
            name: 'Subscription & Plans',
            icon: MdPayment,
            layout: '/admin',
            key: '5',
            hasAccess: ['admin'],
            subMenus: [
                {
                    path: 'plan-requests',
                    name: 'Plan Requests',
                    icon: MdPayment,
                    Component: PlanRequests,
                    layout: '/admin',
                    key: '5.5',
                    hasAccess: ['admin'],
                },
                {
                    path: 'plans',
                    name: 'All Plans',
                    icon: HiOutlineDocumentDuplicate,
                    Component: Plans,
                    layout: '/admin',
                    key: '5.2',
                    hasAccess: ['admin'],
                },
                {
                    path: 'features',
                    name: 'All Features',
                    icon: VscSymbolProperty,
                    Component: Features,
                    layout: '/admin',
                    key: '5.3',
                    hasAccess: ['admin'],
                },
                {
                    path: 'standard-and-payment-method',
                    name: 'Standards and Payment Methods',
                    icon: MdPayment,
                    Component: StandardsAndPayments,
                    layout: '/admin',
                    key: '5.4',
                    hasAccess: ['admin'],
                },
            ],
        },

        // {
        //     path: 'audit-preview/:id',
        //     name: 'Preview Audit',
        //     icon: MailOutlined,
        //     component: AuditPreviewIndex,
        //     layout: '/admin',
        //     key: '15',
        //     subRoute: true,
        // },
        {
            name: 'Assessment',
            icon: MdOutlineAssessment,
            layout: '/admin',
            key: '6',
            hasAccess: ['admin'],
            subMenus: [
                {
                    path: 'pentests',
                    name: 'Penetration Tests',
                    icon: ScanOutlined,
                    Component: Pentests,
                    layout: '/admin',
                    key: '16',
                    hasAccess: ['admin'],
                },
                {
                    path: 'codereview',
                    name: 'Code Review',
                    icon: CodeOutlined,
                    Component: CodeReview,
                    layout: '/admin',
                    key: '17',
                    hasAccess: ['admin'],
                },
            ],
        },

        {
            path: 'accessor',
            name: 'Audit Requests',
            icon: BookOutlined,
            Component: AccessorRequests,
            layout: '/admin',
            key: '24',
            hasAccess: ['aud_admin'],
        },
        {
            path: 'data-room',
            name: 'Data Room',
            icon: BsFillFileEarmarkTextFill,
            Component: DataRoom,
            layout: '/admin',
            key: '38',
            hasAccess: ['admin'],
        },
        {
            path: 'articles',
            name: 'Article',
            icon: BsFillFileEarmarkTextFill,
            Component: Article,
            layout: '/admin',
            key: '39',
            hasAccess: ['admin'],
        },
        {
            path: 'onboarding',
            name: 'Onboarding Items',
            icon: AiOutlineFileAdd,
            Component: Onboarding,
            layout: '/admin',
            key: '6',
            hasAccess: ['admin'],
        },
        {
            path: 'threats',
            name: 'Threats',
            icon: GrThreats,
            Component: Threat,
            layout: '/admin',
            key: '8',
            hasAccess: ['admin'],
        },
        {
            path: 'training',
            name: 'Training',
            icon: MdModelTraining,
            Component: Training,
            layout: '/admin',
            key: 20,
            hasAccess: ['admin'],
        },
        {
            path: 'releases',
            name: 'Releases',
            icon: BiRocket,
            Component: Releases,
            layout: '/admin',
            key: '10',
            hasAccess: ['admin'],
        },
        {
            path: 'questionaire',
            name: 'Vendor Questionaire',
            icon: AiOutlineForm,
            Component: QuestionaireDashboard,
            layout: '/admin',
            key: '11',
            hasAccess: ['admin'],
        },
        {
            path: 'integrations',
            name: 'Integrations',
            icon: GrIntegration,
            Component: Integration,
            layout: '/admin',
            key: '12',
            hasAccess: ['admin'],
        },
        {
            name: 'Resources',
            icon: GrResources,
            layout: '/admin',
            key: '14',
            hasAccess: ['admin'],
            subMenus: [
                {
                    path: 'faqs',
                    name: 'FAQs',
                    icon: FaQuestion,
                    Component: FAQs,
                    layout: '/admin',
                    key: '14.1',
                    hasAccess: ['admin'],
                },
                {
                    path: 'glossary',
                    name: 'Glossary',
                    icon: FaRegListAlt,
                    Component: Glossary,
                    layout: '/admin',
                    key: '14.2',
                    hasAccess: ['admin'],
                },
                {
                    path: 'testimonials',
                    name: 'Testimonials',
                    icon: BsChatLeftQuote,
                    Component: Testimonials,
                    layout: '/admin',
                    key: '15',
                    hasAccess: ['admin'],
                },
            ],
        },
        {
            path: 'survey',
            name: 'Survey',
            icon: HighlightOutlined,
            layout: '/admin',
            key: '23',
            hasAccess: ['admin'],
            subMenus: [
                {
                    path: 'survey-questions',
                    name: 'Survey Questions',
                    icon: GroupOutlined,
                    Component: SurveyQuestions,
                    layout: '/admin',
                    key: '23.1',
                    hasAccess: ['admin'],
                },
                {
                    path: 'survey-response',
                    name: 'Survey Response',
                    icon: GroupOutlined,
                    Component: SurveyResponses,
                    layout: '/admin',
                    key: '23.2',
                    hasAccess: ['admin'],
                },
            ],
        },
        {
            path: 'push-notifications',
            name: 'Push Notifications',
            icon: AiOutlineNotification,
            Component: PushNotifications,
            layout: '/admin',
            key: '22',
            hasAccess: ['admin'],
        },
    ];

    return routes.filter((r) => {
        return r.hasAccess?.includes(role);
    });
};
export default getRoutes;
