import React, { useEffect, useMemo, useState } from 'react';
import CheckDone from '../../../assets/img/dashboard/CheckDone.svg';
import ClipIcon from '../../../assets/img/dashboard/CliqIcon.svg';
import QuestionIcon from '../../../assets/img/dashboard/QuestionClipIcon.svg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material/styles';
import { Progress } from 'antd';
import { Chip, Stack, Typography } from '@mui/material';
import { connect } from 'react-redux';

const SummaryReportCard = ({ details, blockchain, cii, all_criteria_summary, all_cii_summary }) => {
    const theme = useTheme();
    const [getPercentage, setGetPercentage] = useState(0);

    const filteredBlockchain = useMemo(() => {
        return all_criteria_summary?.filter((summary) => summary?.criteria === details?.id);
    }, [all_criteria_summary]);

    const filteredCii = useMemo(() => {
        return all_cii_summary?.filter((summary) => summary?.sector === details?.id);
    }, [all_cii_summary]);

    const userDocuments = filteredBlockchain?.reduce((a, v) => (a = a + v?.data?.user_doc), 0);
    const userForms = filteredBlockchain?.reduce((a, v) => (a = a + v?.data?.user_form), 0);
    const userCondiitonal = filteredBlockchain?.reduce((a, v) => (a = a + v?.data?.user_conditional), 0);
    const totalSummary = filteredBlockchain?.reduce((a, v) => (a = a + v?.total), 0);

    const userCiiDocuments = filteredCii?.reduce((a, v) => (a = a + v?.data?.user_doc), 0);
    const userCiiForms = filteredCii?.reduce((a, v) => (a = a + v?.data?.user_form), 0);
    const userCiiCondiitonal = filteredCii?.reduce((a, v) => (a = a + v?.data?.user_conditional), 0);
    const totalCiiSummary = filteredCii?.reduce((a, v) => (a = a + v?.total), 0);

    useEffect(() => {
        if (filteredCii?.length) {
            let totalStatus = 0;
            let totalTools = 0;
            filteredCii?.forEach((criteria) => {
                if (criteria.tools.length) {
                    totalTools += criteria.tools.length;
                    totalStatus = criteria.tools.reduce((acc, curr) => {
                        if (curr.status) {
                            return (acc += 1);
                        } else {
                            return acc;
                        }
                    }, totalStatus);
                }
            });
            const aggregateSummary = userCiiDocuments + userCiiForms + userCiiCondiitonal + totalStatus;
            const aggregateTools = totalCiiSummary + totalTools;

            const percentage = (aggregateSummary / aggregateTools) * 100;
            setGetPercentage(percentage);
        }
    }, [filteredCii]);

    useEffect(() => {
        if (filteredBlockchain?.length) {
            let totalStatus = 0;
            let totalTools = 0;
            filteredBlockchain?.forEach((criteria) => {
                if (criteria.tools.length) {
                    totalTools += criteria.tools.length;
                    totalStatus = criteria.tools.reduce((acc, curr) => {
                        if (curr.status) {
                            return (acc += 1);
                        } else {
                            return acc;
                        }
                    }, totalStatus);
                }
            });
            const aggregateSummary = userDocuments + userForms + userCondiitonal + totalStatus;
            const aggregateTools = totalSummary + totalTools;

            const percentage = (aggregateSummary / aggregateTools) * 100;
            setGetPercentage(percentage);
        }
    }, [filteredBlockchain]);
    return (
        <div className="flex bg-white items-center summary_shad p-4 px-6 mb-6">
            <div className="flex flex-1  flex-col textbrandColor">
                <div className="flex items-center">
                    <div
                        className={`w-3 h-3 rounded-full ${
                            (
                                blockchain
                                    ? getPercentage === 100
                                    : cii
                                    ? getPercentage === 100
                                    : details?.stepCount === 100
                            )
                                ? 'bg-green-500'
                                : 'bg-red-500'
                        } overflow-hidden`}
                    />
                    <div className="pl-3 font-medium textbrandColor">
                        {blockchain ? details?.name : cii ? details?.name : details?.title}
                    </div>
                </div>
                {blockchain ? (
                    <Stack direction="row" sx={{ flexWrap: 'wrap', marginRight: 1, marginLeft: 2.5, marginTop: 3 }}>
                        {details?.subcriterias?.map((chip) => {
                            const one_summary = all_criteria_summary?.find(
                                (summary) => summary?.subcriteria === chip?.id
                            );
                            const true_tools = one_summary?.tools?.every((item) => item?.status === true);
                            const completed =
                                one_summary?.data?.user_doc === one_summary?.data?.total_doc &&
                                one_summary?.data?.user_form === one_summary?.data?.total_form &&
                                one_summary?.data?.user_conditional === one_summary?.data?.conditional_count &&
                                true_tools;
                            const checkTool = one_summary?.total === 0 && one_summary?.tools?.length === 0;
                            return (
                                <Chip
                                    key={chip?.id}
                                    sx={{ marginRight: 1, marginBottom: 1 }}
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 600,
                                                color: theme.palette.gray[600],
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {chip?.name}
                                            {checkTool === undefined || checkTool === true ? null : (
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: 12,
                                                        height: 12,
                                                        marginLeft: theme.spacing(0.5),
                                                    }}
                                                    color={completed ? 'success' : 'error'}
                                                />
                                            )}
                                        </Typography>
                                    }
                                    variant="outlined"
                                    size="small"
                                />
                            );
                        })}
                    </Stack>
                ) : cii ? (
                    <Stack direction="row" sx={{ flexWrap: 'wrap', marginRight: 1, marginLeft: 2.5, marginTop: 3 }}>
                        {details?.subsectors?.map((chip) => {
                            const one_summary = all_cii_summary?.find((summary) => summary?.subsector === chip?.id);
                            const true_tools = one_summary?.tools?.every((item) => item?.status === true);
                            const completed =
                                one_summary?.data?.user_doc === one_summary?.data?.total_doc &&
                                one_summary?.data?.user_form === one_summary?.data?.total_form &&
                                one_summary?.data?.user_conditional === one_summary?.data?.conditional_count &&
                                true_tools;
                            const checkTool = one_summary?.total === 0 && one_summary?.tools?.length === 0;
                            return (
                                <Chip
                                    key={chip?.id}
                                    sx={{ marginRight: 1, marginBottom: 1 }}
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 600,
                                                color: theme.palette.gray[600],
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {chip?.name}
                                            {checkTool === undefined || checkTool === true ? null : (
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: 12,
                                                        height: 12,
                                                        marginLeft: theme.spacing(0.5),
                                                    }}
                                                    color={completed ? 'success' : 'error'}
                                                />
                                            )}
                                        </Typography>
                                    }
                                    variant="outlined"
                                    size="small"
                                />
                            );
                        })}
                    </Stack>
                ) : (
                    <div className="flex pl-[20px] pt-3">
                        <div className="flex items-center">
                            <img className="ml-2" width={18} src={ClipIcon} alt="Checkdone" />
                            <span className="pl-2 font-medium">
                                {details?.user_doc}/{details?.total_doc}
                            </span>
                        </div>
                        <div className="flex items-center pl-3">
                            <img className="ml-2" width={18} src={QuestionIcon} alt="QuestionIcon" />
                            <span className="pl-2 font-medium">
                                {details?.user_form}/{details?.total_form}
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {(blockchain ? getPercentage === 100 : cii ? getPercentage === 100 : details?.stepCount === 100) ? (
                    <img className="ml-2" width={28} src={CheckDone} alt="Checkdone" />
                ) : (
                    <Progress
                        type="circle"
                        strokeLinecap="butt"
                        width={33}
                        percent={
                            blockchain ? Math.ceil(getPercentage) : cii ? Math.ceil(getPercentage) : details?.stepCount
                        }
                        strokeWidth={12}
                        strokeColor={'#0E2C66'}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_criteria_summary: state?.complianceReducers?.all_criteria_summary,
        all_cii_summary: state?.complianceReducers?.all_cii_summary,
    };
};

export default connect(mapStateToProps, {})(SummaryReportCard);
