import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/styles';
//redux
import { connect } from 'react-redux';
import { GetMerchantASVscanRequests, GetMerchantPentestRequests } from 'store/actions/merchantActions';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const PentestCard = ({
    GetMerchantPentestRequests,
    GetMerchantASVscanRequests,
    merchant_pentests_requests,
    merchant_asvscans_requests,
}) => {
    const theme = useTheme();

    console.log(merchant_pentests_requests);
    useEffect(() => {
        const getMerchantPentestsRequests = async () => {
            const res = await GetMerchantPentestRequests();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getMerchantPentestsRequests();
    }, []);
    // useEffects
    useEffect(() => {
        const getMerchantASVscanRequests = async () => {
            const res = await GetMerchantASVscanRequests();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getMerchantASVscanRequests();
    }, []);
    return (
        <Box
            sx={{
                border: '1px solid #C7C6CA',
                borderRadius: '16px',
                minHeight: '300px',
                // maxHeight: '389px',
                py: 2,
                display: 'flex',
                width: '100%',
            }}
        >
            <Box sx={{ width: '49%', px: 3 }}>
                <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#0E2C66', mb: '10px' }}>
                    Penetration Test
                </Typography>
                <Box sx={{ width: '100%', my: 2 }}>
                    <table
                        style={{
                            border: '0.5px solid #C7C6CA',
                            marginTop: '10px',
                            width: '100%',
                            minHeight: '100px',
                        }}
                    >
                        <thead
                            style={{
                                border: '0.5px solid #C7C6CA',
                            }}
                        >
                            <tr style={{ border: '0.5px solid #C7C6CA' }}>
                                <td
                                    style={{
                                        padding: '5px',
                                        width: '70%',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                        IP
                                    </Typography>
                                </td>
                                <td
                                    style={{
                                        padding: '5px',
                                        width: '50%',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                        Severity Level
                                    </Typography>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {merchant_pentests_requests?.map((pentest) => {
                                return (
                                    <tr style={{ width: '100%' }} key={pentest?.id}>
                                        <td
                                            style={{
                                                padding: '5px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#0E2C66',
                                                    fontWeight: 500,
                                                    lineHeight: '14.52px',
                                                    fontSize: 12,
                                                    mb: 1,
                                                    wordBreak: 'break-all',
                                                }}
                                            >
                                                {pentest?.ip_address ||
                                                    pentest?.assets?.map((asset) => asset?.ip_address)?.toString()}
                                            </Typography>
                                        </td>
                                        <td
                                            style={{
                                                padding: '5px',
                                            }}
                                        >
                                            {!pentest?.severity ? (
                                                <Box
                                                    sx={{
                                                        height: '6px',
                                                        width: '80px',
                                                        background: ' #D9D9D9',
                                                        borderRadius: '8px',
                                                    }}
                                                ></Box>
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        fontWeight: 500,
                                                        lineHeight: '14.52px',
                                                        fontSize: 12,
                                                        color:
                                                            pentest?.severity?.toLowerCase() === 'high'
                                                                ? theme.palette.error[300]
                                                                : pentest?.severity?.toLowerCase() === 'medium'
                                                                ? theme.palette.warning[900]
                                                                : pentest?.severity?.toLowerCase() === 'critical'
                                                                ? theme.palette.error[500]
                                                                : theme.palette.success[500],
                                                        textTransform: 'capitalize',
                                                    }}
                                                >
                                                    {pentest?.severity}
                                                </Typography>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Box>
                {/* {merchant_pentests_requests?.map((pentest) => {
                    return (
                        <Box key={pentest?.id} sx={{ display: 'flex', alignItems: 'center', mb: '8px' }}>
                            <Typography sx={{ fontWeight: 500, fontSize: '10px', color: '#0E2C66', mr: '8px' }}>
                                {pentest?.ip_address}
                            </Typography>
                            <Box
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '10px',
                                    color: '#5E5E62',
                                    border: '0.8px solid #B8BCCC',
                                    borderRadius: '90px',
                                    px: '4px',
                                    py: '1px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {pentest?.status === true ? 'Passed' : 'Failed'}
                            </Box>
                        </Box>
                    );
                })} */}
            </Box>
            <Box sx={{ px: 3, width: '49%' }}>
                <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#0E2C66', mb: '10px' }}>
                    PCI Scans
                </Typography>
                <Box sx={{ width: '100%', my: 2 }}>
                    <table
                        style={{
                            border: '0.5px solid #C7C6CA',
                            marginTop: '10px',
                            width: '100%',
                            minHeight: '100px',
                        }}
                    >
                        <thead
                            style={{
                                border: '0.5px solid #C7C6CA',
                            }}
                        >
                            <tr style={{ border: '0.5px solid #C7C6CA' }}>
                                <td
                                    style={{
                                        padding: '5px',
                                        width: '70%',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                        IP/URL
                                    </Typography>
                                </td>
                                <td
                                    style={{
                                        padding: '5px',
                                        width: '50%',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                        Status
                                    </Typography>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {merchant_asvscans_requests?.map((pciscans) => {
                                return (
                                    <tr style={{ width: '100%' }}>
                                        <td
                                            style={{
                                                padding: '5px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#0E2C66',
                                                    fontWeight: 500,
                                                    lineHeight: '14.52px',
                                                    fontSize: 12,
                                                    mb: 1,
                                                }}
                                            >
                                                {pciscans?.ip_address}
                                            </Typography>
                                        </td>
                                        <td
                                            style={{
                                                padding: '5px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#0E2C66',
                                                    fontWeight: 500,
                                                    lineHeight: '14.52px',
                                                    fontSize: 12,
                                                    mb: 1,
                                                }}
                                            >
                                                {pciscans?.status === true ? 'Passed' : 'Failed'}
                                            </Typography>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Box>
                {/* {merchant_asvscans_requests?.map((pciscans) => {
                    return (
                        <Box key={pciscans?.id} sx={{ display: 'flex', alignItems: 'center', mb: '8px' }}>
                            <Typography sx={{ fontWeight: 500, fontSize: '10px', color: '#0E2C66', mr: '8px' }}>
                                {pciscans?.ip_address}
                            </Typography>
                            <Box
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '10px',
                                    color: '#5E5E62',
                                    border: '0.8px solid #B8BCCC',
                                    borderRadius: '90px',
                                    px: '4px',
                                    py: '1px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {pciscans?.status === true ? 'Passed' : 'Failed'}
                            </Box>
                        </Box>
                    );
                })} */}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_pentests_requests: state?.merchantReducers?.merchant_pentests_requests,
        merchant_asvscans_requests: state?.merchantReducers?.merchant_asvscans_requests,
    };
};
export default connect(mapStateToProps, { GetMerchantPentestRequests, GetMerchantASVscanRequests })(PentestCard);
