import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
// core component
import AppLinearProgress from 'components/new_components/AppLinearProgress';

//translation
import { useTranslation } from 'react-i18next';

const ComplianceProgressBar = (props) => {
    const { percent, label, standard } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');
    return (
        <Stack sx={{ posiiton: 'relative' }}>
            <Typography
                sx={{
                    alignSelf: 'flex-end',
                    fontWeight: standard === 'soc2' ? 400 : 500,
                    fontSize: standard === 'soc2' ? 11 : 12,
                    position: standard === 'soc2' ? 'absolute' : '',
                    color: standard === 'soc2' ? '#64748B' : theme.palette.primary[900],
                    top: standard === 'soc2' ? 25 : 0,
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: standard === 'soc2' ? 700 : 700,
                        fontSize: standard === 'soc2' ? 11 : 12,
                        color: standard === 'soc2' ? '#64748B' : theme.palette.primary[900],
                    }}
                >
                    {percent?.toFixed(2) || 0}%
                </Typography>{' '}
                {standard === 'soc2' ? 'done' : label || t('completed')}
            </Typography>
            <AppLinearProgress value={percent} sx={{ my: 1, color: standard === 'soc2' ? '#395BA9' : 'primary' }} />
        </Stack>
    );
};

export default ComplianceProgressBar;
