import React, { useEffect, useState } from 'react';
import useSearch from 'hooks/useSearch';

// core components
import AppTable from 'components/new_components/app-table';
import LogStatusTag from './components/LogStatusTag';
import DeleteTaskModal from './DeleteTaskModal';
import ReassignTaskModal from './ReassignTaskModal';
import TaskInformationModal from './TaskInformationModal';
import TaskLogFilters from './components/TaskLogFilters';
import TaskLogTableActions from './TaskLogTableActions';
import AssigneeTag from './components/AssigneeTag';

// redux
import { connect } from 'react-redux';
import { GetAllTasks } from 'store/actions/taskActions';

//utils
import { formatDateObjectHandler } from 'utils';
import { toast } from 'react-toastify';
import TaskName from './components/TaskName';

const NO_PER_PAGE = 8;
const TaskLog = (props) => {
    // state
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [modalOpen, setModalOpen] = useState(null);
    const [modalPayload, setModalPayload] = useState({});
    const [filters, setFilters] = useState({ status: '', assignee: '' });

    const { GetAllTasks, all_assigned_tasks } = props;

    // hooks
    const { data, handleSearch } = useSearch(all_assigned_tasks, ['task', 'comment', 'status', 'severity']);

    // functions
    const openModal = (type, record) => {
        setModalOpen(type);
        setModalPayload(record);
    };
    const closeModal = (type) => {
        setModalOpen(type);
        setModalPayload({});
    };
    // logic functions
    const handlePageChange = (page) => {
        setPage(page);
    };
    const handleFilterInput = (e) => {
        const { name, value } = e.target;
        setFilters((current) => ({
            ...current,
            [name]: value,
        }));
    };

    // const handleFiltering = (e) => {
    //     e.preventDefault();
    //     getAllActivities(filters, 1, 'updating');
    //     setPage(1);
    // };
    // async

    // constants
    const columns = [
        {
            title: 'Task',
            render: (row) => {
                return <TaskName data={row} />;
            },
        },
        {
            title: 'Assigned to',
            render: (row) => (
                <div className="w-full">
                    <div className="flex flex-row flex-wrap gap-1 w-full">
                        {row?.assigned_to_name?.map((employee, index) => (
                            <AssigneeTag name={employee} key={index} />
                        ))}
                    </div>
                </div>
            ),
        },
        {
            title: 'Comment',
            render: (row) => <>{row.comments}</>,
        },
        {
            title: 'Date assigned',
            render: (row) => <>{formatDateObjectHandler(row.date_created, 'MMMM DD, YYYY')}</>,
        },
        {
            title: 'Status',
            render: (row) => (
                <>
                    <LogStatusTag
                        text={
                            row?.completion_status === 'waiting_approval'
                                ? 'Awaiting approval'
                                : row?.completion_status?.replaceAll('_', ' ')
                        }
                        type={
                            row?.completion_status === 'not_completed'
                                ? 'error'
                                : row?.completion_status === 'completed'
                                ? 'success'
                                : 'secondary'
                        }
                    />
                </>
            ),
        },
        {
            title: '',
            render: (row) => <TaskLogTableActions row={row} openModal={openModal} />,
        },
    ];

    useEffect(() => {
        const getAllTasks = async () => {
            const res = await GetAllTasks();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };

        getAllTasks();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        let filteredData = data;

        if (filters?.assignee && filters?.status) {
            filteredData = data?.filter(
                (item) =>
                    item?.assigned_to_name?.includes(filters?.assignee) && item?.completion_status === filters?.status
            );
        } else if (filters?.assignee) {
            filteredData = data?.filter((item) => item?.assigned_to_name?.includes(filters?.assignee));
        } else if (filters?.status) {
            filteredData = data?.filter((item) => item?.completion_status === filters?.status);
        }

        setPageData(filteredData?.length > 0 ? filteredData?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);
        return data;
    }, [page, data, filters?.assignee, filters?.status]);
    console.log({ all_assigned_tasks });
    return (
        <>
            <AppTable
                columns={columns}
                data={pageData}
                task_log
                filters={
                    <TaskLogFilters
                        data={data}
                        handleFilter={handleFilterInput}
                        // handleFiltering={handleFiltering}
                        filters={filters}
                    />
                }
                // loading={loading.fetching}
                // noOfPages={getNoOfPages(total_activities)}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                search={true}
                onSearch={handleSearch}
                rowBorder
            />
            <ReassignTaskModal open={modalOpen === 'reassign'} onClose={closeModal} data={modalPayload} />
            <DeleteTaskModal open={modalOpen === 'delete'} handleClose={closeModal} payload={modalPayload} />
            <TaskInformationModal
                open={modalOpen === 'view' || modalOpen === 'update'}
                type={modalOpen}
                handleClose={closeModal}
                payload={modalPayload}
                openDeleteModal={openModal}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_assigned_tasks: state?.tasksReducers?.all_assigned_tasks,
    };
};
export default connect(mapStateToProps, { GetAllTasks })(TaskLog);
