import React from 'react';
import { Close, DoneAll } from '@mui/icons-material';
import { HiOutlineTrash, HiOutlineAnnotation, HiCheckCircle } from 'react-icons/hi';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { useTheme } from '@mui/styles';
import AppButton from 'components/new_components/AppButton';
import AssigneeTag from './components/AssigneeTag';
import LogStatusTag from './components/LogStatusTag';
import TaskName from './components/TaskName';
import { formatDateObjectHandler } from 'utils';

//redux
import { connect } from 'react-redux';
import { UpdateTask } from 'store/actions/taskActions';
import { toast } from 'react-toastify';

const TaskInformationModal = (props) => {
    const { open, handleClose, payload, UpdateTask, openDeleteModal } = props;

    //theme hook
    const theme = useTheme();

    const markAsCompleted = async () => {
        const form_payload = { completion_status: 'completed' };
        const res = await UpdateTask(form_payload, payload?.id);
        if (res?.success) {
            toast.success('Task has been marked as Completed!');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            title={
                <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[800] }}>
                    Task Information
                </Typography>
            }
            headerAction={
                <IconButton color="disabled" onClick={handleClose}>
                    <Close />
                </IconButton>
            }
            titleStyle={{ borderBottom: `1px solid ${theme.palette.neutral[90]}` }}
            width={540}
            padding="0px"
        >
            <Box sx={{ px: 3, pt: 1, pb: 5 }}>
                <Stack direction="row" justifyContent="flex-end">
                    <AppButton
                        icon={<DoneAll />}
                        name="Mark as completed"
                        color="success"
                        variant="text"
                        onClick={markAsCompleted}
                        disabled={
                            payload?.completion_status === 'completed' || payload?.completion_status === 'not_completed'
                        }
                        sx={{
                            fontSize: 12,
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent',
                                color: '#E4E2E6',
                            },
                        }}
                    />
                    <AppButton
                        icon={<HiOutlineTrash />}
                        name="Delete task"
                        color="error"
                        variant="text"
                        sx={{ fontSize: 12 }}
                        onClick={() => openDeleteModal('delete', payload)}
                    />
                </Stack>

                <Stack direction="row" gap="9px" alignItems="flex-start">
                    {payload?.completion_status === 'completed' ? (
                        <HiCheckCircle color="#37A372" size={13} />
                    ) : (
                        <AiFillExclamationCircle
                            size={13}
                            color={payload?.completion_status === 'not_completed' ? '#EB4141' : '#002C72'}
                        />
                    )}
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumn: 'repeat(1, minmax(0, 1fr));',
                            gap: 1,
                            width: '100%',
                        }}
                    >
                        <Typography sx={{ fontSize: 11, fontWeight: 600 }}>Task</Typography>
                        <Typography
                            sx={{
                                borderLeft: `2px solid ${theme.palette.gray[40]}`,
                                p: 0.5,
                                fontSize: 12,
                                fontWeight: 500,
                                color: theme.palette.gray[30],
                                background: '#F8F8F8',
                            }}
                        >
                            <TaskName data={payload} />
                        </Typography>

                        <Typography sx={{ fontSize: 11, fontWeight: 600, color: theme.palette.neutral[50] }}>
                            Assigned to
                        </Typography>
                        <Stack gap={0.5} flexDirection="row" flexWrap="wrap" sx={{ width: '100%' }}>
                            {payload?.assigned_to_name?.map((employee, index) => (
                                <AssigneeTag name={employee} key={index} />
                            ))}
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ color: theme.palette.neutral[50], fontSize: 14 }}
                            gap={0.5}
                        >
                            <HiOutlineAnnotation />{' '}
                            <Typography sx={{ color: theme.palette.neutral[50], fontSize: 10, fontWeight: 400 }}>
                                {payload?.comments || 'Comment not available'}
                            </Typography>
                        </Stack>
                        <Box>
                            <Typography
                                component="span"
                                sx={{ fontSize: 10, fontWeight: 700, color: theme.palette.neutral[50] }}
                            >
                                Assigned on
                            </Typography>{' '}
                            <Typography
                                component="span"
                                sx={{
                                    fontSize: 10,
                                    fontWeight: 500,
                                    color: theme.palette.neutral.new60,
                                }}
                            >
                                {formatDateObjectHandler(payload?.date_created, 'MMMM DD, YYYY:: h:mm a ')}
                            </Typography>
                        </Box>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography sx={{ fontSize: 10, color: theme.palette.neutral.new60 }}>
                                {payload?.completion_status === 'waiting_approval'
                                    ? formatDateObjectHandler(payload?.user_completion_date, 'MMMM DD, YYYY:: h:mm a ')
                                    : payload?.completion_status === 'completed'
                                    ? formatDateObjectHandler(payload?.date_updated, 'MMMM DD, YYYY:: h:mm a ')
                                    : '-'}
                            </Typography>{' '}
                            <LogStatusTag
                                text={
                                    payload?.completion_status === 'waiting_approval'
                                        ? 'Awaiting approval'
                                        : payload?.completion_status?.replaceAll('_', ' ')
                                }
                                type={
                                    payload?.completion_status === 'not_completed'
                                        ? 'error'
                                        : payload?.completion_status === 'completed'
                                        ? 'success'
                                        : 'secondary'
                                }
                                sx={{ fontSize: 10, padding: '2px 3px', borderRadius: '2px' }}
                            />
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { UpdateTask })(TaskInformationModal);
