import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import ContainerWithHeader from '../../commons/ContainerWithHeader';
// import LabeledCircularTag from '../../commons/LabeledCircularTag';
// import LabeledChipTag from '../../commons/LabeledChipTag';

const EmployeeAndVendorsCard = (props) => {
    const {
        dashboardResult,
        all_employees,
        employee_with_agent,
        employee_with_no_background,
        low_vendor,
        medium_vendor,
        high_vendor,
        critical_vendor,
    } = props;

    const {
        employees_with_installed_agents_count,
        // employee_with_backgroundcheck_count,
        employee_count,
        vendors_classification,
        vendors_with_response_count,
        vendors,
    } = dashboardResult || {};
    // const vendorClassification = [
    //     { id: 1, name: 'Low', value: vendors_classification?.low },
    //     { id: 2, name: 'Medium', value: vendors_classification?.medium },
    //     { id: 3, name: 'High', value: vendors_classification?.high },
    //     { id: 4, name: 'Critical', value: vendors_classification?.critical },
    // ];
    // const respondedVendor = [
    //     { id: 1, name: 'Responded', value: vendors_with_response_count },
    //     { id: 2, name: 'Unresponded', value: vendors - vendors_with_response_count },
    // ];
    return (
        <Stack sx={{ pt: 2 }}>
            <ContainerWithHeader header="vendors" sx={{ minHeight: '300px', mb: '16px' }}>
                <Box sx={{ mt: 2.5, mx: 2 }}>
                    <Box>
                        <Typography
                            sx={{ color: '#0E2C66', fontWeight: 700, lineHeight: '14.52px', fontSize: 12, my: 1 }}
                        >
                            Responded Vendor
                        </Typography>
                        <Box sx={{ display: 'flex', mb: 1, my: '4px' }}>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    fontWeight: 700,
                                    color: '#5E5E62',
                                }}
                            >
                                Responded ({vendors_with_response_count})
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    fontWeight: 700,
                                    color: '#5E5E62',
                                    ml: 2,
                                }}
                            >
                                Unresponded ({vendors - vendors_with_response_count})
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: '#0E2C66',
                                fontWeight: 700,
                                lineHeight: '14.52px',
                                fontSize: 12,
                                my: 2,
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Vendor Classification
                        </Typography>
                        <table
                            style={{
                                border: '0.5px solid #C7C6CA',
                                marginTop: '10px',
                                width: '100%',
                            }}
                        >
                            <thead
                                style={{
                                    border: '0.5px solid #C7C6CA',
                                }}
                            >
                                <tr style={{ border: '0.5px solid #C7C6CA', borderRadius: '6px 6px 0px 0px' }}>
                                    <td
                                        style={{
                                            padding: '5px',
                                            borderRadius: '6px 6px 0px 0px',
                                            width: '24%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                            Low ({vendors_classification?.low})
                                        </Typography>
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',

                                            width: '24%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                            Medium ({vendors_classification?.medium})
                                        </Typography>
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',
                                            width: '24%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                            High ({vendors_classification?.high})
                                        </Typography>
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',

                                            width: '24%',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                            Critical ({vendors_classification?.critical})
                                        </Typography>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ width: '100%' }}>
                                    <td
                                        style={{
                                            padding: '5px',
                                        }}
                                    >
                                        {low_vendor?.map((vendor) => {
                                            return (
                                                <Typography
                                                    key={vendor?.id}
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        color: '#0E2C66',
                                                        mb: '8px',
                                                    }}
                                                >
                                                    {vendor?.name}
                                                </Typography>
                                            );
                                        })}
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',
                                        }}
                                    >
                                        {medium_vendor?.map((vendor) => {
                                            return (
                                                <Typography
                                                    key={vendor?.id}
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        color: '#0E2C66',
                                                        mb: '8px',
                                                        ml: 1,
                                                    }}
                                                >
                                                    {vendor?.name}
                                                </Typography>
                                            );
                                        })}
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',
                                        }}
                                    >
                                        {high_vendor?.map((vendor) => {
                                            return (
                                                <Typography
                                                    key={vendor?.id}
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        color: '#0E2C66',
                                                        mb: '8px',
                                                        ml: 1,
                                                    }}
                                                >
                                                    {vendor?.name}
                                                </Typography>
                                            );
                                        })}
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',
                                        }}
                                    >
                                        {critical_vendor?.map((vendor) => {
                                            return (
                                                <Typography
                                                    key={vendor?.id}
                                                    sx={{
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        color: '#0E2C66',
                                                        mb: '8px',
                                                        ml: 1,
                                                    }}
                                                >
                                                    {vendor?.name}
                                                </Typography>
                                            );
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                </Box>
            </ContainerWithHeader>
            <ContainerWithHeader header="employees" sx={{ minHeight: '370px' }}>
                <table
                    style={{
                        border: '0.5px solid #C7C6CA',
                        margin: '16px',
                        width: '97%',
                    }}
                >
                    <thead
                        style={{
                            border: '0.5px solid #C7C6CA',
                        }}
                    >
                        <tr style={{ border: '0.5px solid #C7C6CA' }}>
                            <td
                                style={{
                                    padding: '5px',
                                }}
                            >
                                <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                    Total Number of Employees ({employee_count})
                                </Typography>
                            </td>
                            <td
                                style={{
                                    padding: '5px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: 700,
                                        color: '#46464A',
                                    }}
                                >
                                    Number of Employees with agent ({employees_with_installed_agents_count})
                                </Typography>
                            </td>
                            <td
                                style={{
                                    padding: '5px',
                                }}
                            >
                                <Typography sx={{ fontSize: '13px', fontWeight: 700, color: '#46464A' }}>
                                    Employee with no background check ({employee_with_no_background?.length})
                                </Typography>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ width: '100%' }}>
                            <td
                                style={{
                                    padding: '5px',
                                    width: '33%',
                                }}
                            >
                                {all_employees?.map((employee) => {
                                    return (
                                        <Typography
                                            key={employee?.id}
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '12px',
                                                color: '#0E2C66',
                                                mb: 1,
                                                ml: 1,
                                            }}
                                        >
                                            {employee?.first_name} {employee?.last_name}
                                        </Typography>
                                    );
                                })}
                            </td>
                            <td
                                style={{
                                    padding: '5px',
                                    // width: '33%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {employee_with_agent?.map((employee) => {
                                    return (
                                        <Typography
                                            key={employee?.id}
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '12px',
                                                color: '#0E2C66',
                                                mb: 1,
                                                ml: 1,
                                            }}
                                        >
                                            {employee?.first_name} {employee?.last_name}
                                        </Typography>
                                    );
                                })}
                            </td>
                            <td
                                style={{
                                    padding: '5px',
                                    width: '33%',
                                }}
                            >
                                {employee_with_no_background?.map((employee) => {
                                    return (
                                        <Typography
                                            key={employee?.id}
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: '12px',
                                                color: '#0E2C66',
                                                mb: 1,
                                                ml: 1,
                                            }}
                                        >
                                            {employee?.first_name} {employee?.last_name}
                                        </Typography>
                                    );
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <LabeledCircularTag label="Total Number of Employees" value={employee_count} />
                    <LabeledCircularTag
                        label="Number of Employees with agent"
                        value={employees_with_installed_agents_count}
                    />
                    <LabeledCircularTag
                        label="Employee with no background check"
                        value={employee_with_no_background?.length}
                    />
                </Box>
                <Box sx={{ display: 'flex', pt: 2 }}>
                    <Box sx={{ borderRight: '1px solid #0E2C66', width: '33%' }}>
                        {all_employees?.map((employee) => {
                            return (
                                <Typography
                                    key={employee?.id}
                                    sx={{ fontWeight: 500, fontSize: '10px', mb: '4px', ml: 3 }}
                                >
                                    {employee?.first_name} {employee?.last_name}
                                </Typography>
                            );
                        })}
                    </Box>
                    <Box sx={{ borderRight: '1px solid #0E2C66', width: '33%' }}>
                        {employee_with_agent?.map((employee) => {
                            return (
                                <Typography
                                    key={employee?.id}
                                    sx={{ fontWeight: 500, fontSize: '10px', mb: '4px', ml: 3 }}
                                >
                                    {employee?.first_name} {employee?.last_name}
                                </Typography>
                            );
                        })}
                    </Box>
                    <Box>
                        {employee_with_no_background?.map((employee) => {
                            return (
                                <Typography
                                    key={employee?.id}
                                    sx={{ fontWeight: 500, fontSize: '10px', mb: '4px', ml: 3 }}
                                >
                                    {employee?.first_name} {employee?.last_name}
                                </Typography>
                            );
                        })}
                    </Box>
                </Box> */}
            </ContainerWithHeader>
        </Stack>
    );
};

export default EmployeeAndVendorsCard;
