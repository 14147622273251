import React from 'react';
import { ReactComponent as WhiteLogo } from 'assets/img/brand/logo-white.svg';
import { ReactComponent as BlueLogo } from 'assets/img/brand/logo.svg';
import { Box } from '@mui/material';

const Brand = (props) => {
    const { type } = props;
    return (
        <Box sx={{ mx: 3, my: 3.5 }}>
            {type === 'blue' ? <BlueLogo className="w-[120px]" /> : <WhiteLogo className="w-[120px]" />}
        </Box>
    );
};

export default Brand;
