import React from 'react';
import { Container, Card, TableContainer, Table, Stack } from '@mui/material';

// core components
import TableTopbar from './TableTopbar';
import RiskTableTopbar from './RiskTableTopbar';
import TableHeader from './TableHeader';
import AppTableBody from './AppTableBody';
import AppTablePagination from './AppTablePagination';
import TaskTableTopbar from './TaskTableTopbar';

const AppTable = (props) => {
    const {
        columns,
        data,
        title,
        actions,
        sorter,
        page,
        onPageChange,
        emptyStateText,
        noPerPage,
        dataLength,
        loading,
        showTitleBar = true,
        sx = {},
        rowSpacing,
        onRowClick,
        border,
        clickableRow,
        search,
        onSearch,
        toggleTabs,
        tabs,
        tabState,
        showPagination = true,
        risk_assessment,
        task_log,
        filters,
        noBackground,
        rowBorder,
        ref1,
        ref2,
    } = props;
    const spacingBorderStyles = {
        borderCollapse: 'separate',
        borderSpacing: '0 24px',
    };
    const borderStyles = {
        border: '0.5px solid #77777A',
        borderRadius: 8,
    };
    return (
        <Container
            component={Card}
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: data?.length ? '100%' : '',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
                ...sx?.container,
            }}
        >
            <Stack sx={{ flex: 1 }}>
                {showTitleBar && (
                    <>
                        {risk_assessment ? (
                            <RiskTableTopbar
                                title={title}
                                actions={actions}
                                search={search}
                                onSearch={onSearch}
                                rowSpacing={rowSpacing}
                                toggleTabs={toggleTabs}
                                tabs={tabs}
                                tabState={tabState}
                            />
                        ) : task_log ? (
                            <TaskTableTopbar
                                title={title}
                                actions={actions}
                                rowSpacing={rowSpacing}
                                search={search}
                                onSearch={onSearch}
                                filters={filters}
                            />
                        ) : (
                            <TableTopbar
                                title={title}
                                actions={actions}
                                search={search}
                                onSearch={onSearch}
                                rowSpacing={rowSpacing}
                                toggleTabs={toggleTabs}
                                tabs={tabs}
                                tabState={tabState}
                                ref1={ref1}
                                ref2={ref2}
                            />
                        )}
                    </>
                )}
                <TableContainer sx={{ flex: 1, px: rowSpacing ? 2.5 : 0 }} component={Stack}>
                    <Table
                        sx={{
                            ...(rowSpacing ? spacingBorderStyles : {}),
                            ...(border ? borderStyles : {}),
                        }}
                    >
                        <TableHeader columns={columns} noBackground={noBackground} />
                        <AppTableBody
                            columns={columns}
                            data={data}
                            sorter={sorter}
                            emptyStateText={emptyStateText}
                            loading={loading}
                            rowSpacing={rowSpacing}
                            clickableRow={clickableRow}
                            onRowClick={onRowClick}
                            rowBorder={rowBorder}
                        />
                    </Table>
                </TableContainer>
            </Stack>
            {!!data?.length && showPagination && (
                <AppTablePagination
                    page={page}
                    onPageChange={onPageChange}
                    noPerPage={noPerPage}
                    dataLength={dataLength}
                />
            )}
        </Container>
    );
};

export default AppTable;
