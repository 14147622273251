import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { Upload, Scanner } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { Stack, Typography, Box, useTheme } from '@mui/material';

//core components
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
import AppButton from 'components/new_components/AppButton';
import PCIScansTableActions from './PCIScansTableActions';
import RequestScanAction from './RequestScanAction';
import AppColorTag from 'components/new_components/AppColorTag';
import RequestModal from './RequestModal';
import AppUploadCSVModal from 'components/new_components/AppUploadCSVModal';
import UploadReportModal from './UploadReportModal';
import PCIReportModal from './PCIReportModal';
import PCIDeleteModal from './PCIDeleteModal';
import useSearch from 'hooks/useSearch';

//redux
import { connect, useSelector } from 'react-redux';
import { GetMerchantASVscanRequests, UploadASVScanCSV } from 'store/actions/merchantActions';

//utils
import { requestInitialValues, uploadReportInitialValues } from './utils/constant';
import PageHeader from 'components/new_components/PageHeader';
import SubscriptionAlert from '../../../components/Pricing/SubscriptionAlert';
import { GetRemaining } from '../../../components/Pricing/usePricingHook';
import { formatDateObjectHandler } from 'utils';
import RerunScanModal from './RerunScanModal';
import FormTooltip from 'components/new_components/FormTooltip';

//context
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

//userguide
import AppUserGuide from 'components/new_components/AppUserGuide';
import { steps } from './steps';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 8;

const PCIScans = ({
    GetMerchantASVscanRequests,
    UploadASVScanCSV,
    merchant_asvscans_requests,
    pricing_plan,
    userguide_info,
    all_article_header,
}) => {
    const [openRequestAction, setOpenRequestAction] = useState(false);
    const [modalPayload, setModalPayload] = useState({});
    const [modalType, setModalType] = useState('');
    const [openPCIRequestModal, setopenPCIRequestModal] = useState(false);
    const [openUploadCSVModal, setOpenUploadCSVModal] = useState(false);
    const [openUploadReportModal, setOpenUploadReportModal] = useState(false);
    const [openDowloadReportModal, setOpenDownloadReportModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayloadID, setDeletePayloadID] = useState(0);
    const [report, setReport] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [rerunModalOpen, setrerunModalOpen] = useState(false);
    const [scanId, setScanId] = useState(0);
    const [uploadModalPayload, setUploadModalPayload] = useState(uploadReportInitialValues);

    const stepsTargetRef = useRef([]);
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const theme = useTheme();

    //translation
    const { t } = useTranslation('pciscans');

    // hooks
    const { data, handleSearch } = useSearch(merchant_asvscans_requests, [
        'name',
        'ip_address',
        'uploaded_by',
        'status',
        'quater',
    ]);

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    //context api for auditor's selected merchant
    const { activeMerchant } = useContext(SelectedMerchantContext);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'PCIScans'),
        [all_article_header]
    );

    //functions
    const openRequestActionModal = () => {
        setOpenRequestAction(true);
    };
    const closeRequestActionModal = () => {
        setOpenRequestAction(false);
    };
    const openPCIScanRequestModal = (type, data, id) => {
        setModalType(type);
        setopenPCIRequestModal(true);
        setModalPayload(data);
        setScanId(id);
        setOpenRequestAction(false);
    };
    const closePCIScanRequestModal = () => {
        setopenPCIRequestModal(false);
        setTimeout(() => {
            setModalPayload({});
            setModalType('');
            setScanId(0);
        }, 1000);
    };
    const openPCIScanUploadCSVModal = () => {
        setOpenUploadCSVModal(true);
        setOpenRequestAction(false);
    };
    const closePCIScanUploadCSVModal = () => {
        setOpenUploadCSVModal(false);
    };
    const openUploadPCIReportModal = () => {
        setOpenUploadReportModal(true);
    };
    const closeUploadPCIReportModal = () => {
        setOpenUploadReportModal(false);
        setUploadModalPayload({});
    };

    const openDownloadPCIReportModal = (payload) => {
        setOpenDownloadReportModal(true);
        setReport(payload);
    };
    const closeDownloadPCIReportModal = () => {
        setOpenDownloadReportModal(false);
        setTimeout(() => setReport(''), 1000);
    };

    const openRerunModal = (data) => {
        setrerunModalOpen(true);
        setModalPayload(data);
    };
    const closeRerunModal = () => {
        setrerunModalOpen(false);
        setTimeout(() => {
            setModalPayload({});
        }, 1000);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ pci_scans: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, pciscans: false });
        setOpenArticle(false);
    };

    //Delete Modal
    const openDeleteModal = (id) => {
        setDeleteModalOpen(true);
        setDeletePayloadID(id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setTimeout(() => setDeletePayloadID(''), 1000);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };

    const currentMonthForPciscans = (currentDate) => {
        const date = new Date(currentDate);
        const month = date.getMonth() + 1;

        if (month >= 1 && month <= 3) {
            return 'January-March';
        } else if (month > 3 && month <= 6) {
            return 'April-June';
        } else if (month > 6 && month <= 9) {
            return 'July-September';
        } else if (month > 9 && month <= 12) {
            return 'October-December';
        } else {
            return null;
        }
    };
    //
    const columns = [
        {
            title: t('table.column1'),
            render: (row) => <>{row?.name}</>,
        },
        {
            title: t('table.column2'),
            key: 'server',
            render: (row) => <>{row?.ip_address}</>,
        },
        {
            title: t('table.column3'),
            key: 'progress',
            render: (row) => (
                <>
                    <AppTag
                        text={row?.progress}
                        type={row?.progress?.toLowerCase() === 'completed' ? 'success' : 'default'}
                        fontWeight={500}
                    />
                </>
            ),
        },
        {
            title: t('table.column4'),
            key: 'status',
            render: (row) =>
                (!row?.status && !row?.uploaded_by) || row?.progress === 'running' || row?.progress === 'rerunning' ? (
                    <Box sx={{ height: '6px', width: '80px', background: ' #D9D9D9', borderRadius: '8px' }}></Box>
                ) : (
                    <AppColorTag
                        text={row?.status ? t('table.success') : t('table.failed')}
                        type={row?.status ? 'success' : 'error'}
                        margin="0"
                        fontWeight={500}
                    />
                ),
        },
        {
            title: t('table.column5'),
            key: 'scanned_by',
            render: (row) => (
                <>
                    {row?.uploaded_by}
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: 10, fontWeight: 500 }}>
                        {row?.uploaded_by?.toLowerCase() !== 'clone systems'
                            ? t('table.uploaded')
                            : t('table.requested')}
                    </Typography>
                </>
            ),
        },
        {
            title: t('table.column6'),
            key: 'date',
            render: (row) => (
                <>
                    {formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY') || 'Nil'}
                    <Typography sx={{ fontSize: 14 }}>({currentMonthForPciscans(row?.date_created)})</Typography>
                </>
            ),
        },
        {
            title: (
                <>
                    {t('table.column7')}
                    <FormTooltip placement="top-end" text={t('table.column7Tip')} />
                </>
            ),
            key: 'frequency',
            render: (row) => {
                return row?.uploaded_by?.toLowerCase() === 'clone systems' ? (
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            height: '20px',
                            width: '20px',
                            borderRadius: '90px',
                            border: `0.8px solid ${theme.palette.gray[300]}`,
                            padding: 1.6,
                            fontSize: 12,
                        }}
                    >
                        {row?.frequency}
                    </Stack>
                ) : (
                    <Box sx={{ height: '6px', width: '80px', background: ' #D9D9D9', borderRadius: '8px' }}></Box>
                );
            },
        },
        {
            title: t('table.column8'),
            align: 'center',
            key: 'action',
            render: (row) => (
                <PCIScansTableActions
                    row={row}
                    openDeleteModal={openDeleteModal}
                    openDownloadPCIReportModal={openDownloadPCIReportModal}
                    openChangeScheduleModal={openPCIScanRequestModal}
                    openRerunModal={openRerunModal}
                    user_type={user_type}
                    role={role}
                />
            ),
        },
    ];

    // useEffects
    useEffect(() => {
        const getMerchantASVscanRequests = async () => {
            setLoading(true);
            const res = await GetMerchantASVscanRequests(activeMerchant);
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getMerchantASVscanRequests();
    }, [activeMerchant]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);
        return data;
    }, [page, data]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.pciscans ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.pciscans]);

    const { reminderCount, showReminder } = GetRemaining(pricing_plan?.pci_scan, merchant_asvscans_requests?.length);

    return (
        <>
            <PageHeader browserTitle="PCI Scans | Smartcomply" />
            {showReminder && (
                <SubscriptionAlert isDashboardPage={false} limitedNumber={`${reminderCount} IP Servers`} />
            )}

            <AppTable
                title={t('title')}
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={
                    user_type === 'auditor' || role === 'auditor'
                        ? []
                        : [
                              <AppButton
                                  icon={<Scanner />}
                                  name={t('requestScan')}
                                  color="primary"
                                  variant="contained"
                                  disabled={pricing_plan?.pci_scan === merchant_asvscans_requests?.length}
                                  disableElevation
                                  onClick={openRequestActionModal}
                                  key={1}
                                  ref={(el) => (stepsTargetRef.current[0] = el)}
                              />,
                              <AppButton
                                  icon={<Upload />}
                                  name={t('uploadReport')}
                                  color="primary"
                                  variant="outlined"
                                  disabled={pricing_plan?.pci_scan === merchant_asvscans_requests?.length}
                                  disableElevation
                                  onClick={openUploadPCIReportModal}
                                  key={2}
                                  ref={(el) => (stepsTargetRef.current[1] = el)}
                              />,
                          ]
                }
                search={true}
                onSearch={handleSearch}
            />

            <RequestScanAction
                open={openRequestAction}
                handleClose={closeRequestActionModal}
                handleOpenRequestModal={() => openPCIScanRequestModal('request', requestInitialValues)}
                handleOpenUploadCSVModal={openPCIScanUploadCSVModal}
                className="requestScan"
            />

            <RequestModal
                open={openPCIRequestModal}
                handleClose={closePCIScanRequestModal}
                payload={modalPayload}
                type={modalType}
                id={scanId}
            />
            <AppUploadCSVModal
                open={openUploadCSVModal}
                handleClose={closePCIScanUploadCSVModal}
                title={t('uploadCSVModal.title')}
                subheader={t('uploadCSVModal.subtitle')}
                templateLink="https://smartcomply-prod.s3.us-west-2.amazonaws.com/asv_upload_template.xlsx"
                uploadCSVAction={UploadASVScanCSV}
            />
            <UploadReportModal
                open={openUploadReportModal}
                handleClose={closeUploadPCIReportModal}
                title={t('uploadReport')}
                payload={uploadModalPayload}
            />

            <PCIReportModal
                open={openDowloadReportModal}
                handleClose={closeDownloadPCIReportModal}
                title={t('pciReportModal.title')}
                subtitle={
                    <span>
                        <strong>{t('pciReportModal.subtitle1')}</strong> {t('pciReportModal.subtitle2')}{' '}
                        <strong>{t('pciReportModal.subtitle3')}</strong> {t('pciReportModal.subtitle4')}
                    </span>
                }
                report={report}
                className="uploadReport"
            />

            <PCIDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={deletePayloadID} />

            <RerunScanModal
                open={rerunModalOpen}
                handleClose={closeRerunModal}
                title={t('rerunScanModal.title')}
                subtitle={t('rerunScanModal.subtitle')}
                payload={modalPayload}
            />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="PCI Scans"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_asvscans_requests: state?.merchantReducers?.merchant_asvscans_requests,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.pci_scans,
        // companyName: state?.generalReducers?.user_info?.organization?.name,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps, {
    GetMerchantASVscanRequests,
    UploadASVScanCSV,
})(PCIScans);
