import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CheckCircle } from '@mui/icons-material';
import { AppSubmitButton } from 'components/new_components/forms';
import AppButton from 'components/new_components/AppButton';

//translation
import { useTranslation } from 'react-i18next';

const VendorSectionLayout = (props) => {
    const {
        children,
        questionnaireFormsCount,
        categories,
        page,
        setPage,
        showFooter,
        readonly,
        onNextClick,
        onPrevClick,
        // onPageChange,
        loading,
        responseCount,
    } = props;

    // const progressPercent = (page / questionnaireFormsCount) * 100;

    //translation
    const { t } = useTranslation('vendorManagement');

    return (
        <Box sx={{ display: 'flex', padding: '40px 0 0 75px', height: 'calc(100% - 94px)' }}>
            <Stack sx={{ backgroundColor: '#EEF0FF', width: '27% !important', height: '100%' }}>
                <Box
                    sx={{
                        height: '94px',
                        display: 'flex',
                        padding: '22px 16px 0',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '16px',
                            color: '#919094',
                        }}
                    >
                        {t('categories')}
                    </Typography>
                </Box>
                {categories?.map((category, index) => {
                    return (
                        <Box
                            sx={{
                                height: '58px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '0px 20px',
                                backgroundColor: `${page === index + 1 ? '#fff' : '#EEF0FF'}`,
                            }}
                            onClick={() => (responseCount >= index ? setPage(index + 1) : null)}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    color: `${page === index + 1 ? '#0E2C66' : '#919094'}`,
                                }}
                            >
                                {category}
                            </Typography>
                            {responseCount > index ? (
                                <CheckCircle color={page === index + 1 ? 'success' : 'disabled'} />
                            ) : null}
                        </Box>
                    );
                })}
            </Stack>
            <Stack sx={{ width: '73%' }}>
                <Box
                    sx={{
                        overflowY: 'scroll',
                        marginTop: 0,
                    }}
                >
                    {children}
                </Box>
                {showFooter && (
                    <Box sx={{ padding: '1rem 2rem', backgroundColor: '#F7F8FB' }}>
                        <Stack direction="row" alignItems="center" sx={{ width: '80%' }}>
                            <LoadingButton
                                variant="outlined"
                                // color="primary"
                                onClick={onPrevClick}
                                disabled={page <= 1}
                                textTransform="none"
                                sx={{ fontWeight: 600, fontSize: '16px', mr: '32px', height: '56px' }}
                            >
                                {t('vendorQuestionnaire.previous')}
                            </LoadingButton>
                            {/* <Pagination
                            color="primary"
                            shape="rounded"
                            count={questionnaireFormsCount}
                            page={page}
                            onChange={(_, page) => onPageChange(page)}
                            hideNextButton={true}
                            hidePrevButton={true}
                            size="large"
                            renderItem={(item) => {
                                const isOpened = responseCount >= item.page - 1;
                                return (
                                    <PaginationItem
                                        {...item}
                                        color={isOpened ? 'primary' : 'standard'}
                                        variant={item.selected ? 'text' : 'outlined'}
                                        sx={{ fontSize: '1rem', fontWeight: '600' }}
                                        disabled={!isOpened}
                                    />
                                );
                            }}
                        /> */}
                            {readonly ? (
                                <AppButton
                                    variant="contained"
                                    color="primary"
                                    onClick={onNextClick}
                                    disabled={page >= questionnaireFormsCount}
                                    name={t('vendorQuestionnaire.next')}
                                    sx={{ fontWeight: 600, fontSize: '16px', height: '56px' }}
                                />
                            ) : (
                                <AppSubmitButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    loading={loading.form}
                                    text={
                                        page >= questionnaireFormsCount
                                            ? t('vendorQuestionnaire.save1')
                                            : t('vendorQuestionnaire.save2')
                                    }
                                    sx={{ fontWeight: 600, fontSize: '16px', height: '56px' }}
                                />
                            )}
                        </Stack>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

export default VendorSectionLayout;
