import React from 'react';
//core components
import { List, ListItem, ListItemText, ListItemAvatar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppAgentModal from 'components/new_components/AppAgentModal';
import { ReactComponent as WindowsLogo } from 'assets/img/brand/windows.svg';
import { ReactComponent as LinuxLogo } from 'assets/img/brand/linux.svg';

//translations
import { useTranslation } from 'react-i18next';

const InfrastructuresAgentModal = (props) => {
    //theme
    const theme = useTheme();
    //props
    const { open, handleClose, title, windowsAgentUrl, linuxAgentUrl } = props;

    //translation
    const { t } = useTranslation('inventory');

    return (
        <AppAgentModal open={open} handleClose={handleClose} title={title} width={500}>
            <List>
                <ListItem sx={{ alignItems: 'flex-start' }}>
                    <ListItemAvatar>
                        {/* <Avatar> */}
                        <LinuxLogo />
                        {/* </Avatar> */}
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            fontSize: '16px',
                            color: theme.palette.gray[800],
                            marginLeft: '0.5rem',
                        }}
                    >
                        <Typography
                            color={theme.palette.gray[900]}
                            sx={{
                                fontWeight: 500,
                                fontSize: '18px',
                                marginTop: '-8px',
                            }}
                        >
                            {t('infrastructureAgentModal.downloadLinux')}
                        </Typography>
                        <a
                            href={linuxAgentUrl}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                color: theme.palette.primary[900],
                                textDecoration: 'underline',
                            }}
                        >
                            {t('infrastructureAgentModal.clickHere')}
                        </a>
                    </ListItemText>
                </ListItem>
                <ListItem sx={{ alignItems: 'flex-start', margin: '1.5rem 0' }}>
                    <ListItemAvatar>
                        {/* <Avatar> */}
                        <WindowsLogo />
                        {/* </Avatar> */}
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            fontSize: '16px',
                            color: theme.palette.gray[800],
                            marginLeft: '0.5rem',
                        }}
                    >
                        <Typography
                            color={theme.palette.gray[900]}
                            sx={{ fontWeight: 500, fontSize: '18px', marginTop: '-8px' }}
                        >
                            {t('infrastructureAgentModal.downloadWindows')}
                        </Typography>
                        <a
                            style={{
                                color: theme.palette.primary[900],
                                textDecoration: 'underline',
                                paddingRight: '5px',
                                cursor: 'pointer',
                            }}
                            href={windowsAgentUrl}
                        >
                            {t('infrastructureAgentModal.clickHere')}
                        </a>
                    </ListItemText>
                </ListItem>
            </List>
        </AppAgentModal>
    );
};

export default InfrastructuresAgentModal;
