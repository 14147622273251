export const VALUE_TYPES = [
    { label: 'Absolute Value', key: 'abs_value' },
    { label: 'Upto Value', key: 'upto' },
    { label: 'Check', key: 'check' },
];
export const FEATURE_KEYS = [
    'ciso_report',
    'vendor_mgt',
    'support',
    'trust_report',
    'risk_assessment',
    'audits_collaboration',
    'pentest_scan',
    'pentest_scan_freq',
    'pci_scan',
    'users',
    'automated_pentest',
    'ai_roc',
    'auditor_support',
    'server_monitoring',
    'vulnerability_scan',
    'background_check',
    'automated_scan',
    'pci_scan',
    'pci_scan_freq',
];
export const FEATURE_CATEGORIES = ['Features & content', 'Scan functionality', 'Auditing'];
