import React from 'react';
import { FormHelperText } from '@mui/material';
// formik
import { useFormikContext } from 'formik';
// core components
import AppSelectInput from '../AppSelectInput';

import { formatSentence } from 'utils';

const AppFormSelect = (props) => {
    const { name, multiple, ...restProps } = props;

    // get the formik context values
    const { setFieldTouched, handleChange, touched, values, errors } = useFormikContext();
    const initialValue = multiple ? [] : '';

    const handleSelectChange = (e) => {
        handleChange(name)(e);
        setTimeout(() => setFieldTouched(name), 400);
    };
    const handleBlur = () => {
        if (restProps.options?.includes('Yes')) {
            return null;
        } else {
            setFieldTouched(name);
        }
    };

    return (
        <>
            <AppSelectInput
                value={values[name] || initialValue}
                onChange={handleSelectChange}
                onBlur={handleBlur}
                error={touched[name] && errors[name]}
                multiple={multiple}
                {...restProps}
            />
            {touched[name] && <FormHelperText error>{formatSentence(errors[name])}</FormHelperText>}
        </>
    );
};

export default AppFormSelect;
