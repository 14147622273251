import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useState } from 'react';
import ScanViewModal from './Modals/ScanViewModal';
import AssignmentAdd from '../../../../assets/img/AssignmentAdd.svg';
import AssignTaskModal from './Modals/AssignTaskModal';
import { connect } from 'react-redux';

const ScanViewCategory = (props) => {
    const { sub, isScan, category } = props;
    const theme = useTheme();
    const [openExemptModal, setOpenExemptModal] = useState(false);
    const [assignTask, setAssignTask] = useState(false);
    const [data, setData] = useState({});

    const openViewModal = () => {
        setOpenExemptModal(true);
        setData(sub);
    };
    const closeViewModal = () => {
        setOpenExemptModal(false);
        setTimeout(() => setData({}));
    };
    const find_failled_scan = Object?.keys(sub?.accounts || sub?.account)?.find((item) =>
        sub?.accounts ? sub?.accounts[item] === 'failed' : null
    );
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: '1px solid #E1E2EC',
                    borderRadius: '5px',
                    px: 1.5,
                    py: 1,
                    mb: 2,
                    cursor: 'pointer',
                }}
                className="parent_card"
                onClick={openViewModal}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: theme.palette.gray[30],
                            textTransform: 'capitalize',
                        }}
                    >
                        {sub?.name?.replace(/_/g, ' ')}
                    </Typography>
                    {sub?.accounts ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <Typography sx={{ fontSize: '10px', fontWeight: 500, color: theme.palette.neutral[50] }}>
                                Avaliable Instances:
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    ml: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        textAlign: 'left',
                                        flexWrap: 'wrap',
                                        ml: 1,
                                    }}
                                >
                                    {Object?.keys(sub?.accounts)?.map((scan, index) => {
                                        return scan === 'scan_time' ? null : (
                                            <ScanStatus key={index} type={sub?.accounts[scan]} name={scan} />
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <Typography sx={{ fontSize: '10px', fontWeight: 500, color: theme.palette.neutral[50] }}>
                                Avaliable Instances:
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    textAlign: 'left',

                                    ml: 1,
                                }}
                            >
                                {Object?.keys(sub?.account)?.map((scan, index) => (
                                    <ScanStatus key={index} type={'failed'} name={scan} />
                                ))}
                            </Box>
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {sub?.accounts && sub.is_assigned_to_current_user && (
                        <Typography
                            sx={{
                                backgroundColor: '#D2A500',
                                color: '#FFFFFF',
                                fontWeight: 900,
                                fontSize: '9px',
                                float: 'right',
                                px: 1,
                                py: 0.5,
                                borderRadius: '2px',
                                mr: 2,
                            }}
                        >
                            Assigned to you
                        </Typography>
                    )}
                    {sub?.accounts ? (
                        <ScanStatus
                            type={find_failled_scan ? 'failed' : 'passed'}
                            name={find_failled_scan ? 'Needs attention' : 'Looks okay'}
                        />
                    ) : null}

                    {find_failled_scan &&
                        !category?.is_assigned_to_current_user &&
                        !sub?.is_assigned_to_current_user && (
                            <Box
                                className="second-child_"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenExemptModal(false);
                                    setAssignTask(true);
                                }}
                            >
                                <img src={AssignmentAdd} alt="AssignmentAdd" />
                            </Box>
                        )}
                </Box>
            </Box>

            <ScanViewModal
                open={openExemptModal}
                handleClose={closeViewModal}
                scan_details={data}
                setOpen={setOpenExemptModal}
                sub={sub}
                isScan={isScan}
                category={category}
            />
            <AssignTaskModal
                open={assignTask}
                onClose={() => setAssignTask(false)}
                type="Scan Sub-constraint"
                sub_category={sub}
                category={category}
            />
        </>
    );
};

const ScanStatus = ({ type, name }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                borderRadius: '5px',
                px: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '10px',
                mr: 1,
            }}
        >
            <span
                style={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '50%',
                    marginRight: '8px',
                    backgroundColor:
                        type === 'passed' || type === 'success' ? theme.palette.success[300] : theme.palette.error[500],
                    display: 'inline-block',
                    fontSize: '12px',
                }}
            ></span>
            <Typography sx={{ letterSpacing: '1.2px', fontSize: '10px' }}>{name}</Typography>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.automatedScansReducers?.all_assigned_task,
        user: state?.generalReducers?.user_info,
    };
};

export default connect(mapStateToProps, {})(ScanViewCategory);
