// import { AccessTime } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import AppButton from 'components/new_components/AppButton';
import LoadingState from 'components/new_components/LoadingState';
import React from 'react';
import VideoPlayer from './VideoPlayer';
import { Link } from 'react-router-dom';

//translations
import { useTranslation } from 'react-i18next';

const VideoView = (props) => {
    const { course, currentVideo, disabledCert, loading, onVideoWatched, goToCertificate, readOnly } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('training');

    return (
        <Box sx={{ flex: 1 }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: theme.palette.white.main, py: 1.8, pl: 5, pr: 10 }}
            >
                <Box>
                    <Typography sx={{ fontWeight: 700, fontSize: 18, color: theme.palette.primary[900] }}>
                        {t('videoView.course')} {course?.title}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ fontSize: 12, fontWeight: 500, color: theme.palette.primary[900] }}>
                            {t('videoView.part')} {currentVideo?.title}
                        </Typography>
                        {/* <Chip
                            label="4 minutes"
                            variant="outlined"
                            icon={<AccessTime sx={{ fontSize: 14 }} />}
                            sx={{ py: 0.25, px: 0.5, fontSize: 10, height: 'unset' }}
                        /> */}
                    </Box>
                </Box>
                {readOnly ? (
                    <Box
                        component={Link}
                        to="/merchant/training"
                        sx={{ color: theme.palette.primary[900], fontWeight: 700, fontSize: 16 }}
                    >
                        {t('videoView.back')}
                    </Box>
                ) : (
                    <>
                        <AppButton
                            name={t('videoView.view')}
                            color="primary"
                            variant="contained"
                            disabled={disabledCert}
                            onClick={goToCertificate}
                        />
                    </>
                )}
            </Stack>
            <Stack sx={{ py: 3.4, pl: 5, pr: 10, gap: 5 }}>
                {loading ? (
                    <Box>
                        <LoadingState />
                    </Box>
                ) : (
                    <>
                        <VideoPlayer src={currentVideo?.file} onVideoEnded={onVideoWatched} />
                        <Box>
                            <Typography
                                sx={{
                                    borderBottom: `4px solid ${theme.palette.primary[900]}`,
                                    pb: 1,
                                    width: 'fit-content',
                                    color: theme.palette.gray[40],
                                    fontWeight: 700,
                                }}
                            >
                                {t('videoView.description')}
                            </Typography>
                            <Typography sx={{ fontSize: 14, fontWeight: 500, mt: 3, color: theme.palette.gray[40] }}>
                                {currentVideo?.description}
                            </Typography>
                        </Box>
                    </>
                )}
            </Stack>
        </Box>
    );
};

export default VideoView;
