import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import GroupCard from './GroupCard';
import { connect } from 'react-redux';

//translation
import { useTranslation } from 'react-i18next';

const CategoryCard = (props) => {
    const {
        metric,
        resourceLabel,
        onViewChange,
        id,
        title,
        description,
        subcriteria,
        exception,
        all_criteria_summary,
    } = props;

    //translation
    const { t } = useTranslation('compliance');

    const filteredSummary = all_criteria_summary?.filter((summary) => summary?.criteria === id);

    const subcriteriaIsValid = (summary) => {
        return summary?.total > 0 || summary?.tools?.length > 0;
    };
    const criteriaIsValid = useMemo(() => {
        return filteredSummary?.some((sub) => subcriteriaIsValid(sub));
    }, [filteredSummary]);

    // functions
    const handleCardClick = () => {
        if (criteriaIsValid || exception) onViewChange(id);
    };

    return (
        <GroupCard
            percentCompleted={
                !metric?.totalValue && !metric?.totalAttendedTo
                    ? 100
                    : (metric?.totalAttendedTo / metric?.totalValue) * 100
            }
            totalValue={metric?.totalValue}
            totalAttendedTo={metric?.totalAttendedTo}
            resourceLabel={resourceLabel}
            onCardClick={handleCardClick}
            empty={criteriaIsValid || exception ? false : true}
            subcriteria={subcriteria}
            exception={exception}
            criteria={id}
            all_criteria_summary={all_criteria_summary}
        >
            <Typography variant="h3" component="h3">
                {title}
            </Typography>
            <Typography
                sx={{
                    alignSelf: 'flex-start',
                    fontSize: 12,
                    fontWeight: 500,
                }}
            >
                <strong>{t('blockchain.applicableArea')}</strong>
                {description}
            </Typography>
        </GroupCard>
    );
};

const mapStateToProps = (state) => {
    return {
        all_criteria_summary: state?.complianceReducers?.all_criteria_summary,
    };
};

export default connect(mapStateToProps, {})(CategoryCard);
