import { ArrowForward } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Brand from 'components/new_components/Brand';
import React, { useMemo, useState } from 'react';
import { ReactComponent as TrainingIcon } from 'assets/img/icons/training.svg';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

// redux
import { connect } from 'react-redux';
import { PREVIEW_COURSE } from '../utils/data';

//translations
import { useTranslation } from 'react-i18next';

const TrainingIntro = (props) => {
    const { goToTraining, one_trainee, training, readOnly } = props;
    const theme = useTheme();

    // state
    const [proceeding, setProceeding] = useState(false);

    //translation
    const { t } = useTranslation('training');

    // memos
    const course = useMemo(() => (readOnly ? PREVIEW_COURSE : training?.course_info), [training, readOnly]);

    // functions
    const proceedToTraining = async () => {
        setProceeding(true);
        await goToTraining();
        setProceeding(false);
    };

    return (
        <Box>
            <Box
                sx={{
                    width: 46,
                    height: '100vh',
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    '&::after, &::before': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        backgroundColor: theme.palette.primary[900],
                        width: '100%',
                        height: '30%',
                    },
                    '&::after': {
                        top: 0,
                    },
                    '&::before': {
                        bottom: 0,
                    },
                }}
            />
            <Box sx={{ pl: 10 }}>
                <Box component="header">
                    <Brand type="blue" />
                    <Typography sx={{ fontWeight: 700, fontSize: 16, color: theme.palette.primary[900], ml: 3, mt: 5 }}>
                        {t('trainingIntro.hello')},{' '}
                        {readOnly
                            ? t('trainingIntro.employeeName')
                            : `${one_trainee?.first_name} ${one_trainee?.last_name}`}
                    </Typography>
                </Box>
                <Stack direction="row" sx={{ pt: 14, mx: 3, color: theme.palette.gray[40] }}>
                    <Box sx={{ flex: 0.55 }}>
                        <Typography sx={{ fontWeight: 500, fontSize: 16, color: 'inherit' }}>
                            {t('trainingIntro.trainingIn')}
                        </Typography>
                        <Typography sx={{ fontWeight: 700, fontSize: 40, color: 'inherit' }}>
                            {course?.title}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 16, color: 'inherit', my: 5 }}>
                            {course?.description}
                        </Typography>
                        <AppLoadingButton
                            text={
                                <>
                                    <span>{t('trainingIntro.proceed')}</span>
                                    <ArrowForward />
                                </>
                            }
                            color="primary"
                            variant="contained"
                            sx={{ minWidth: 390, py: 1.5 }}
                            onClick={proceedToTraining}
                            loading={proceeding}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            flex: 0.45,
                            alignSelf: 'flex-end',
                        }}
                    >
                        <TrainingIcon />
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};
const mapStateToProps = (state) => ({
    one_trainee: state?.trainingReducers?.one_trainee,
});
export default connect(mapStateToProps, {})(TrainingIntro);
