import React, { useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//redux
import { connect } from 'react-redux';
import { GetAllTasksByStandard } from 'store/actions/taskActions';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import { UpdateTask } from 'store/actions/taskActions';
import SouthIcon from '@mui/icons-material/South';
import EastIcon from '@mui/icons-material/East';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { GetDocumentsByControl } from 'store/actions/adminActions';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';
import UploadDocumentModal from './UploadDocumentModal';
import PolicyDocument from './PolicyDocumentItem';
import { GetEvidencesByControl, GetEvidencesBySubcontrol } from 'store/actions/adminActions';
import Evidences from './Evidences';
import UploadEvidenceModal from './UploadEvidenceModal';
import PolicyHistoryModal from './PolicyHistoryModal';
import { getResourceDetailsByStandard } from '../utils';
import EvidenceHistoryModal from './EvidenceHistoryModal';

const DataRoomModal = (props) => {
    //props
    const {
        open,
        handleClose,
        all_requirements,
        all_metrics,
        GetDocumentsByControl,
        GetEvidencesByControl,
        documents_by_control,
        standard,
        evidences_by_control,
        all_evidences_metrics,
        evidence_type,
        all_merchant_documents,
        all_merchant_evidences,
    } = props;
    // state
    const [type, setType] = useState('policies');
    const [segment, setSegment] = useState('');
    const [segmentNumber, setSegmentNumber] = useState();
    const [active, setActive] = useState();
    const [doc_id, setDoc_id] = useState();
    const [control, setControl] = useState();
    const [loading, setLoading] = useState(false);
    const [policyHistoryModal, setPolicyHistoryModal] = useState(false);
    const [evidenceHistoryModal, setEvidenceHistoryModal] = useState(false);
    const [uploadDocumentModal, setUploadDocuementModal] = useState(false);
    const [uploadEvidenceModal, setUploadEvidenceModal] = useState(false);
    const [evidence, setEvidence] = useState(null);

    const onClose = () => {
        setActive();
        handleClose();
        setType('policies');
    };

    // mui
    const theme = useTheme();

    const openHistoryModal = () => {
        setPolicyHistoryModal(true);
    };

    const closeHistoryModal = () => {
        setPolicyHistoryModal(false);
    };

    const openEvidenceHistoryModal = () => {
        setEvidenceHistoryModal(true);
    };

    const closeEvidenceHistoryModal = () => {
        setEvidenceHistoryModal(false);
    };

    const openUploadModal = () => {
        setUploadDocuementModal(true);
    };

    const closeUploadModal = () => {
        setUploadDocuementModal(false);
    };

    const openEvidenceModal = (item, number) => {
        setSegment(item);
        setSegmentNumber(number);
        setUploadEvidenceModal(true);
    };

    const closeEvidenceModal = () => {
        setUploadEvidenceModal(false);
    };

    // async functions
    const getControlDocuments = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const res = await GetDocumentsByControl(subcontrolId);
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    const getControlEvidences = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const res = await GetEvidencesByControl(subcontrolId);
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.document_id === doc_id);
    }, [doc_id, all_merchant_documents]);

    const selectedEvidence = useMemo(() => {
        return all_merchant_evidences?.find((doc) => doc?.evidence_id === doc_id);
    }, [doc_id, all_merchant_evidences]);
    return (
        <AppViewModal open={open} handleClose={onClose} width={520}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2, alignItems: 'center' }}>
                    <ArrowBackIosIcon
                        onClick={onClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        Data Room
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: '7rem', mt: 4 }}>
                    <Box>
                        <Typography
                            sx={{
                                color: type === 'policies' ? '#202D66' : '#94A3B8',
                                fontSize: '14px',
                                fontWeight: 500,
                                textAlign: 'right',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setActive();
                                setType('policies');
                            }}
                        >
                            Policies
                        </Typography>
                        {type === 'policies' && (
                            <Box
                                sx={{
                                    backgroundColor: '#202D66',
                                    width: '100%',
                                    pt: 0.5,
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                }}
                            ></Box>
                        )}
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: type === 'evidences' ? '#202D66' : '#94A3B8',
                                fontSize: '14px',
                                fontWeight: 500,
                                textAlign: 'left',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setActive();
                                setType('evidences');
                            }}
                        >
                            Evidences
                        </Typography>
                        {type === 'evidences' && (
                            <Box
                                sx={{
                                    backgroundColor: '#202D66',
                                    width: '100%',
                                    pt: 0.5,
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                }}
                            ></Box>
                        )}
                    </Box>
                </Box>
                <Divider sx={{ mx: '-3.5rem' }} />
                <Box sx={{ mt: 4 }}>
                    {type === 'policies' ? (
                        <Box>
                            {all_requirements?.map((requirement) => {
                                const { total_doc, user_doc } = all_metrics?.[requirement?.title] || {};
                                const metric = { totalValue: total_doc, totalAttendedTo: user_doc };
                                console.log({ metric, requirement });
                                return (
                                    <Box key={requirement?.id}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                px: 2,
                                                backgroundColor: active === requirement?.id ? '#F1F5F9' : '',
                                                borderTop: '1px solid #F1F5F9',
                                                borderBottom: '1px solid #F1F5F9',
                                                mb: 2,
                                                py: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => getControlDocuments(requirement?.id)}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {active === requirement?.id ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <SouthIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <EastIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                <Typography
                                                    sx={{ color: '#475569', fontSize: '15px', fontWeight: 600, ml: 2 }}
                                                >
                                                    {requirement?.title}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: '6px',
                                                        height: '6px',
                                                        color:
                                                            metric?.totalAttendedTo === 0 && metric?.totalValue > 0
                                                                ? ''
                                                                : metric?.totalAttendedTo > 0 &&
                                                                  metric?.totalAttendedTo < metric?.totalValue
                                                                ? '#D2A500'
                                                                : '#55BE8B',
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        color: metric?.totalAttendedTo === 0 ? '#77777A' : '#64748B',
                                                        ml: 0.5,
                                                    }}
                                                >
                                                    {metric?.totalAttendedTo > 0 &&
                                                    metric?.totalAttendedTo < metric?.totalValue
                                                        ? 'Incomplete Uploads'
                                                        : metric?.totalAttendedTo === 0 && metric?.totalValue > 0
                                                        ? 'No uploads yet'
                                                        : 'Completed'}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {active === requirement?.id && (
                                            <Box sx={{ ml: 4, mr: 2, mb: 2 }}>
                                                {loading ? (
                                                    <LoadingState />
                                                ) : (
                                                    <>
                                                        {documents_by_control?.length > 0 ? (
                                                            <Box>
                                                                {documents_by_control?.map((control) => (
                                                                    <PolicyDocument
                                                                        key={control?.id}
                                                                        control={control}
                                                                        setDoc_id={setDoc_id}
                                                                        openHistoryModal={openHistoryModal}
                                                                        standard={standard}
                                                                        closeHistoryModal={closeHistoryModal}
                                                                        policyHistoryModal={policyHistoryModal}
                                                                        openUploadModal={openUploadModal}
                                                                        doc_id={doc_id}
                                                                        getControlDocuments={getControlDocuments}
                                                                        subcontrolId={requirement?.id}
                                                                    />
                                                                ))}
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: '#475569',
                                                                    fontSize: '15px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <Typography>No Policy Document Found</Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : null}

                    {type === 'evidences' ? (
                        <Box>
                            {all_requirements?.map((requirement) => {
                                const { total_evidences, user_evidences } =
                                    all_evidences_metrics?.[requirement?.title] || {};
                                const metric = { totalValue: total_evidences, totalAttendedTo: user_evidences };
                                return (
                                    <Box key={requirement?.id}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                px: 2,
                                                backgroundColor: active === requirement?.id ? '#F1F5F9' : '',
                                                borderTop: '1px solid #F1F5F9',
                                                borderBottom: '1px solid #F1F5F9',
                                                mb: 2,
                                                py: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => getControlEvidences(requirement?.id)}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {active === requirement?.id ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <SouthIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <EastIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                <Typography
                                                    sx={{ color: '#475569', fontSize: '15px', fontWeight: 600, ml: 2 }}
                                                >
                                                    {requirement?.title}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: '6px',
                                                        height: '6px',
                                                        color:
                                                            metric?.totalAttendedTo === 0
                                                                ? '#C7C6CA'
                                                                : metric?.totalAttendedTo < metric?.totalValue
                                                                ? '#D2A500'
                                                                : '#55BE8B',
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        color: metric?.totalAttendedTo === 0 ? '#77777A' : '#64748B',
                                                        ml: 0.5,
                                                    }}
                                                >
                                                    {metric?.totalAttendedTo === 0
                                                        ? 'No uploads yet'
                                                        : metric?.totalAttendedTo < metric?.totalValue
                                                        ? 'Not completed'
                                                        : 'OK'}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {active === requirement?.id && (
                                            <Box sx={{ ml: 4, mr: 2, mb: 2 }}>
                                                {loading ? (
                                                    <LoadingState />
                                                ) : (
                                                    <>
                                                        {evidences_by_control?.length > 0 ? (
                                                            <Box>
                                                                {evidences_by_control.map((control) => (
                                                                    <Evidences
                                                                        key={control?.id}
                                                                        control={control}
                                                                        setDoc_id={setDoc_id}
                                                                        openEvidenceHistoryModal={
                                                                            openEvidenceHistoryModal
                                                                        }
                                                                        closeEvidenceHistoryModal={
                                                                            closeEvidenceHistoryModal
                                                                        }
                                                                        evidenceHistoryModal={evidenceHistoryModal}
                                                                        standard={standard}
                                                                        doc_id={doc_id}
                                                                        req_id={requirement}
                                                                        setControl={setControl}
                                                                        evidence_type={evidence_type}
                                                                        selectedEvidence={selectedEvidence}
                                                                        setControlModal={setEvidence}
                                                                    />
                                                                ))}
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: '#475569',
                                                                    fontSize: '15px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <Typography>No Evidence Required</Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <UploadDocumentModal
                open={uploadDocumentModal}
                handleClose={closeUploadModal}
                doc_id={doc_id}
                standard={standard}
            />

            <UploadEvidenceModal
                open={uploadEvidenceModal}
                handleClose={closeEvidenceModal}
                doc_id={doc_id}
                standard={standard}
                control={control?.id}
                evidence_type={evidence_type}
                segment={segment}
                segmentNumber={segmentNumber}
            />

            <PolicyHistoryModal
                open={policyHistoryModal}
                handleClose={closeHistoryModal}
                selected={selectedDocument}
                document={getResourceDetailsByStandard(selectedDocument, standard)}
                doc_id={doc_id}
            />
            <EvidenceHistoryModal
                open={evidenceHistoryModal}
                handleClose={closeEvidenceHistoryModal}
                selected={selectedEvidence}
                doc_id={doc_id}
                evidence_type={evidence_type}
                evidence={evidence}
                openEvidenceModal={openEvidenceModal}
                control={control?.id}
            />
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
        documents_by_control: state?.adminReducers?.all_admin_docs_by_common_control,
        evidences_by_control: state?.adminReducers?.all_admin_evidence_by_common_control,
        merchant_documents: state?.complianceReducers?.all_merchant_documents,
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
    };
};
export default connect(mapStateToProps, {
    GetAllTasksByStandard,
    UpdateTask,
    GetDocumentsByControl,
    GetEvidencesByControl,
    GetEvidencesBySubcontrol,
})(DataRoomModal);
