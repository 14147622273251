import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

const AutomatedScanCategoryCard = ({ category, passed, failed }) => {
    return (
        <Box
            sx={{
                width: '100%',
                // my: 1,
                border: '0.5px solid #0E2C66',
                p: 1.5,
                borderRadius: '8px',
                height: '100%',
                maxHeight: 75,
            }}
        >
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#0E2C66',
                    mb: 1,
                    textTransform: 'capitalize',
                }}
            >
                {category}
            </Typography>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '38%' }}>
                <Typography
                    sx={{
                        color: '#058F5D',
                        py: 0.5,
                        px: 1,
                        fontSize: '10px',
                        fontWeight: 700,
                        maxWidth: '86px',
                    }}
                >
                    Passed <span className="pl-[4px]">({passed})</span>
                </Typography>
                <Typography
                    sx={{
                        color: '#BA1A1A',
                        py: 0.5,
                        px: 1.5,
                        fontSize: '10px',
                        fontWeight: 700,
                        maxWidth: '88px',
                    }}
                >
                    Failed <span className="pl-[6px]">({failed})</span>
                </Typography>
            </Stack>
        </Box>
    );
};

export default AutomatedScanCategoryCard;
