export const initialInfrastructureValues = {
    location: '',
    department: '',
    sensitivity: '',
    ip_type: '',
    ip_address: '',
    asset_number: '',
};

export const sensitivityOptions = [
    {
        name: 'Low',
        value: 'low',
    },
    {
        name: 'Medium',
        value: 'medium',
    },
    {
        name: 'Critical',
        value: 'critical',
    },
];

export const infrastructureTypesOption = [
    {
        name: 'Local',
        value: 'local',
    },
    {
        name: 'Public',
        value: 'public',
    },
];
