import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Badge, Box, Card, Container, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import OutlinedLayout from 'components/new_components/tabs/OutlinedLayout';
import Welcome from './Welcome';
import Overview from './Overview/Overview';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Ploygon from '../../../assets/img/Polygon.svg';
import SettingsIcon from '../../../assets/img/icons/SettingsIcon.svg';
import AboutFraudModal from './components/AboutFraudViewModal';
import ApiSettings from './ApiSettings';
import { connect } from 'react-redux';
import { RequestFraudApi, GetAllApprovedUser, UpdateApprovedUser } from 'store/actions/FraudGauardianActions';
import { toast } from 'react-toastify';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import PageHeader from 'components/new_components/PageHeader';

//Userguide
// import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { useSelector } from 'react-redux';

const index = (props) => {
    const { RequestFraudApi, GetAllApprovedUser, all_approved_user, UpdateApprovedUser } = props;
    const theme = useTheme();
    const [indexValue, setIndexValue] = useState(1);
    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState('overview');
    // const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    // const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.pentest);
    const all_article_header = useSelector((state) => state?.articlesReducers?.all_article_header);

    const {
        // updateOneUserGuide,
        toggleUserGuide,
        setToggleUserGuide,
    } = useContext(UserGuideContext);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Fraud Guardian'),
        [all_article_header]
    );

    //UserGuide
    // const OpenUserGuide = () => {
    //     setOpenUserGuide(true);
    // };
    // const CloseUserGuide = () => {
    //     updateOneUserGuide({ pentest: true });
    //     setOpenUserGuide(false);
    // };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, fraud_guardian: false });
        setOpenArticle(false);
    };

    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const openModal = () => {
        setOpen(true);
        closeMenu();
    };
    const CloseModal = () => {
        setOpen(false);
    };

    const onChangeTab = async (val) => {
        setIndexValue(val);
    };
    const tabList = [
        {
            id: 1,
            name: page === 'welcome' ? 'Welcome' : page === 'token' ? 'Api settings' : 'Dashboard Overview',
        },
    ];
    const handlePagechange = (page_) => {
        setPage(page_);
        closeMenu();
    };
    useEffect(() => {
        const getApprovedUser = async () => {
            const res = await GetAllApprovedUser();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getApprovedUser();
    }, []);

    const handleRequest = async () => {
        setLoading(true);
        const res = await RequestFraudApi();
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const handleNotificationView = async () => {
        const body = {
            passkey: true,
        };
        const res = await UpdateApprovedUser(all_approved_user?.id, body);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (all_approved_user?.created_status === false) {
            setPage('welcome');
        }
    }, [all_approved_user?.created_status]);

    useEffect(() => {
        // !userguide_info ? OpenUserGuide() :
        toggleUserGuide?.fraud_guardian ? OpenArticle() : null;
    }, [
        // userguide_info
        toggleUserGuide?.fraud_guardian,
    ]);

    return (
        <>
            <PageHeader browserTitle="Fraud Guardian | Smartcomply" />
            <Container
                component={Card}
                maxWidth={false}
                elevation={0}
                sx={{
                    p: '0 !important',
                    minHeight: '100%',
                }}
            >
                <OutlinedLayout
                    tabList={tabList}
                    indexValue={indexValue}
                    onChangeTab={onChangeTab}
                    all_approved_user={all_approved_user}
                    handlePagechange={handlePagechange}
                    border
                    tab_more={
                        <Box sx={{ display: 'flex' }}>
                            {all_approved_user?.enabled && page !== 'token' && (
                                <Badge
                                    variant="dot"
                                    invisible={all_approved_user?.created_status}
                                    color="error"
                                    onClick={() => handlePagechange('token')}
                                >
                                    <img
                                        src={SettingsIcon}
                                        alt="SettingsIcon"
                                        style={{
                                            color: theme.palette.neutral[50],
                                            cursor: 'pointer',
                                            positon: 'relative',
                                        }}
                                    />
                                </Badge>
                            )}
                            <Box sx={{ border: '1px solid #E1E2EC', ml: 1, borderRadius: '5px' }}>
                                <MoreHorizIcon
                                    sx={{ color: theme.palette.neutral[50], cursor: 'pointer' }}
                                    onClick={openMenu}
                                />
                                <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                                    <MenuItem
                                        sx={{ fontSize: '13px', fontWeight: 500, color: theme.palette.neutral.main }}
                                    >
                                        <a
                                            href="https://documenter.getpostman.com/view/24557562/2s9YJdVMe8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Read Documentation
                                        </a>
                                    </MenuItem>
                                    <MenuItem
                                        sx={{ fontSize: '13px', fontWeight: 500, color: theme.palette.neutral.main }}
                                        onClick={() => handlePagechange('token')}
                                        disabled={all_approved_user?.passkey && page !== 'token' ? false : true}
                                    >
                                        My Token
                                    </MenuItem>
                                    <MenuItem
                                        sx={{ fontSize: '13px', fontWeight: 500, color: theme.palette.neutral.main }}
                                        onClick={openModal}
                                        disabled={page === 'welcome'}
                                    >
                                        About Fraud Guardian
                                    </MenuItem>
                                </Menu>
                            </Box>
                            {all_approved_user?.enabled && !all_approved_user?.passkey && (
                                <Card
                                    variant="outlined"
                                    sx={{
                                        position: 'absolute',
                                        top: '11%',
                                        right: '5.5%',
                                        width: '360px',
                                        height: '200px',
                                        p: 2,
                                        zIndex: 1000,
                                        boxShadow: '2px 4px 8px 0px #0000001A',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            color: theme.palette.gray[30],
                                            borderBottom: '1px solid #F0F0FA',
                                            pb: 0.5,
                                            mb: 3,
                                        }}
                                    >
                                        {' '}
                                        API Token ready
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            color: theme.palette.gray[30],
                                            pb: 0.5,
                                            mb: 3,
                                        }}
                                    >
                                        You can access your Fraud guardian API token here. If necessary, the token can
                                        also be regenerated.
                                    </Typography>
                                    <AppLoadingButton
                                        text="Ok, got it"
                                        loading={loading}
                                        sx={{ textTransform: 'inherit', float: 'right' }}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNotificationView}
                                    />
                                </Card>
                            )}
                            {all_approved_user?.enabled && !all_approved_user?.passkey && (
                                <img
                                    src={Ploygon}
                                    alt="Ploygon"
                                    style={{ position: 'absolute', top: '9%', right: '5.5%', width: '50px' }}
                                />
                            )}
                        </Box>
                    }
                />
                {indexValue === 1 && (
                    <div className="px-[1rem] mt-5">
                        {page === 'welcome' ? (
                            <Welcome
                                loading={loading}
                                handleRequest={handleRequest}
                                all_approved_user={all_approved_user}
                            />
                        ) : page === 'token' ? (
                            <ApiSettings handlePagechange={handlePagechange} />
                        ) : (
                            <Overview />
                        )}
                    </div>
                )}
                <AboutFraudModal open={open} handleClose={CloseModal} handlePagechange={handlePagechange} />
            </Container>

            {/* <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} targets={steps.target} /> */}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Fraud Guardian"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_approved_user: state?.fraudGuardianReducers?.all_approved_user,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps, { RequestFraudApi, GetAllApprovedUser, UpdateApprovedUser })(index);
