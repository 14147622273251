import React, { useState, useMemo, useEffect } from 'react';
import LoadingState from 'components/new_components/LoadingState';

// redux
import { connect } from 'react-redux';
import QuestionItem from '../../common/audit_questions/QuestionItem';
import DescriptionLayer from '../../common/DescriptionLayer';
import DocumentItem from '../../common/document/DocumentItem';
import SubgroupView from '../../common/SubgroupView';

//translations
import { useTranslation } from 'react-i18next';

const ResourceView = (props) => {
    // props
    const {
        goBack,
        resource,
        isogroupID,
        all_isogroups,
        all_isogroup_metrics,
        isogroupResources,
        merchantResources,
        loading,
        setFormPayload,
        touchedFields,
        submitting,
        handleResourceExempted,
        openTaskAssignModal,
        // all_compliance_assigned_tasks,
        assigned_task_documents_info,
        assigned_task_questions_info,
    } = props;

    // state
    const [page, setPage] = useState(1);
    const [overallMetric, setOverallMetric] = useState('');
    const [isogroupPercent, setIsogroupPercent] = useState(0);

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    // memos
    // get the selected isogroup by the given isogroup id
    const selectedIsogroup = useMemo(() => {
        return all_isogroups?.find((isogroup) => isogroup?.id === isogroupID);
    }, [isogroupID, page]);
    // get the status of te resource in category
    const resourcesHaveBeenAttendedTo = useMemo(() => {
        return isogroupResources?.map((isogroupRes) => {
            return merchantResources?.some((merchRes) =>
                resource === 'doc' ? merchRes?.document_id === isogroupRes?.id : merchRes?.form_id === isogroupRes?.id
            );
        });
    }, [isogroupResources, merchantResources]);
    // get the resource for the current page
    const selectedResource = useMemo(() => {
        return isogroupResources?.[page - 1];
    }, [isogroupResources, page]);

    // logic functions
    const goToResource = (page) => {
        if (page > isogroupResources?.length) {
            setPage(isogroupResources?.length);
        } else if (page < 1) {
            setPage(1);
        } else {
            setPage(page);
        }
    };
    const onResourceExempted = () => {
        handleResourceExempted(selectedResource?.annex);
    };

    // useeffect
    // whenever the selected resource chnages and the current one is undefined, then go back.
    useEffect(() => {
        if (!selectedResource) {
            if (isogroupResources) {
                return goToResource(isogroupResources?.length);
            }
            goBack();
        }
    }, [selectedResource]);
    // to calculate the percentage and mtrics for each category
    useEffect(() => {
        const { total_form, total_doc, user_form, user_doc } = all_isogroup_metrics[selectedIsogroup?.title];
        let percentage, metric;
        if (resource === 'doc') {
            metric = `${user_doc}/${total_doc} ${t('documentsUploaded')}`;
            percentage = (user_doc / total_doc) * 100;
        } else {
            metric = `${user_form}/${total_form} ${t('questionsAnswered')}`;
            percentage = (user_form / total_form) * 100;
        }
        setOverallMetric(metric);
        setIsogroupPercent(percentage);
    }, [all_isogroup_metrics, selectedIsogroup]);
    // to set the current question's answer to the appform initial value.
    useEffect(() => {
        if (resource === 'form') {
            // get the admin resource for that page
            const adminResource = selectedResource;
            // get the merchant resource corresponding to that page
            const merchantResource = merchantResources
                ?.sort((a, b) => b?.id - a?.id)
                ?.find((resource) => resource?.form_id === adminResource?.id);
            // set the answers
            let initialValues = {},
                requiredValues = {};
            adminResource?.form_fields?.forEach((field) => {
                initialValues[field?.id] = merchantResource?.answer?.[field?.id] || '';
                requiredValues[field?.id] = field?.required;
            });
            setFormPayload({ values: initialValues, form_id: adminResource?.id, requiredValues });
        }
    }, [page, merchantResources, selectedResource]);

    return (
        <SubgroupView
            goBack={goBack}
            title={selectedIsogroup?.title}
            groupMetric={overallMetric}
            goToNextResource={goToResource}
            currentResourcePage={page}
            data={resourcesHaveBeenAttendedTo}
            resource={resource}
            touchedFields={touchedFields}
            submitting={submitting}
            categoryResources={isogroupResources}
            assigned_task_documents_info={assigned_task_documents_info}
            assigned_task_questions_info={assigned_task_questions_info}
        >
            {loading ? (
                <LoadingState />
            ) : (
                <>
                    <DescriptionLayer percentCompletion={isogroupPercent} description="" standard="iso27001" />
                    {resource === 'doc' ? (
                        <DocumentItem
                            document={selectedResource}
                            title_key={
                                language?.includes('fr')
                                    ? 'iso_title_fr'
                                    : language?.includes('en')
                                    ? 'iso_title_en'
                                    : 'iso_title'
                            }
                            standard="iso27001"
                            onResourceChange={onResourceExempted}
                            resource="doc"
                            openTaskAssignModal={openTaskAssignModal}
                            taskInfo={{
                                annex: selectedIsogroup?.id,
                            }}
                        />
                    ) : (
                        <QuestionItem
                            form={selectedResource}
                            title={
                                language?.includes('fr')
                                    ? 'iso_title_fr'
                                    : language?.includes('en')
                                    ? 'iso_title_en'
                                    : 'iso_title'
                            }
                            standard="iso27001"
                            onResourceChange={onResourceExempted}
                            resource="form"
                            openTaskAssignModal={openTaskAssignModal}
                            taskInfo={{
                                annex: selectedIsogroup?.id,
                            }}
                            category={selectedIsogroup}
                        />
                    )}
                </>
            )}
        </SubgroupView>
    );
};

const mapStateToProps = (state) => {
    return {
        all_isogroups: state?.adminReducers?.all_isogroups,
        all_isogroup_metrics: state?.complianceReducers?.all_isogroup_metrics,
        assigned_task_documents_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.documents?.forms?.iso27001,
        assigned_task_questions_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.questions?.forms?.iso27001,
    };
};
export default connect(mapStateToProps, {})(ResourceView);
