import React from 'react';
import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DoneStatusChipNew = (props) => {
    const { label, done } = props;
    const theme = useTheme();
    const color = theme.palette[done ? 'success' : 'error'];
    return (
        <Chip
            label={label}
            sx={{
                border: '1px solid',
                borderColor: color[500],
                borderRadius: 1,
                backgroundColor: color[100] + '50',
                color: color[done ? 900 : 500],
                fontSize: 12,
                fontWeight: 600,
                textTransform: 'inherit !important',
                p: 0,
            }}
        />
    );
};

export default DoneStatusChipNew;
