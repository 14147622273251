import React, { useState } from 'react';
// mui components
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

// core components
import CircularLoader from 'components/Loaders/CircularLoader';

// redux
import { connect } from 'react-redux';
import { GetOneVendor, UpdateVendorDetails } from 'store/actions/vendorActions';
import { toast } from 'react-toastify';

import { ErrorOutline } from '@mui/icons-material/';
import AppCard from 'components/new_components/AppCard';

//translation
import { Language } from '@mui/icons-material';
import LanguageMenu from 'components/new_components/merchant-layout/header/menus/LanguageMenu';
import { useTranslation } from 'react-i18next';

const VendorInformation = (props) => {
    // props
    const { setPage, UpdateVendorDetails, one_vendor, contentLoading } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();

    //translation
    const { t } = useTranslation('vendorManagement');

    const open = Boolean(anchorEl);
    const openLanguageMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeLanguageMenu = () => {
        setAnchorEl(null);
    };

    // functions
    const handleGoToNextPage = async () => {
        setLoading(true);
        const res = await UpdateVendorDetails({ has_confirmed_details: true }, one_vendor?.id);
        setLoading(false);
        if (res?.success) {
            setPage(1);
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <>
            {!contentLoading ? (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'right', mx: '12.5%', mt: '50px' }}>
                        <Box className="text-[14px] font-medium" onClick={openLanguageMenu}>
                            <Language fontSize="small" className="mr-2" />
                            {window.localStorage.i18nextLng?.includes('en')
                                ? 'English'
                                : window.localStorage.i18nextLng?.includes('fr')
                                ? 'French'
                                : window.localStorage.i18nextLng}
                        </Box>
                        <LanguageMenu
                            open={open}
                            closeLanguageMenu={closeLanguageMenu}
                            anchorEl={anchorEl}
                            language={
                                window.localStorage.i18nextLng?.includes('en')
                                    ? 'English'
                                    : window.localStorage.i18nextLng?.includes('fr')
                                    ? 'French'
                                    : window.localStorage.i18nextLng
                            }
                            menuListStyles={{
                                parent: {
                                    right: '15%',
                                },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            mx: '12.5%',
                            mt: '70px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{ fontWeight: 700, fontSize: '16px', color: '#0E2C66', mt: '50px', mb: '30px' }}
                        >
                            {t('vendorQuestionnaire.hello')} {one_vendor?.name}
                        </Typography>
                        <AppCard
                            sx={{
                                p: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mb: '50px',
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: '12px',
                                    color: theme.palette.gray[40],
                                    fontWeight: 400,
                                    border: '0.3px solid #0E2C66',
                                    p: 0.5,
                                    mx: 1,
                                    my: 1,
                                    mb: '50px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <ErrorOutline color="#77777A" fontSize="small" />
                                <Typography sx={{ fontSize: '12px', color: '#77777A', marginLeft: '10px' }}>
                                    <strong>{one_vendor?.merchant_name}</strong> {t('vendorQuestionnaire.listed')}
                                    <br /> {t('vendorQuestionnaire.suggests')}{' '}
                                    <strong>{one_vendor?.merchant_name}</strong>
                                    {t('vendorQuestionnaire.smooth')}
                                    <br />
                                    {t('vendorQuestionnaire.summary')}
                                    <strong>{one_vendor?.merchant_name}</strong> {t('vendorQuestionnaire.below')}
                                </Typography>
                            </Box>
                            <Box sx={{ mb: '108px' }}>
                                <Box sx={{ mb: '8px' }}>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 500, fontSize: '16px', color: '#5E5E62' }}
                                    >
                                        {t('vendorQuestionnaire.vendorName')}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 400, fontSize: '16px', color: '#0E2C66', ml: 1 }}
                                    >
                                        {one_vendor?.name || ''}
                                    </Typography>
                                </Box>
                                <Box sx={{ my: '8px' }}>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 500, fontSize: '16px', color: '#5E5E62' }}
                                    >
                                        {t('vendorQuestionnaire.email')}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 400, fontSize: '16px', color: '#0E2C66', ml: 1 }}
                                    >
                                        {one_vendor?.email || ''}
                                    </Typography>
                                </Box>
                                <Box sx={{ my: '8px' }}>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 500, fontSize: '16px', color: '#5E5E62' }}
                                    >
                                        {t('vendorQuestionnaire.service')}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 400, fontSize: '16px', color: '#0E2C66', ml: 1 }}
                                    >
                                        {one_vendor?.description || ''}
                                    </Typography>
                                </Box>
                                <Box sx={{ my: '8px' }}>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 500, fontSize: '16px', color: '#5E5E62' }}
                                    >
                                        {t('vendorQuestionnaire.dataShared')}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 400, fontSize: '16px', color: '#0E2C66', ml: 1 }}
                                    >
                                        {one_vendor?.data || ''}
                                    </Typography>
                                </Box>
                                <Box sx={{ my: '8px' }}>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 500, fontSize: '16px', color: '#5E5E62' }}
                                    >
                                        {t('vendorQuestionnaire.riskLevel')}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 400, fontSize: '16px', color: '#0E2C66', ml: 1 }}
                                    >
                                        {one_vendor?.sensitivity || ''}
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: '8px' }}>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 500, fontSize: '16px', color: '#5E5E62' }}
                                    >
                                        {t('vendorQuestionnaire.products')}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{ fontWeight: 400, fontSize: '16px', color: '#0E2C66', ml: 1 }}
                                    >
                                        {one_vendor?.products || ''}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', mb: '45px', height: '40px' }}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{ textTransform: 'unset' }}
                                    onClick={handleGoToNextPage}
                                    loading={loading}
                                >
                                    {t('vendorQuestionnaire.proceed')}
                                </LoadingButton>
                            </Box>
                        </AppCard>
                    </Box>
                </>
            ) : (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularLoader />
                </div>
            )}
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        one_vendor: state?.vendorReducers?.one_vendor,
    };
};
export default connect(mapStateToProps, { GetOneVendor, UpdateVendorDetails })(VendorInformation);
