import { Button, Col, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';

// redux
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UpdateAuditRequest } from 'store/actions/auditActions';
import { AUDIT_STATUS } from 'views/merchant/audit/utils';

const SignModal = (props) => {
    const { open, closeModal, record, UpdateAuditRequest, all_auditors, all_qa_reviewers } = props;

    // states
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // function

    const handleSelectChange = (name, value) => {
        setData({ ...data, [name]: value });
    };
    const signROC = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await UpdateAuditRequest(
            { merchant: record?.merchant, status: AUDIT_STATUS.AUDITING, ...data },
            record?.id
        );
        setLoading(false);
        if (res?.success) {
            closeModal();
            setData({});
            return toast.success(
                'The admin signature has been attached to the ROC document, the merchant will be notified immediately.'
            );
        } else {
            return toast.error(res?.message);
        }
    };

    return (
        <Modal title="Select QA Reviewer and Assessor" open={open} onCancel={closeModal} footer={null} destroyOnClose>
            <form onSubmit={signROC}>
                <Row justify="end">
                    <Col span={24} className="my-1">
                        <label htmlFor="auditor">Lead Assessor</label>
                        <Select
                            id="auditor"
                            name="auditor"
                            size="large"
                            className="w-full"
                            onChange={(value) => handleSelectChange('auditor', value)}
                            required
                        >
                            {all_auditors?.map((auditor) => (
                                <Select.Option value={auditor?.id} key={auditor?.id}>
                                    {auditor?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={24} className="my-1">
                        <label htmlFor="qa_reviewer">QA Reviewer</label>
                        <Select
                            id="qa_reviewer"
                            name="qa_reviewer"
                            size="large"
                            className="w-full"
                            onChange={(value) => handleSelectChange('qa_reviewer', value)}
                            required
                        >
                            {all_qa_reviewers?.map((qa_reviewer) => (
                                <Select.Option value={qa_reviewer?.id} key={qa_reviewer?.id}>
                                    {qa_reviewer?.qa_reviewer_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col className="mt-3">
                        <Button loading={loading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => ({
    all_auditors: state?.adminReducers?.all_auditors,
    all_qa_reviewers: state?.auditReducers?.all_qa_reviewers,
});
export default connect(mapStateToProps, { UpdateAuditRequest })(SignModal);
