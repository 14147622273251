import React, { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Stack } from '@mui/material';
import { DoNotDisturb } from '@mui/icons-material';

// core component
import ComplianceProgressBar from './ComplianceProgressBar';

const GroupCard = forwardRef((props, ref) => {
    const theme = useTheme();
    const {
        children,
        percentCompleted = 0,
        totalValue = 0,
        totalAttendedTo = 0,
        resourceLabel = '',
        onCardClick,
        // onHover,
        // onHoverOut,
        empty,
        id,
        exempted,
        isCategoryAssigned,
        isSubCatgoryAssigned,
        isResourceAssigned,
        standard,
        tag,
    } = props;

    return (
        <Grid
            item
            component="span"
            xs={12}
            sm={6}
            lg={standard === 'cii' ? 4 : standard === 'soc2' ? 6 : 6}
            xl={standard === 'soc2' ? 4 : 3}
            ref={ref}
            id={id}
        >
            <Box
                variant="outlined"
                sx={{
                    backgroundColor: standard === 'soc2' ? '#FFFFFF' : '',
                    borderRadius: standard === 'cii' ? 2 : 2,
                    color: '#525f7f',
                    borderColor: theme.palette.gray[300],
                    p: 1.7,
                    height: '100%',
                    display: 'grid',
                    border: standard === 'soc2' ? '' : '1px solid rgba(0, 0, 0, 0.12)',
                    padding: standard === 'cii' ? '20px' : '13.6px',
                    alignContent: 'space-between',
                    cursor: empty ? 'not-allowed' : 'pointer',
                    position: 'relative',
                }}
                onClick={!exempted && onCardClick}
                // onMouseEnter={onHover && (() => onHover(id))}
                // onMouseLeave={onHoverOut && (() => onHoverOut(id))}
            >
                <Box sx={{ pb: standard === 'soc2' ? 9 : 2 }}>{children}</Box>
                <Box sx={{ position: 'relative' }}>
                    {tag && (
                        <Typography sx={{ fontSize: '11px', fontWeight: 600, color: '#DE3730', position: 'absolute' }}>
                            {tag}
                        </Typography>
                    )}
                    <ComplianceProgressBar percent={percentCompleted} standard={standard} />
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            sx={{
                                alignSelf: 'flex-start',
                                fontSize: 12,
                                color: theme.palette.black.main,
                                fontWeight: 500,
                            }}
                        >
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: 12,
                                    color: theme.palette.primary[900],
                                    pr: 0.5,
                                }}
                            >
                                {totalAttendedTo} / {totalValue}
                            </Typography>
                            {standard === 'soc2' ? 'Control objectives' : resourceLabel}
                        </Typography>
                        {isCategoryAssigned ? (
                            <Typography
                                sx={{
                                    color: theme.palette.white.main,
                                    background: theme.palette.tertiary[70],
                                    fontSize: 9,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    px: 0.5,
                                    fontWeight: 500,
                                    mt: exempted ? 8 : 4,
                                }}
                            >
                                Assigned to you
                            </Typography>
                        ) : null}

                        {exempted && (
                            <Typography sx={{ fontSize: 12, fontWeight: 500, color: theme.palette.error.main, mt: 4 }}>
                                <DoNotDisturb sx={{ color: theme.palette.neutral[50] }} fontSize={'12px'} /> Exempted
                            </Typography>
                        )}
                        {isSubCatgoryAssigned || isResourceAssigned ? (
                            <Stack
                                sx={{
                                    position: 'absolute',
                                    right: '-1.5px',
                                    top: '-0.7px',
                                    backgroundColor: theme.palette.shades.white,
                                    width: '12px',
                                    height: '12px',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: theme.palette.tertiary[70],
                                    }}
                                />
                            </Stack>
                        ) : null}
                    </Stack>
                </Box>
            </Box>
        </Grid>
    );
});

export default GroupCard;
