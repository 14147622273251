import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import CircularLoader from 'components/Loaders/CircularLoader';
import { GetQuestionnaireForms, GetVendorResponses, GetAllResponses } from 'store/actions/vendorActions';

import VendorResponseView from './VendorResponseView';

const VendorResponseInformation = (props) => {
    const {
        readonly,
        merchant,
        vendor,
        page,
        setPage,
        GetAllResponses,
        all_responses,
        GetQuestionnaireForms,
        GetVendorResponses,
        questionnaire_forms,
        vendor_responses,
        activeTab,
        setActiveTab,
        handleChangeView,
        goBack,
    } = props;

    //state
    const [loading, setLoading] = useState({
        content: false,
        form: false,
    });

    // async functions
    const getAllResponses = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetAllResponses();
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getQuestionnaire = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetQuestionnaireForms();
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getVendorResponse = async () => {
        setLoading((loading) => ({ ...loading, content: true }));
        const res = await GetVendorResponses(vendor);
        setLoading((loading) => ({ ...loading, content: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllResponses();
        getQuestionnaire();
        getVendorResponse();
    }, []);

    return (
        <div>
            {!loading.content ? (
                <VendorResponseView
                    all_responses={all_responses}
                    questionnaire_forms={questionnaire_forms}
                    vendor_responses={vendor_responses}
                    readonly={readonly}
                    vendor={vendor}
                    merchant={merchant}
                    setPage={setPage}
                    page={page}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    loading={loading}
                    handleChangeView={handleChangeView}
                    goBack={goBack}
                />
            ) : (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularLoader />
                </div>
            )}
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        questionnaire_forms: state?.vendorReducers?.questionnaire_forms,
        vendor_responses: state?.vendorReducers?.vendor_responses,
        all_responses: state?.vendorReducers?.all_responses,
    };
};
export default connect(mapStateToProps, {
    GetQuestionnaireForms,
    GetVendorResponses,
    GetAllResponses,
})(VendorResponseInformation);
