import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { connect } from 'react-redux';
import { GetMerchantPeople } from 'store/actions/merchantActions';

const AwarenessTrainingCard = ({ GetMerchantPeople, all_employees }) => {
    const theme = useTheme();

    const hasCompletedAwarenessTraining = useMemo(() => {
        return all_employees?.filter((emp) => emp?.training);
    }, [all_employees]);

    useEffect(() => {
        const getMerchantPeople = async () => {
            const res = await GetMerchantPeople();
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getMerchantPeople();
    }, []);
    return (
        <Stack
            sx={{
                border: '1px solid #C7C6CA',
                borderRadius: '16px',
                minHeight: '200px',
                // maxHeight: '389px',
                color: theme.palette.primary[900],
                py: 2,
                px: 3,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: '4px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700, mr: '8px' }}>
                    Awareness Training completion
                </Typography>
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                    }}
                >
                    ({hasCompletedAwarenessTraining?.length} People)
                </Typography>
            </Box>
            {hasCompletedAwarenessTraining?.map((employee) => {
                return (
                    <Typography sx={{ fontWeight: 500, fontSize: '10px', mb: '4px' }}>
                        {employee?.first_name} {employee?.last_name}
                    </Typography>
                );
            })}
        </Stack>
    );
};

const mapStateToProps = (state) => {
    return {
        all_employees: state?.merchantReducers?.all_merchant_people,
    };
};
export default connect(mapStateToProps, { GetMerchantPeople })(AwarenessTrainingCard);
