import React from 'react';
// import { useTheme } from '@mui/material/styles';
import { IconButton, Stack, Tooltip } from '@mui/material';

// core component
// import AppLinearProgress from 'components/new_components/AppLinearProgress';

//translation
// import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';
import { MdAssignmentAdd } from 'react-icons/md';

const NewProgressBar = (props) => {
    const { percent, openTaskAssignModal, standard, isTaskAssignedToSelectSubRequirement } = props;
    // const theme = useTheme();

    //translation
    // const { t } = useTranslation('compliance');
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <Progress
                type="circle"
                strokeLinecap="butt"
                width={33}
                percent={Math.round(percent)}
                strokeWidth={12}
                strokeColor={'#0E2C66'}
            />
            {/* <Progress
                type="circle"
                percent={Math.round(percent)}
                size="small"
                width={30}
                className="text-[7px] font-bold text-[#002C72]"
            /> */}
            {!(
                standard === 'ndpr' ||
                standard === 'kdpa' ||
                standard === 'gdpa' ||
                standard?.includes('iso') ||
                standard?.includes('soc2')
            ) ? (
                <Tooltip
                    title={
                        isTaskAssignedToSelectSubRequirement
                            ? 'Sorry, you cannot assign this to someone else because it has been assigned to you'
                            : ''
                    }
                >
                    <span>
                        <IconButton onClick={openTaskAssignModal} disabled={isTaskAssignedToSelectSubRequirement}>
                            <MdAssignmentAdd size={26} />
                        </IconButton>
                    </span>
                </Tooltip>
            ) : null}

            {/* <AppLinearProgress value={percent} sx={{ my: 1 }} /> */}
        </Stack>
    );
};

export default NewProgressBar;
