import React, { useEffect, useState } from 'react';
import { CertificateDetails } from 'store/actions/auditActions';
import CircularLoader from 'components/Loaders/CircularLoader';
import { Card, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const CertificateVeriffication = (props) => {
    const { CertificateDetails, certificate_details, merchant_info } = props;
    const { confirmation_token } = useParams();

    const [loading, setLoading] = useState(false);
    const certificatesDetails = async () => {
        setLoading(true);
        const response = await CertificateDetails(confirmation_token);
        setLoading(false);
        if (!response?.success) {
            toast.error('Certificate');
        }
    };
    useEffect(() => {
        certificatesDetails();
    }, []);

    return (
        <div>
            {loading ? (
                <CircularLoader />
            ) : certificate_details ? (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '100vh' }}
                >
                    <Grid
                        item
                        xs={6}
                        elevation={0}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Card
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '4rem 2rem',
                                width: '70%',
                            }}
                        >
                            <CheckIcon sx={{ color: '#0CB732', width: '100px', height: '100px' }} />
                            <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#000000' }}>
                                This certificate was issued under the <span>{merchant_info?.cert_body}</span> merchant
                                compliance scheme by 386konsult Limited, implemented by Smartcomply
                            </Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000000', mt: 3 }}>
                                <strong>Name of Company</strong>: {certificate_details?.merchant_name}
                            </Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000000', mt: 2 }}>
                                <strong>Service Covered</strong>: {certificate_details?.service_covered}
                            </Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000000', mt: 2 }}>
                                <strong>Date Validity</strong>: {moment(certificate_details?.date).format('MM-DD-YYYY')}
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            ) : (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '100vh' }}
                >
                    <Grid
                        item
                        xs={6}
                        elevation={0}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Card
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '4rem 2rem',
                                width: '70%',
                            }}
                        >
                            <CloseIcon sx={{ color: 'red', width: '100px', height: '100px' }} />
                            <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#000000' }}>
                                Invalid Certificate
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        certificate_details: state?.auditReducers?.certificate_details,
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};

export default connect(mapStateToProps, { CertificateDetails })(CertificateVeriffication);
