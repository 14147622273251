import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';

//core components
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import TabLayout from 'components/new_components/tabs/TabLayout';
import QuestionnaireView from './Questionnaire/QuestionnaireView';
import ActingAsAVendorTable from './ActingAsVendorTable';
import { Stack, Box } from '@mui/system';

//translations
import { useTranslation } from 'react-i18next';

const ActingAsAVendorLayout = (props) => {
    const { vendor_uploaded_questionnaire } = props;
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [view, setView] = useState(0);
    const [selectedQuestionnaire, setSelectedQuestionaire] = useState(null);

    //translation
    const { t } = useTranslation('vendorManagement');

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    const handleViewChange = (id) => {
        setView(1);
        setSelectedQuestionaire(id);
    };
    const goBack = () => {
        setView(0);
        setSelectedQuestionaire(null);
    };

    const completed = useMemo(
        () => vendor_uploaded_questionnaire?.filter((upload) => upload?.status === 'completed').length,
        [vendor_uploaded_questionnaire]
    );
    const notCompleted = useMemo(
        () => vendor_uploaded_questionnaire?.filter((upload) => upload?.status !== 'completed').length,
        [vendor_uploaded_questionnaire]
    );

    // constants
    const TABS = [
        { name: t('actingAsAVendor.all'), id: 0 },
        {
            name: (
                <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                    {t('actingAsAVendor.inProgress')}
                    <Box
                        sx={{
                            backgroundColor: '#7DF4FF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: '10px',
                            px: '8px',
                            py: '4px',
                            width: '26px',
                            height: '26px',
                            borderRadius: '8px',
                            fontWeight: 600,
                            fontSize: '16px',
                        }}
                    >
                        {notCompleted}
                    </Box>
                </Stack>
            ),
            id: 1,
        },
        {
            name: (
                <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                    {t('actingAsAVendor.completed')}
                    <Box
                        sx={{
                            backgroundColor: '#7DF4FF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: '10px',
                            px: '8px',
                            py: '4px',
                            width: '26px',
                            height: '26px',
                            borderRadius: '8px',
                            fontWeight: 600,
                            fontSize: '16px',
                        }}
                    >
                        {completed}
                    </Box>
                </Stack>
            ),
            id: 2,
        },
    ];
    return (
        <>
            {view === 0 ? (
                <TabLayout onTabChange={handleTabChange} tab={currentTab} tabs={TABS} tabVariant="outlined">
                    <AppTabPanel value={currentTab} index={0}>
                        <ActingAsAVendorTable handleViewChange={handleViewChange} currentTab="all" />
                    </AppTabPanel>
                    <AppTabPanel value={currentTab} index={1}>
                        <ActingAsAVendorTable handleViewChange={handleViewChange} currentTab="in_progress" />
                    </AppTabPanel>
                    <AppTabPanel value={currentTab} index={2}>
                        <ActingAsAVendorTable handleViewChange={handleViewChange} currentTab="completed" />
                    </AppTabPanel>
                </TabLayout>
            ) : (
                <QuestionnaireView
                    goBack={goBack}
                    handleViewChange={handleViewChange}
                    selectedQuestionnaire={selectedQuestionnaire}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        vendor_uploaded_questionnaire: state?.vendorReducers?.vendor_uploaded_questionnaire,
    };
};
export default connect(mapStateToProps)(ActingAsAVendorLayout);
