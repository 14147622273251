import { Stack, InputAdornment } from '@mui/material';
import React from 'react';
import AppInput from 'components/new_components/AppInput';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { GetAllMerchantRiskAssessments } from 'store/actions/merchantActions';
import SearchIcon from '../../../../../assets/img/Searchicon.svg';

const TransactionHead = (props) => {
    const { onSearch } = props;
    const theme = useTheme();

    return (
        <Stack direction="row" alignItems="center" sx={{ marginLeft: '-1rem', mt: '-1rem' }}>
            <AppInput
                placeholder="Search..."
                fullWidth
                sx={{
                    mr: 3,
                    borderColor: theme.palette.gray[30],
                    height: 38,
                    '& input::placeholder': {
                        color: theme.palette.gray[40],
                        fontWeight: 600,
                    },
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <img src={SearchIcon} alt="SearchIcon" width="25px" />
                    </InputAdornment>
                }
                onChange={onSearch}
            />
        </Stack>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_risk_assessments: state?.merchantReducers?.all_merchant_risk_assessments,
    };
};
export default connect(mapStateToProps, { GetAllMerchantRiskAssessments })(TransactionHead);
