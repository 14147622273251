import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { connect } from 'react-redux';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppUserGuide from 'components/new_components/AppUserGuide';
import { steps } from './steps';
import SubgroupChipNew from './SubgroupChipNew';

const SubGroupNav = (props) => {
    const {
        title,
        subgroups,
        onSubgroupChange,
        assignedSubrequirements,
        userguide_info,
        all_criteria_summary,
        exception,
        // criteria,
        isCriteria,
        all_cii_summary,
        isCii,
        goBack,
        selectedSubgroupId,
    } = props;

    const [openUserGuide, setOpenUserGuide] = useState(false);

    const stepsTargetRef = useRef([]);

    const { updateOneUserGuide } = useContext(UserGuideContext);
    // UserGuide;
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ sub_requirements: true });
        setOpenUserGuide(false);
    };

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : null;
    }, [userguide_info]);
    return (
        <Box
            sx={{
                px: 2,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                borderTop: '1px solid #F1F5F9',
                mb: 5,
            }}
        >
            <Box
                sx={{
                    p: 2,
                    display: 'flex',
                    cursor: 'pointer',
                }}
                onClick={goBack}
            >
                <ArrowBackIosIcon sx={{ color: '#395BA9' }} />
                <Typography sx={{ color: '#475569', fontWeight: 600, fontSize: '14px', ml: 1 }}>{title}</Typography>
            </Box>
            <Box sx={{ ml: 2 }}>
                <Stack>
                    <Stack direction="row" spacing={2}>
                        {subgroups?.map((subgroup, index) => {
                            const isActive = selectedSubgroupId === subgroup.id;
                            // Criteria
                            const one_criteria_summary = all_criteria_summary?.find(
                                (summary) => summary?.subcriteria === subgroup.id
                            );
                            const true_criteria_tools = one_criteria_summary?.tools?.every(
                                (item) => item?.status === true
                            );

                            const isCriteriaCompleted =
                                one_criteria_summary?.data?.user_doc === one_criteria_summary?.data?.total_doc &&
                                one_criteria_summary?.data?.user_form === one_criteria_summary?.data?.total_form &&
                                one_criteria_summary?.data?.user_conditional ===
                                    one_criteria_summary?.data?.conditional_count &&
                                true_criteria_tools;
                            const filteredCriteriaSummary = all_criteria_summary?.filter(
                                (summary) => summary?.subcriteria === subgroup?.id
                            );
                            const isAssigned = assignedSubrequirements?.includes(subgroup.id);
                            const filteredSubcriteria = filteredCriteriaSummary?.find(
                                (summary) => summary?.total === 0
                            );
                            const criteria_disabled =
                                filteredSubcriteria?.total === 0 && filteredSubcriteria?.tools?.length === 0;

                            // CII
                            const one_cii_summary = all_cii_summary?.find(
                                (summary) => summary?.subsector === subgroup.id
                            );
                            // const true_cii_tools = one_cii_summary?.tools?.every((item) => item?.status === true);

                            const isCiiCompleted =
                                one_cii_summary?.data?.user_doc === one_cii_summary?.data?.total_doc &&
                                one_cii_summary?.data?.user_form === one_cii_summary?.data?.total_form &&
                                one_cii_summary?.data?.user_conditional === one_cii_summary?.data?.conditional_count;
                            const filteredCiiSummary = all_cii_summary?.filter(
                                (summary) => summary?.subsector === subgroup?.id
                            );
                            const filteredCii = filteredCiiSummary?.find((summary) => summary?.total === 0);
                            const cii_disabled = filteredCii?.total === 0 && filteredCii?.tools?.length === 0;
                            return exception ? (
                                <SubgroupChipNew
                                    label={subgroup.title}
                                    id={subgroup?.id}
                                    onClick={onSubgroupChange}
                                    key={subgroup?.id}
                                    active={isActive}
                                    exception={exception}
                                />
                            ) : (
                                <SubgroupChipNew
                                    label={subgroup.label || subgroup.title}
                                    id={subgroup?.id}
                                    onClick={onSubgroupChange}
                                    key={subgroup?.id}
                                    active={isActive}
                                    disabled={
                                        isCriteria ? criteria_disabled : isCii ? cii_disabled : !subgroup?.has_resource
                                    }
                                    showCheck={
                                        isCriteria ? !criteria_disabled : isCii ? !cii_disabled : subgroup?.has_resource
                                    }
                                    completed={
                                        isCriteria ? isCriteriaCompleted : isCii ? isCiiCompleted : subgroup?.completed
                                    }
                                    isAssigned={isAssigned}
                                    ref={(el) => (stepsTargetRef.current[index] = el)}
                                />
                            );
                        })}
                    </Stack>

                    <AppUserGuide
                        open={openUserGuide}
                        onClose={CloseUserGuide}
                        steps={steps}
                        targets={stepsTargetRef.current}
                    />
                </Stack>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.sub_requirements,
        all_criteria_summary: state?.complianceReducers?.all_criteria_summary,
        all_cii_summary: state?.complianceReducers?.all_cii_summary,
    };
};

export default connect(mapStateToProps)(SubGroupNav);
