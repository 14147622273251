// import { useTheme } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import OutlinedLayout from 'components/new_components/tabs/OutlinedLayout';
import { Box } from '@mui/material';
import KycCheck from './kyc/KycChecks';
import TransactionChecks from './transactions/TransactionChecks';
import { GetKycResults, GetFraudTransactions } from 'store/actions/FraudGauardianActions';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';

const Welcome = (props) => {
    const { GetKycResults, GetFraudTransactions, all_kyc_result, all_fraud_transactions } = props;
    const [indexValue, setIndexValue] = useState(1);
    const [match_status, setMatch_Status] = useState('');
    const [entity_type, setEntityType] = useState('');
    const [loading, setLoading] = useState('');
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [dateModal, setDateModal] = useState(false);
    const [date_created, setDate_created] = useState('');

    const onChangeTab = async (val) => {
        setIndexValue(val);
    };

    const getPreviousDays = (num) => {
        const date = new Date();
        const previous = new Date(date.getTime());
        previous.setDate(date.getDate() - num);
        return previous;
    };

    const getPreviousMonths = (priorMonths) => {
        const date = new Date();

        // accessing current month of the date
        const currentMonth = date.getMonth();

        // subtracting required number of months
        date.setMonth(currentMonth - priorMonths);
        date.setDate(date.getDate() - 1);

        return date;
    };

    const getPreviousYears = (priorYears) => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - priorYears);
        date.setDate(date.getDate() - 1);

        return date;
    };

    const prevDay = (prev) => {
        const previous = new Date(prev.getTime());
        previous.setDate(prev.getDate() - 1);
        return previous;
    };

    const nextDay = (prev) => {
        const previous = new Date(prev.getTime());
        previous.setDate(prev.getDate() + 1);
        return previous;
    };
    const query_params = useMemo(() => {
        if (date_created === 'custom') {
            if (from && to && !dateModal) {
                const params = {
                    date_created__gt: moment(prevDay(from?.$d)).format('YYYY-MM-DD'),
                    date_created__lt: moment(nextDay(to?.$d)).format('YYYY-MM-DD'),
                };
                return params;
            }
        } else {
            const params = {
                ...(match_status.length && { match_status: match_status === 'All' ? '' : match_status }),
                ...(entity_type.length && { entity_type: entity_type === 'All' ? '' : entity_type }),
                ...(date_created.length && date_created === 'today'
                    ? {
                          date_created__gt: moment(getPreviousDays(1)).format('YYYY-MM-DD'),
                      }
                    : date_created.length && date_created === 'yesterday'
                    ? {
                          date_created__gt: moment(getPreviousDays(2)).format('YYYY-MM-DD'),
                          date_created__lt: moment(getPreviousDays(0)).format('YYYY-MM-DD'),
                      }
                    : date_created.length && date_created === 'last_seven_days'
                    ? {
                          date_created__gt: moment(getPreviousDays(8)).format('YYYY-MM-DD'),
                          date_created__lt: moment(getPreviousDays(1)).format('YYYY-MM-DD'),
                      }
                    : date_created.length && date_created === 'last_thirty_days'
                    ? {
                          date_created__gt: moment(getPreviousDays(31)).format('YYYY-MM-DD'),
                          date_created__lt: moment(getPreviousDays(1)).format('YYYY-MM-DD'),
                      }
                    : date_created.length && date_created === 'last_three_months'
                    ? {
                          date_created__gt: moment(getPreviousMonths(3)).format('YYYY-MM-DD'),
                          date_created__lt: moment(getPreviousDays(1)).format('YYYY-MM-DD'),
                      }
                    : date_created.length && date_created === 'last_twelve_months'
                    ? {
                          date_created__gt: moment(getPreviousYears(1)).format('YYYY-MM-DD'),
                          date_created__lt: moment(getPreviousDays(1)).format('YYYY-MM-DD'),
                      }
                    : null),
            };
            return params;
        }
    }, [match_status, entity_type, date_created, to, from, dateModal]);

    const handleFilter = (event) => {
        setDate_created(event.target.value);
        setTo(null);
        setFrom(null);
    };

    const closeDatePickerModal = () => {
        setDate_created('');
        setFrom(null);
        setTo(null);
        setDateModal(false);
    };

    useEffect(() => {
        if (date_created === 'custom') {
            if (to && from && !dateModal) {
                const getKycResult = async () => {
                    setLoading(true);
                    const res = await GetKycResults(query_params);
                    setTo(null);
                    setFrom(null);
                    setLoading(false);
                    if (!res?.success) {
                        toast.error(res?.message);
                    }
                };
                getKycResult();
            }
        } else {
            const getKycResult = async () => {
                setLoading(true);
                const res = await GetKycResults(query_params);
                setLoading(false);
                if (!res?.success) {
                    toast.error(res?.message);
                }
            };
            getKycResult();
        }
    }, [query_params, date_created, to, from, dateModal]);

    useEffect(() => {
        const getFraudTransactions = async () => {
            setLoading(true);
            const res = await GetFraudTransactions();
            setLoading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getFraudTransactions();
    }, []);

    const tabList = [
        {
            id: 1,
            name: 'kyc check',
            length: all_kyc_result?.length,
        },
        {
            id: 2,
            name: 'Fraudulent transaction check',
            length: all_fraud_transactions?.length,
        },
    ];
    return (
        <Box>
            <OutlinedLayout tabList={tabList} indexValue={indexValue} onChangeTab={onChangeTab} />
            <Box sx={{ mx: 1 }}>
                {indexValue === 1 && (
                    <KycCheck
                        match_status={match_status}
                        setMatch_Status={setMatch_Status}
                        entity_type={entity_type}
                        setEntityType={setEntityType}
                        loading={loading}
                        closeDatePickerModal={closeDatePickerModal}
                        handleFilter={handleFilter}
                        date_created={date_created}
                        dateModal={dateModal}
                        setDateModal={setDateModal}
                        from={from}
                        setFrom={setFrom}
                        to={to}
                        setTo={setTo}
                    />
                )}
                {indexValue === 2 && <TransactionChecks loading={loading} />}
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_kyc_result: state?.fraudGuardianReducers?.all_kyc_result,
        all_fraud_transactions: state?.fraudGuardianReducers?.all_fraud_transactions,
    };
};

export default connect(mapStateToProps, { GetKycResults, GetFraudTransactions })(Welcome);
