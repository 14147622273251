import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Badge, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { KeyboardArrowRightRounded } from '@mui/icons-material';

const SidebarListItem = (props) => {
    const theme = useTheme();
    const { active, icon, icon_active, text, link, isNew, disabled, subMenus, title } = props;
    let extraStyles = {};
    if (active) {
        extraStyles = {
            backgroundColor: theme.palette.primary.new,
            color: theme.palette.white.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.new,
                color: theme.palette.white.main,
            },
        };
    } else {
        extraStyles = {
            backgroundColor: title === text ? theme.palette.primary.new : 'transparent',
            color: theme.palette.white.main,
            border: title === text ? '1px solid #6E8EDF' : '',
            '&:hover': {
                color: theme.palette.white.main,
            },
        };
    }

    return (
        <ListItemButton
            sx={{
                mx: 1,
                p: 0.8,
                pl: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '4px',
                zIndex: 1300,
                '&, & *': {
                    transitionProperty: 'color, background-color, border-bottom, fill',
                    transitionDuration: '0.45s',
                },
                ...extraStyles,
            }}
            to={link}
            component={Link}
            disabled={disabled}
        >
            <Stack direction="row" alignItems="center">
                <ListItemIcon className="listItem__icon" sx={{ minWidth: 40 }}>
                    {active ? icon_active : icon}
                </ListItemIcon>
                <Badge variant="dot" invisible={!isNew} color="error">
                    <ListItemText primary={text} sx={{ '& span': { fontSize: '13px', fontWeight: 400 } }} />
                </Badge>
            </Stack>
            {subMenus?.length ? <KeyboardArrowRightRounded /> : null}
        </ListItemButton>
    );
};

export default SidebarListItem;
