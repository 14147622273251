import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { CircularProgress, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import SpeakerphoneOutline from 'assets/img/icons/speakerphone_outline.svg';
// core component
import AppDeleteModal from 'components/new_components/AppDeleteModal';

// redux
import { connect } from 'react-redux';
import {
    ToggleOnboardingItemActive,
    DeleteOnboardingItem,
    CreateMerchantOnboardingItem,
    EditMerchantOnboardingItem,
} from 'store/actions/onboardingActions';
// import { accept } from 'validate';
import PolicyOverwriteModal from './PolicyOverwriteModal';
import AppButton from 'components/new_components/AppButton';

//translation
import { useTranslation } from 'react-i18next';

const OnboardTableActions = (props) => {
    const theme = useTheme();
    const {
        row,
        ToggleOnboardingItemActive,
        DeleteOnboardingItem,
        merchant,
        type,
        // CreateMerchantOnboardingItem,
        // EditMerchantOnboardingItem,
    } = props;
    //translation hooks
    const { t } = useTranslation('onboardingPolicy');
    // state
    const [anchor, setAnchor] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [overwriteModal, setOverwriteModal] = useState(false);
    const [isNotifyIconClicked, setIsNotifyIconClicked] = useState(false);

    // logic functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const openDeleteModal = () => {
        closeMenu();
        setDeleteModal(true);
    };
    const closeDeleteModal = () => setDeleteModal(false);

    const openOverwriteModal = (check) => {
        closeMenu();
        setOverwriteModal(true);
        check?.length && setIsNotifyIconClicked(true);
    };
    const closeOverwriteModal = () => {
        setOverwriteModal(false);
        setTimeout(() => {
            setIsNotifyIconClicked(false);
        }, 100);
    };
    // async functions
    const handleActiveItemToggle = async () => {
        closeMenu();
        const payload = {
            is_deactivated: !row.is_deactivated,
            video_id: row?.id,
        };
        setLoading(true);
        const res = await ToggleOnboardingItemActive(payload);
        // this is to effect the delay in changing the state in the reedux store
        setTimeout(() => setLoading(false), 500);
        if (res?.success) {
            toast?.success('Item updated successfully');
        } else {
            toast?.error(res?.message);
        }
    };
    const resetOnboardingItem = async () => {
        setDeleting(true);
        const res = await DeleteOnboardingItem(row?.id);
        setDeleting(false);
        if (res?.success) {
            toast?.success('Item resetted successfully');
        } else {
            toast?.error(res?.message);
        }
    };

    // const { pdf, ppt, excel, docx, txt } = accept;

    return (
        <>
            {loading ? (
                <CircularProgress style={{ color: theme.palette.gray[500] }} size={20} />
            ) : row?.is_deactivated ? (
                <AppButton name="Activate" color="primary" variant="contained" onClick={handleActiveItemToggle} />
            ) : (
                <Stack direction="row" justifyContent="flex-end">
                    {row?.notify_list?.includes(merchant) && !row?.has_clicked_admin_policy ? (
                        <AppButton
                            name={<img src={SpeakerphoneOutline} alt="notification icon" />}
                            variant="text"
                            disableRipple={true}
                            sx={{
                                minWidth: 'unset',
                                px: 0,
                                py: 0,
                                backgroundColor: 'white !important',
                                '&:hover': {
                                    backgroundColor: 'white',
                                },
                            }}
                            onClick={() => openOverwriteModal('notify')}
                        />
                    ) : null}
                    <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                        <MoreVert />
                    </IconButton>
                </Stack>
            )}
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                <MenuItem sx={{ position: 'relative' }} onClick={openOverwriteModal}>
                    {t('table.tableAction.overwrite')}
                </MenuItem>
                <MenuItem onClick={openDeleteModal} disabled={row?.admin}>
                    {t('table.tableAction.reset')}
                </MenuItem>
                <MenuItem component="a" href={row?.file} target="_blank" rel="noreferer">
                    {t('table.tableAction.download')}
                </MenuItem>
                {!row?.is_deactivated ? (
                    <MenuItem onClick={handleActiveItemToggle}>{t('table.tableAction.deactivate')}</MenuItem>
                ) : null}
            </Menu>
            <AppDeleteModal
                open={deleteModal}
                handleClose={closeDeleteModal}
                title={t('deleteModal.title')}
                subtitle={t('deleteModal.subtitle')}
                deleting={deleting}
                onDelete={resetOnboardingItem}
                deleteText={t('deleteModal.deleteText')}
            />
            <PolicyOverwriteModal
                open={overwriteModal}
                handleClose={closeOverwriteModal}
                data={row}
                isNotifyIconClicked={isNotifyIconClicked}
                type={type}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant: state?.generalReducers?.user_info?.organization?.id,
    };
};
export default connect(mapStateToProps, {
    ToggleOnboardingItemActive,
    DeleteOnboardingItem,
    CreateMerchantOnboardingItem,
    EditMerchantOnboardingItem,
})(OnboardTableActions);
