import React from 'react';
import { Box } from '@mui/material';

//translations
import { useTranslation } from 'react-i18next';

const VideoPlayer = (props) => {
    const { src, onVideoEnded } = props;

    //translation
    const { t } = useTranslation('training');

    return (
        <Box sx={{ maxWidth: 1100, minHeight: 470 }}>
            <video
                controls
                width="100%"
                height="470"
                controlsList="play timeline volume nodownload"
                style={{ height: 470 }}
                onEnded={onVideoEnded}
            >
                <source src={src} />
                {t('videoTag')}
            </video>
        </Box>
    );
};

export default VideoPlayer;
