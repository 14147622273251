import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import SmilingFace from '../../../assets/img/SmilingFace.svg';
import CachedIcon from '@mui/icons-material/Cached';
import { toast } from 'react-toastify';
import { copierHelper } from 'utils';
import { GenerateTokenRequest, GenerateToken, UpdateApprovedUser } from 'store/actions/FraudGauardianActions';
import { connect } from 'react-redux';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { useEffect } from 'react';
import ReadDocumentation from './components/ReadDocumentation';

const ApiSettings = (props) => {
    const { GenerateTokenRequest, UpdateApprovedUser, all_approved_user, GenerateToken, handlePagechange } = props;
    const theme = useTheme();
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleCopied = async () => {
        const body = {
            copied_status: true,
        };
        const res = await UpdateApprovedUser(all_approved_user?.id, body);
        if (res?.success) {
            copierHelper(token);
        } else {
            res.error(res.message);
        }
    };

    const handleCreated = async () => {
        const body = {
            created_status: true,
        };
        const res = await UpdateApprovedUser(all_approved_user?.id, body);
        if (res?.success) {
            handlePagechange('token');
            toast.success('Token Successfully generated!');
        } else {
            res.error(res.message);
        }
    };

    const generateTokenRequest = async () => {
        setLoading(true);
        const res = await GenerateTokenRequest();
        setLoading(false);
        if (res?.success) {
            setSuccess(res?.success);
            handleCreated();
            setToken(res?.data);
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (all_approved_user?.created_status && token === '') {
            const generateToken = async () => {
                const res = await GenerateToken();
                if (res?.success) {
                    setToken(res?.data);
                } else {
                    toast.error(res?.message);
                }
            };
            generateToken();
        }
    }, [all_approved_user, token]);
    return (
        <Box sx={{ mb: 5 }}>
            <Typography sx={{ color: '#395BA9', fontWeight: 600, fontSize: '16px' }}>
                Fraud guardian API token
            </Typography>
            <Box sx={{ my: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        [theme.breakpoints.down('xl')]: {
                            display: 'block',
                        },
                    }}
                >
                    {token && (
                        <Box sx={{ mr: 2 }}>
                            {success && (
                                <Typography
                                    sx={{
                                        fontSize: '11px',
                                        fontWeight: 400,
                                        color: theme.palette.neutral[50],
                                    }}
                                >
                                    Copy this token and use it to make request.
                                </Typography>
                            )}
                            <Typography
                                onClick={() => {
                                    if (token?.length === 64) {
                                        handleCopied();
                                    }
                                }}
                                sx={{
                                    pl: 1,
                                    pr: 5,
                                    py: 1,
                                    border: '1px solid #E1E2EC',
                                    borderRadius: '5px',
                                    mt: 2,
                                    color: '#757780',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    cursor: token?.length === 64 ? 'pointer' : 'no-pointer',
                                }}
                            >
                                {token}
                            </Typography>
                        </Box>
                    )}
                    <AppLoadingButton
                        text={all_approved_user?.created_status ? 'Regenerate token' : ' Click to generate token'}
                        loading={loading}
                        variant="outlined"
                        sx={{
                            textTransform: 'inherit',
                            [theme.breakpoints.down('xl')]: {
                                mt: 2,
                            },
                        }}
                        startIcon={<CachedIcon />}
                        onClick={generateTokenRequest}
                    />
                </Box>
                {success && (
                    <Typography sx={{ fontSize: '11px', fontWeight: 500, color: theme.palette.gray[40], mt: 1.5 }}>
                        Please copy this key and save it somewhere safe.{' '}
                        <Typography component={'span'} sx={{ fontSize: '11px', fontWeight: 500, color: 'red' }}>
                            For security reasons, we cannot show it to you again
                        </Typography>
                    </Typography>
                )}
            </Box>
            <Divider />
            <Box sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                    <Typography sx={{ mr: 1, fontSize: '16px', fontWeight: 600, color: '#395BA9' }}>
                        Still feeling lost?
                    </Typography>
                    <img src={SmilingFace} alt="SmilingFace" />
                </Box>
                <Typography sx={{ color: theme.palette.gray[30], fontWeight: 400, fontSize: '13px', mb: 2.5 }}>
                    Take a look at Fraud guardian documentation to ease implementation
                </Typography>
            </Box>
            <ReadDocumentation width="50%" />

            <Divider />
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        all_approved_user: state?.fraudGuardianReducers?.all_approved_user,
    };
};

export default connect(mapStateToProps, { GenerateTokenRequest, GenerateToken, UpdateApprovedUser })(ApiSettings);
