import React, { useState, useMemo, useEffect } from 'react';
import LoadingState from 'components/new_components/LoadingState';

//components
import QuestionItem from '../../common/audit_questions/QuestionItem';
import DescriptionLayer from '../../common/DescriptionLayer';
import DocumentItem from '../../common/document/DocumentItem';
import SubgroupView from '../../common/SubgroupView';

//redux
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';

const ResourceView = (props) => {
    // props
    const {
        goBack,
        resource,
        continuityGroupID,
        continuity_groups,
        all_continuitygroups_metrics,
        continuityGroupResources,
        merchantResources,
        loading,
        setFormPayload,
        touchedFields,
        submitting,
        openTaskAssignModal,
        // all_compliance_assigned_tasks,
        assigned_task_documents_info,
        assigned_task_questions_info,
    } = props;
    // state
    const [page, setPage] = useState(1);
    const [overallMetric, setOverallMetric] = useState('');
    const [isogroupPercent, setIsogroupPercent] = useState(0);

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    // memos
    // get the selected continuityGroup by the given continuityGroup id
    const selectedContinuityGroup = useMemo(() => {
        return continuity_groups?.find((continuityGroup) => continuityGroup?.id === continuityGroupID);
    }, [continuityGroupID, page]);
    // get the status of te resource in category
    const resourcesHaveBeenAttendedTo = useMemo(() => {
        return continuityGroupResources?.map((isogroupRes) => {
            return merchantResources?.some((merchRes) =>
                resource === 'doc' ? merchRes?.document_id === isogroupRes?.id : merchRes?.form_id === isogroupRes?.id
            );
        });
    }, [continuityGroupResources, merchantResources]);
    // get the resource for the current page
    const selectedResource = useMemo(() => {
        return continuityGroupResources?.[page - 1];
    }, [continuityGroupResources, page]);

    // logic functions
    const goToResource = (page) => {
        if (page > continuityGroupResources?.length) {
            setPage(continuityGroupResources?.length);
        } else if (page < 1) {
            setPage(1);
        } else {
            setPage(page);
        }
    };
    // const onResourceExempted = () => {
    //     handleResourceExempted(selectedResource?.annex);
    // };

    // useeffect
    // whenever the selected resource chnages and the current one is undefined, then go back.
    useEffect(() => {
        if (!selectedResource) {
            if (continuityGroupResources) {
                return goToResource(continuityGroupResources?.length);
            }
            goBack();
        }
    }, [selectedResource]);
    // to calculate the percentage and mtrics for each category
    useEffect(() => {
        const { total_form, total_doc, user_form, user_doc } =
            all_continuitygroups_metrics[selectedContinuityGroup?.title];
        let percentage, metric;
        if (resource === 'doc') {
            metric = `${user_doc}/${total_doc} ${t('documentsUploaded')}`;
            percentage = (user_doc / total_doc) * 100;
        } else {
            metric = `${user_form}/${total_form} ${t('questionsAnswered')}`;
            percentage = (user_form / total_form) * 100;
        }
        setOverallMetric(metric);
        setIsogroupPercent(percentage);
    }, [all_continuitygroups_metrics, selectedContinuityGroup]);
    // to set the current question's answer to the appform initial value.
    useEffect(() => {
        if (resource === 'form') {
            // get the admin resource for that page
            const adminResource = selectedResource;
            // get the merchant resource corresponding to that page
            const merchantResource = merchantResources
                ?.sort((a, b) => b?.id - a?.id)
                ?.find((resource) => resource?.form_id === adminResource?.id);
            // set the answers
            let initialValues = {},
                requiredValues = {};
            adminResource?.form_fields?.forEach((field) => {
                initialValues[field?.id] = merchantResource?.answer?.[field?.id] || '';
                requiredValues[field?.id] = field?.required;
            });
            setFormPayload({ values: initialValues, form_id: adminResource?.id, requiredValues });
        }
    }, [page, merchantResources, selectedResource]);
    return (
        <SubgroupView
            goBack={goBack}
            title={selectedContinuityGroup?.title}
            groupMetric={overallMetric}
            goToNextResource={goToResource}
            currentResourcePage={page}
            data={resourcesHaveBeenAttendedTo}
            resource={resource}
            touchedFields={touchedFields}
            submitting={submitting}
            categoryResources={continuityGroupResources}
            assigned_task_documents_info={assigned_task_documents_info}
            assigned_task_questions_info={assigned_task_questions_info}
        >
            {loading ? (
                <LoadingState />
            ) : (
                <>
                    <DescriptionLayer percentCompletion={isogroupPercent} description="" />
                    {resource === 'doc' ? (
                        <DocumentItem
                            document={selectedResource}
                            title_key={
                                language?.includes('fr')
                                    ? 'continuity_iso_title_fr'
                                    : language?.includes('en')
                                    ? 'continuity_iso_title_en'
                                    : 'continuity_iso_title'
                            }
                            standard="iso22301"
                            openTaskAssignModal={openTaskAssignModal}
                            taskInfo={{
                                continuity_iso: selectedContinuityGroup?.id,
                            }}
                            // onResourceChange={onResourceExempted}
                        />
                    ) : (
                        <QuestionItem
                            form={selectedResource}
                            title={
                                language?.includes('fr')
                                    ? 'continuity_iso_title_fr'
                                    : language?.includes('en')
                                    ? 'continuity_iso_title_en'
                                    : 'continuity_iso_title'
                            }
                            standard="iso22301"
                            resource="form"
                            openTaskAssignModal={openTaskAssignModal}
                            taskInfo={{
                                continuity_iso: selectedContinuityGroup?.id,
                            }}
                            category={selectedContinuityGroup}
                            // onResourceChange={onResourceExempted}
                        />
                    )}
                </>
            )}
        </SubgroupView>
    );
};

const mapStateToProps = (state) => {
    return {
        continuity_groups: state?.adminReducers?.all_continuity_clauses,
        all_continuitygroups_metrics: state?.complianceReducers?.all_continuitygroups_metrics,
        assigned_task_documents_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.documents?.forms?.is022301,
        assigned_task_questions_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.questions?.forms?.is022301,
    };
};
export default connect(mapStateToProps, {})(ResourceView);
