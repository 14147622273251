import { MoreVert } from '@mui/icons-material';
import { Box, Button, Divider, Menu, MenuItem, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import FileUnCheck from 'assets/img/FileUncheck.svg';
import HistoryIcon from '@mui/icons-material/History';
import Download from 'assets/img/download01.svg';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { connect } from 'react-redux';
import { ExemptMerchantDocument } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';

const PolicyDocument = (props) => {
    const {
        control,
        setDoc_id,
        openHistoryModal,
        openUploadModal,
        all_merchant_documents,
        standard,
        ExemptMerchantDocument,
        getControlDocuments,
        subcontrolId,
    } = props;
    const [anchor, setAnchor] = useState(null);
    const [loading, setLoading] = useState({ upload: false, exempt: false });

    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const selectedDocument = useMemo(() => {
        return all_merchant_documents?.find((doc) => doc?.document_id === control?.id);
    }, [control, all_merchant_documents]);

    const exemptMerchantDocument = async () => {
        const payload = {
            document: control?.id,
            standard,
        };
        setLoading({ ...loading, exempt: true });
        const res = await ExemptMerchantDocument(payload);
        setLoading({ ...loading, exempt: false });
        if (res?.success) {
            toast.success(res?.message);
            getControlDocuments(subcontrolId);
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Box key={control?.id}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '60%',
                        }}
                    >
                        <img src={FileUnCheck} alt="fileunckeck" />{' '}
                        <Typography
                            sx={{
                                ml: 2,
                                color: '#395BA9',
                                fontSize: '13px',
                                fontWeight: 500,
                            }}
                        >
                            {control?.soc2_title}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: 'inherit',
                                fontSize: '12px',
                                fontWeight: 600,
                                color:
                                    selectedDocument?.approved_status === 'approved'
                                        ? '#37A372'
                                        : selectedDocument?.approved_status === 'pending'
                                        ? '#D2A500'
                                        : '#395BA9',
                                border: '1px solid #E2E8F0',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            onClick={() => {
                                if (!selectedDocument) {
                                    setDoc_id(control?.id);
                                    openUploadModal();
                                }
                            }}
                            variant="outlined"
                            startIcon={
                                <FiberManualRecordIcon
                                    sx={{
                                        width: 10,
                                        height: 10,
                                        color:
                                            selectedDocument?.approved_status === 'approved'
                                                ? '#37A372'
                                                : selectedDocument?.approved_status === 'pending'
                                                ? '#D2A500'
                                                : '#395BA9',
                                    }}
                                />
                            }
                        >
                            {selectedDocument?.approved_status === 'approved'
                                ? 'OK'
                                : selectedDocument?.approved_status === 'pending'
                                ? 'Pending approval'
                                : 'Upload policy'}
                        </Button>
                        <>
                            <Box sx={{ ml: 2, cursor: 'pointer' }} onClick={openMenu}>
                                <MoreVert />
                            </Box>
                            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
                                <MenuItem
                                    disabled={selectedDocument?.document_id !== control?.id}
                                    onClick={() => {
                                        setDoc_id(control?.id);
                                        openHistoryModal();
                                        closeMenu();
                                    }}
                                >
                                    <HistoryIcon sx={{ color: '#64748B' }} />

                                    <Typography
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            color: '#77777A',
                                            ml: 0.5,
                                        }}
                                    >
                                        {' '}
                                        Policy History
                                    </Typography>
                                </MenuItem>
                                <MenuItem>
                                    <Box
                                        component="a"
                                        href={control?.default}
                                        rel="noreferrer"
                                        target="_blank"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img src={Download} alt="Download" />{' '}
                                        <Typography
                                            sx={{
                                                fontSize: '13px',
                                                fontWeight: 500,
                                                color: '#77777A',
                                                ml: 0.5,
                                                textDecoration: 'none',
                                            }}
                                        >
                                            Download template
                                        </Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem onClick={exemptMerchantDocument}>
                                    <DoDisturbAltIcon sx={{ color: 'red' }} />{' '}
                                    <Typography
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            color: 'red',
                                            ml: 0.5,
                                        }}
                                    >
                                        {loading.exempt ? 'Exempting...' : 'Exempt policy'}
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </>
                    </Box>
                </Box>
                <Divider sx={{ color: '#F1F5F9', mx: '-2rem' }} />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
        documents_by_control: state?.adminReducers?.all_admin_docs_by_common_control,
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
    };
};
export default connect(mapStateToProps, { ExemptMerchantDocument })(PolicyDocument);
