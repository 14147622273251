import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CisoReportCard from './CisoReportCard';
import { useSelector, useDispatch } from 'react-redux';
import { GetMerchantTechSecGeneralSummary, GetMerchantTechSecSummaryByTechSec } from 'store/actions/dashboardActions';
import { GetAllTechCategories } from 'store/actions/iso27001+iso27035Actions';

const TECHSECReport = () => {
    const dispatch = useDispatch();
    //access value from redux store
    const all_tech_sec_categories = useSelector(
        (state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_categories
    );
    const techSecSummary = useSelector((state) => state?.dashboardReducers?.techSecSummary);
    const toListEntry = Object.entries(techSecSummary || {});
    const newList = toListEntry?.map((res) => {
        const { user_doc, user_form, total_form, total_doc } = res?.[1] || {};
        const steps = ((user_doc + user_form) * 100) / (total_doc + total_form) || 0;
        return {
            title: res?.[0],
            ...res?.[1],
            stepCount: parseInt(steps.toString()),
        };
    });
    const Clauses = newList?.filter((clause) => clause?.title?.includes('Clause'));
    const Annexes = newList?.filter((annex) => annex?.title?.includes('A.'));
    const Cybersecurity = newList?.filter(
        (cybersecurity) => !cybersecurity?.title?.includes('Clause') && cybersecurity?.title?.includes('A.')
    );
    const sortedClauses = Clauses?.sort((a, b) => {
        const clauseNumberA = parseInt(a?.title?.split(' ')?.[1]);
        const clauseNumberB = parseInt(b?.title?.split(' ')?.[1]);

        return clauseNumberA - clauseNumberB;
    });
    useEffect(() => {
        const getAllIsoData = async () => {
            await dispatch(GetMerchantTechSecGeneralSummary());
            await dispatch(GetMerchantTechSecSummaryByTechSec());
            await dispatch(GetAllTechCategories());
        };
        getAllIsoData();
    }, []);

    console.log({ techSecSummary, all_tech_sec_categories });
    return (
        <>
            <Box sx={{ mt: '20px', display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#0E2C66', mr: '10px' }}>
                    ISO27017 + ISO27032
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#46464A' }}>
                    Implementation Phase Completion
                </Typography>
            </Box>
            <Typography
                sx={{
                    fontSize: '10px',
                    fontWeight: 600,
                    color: '#0E2C66',
                    borderBottom: '1px solid #0E2C66',
                    width: 'fit-content',
                    mt: '20px',
                    mb: '12px',
                    pb: '4px',
                }}
            >
                Management Clauses
            </Typography>
            <Grid container spacing={1}>
                {sortedClauses?.map((res, index) => (
                    <Grid key={index} item xs={12} md={6} lg={6}>
                        <CisoReportCard details={res} />
                    </Grid>
                ))}
            </Grid>
            <Typography
                sx={{
                    fontSize: '10px',
                    fontWeight: 600,
                    color: '#0E2C66',
                    borderBottom: '1px solid #0E2C66',
                    width: '45px',
                    mt: '20px',
                    mb: '12px',
                    pb: '4px',
                }}
            >
                Annexes
            </Typography>
            <Grid container spacing={1}>
                {Annexes?.map((res, index) => (
                    <Grid key={index} item xs={12} md={6} lg={6}>
                        <CisoReportCard details={res} />
                    </Grid>
                ))}
            </Grid>
            <Typography
                sx={{
                    fontSize: '10px',
                    fontWeight: 600,
                    color: '#0E2C66',
                    borderBottom: '1px solid #0E2C66',
                    width: '45px',
                    mt: '20px',
                    mb: '12px',
                    pb: '4px',
                }}
            >
                Cybersecurity
            </Typography>
            <Grid container spacing={1}>
                {Cybersecurity?.map((res, index) => (
                    <Grid key={index} item xs={12} md={6} lg={6}>
                        <CisoReportCard details={res} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default TECHSECReport;
