import React, { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { toast } from 'react-toastify';

// app components
import PlanCard from './components/PlanCard';
import PendingRequestToast from './components/PendingRequestToast';
import LoadingState from 'components/new_components/LoadingState';

// redux
import { connect } from 'react-redux';
import { GetAllPlans } from 'store/actions/subscriptionActions';
import { GetCompanyDetails } from 'store/actions/merchantActions';
import { useMemo } from 'react';

//translations
import { useTranslation } from 'react-i18next';

const PlansPage = (props) => {
    const { handlePlanSelect, GetAllPlans, all_plans, GetCompanyDetails, merchant_info } = props;

    // state
    const [loading, setLoading] = useState(false);

    //translation
    const { t } = useTranslation('common');
    const language = localStorage.getItem('i18nextLng');

    // memos
    const merchantActivePlan = useMemo(() => merchant_info?.plan, [merchant_info]);
    const pendingPlan = useMemo(() => merchant_info?.pending_plan_id, [merchant_info]);
    const hasPendingPlan = useMemo(() => merchant_info?.pending_plan);

    // async functions
    const getAllPlans = async () => {
        setLoading(true);
        const res = await GetAllPlans();
        setLoading(false);
        if (!res.success) {
            toast.error("Something went wrong! We couldn't get plans.");
        }
    };
    const getCompanyDetails = async () => {
        setLoading(true);
        const res = await GetCompanyDetails();
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong! We couldn't retrieve your active plan details.");
        }
    };

    // useEffect
    useEffect(() => {
        getAllPlans();
        getCompanyDetails();
    }, []);

    return (
        <Box sx={{ maxWidth: 1050, mx: 'auto', my: 5 }}>
            {hasPendingPlan && <PendingRequestToast />}
            <Stack
                direction="row"
                sx={{
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                }}
            >
                {loading ? (
                    <LoadingState />
                ) : (
                    <>
                        {all_plans
                            ?.sort((a, b) => new Date(a?.date_created) - new Date(b?.date_created))
                            ?.map((plan) => {
                                return (
                                    <PlanCard
                                        key={plan.id}
                                        name={
                                            language?.includes('fr')
                                                ? plan.name_fr
                                                : language?.includes('en')
                                                ? plan.name_en
                                                : plan.name
                                        }
                                        id={plan.id}
                                        onPlanSelect={handlePlanSelect}
                                        features={
                                            language?.includes('fr')
                                                ? plan.features_fr
                                                : language?.includes('en')
                                                ? plan.features_en
                                                : plan.features
                                        }
                                        description={
                                            language?.includes('fr')
                                                ? plan.description_fr
                                                : language?.includes('en')
                                                ? plan.description_en
                                                : plan.description
                                        }
                                        employeeRange={plan.employee_range}
                                        active={plan?.id === merchantActivePlan}
                                        isPendingPlan={pendingPlan === plan?.id}
                                    />
                                );
                            })}
                        <PlanCard
                            name={t('custom')}
                            key="custom"
                            description={t('description')}
                            subDesc={t('subdescription')}
                            onPlanSelect={handlePlanSelect}
                            id="custom"
                            active={merchant_info?.merchant_plan?.tag === 'custom'}
                            isPendingPlan={pendingPlan === null && hasPendingPlan}
                        />
                    </>
                )}
            </Stack>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_plans: state?.subscriptionReducers?.all_plans,
        merchant_info: state?.merchantReducers?.merchant_info,
    };
};
export default connect(mapStateToProps, { GetAllPlans, GetCompanyDetails })(PlansPage);
