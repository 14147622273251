import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography, Tooltip } from '@mui/material';
// import ShieldIcon from 'assets/img/shield.svg';
import ComplianceAIIcon from 'assets/img/compliance-ai-icon.svg';
// core components
import { AppFormInput, AppFormSelect } from 'components/new_components/forms';

//translations
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const FieldItem = (props) => {
    const theme = useTheme();
    const { field, number, handleInputFocus, openSuggestionModal, isAIOn, focusedInput, standard } = props;

    //translation
    const language = localStorage.getItem('i18nextLng');

    const user_type = useSelector((state) => state?.authReducers?.user_type);

    return (
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
            <Box
                sx={{
                    width: 28,
                    height: 28,
                    minWidth: 28,
                    minHeight: 28,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary[900],
                    color: theme.palette.white.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 14,
                    fontWeight: 700,
                }}
            >
                {number}
            </Box>
            <Stack>
                <Typography
                    sx={{
                        color: theme.palette.gray[800],
                        fontWeight: 500,
                        maxWidth: 750,
                    }}
                >
                    {language?.includes('fr') ? field.name_fr : language?.includes('en') ? field.name_en : field.name}
                </Typography>
                <Stack direction="row" alignItems="end" sx={{ width: 500 }}>
                    <Field
                        field={field}
                        name={field?.id?.toString()}
                        handleInputFocus={handleInputFocus}
                        standard={standard}
                        disabled={user_type === 'auditor'}
                    />
                    {field?.id?.toString() === focusedInput && isAIOn ? (
                        <Tooltip
                            title="Click to see suggested answers from compliance AI"
                            arrow
                            placement="bottom"
                            sx={{
                                '& .MuiTooltip-popper': {
                                    backgroundColor: '#00000',
                                },
                            }}
                        >
                            <button
                                className="rounded-tl-2xl rounded-tr-2xl rounded-br-2xl shadow border border-blue-950 justify-center items-center gap-2.5 inline-flex ml-2 p-2 bg-white"
                                onClick={openSuggestionModal}
                            >
                                <img src={ComplianceAIIcon} alt="shield icon" className="w-[24px]" />
                            </button>
                        </Tooltip>
                    ) : null}
                </Stack>
            </Stack>
        </Stack>
    );
};

const Field = (props) => {
    const { field, name, handleInputFocus, standard, disabled } = props;

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    return field?.type === 'text' ? (
        <AppFormInput
            type="text"
            name={name}
            placeholder={t('response')}
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'number' ? (
        <AppFormInput
            type="number"
            name={name}
            placeholder={t('number')}
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'textarea' ? (
        <AppFormInput
            type="text"
            name={name}
            placeholder={t('details')}
            multiline
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'date' ? (
        <AppFormInput
            type="date"
            name={name}
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'time' ? (
        <AppFormInput
            type="time"
            name={name}
            onClick={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : field?.type === 'dropdown' ? (
        <AppFormSelect
            name={name}
            options={
                standard === 'gdpa' || standard === 'kdpa' || standard === 'isms' || standard === 'tech_sec'
                    ? field?.options?.split(',') || []
                    : language?.includes('fr')
                    ? field?.options_fr?.split(',') || []
                    : language?.includes('en')
                    ? field?.options_en?.split(',') || []
                    : field?.options?.split(',') || []
            }
            defaultValue={t('select')}
            onOpen={() => {
                handleInputFocus(name);
            }}
            disabled={disabled}
        />
    ) : null;
};

export default FieldItem;
