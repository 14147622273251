import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import AddDocumentModal from '../Operations/AddDocumentModal';
import EditDocumentModal from '../Operations/EditDocumentModal';

import { Card, Row, Col, Button, Typography, Modal, Empty, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BiUnlink } from 'react-icons/bi';

import { PlusOutlined, EditOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
// redux
import { useDispatch } from 'react-redux';
import { DeleteDocument, EditDocument, GetAllDocumentsByAltTag } from 'store/actions/adminActions';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const NpdrDocumentCardsView = (props) => {
    const { category_id, all_documents_by_subreq } = props;
    const [modal, setModal] = useState({ add: false, edit: false });
    const [one_doc, setOneDoc] = useState({});
    const [loading, setLoading] = useState(false);

    // redux
    const dispatch = useDispatch();

    const openAddDocumentModal = () => setModal({ ...modal, add: true });
    const closeAddDocumentModal = () => setModal({ ...modal, add: false });
    const openEditDocumentModal = (doc) => {
        setModal({ ...modal, edit: true });
        setOneDoc(doc);
    };
    const closeEditDocumentModal = () => setModal({ ...modal, edit: false });

    //Actions
    const documentActions = (document) => {
        let actions = [
            <Tooltip title="Download this Document" color="blue">
                <a key="preview" href={document?.default}>
                    <DownloadOutlined />
                </a>
            </Tooltip>,
            <Tooltip title="Edit this Document" color="blue">
                <EditOutlined key="edit" title="Edit this Document" onClick={() => openEditDocumentModal(document)} />
            </Tooltip>,
            <Tooltip title="Delete this Document" color="red">
                <DeleteOutlined key="delete" title="Delete this document" onClick={() => deleteModal(document)} />
            </Tooltip>,
        ];
        if (document?.tags?.split(',').length > 1 && document?.tags?.split(',').includes('ndpr')) {
            actions.push(
                <Tooltip title="Unlink this Document">
                    <BiUnlink key="unlink" title="Unlink this document" onClick={() => unlinkModal(document)} />
                </Tooltip>
            );
        }
        return actions;
    };
    const deleteModal = (document) => {
        confirm({
            title: 'Do you want to delete this document?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const res = await dispatch(DeleteDocument(document?.id, category_id, 'ndpr'));
                if (res.success) {
                    toast.success(res?.message);
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const unlinkModal = (document) => {
        confirm({
            title: 'Do you want to unlink this document?',
            icon: <ExclamationCircleOutlined />,
            content: 'Unlinking this document will detach this document from this compliance standard',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                let newTags = document.tags
                    .split(',')
                    .filter((tag) => tag !== 'ndpr')
                    .join();
                const formData = new FormData();
                formData.append('tags', newTags);
                const res = await dispatch(EditDocument(formData, document?.id, 'ndpr', document?.category));
                if (res.success) {
                    toast.success('Document Unlinked Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const getAllDocumentsByAltTags = async () => {
        const res = await dispatch(GetAllDocumentsByAltTag('ndpr'));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        setLoading(true);
        getAllDocumentsByAltTags();
        setLoading(false);
    }, []);
    return (
        <>
            <Row justify="end">
                <Button type="primary" icon={<PlusOutlined />} onClick={openAddDocumentModal}>
                    Add Document
                </Button>
            </Row>
            <section className="my-3">
                {loading ? (
                    'loading...'
                ) : (
                    <>
                        {all_documents_by_subreq && all_documents_by_subreq?.length > 0 ? (
                            <Row gutter={16}>
                                {all_documents_by_subreq
                                    ?.sort((a, b) => a?.id - b?.id)
                                    ?.map((document) => {
                                        return (
                                            <Col
                                                xs={24}
                                                sm={12}
                                                md={8}
                                                lg={6}
                                                style={{ marginBottom: '1rem' }}
                                                key={document?.id}
                                            >
                                                <Card loading={false} actions={documentActions(document)}>
                                                    <Title level={5} style={{ textAlign: 'center' }}>
                                                        {document?.ndpr_title}
                                                    </Title>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Document</h3>
                                                <span>Add a new document </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            {modal.add && (
                <AddDocumentModal
                    open={modal.add}
                    handleClose={closeAddDocumentModal}
                    tag="ndpr"
                    payload={{
                        category: category_id,
                    }}
                    titleLabel="ndpr_title"
                    descLabel="ndpr_document_description"
                    groupIdLabel="category"
                />
            )}
            {modal.edit && (
                <EditDocumentModal
                    open={modal.edit}
                    handleClose={closeEditDocumentModal}
                    one_document={one_doc}
                    tag="ndpr"
                    payload={{
                        category: category_id,
                    }}
                    titleLabel="ndpr_title"
                    descLabel="ndpr_document_description"
                    groupIdLabel="category"
                />
            )}
        </>
    );
};

export default NpdrDocumentCardsView;
