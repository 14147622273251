import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Stack } from '@mui/material';
import { CancelRounded, CheckCircle } from '@mui/icons-material';

//translations
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CreateOauthIntegration } from 'store/actions/integrationsActions';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ConnectButton = (props) => {
    const theme = useTheme();
    const {
        connected,
        onDisconnect,
        onConnect,
        integration,
        CreateOauthIntegration,
        // openConnectModal
    } = props;
    const history = useHistory();

    //translation
    const { t } = useTranslation('setups');

    // const oauth_types = ['gitlab', 'heroku', 'digitalocean', 'github', 'jira', 'slack'];

    const integration_name = localStorage.getItem('integration_name');
    const org_name = localStorage.getItem('org_name');
    const domain = localStorage.getItem('domain');
    const one_integration = localStorage.getItem('one_integration');
    const email = localStorage.getItem('email');
    var urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const oauth_route =
        one_integration === 'github'
            ? 'oauth_github'
            : one_integration === 'gitlab'
            ? 'oauth_gitlab'
            : one_integration === 'heroku'
            ? 'oauth_heroku'
            : one_integration === 'digitalocean'
            ? 'oauth_digitalocean'
            : one_integration === 'jira'
            ? 'oauth_jira'
            : one_integration === 'slack'
            ? 'oauth_slack'
            : 'oauth_sage';

    const handleSubmit = async () => {
        const github_body = {
            user_integration_name: integration_name,
            user_fields: { org_name },
            code,
            state,
        };
        const others_body = {
            user_integration_name: integration_name,
            code,
            state,
        };
        const jira_body = {
            user_integration_name: integration_name,
            code,
            state,
            user_fields: { domain, email },
        };

        const sage_body = {
            user_integration_name: integration_name,
            code,
            state,
            user_fields: { domain },
        };
        const body =
            oauth_route === 'github'
                ? github_body
                : oauth_route === 'sage'
                ? sage_body
                : oauth_route === 'jira'
                ? jira_body
                : others_body;
        const res = await CreateOauthIntegration(body, oauth_route);
        if (res?.success) {
            localStorage.removeItem('one_integration');
            localStorage.removeItem('org_name');
            localStorage.removeItem('domain');
            localStorage.removeItem('domain');
            toast.success(`You have successfully integrated ${one_integration} to your dashboard.`);
            history.push('/merchant/settings/server');
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error(res?.message);
            }
        }
    };
    useEffect(() => {
        if (code && state && integration?.name === one_integration) {
            handleSubmit();
        }
    }, [code, state, one_integration]);
    // github
    return (
        <>
            <Box sx={{ mt: 3.5, width: '100%' }}>
                <Button
                    sx={{
                        textTransform: 'unset',
                        py: 1.2,
                        fontSize: 16,
                        fontWeight: 600,
                        borderRadius: 1,
                        color: theme.palette.primary[900],
                        backgroundColor: theme.palette.gray[200],
                        boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                            backgroundColor: theme.palette.gray[200],
                        },
                        '&.Mui-disabled': {
                            color: theme.palette.primary[900],
                            borderColor: theme.palette.primary[500] + '80',
                            backgroundColor: theme.palette.white.main,
                            boxShadow: 'unset',
                        },
                    }}
                    variant={connected ? 'outlined' : 'contained'}
                    fullWidth={true}
                    disabled={connected}
                    // onClick={oauth_types?.includes(integration?.name) ? openConnectModal : onConnect}
                    onClick={onConnect}
                >
                    {connected ? (
                        <>
                            {t('connectButton.connected')}
                            <CheckCircle sx={{ fontSize: 16, ml: 1 }} color="success" />
                        </>
                    ) : (
                        t('connectButton.connect')
                    )}
                </Button>
                <Stack sx={{ minHeight: 30, px: 1 }} justifyContent="center">
                    {connected ? (
                        <Box className="flex justify-center items-center flex-col md:flex-row ">
                            <FooterBtn Icon={CancelRounded} label={t('footer.disconnect')} onClick={onDisconnect} />
                        </Box>
                    ) : null}
                </Stack>
            </Box>
        </>
    );
};
const FooterBtn = (props) => {
    const theme = useTheme();

    const { Icon, label, onClick } = props;
    return (
        <Stack
            sx={{
                fontSize: 12,
                color: theme.palette.primary[200],
                fontWeight: 500,
                background: 'transparent',
                border: 'none',
                outline: 'none',
                gap: 0.5,
            }}
            component="button"
            direction="row"
            alignItems="center"
            onClick={onClick}
        >
            <Icon color="primary" sx={{ fontSize: 16 }} />
            <Box component="span">{label}</Box>
        </Stack>
    );
};

const mapStateToProps = () => {
    return {};
};
export default connect(mapStateToProps, { CreateOauthIntegration })(ConnectButton);
