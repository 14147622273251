import React from 'react';
import { Stack, Typography, Button, CircularProgress } from '@mui/material';
import NewProgressBar from './NewProgressBar';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { useTheme } from '@mui/material/styles';
import ComplianceAIIcon from 'assets/img/compliance-ai-icon.svg';
import ComplianceAIIconCompleted from 'assets/img/compliance-ai-icon-green.svg';

import { truncateText } from 'utils';

const DescriptionLayer = (props) => {
    const {
        description,
        percentCompletion,
        type,
        subCategory,
        standard,
        openDescriptionDrawer,
        resource,
        category,
        isTaskAssignedToSelectSubRequirement,
        openTaskAssignModal,
        isComplianceOn,
        getComplianceAIAnswers,
        fetchingAIAnswers,
        autofilled,
    } = props;
    const theme = useTheme();

    //translation
    // const { t } = useTranslation('compliance');
    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
            <Typography
                sx={{
                    flexBasis: '60%',
                    fontWeight: 500,
                    fontSize: 14,
                    color: theme.palette.black.main,
                }}
            >
                {description?.length > 300 ? (
                    <>
                        {truncateText(description, '250')}{' '}
                        <Button
                            variant="text"
                            sx={{
                                textDecoration: 'underline',
                                color: '#5474C4',
                                textTransform: 'none',
                                minWidth: 'unset',
                                p: 0,
                            }}
                            onClick={openDescriptionDrawer}
                        >
                            read more
                        </Button>{' '}
                    </>
                ) : (
                    description
                )}
            </Typography>
            <Stack alignItems="center" justifyContent="flex-end" gap={1}>
                {resource === 'form' && isComplianceOn ? (
                    <AppLoadingButton
                        // variant="outlined"
                        sx={{
                            textTransform: 'none',
                            width: '100%',
                            py: 0.5,
                            px: 0.2,
                            border:
                                fetchingAIAnswers || (!fetchingAIAnswers && autofilled)
                                    ? 'none'
                                    : '1px solid rgba(14, 44, 102, 0.5) !important',
                            color:
                                !fetchingAIAnswers && autofilled
                                    ? theme.palette.success[700]
                                    : theme.palette.primary.main,
                        }}
                        onClick={() => getComplianceAIAnswers()}
                        text={
                            <>
                                {fetchingAIAnswers ? (
                                    <>
                                        <CircularProgress color="neutral" size={18} sx={{ mr: 2 }} /> Auto-fill in
                                        progress
                                    </>
                                ) : !fetchingAIAnswers && autofilled ? (
                                    <>
                                        <img src={ComplianceAIIconCompleted} className="w-[24px] mr-1" /> Auto - fill
                                        completed
                                    </>
                                ) : (
                                    <>
                                        <img src={ComplianceAIIcon} className="w-[24px] mr-1" /> Auto - fill this sub
                                        requirement
                                    </>
                                )}
                            </>
                        }
                    />
                ) : null}
                <NewProgressBar
                    percent={percentCompletion}
                    openTaskAssignModal={openTaskAssignModal}
                    type={type}
                    subCategory={subCategory}
                    standard={standard}
                    resource={resource}
                    category={category}
                    isTaskAssignedToSelectSubRequirement={isTaskAssignedToSelectSubRequirement}
                />{' '}
                {/* <Stack direction="row" alignItems="center" gap={0.5} sx={{ fontSize: 12 }}>
                    <TextSnippet fontSize="inherit" />
                    <Typography sx={{ fontSize: 11, color: theme.palette.gray.new60, fontWeight: 500 }}>
                        3/11
                    </Typography>
                    <Typography sx={{ fontSize: 11, color: theme.palette.gray[40], fontWeight: 500 }}>
                        complete
                    </Typography>
                </Stack> */}
                {isTaskAssignedToSelectSubRequirement ? (
                    <Typography
                        sx={{
                            color: theme.palette.white.main,
                            background: theme.palette.tertiary[70],
                            fontSize: 9,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '18px',
                            px: '4px',
                            fontWeight: 500,
                            borderRadius: '2px',
                        }}
                    >
                        Assigned to you
                    </Typography>
                ) : null}
            </Stack>
        </Stack>
    );
};

export default DescriptionLayer;
