import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Drawer, Slide, Box, Stack, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const AppDrawer = (props) => {
    const theme = useTheme();
    const { open, handleClose, children, title, width, icon, anchor, backIcon, closeicon, noClose } = props;
    return (
        <Drawer
            open={open}
            anchor={anchor || 'right'}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: { sm: '85vw', md: '500px' },
                    width,
                    flex: 1,
                    overflow: 'hidden',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <Box>
                {title && (
                    <Stack
                        sx={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme.palette.primary[900],
                            px: 2,
                            py: 1.5,
                            width: '100%',
                            borderBottom: '1px solid #E1E2EC',
                        }}
                        direction="row"
                        alignItems={backIcon ? 'center' : 'start'}
                        justifyContent={backIcon ? 'initial' : 'space-between'}
                    >
                        {backIcon ? backIcon : null}
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={3}>
                            {icon && <IconButton onClick={handleClose}>{icon}</IconButton>}
                            {title}
                        </Stack>

                        {noClose ? null : (
                            <>
                                {closeicon ? (
                                    closeicon
                                ) : (
                                    <IconButton onClick={handleClose}>
                                        <Close sx={{ color: theme.palette.gray[500] }} />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </Stack>
                )}
            </Box>
            <Box sx={{ flex: 1 }}>{children}</Box>
        </Drawer>
    );
};

export default AppDrawer;
