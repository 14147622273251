import React from 'react';
import { Typography, Box } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import ResponseItem from './components/ResponseItem';

//translations
import { useTranslation } from 'react-i18next';

const ResponseItemContainer = (props) => {
    const { all_responses, activeTab, setActiveTab, vendor, goBack } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('vendorManagement');

    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.gray[60], height: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        p: '15px',
                    }}
                >
                    <Box
                        onClick={() => goBack()}
                        component="button"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%',
                            height: '35px',
                            width: '35px',
                            padding: '7px',
                            backgroundColor: theme.palette.primary[300] + '30',
                            cursor: 'pointer',
                        }}
                    >
                        <KeyboardArrowLeft />
                    </Box>
                    <Typography
                        variant="h1"
                        noWrap
                        component="h1"
                        sx={{
                            fontWeight: '700',
                            paddingLeft: '10px',
                            paddingTop: '5px',
                            color: '#919094',
                            fontSize: '18px',
                        }}
                    >
                        {t('categories')}
                    </Typography>
                </Box>
                {all_responses ? (
                    all_responses
                        ?.filter((response) => response?.vendor_details?.id === vendor)
                        ?.map((response) => {
                            return (
                                <ResponseItem
                                    key={response?.id}
                                    title={response?.form_title}
                                    id={response?.id}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                            );
                        })
                ) : (
                    <div></div>
                )}
            </Box>
        </>
    );
};

export default ResponseItemContainer;
