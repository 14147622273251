export const StatusOption = [
    { name: 'All', value: 'All' },
    { name: 'Potential Match', value: 'potential_match' },
    { name: 'No Match', value: 'no_match' },
    { name: 'Unknown', value: 'unknown' },
    { name: 'False Positive', value: 'false_positive' },
    { name: 'True Positive', value: 'true_positive' },
    { name: 'True Positive - Approve', value: 'true_positive-approve' },
    { name: 'True Positive - Reject', value: 'true_positive-reject' },
];

export const EntityTypeOption = [
    { name: 'All', value: 'All' },
    { name: 'Person', value: 'person' },
    { name: 'Company', value: 'company' },
    { name: 'Organisation', value: 'organisation' },
    { name: 'Vessel', value: 'vessel' },
    { name: 'Aircraft', value: 'aircraft' },
];

export const DATE_OPTIONS = [
    {
        name: 'Any Time',
        value: 'all',
    },
    {
        name: 'Today',
        value: 'today',
    },
    {
        name: 'Yesterday',
        value: 'yesterday',
    },
    {
        name: 'Last 7 days',
        value: 'last_seven_days',
    },
    {
        name: 'Last 30 days',
        value: 'last_thirty_days',
    },
    {
        name: 'Last 3 months',
        value: 'last_three_months',
    },
    {
        name: 'Last 12 months',
        value: 'last_twelve_months',
    },
];
