export const AVAILABLE_PROMPTS = ['Fix issue on infrastructure scan', 'Penetration test', 'API Compliance'];

export const CONVERSATION = [
    {
        id: 1,
        text: 'Penetration testing',
        type: 'prompt',
    },
    {
        id: 2,
        text: 'What would you like to know about penetration testing?',
        type: 'response',
    },
    {
        id: 3,
        text: 'What would you like to know about penetration testing?',
        type: 'response',
    },
    {
        id: 4,
        text: 'What would you like to know about penetration testing?',
        type: 'response',
    },
    {
        id: 5,
        text: 'What would you like to know about penetration testing?',
        type: 'response',
    },
    {
        id: 6,
        text: 'What would you like to know about penetration testing?',
        type: 'prompt',
    },
    {
        id: 7,
        text: 'What would you like to know about penetration testing?',
        type: 'response',
    },
    {
        id: 8,
        text: 'What would you like to know about penetration testing?',
        type: 'response',
    },
    {
        id: 9,
        text: 'What would you like to know about penetration testing?',
        type: 'response',
    },
    {
        id: 10,
        text: 'What would you like to know about penetration testing?',
        type: 'response',
    },
];
