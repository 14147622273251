import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import ReadDocument from '../../../../assets/img/ReadDocument.svg';

const ReadDocumentation = (props) => {
    const { width } = props;
    const theme = useTheme();
    return (
        <a href="https://documenter.getpostman.com/view/24557562/2s9YJdVMe8" target="_blank" rel="noopener noreferrer">
            <Box
                sx={{
                    mt: 2,
                    height: '100px',
                    backgroundColor: '#F8F8F8',
                    display: 'flex',
                    alignItems: 'center',
                    px: 3,
                    textDecoration: 'none',
                    width: width ? width : '100%',
                    [theme.breakpoints.down('xl')]: {
                        width: '100%',
                    },
                }}
            >
                <img src={ReadDocument} alt="ReadDocument" />
                <Box sx={{ ml: 4 }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600, color: theme.palette.gray[30] }}>
                        Read the documentation
                    </Typography>
                    <Typography sx={{ fontSize: '10px', fontWeight: 400, color: theme.palette.gray[40] }}>
                        Get to know how to implement fraud guardian on your application
                    </Typography>
                </Box>
            </Box>{' '}
        </a>
    );
};

export default ReadDocumentation;
