import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';

// core components
import AppButton from 'components/new_components/AppButton';
import ComplianceTip from './ComplianceTip';
import CompliancePagination from './CompliancePagination';
import { AppSubmitButton } from 'components/new_components/forms';
import { checkAllAndSomeTrue } from 'utils';
import SubGroupNav from './SubGroupNav';

//translation
import { useTranslation } from 'react-i18next';

const SubgroupView = (props) => {
    const {
        children,
        goBack,
        title,
        groupMetric,
        goToNextResource,
        currentResourcePage,
        data,
        resource,
        touchedFields,
        submitting,
        standard,
        selectedSubgroup,
        categoryResources,
        assigned_task_documents_info,
        assigned_task_questions_info,
        highestPercentageUsed,
        subgroups,
        onSubgroupChange,
        assignedSubrequirements,
        selectedSubgroupId,
    } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');

    //functions
    const handleNextClick = () => {
        if (data?.length === currentResourcePage) {
            goBack();
        } else {
            goToNextResource(currentResourcePage + 1);
        }
    };
    const isFieldTouched = useMemo(() => {
        return touchedFields
            ? Object.values(touchedFields)?.some((field) => field) || checkAllAndSomeTrue(highestPercentageUsed)
            : null;
    }, [touchedFields, highestPercentageUsed]);

    const pagesAssigned = useMemo(() => {
        const tasks = resource === 'doc' ? assigned_task_documents_info : assigned_task_questions_info;
        const indexes = tasks?.map((id) => {
            return categoryResources?.findIndex((item) => item.id === id);
        });
        return indexes?.map((indx) => indx + 1);
    }, [resource, assigned_task_documents_info, assigned_task_questions_info, categoryResources]);
    return (
        <>
            {standard === 'soc2' ? (
                <>
                    <SubGroupNav
                        title={title}
                        subgroups={subgroups}
                        onSubgroupChange={onSubgroupChange}
                        assignedSubrequirements={assignedSubrequirements}
                        goBack={goBack}
                        selectedSubgroupId={selectedSubgroupId}
                    />
                    <Box sx={{ my: -4, mx: 3, backgroundColor: '#FFFFFF', height: '100%', p: 2 }}>
                        <Box sx={{ flex: 1 }}>{children}</Box>
                        {data?.length > 0 && (
                            <Stack alignItems="flex-end" justifyContent="center" sx={{ px: 2, pb: 4, mt: 25 }}>
                                {data?.length === 1 ? null : (
                                    <ComplianceTip
                                        tip={
                                            <>
                                                <Typography
                                                    color="primary"
                                                    sx={{ fontWeight: 500, fontSize: 13 }}
                                                    component="span"
                                                >
                                                    {t('submit')}
                                                </Typography>{' '}
                                                {t('before')}
                                            </>
                                        }
                                    />
                                )}
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    component="footer"
                                    sx={{
                                        borderRadius: 1,
                                        p: 1.25,
                                        width: '100%',
                                    }}
                                >
                                    {(resource === 'doc' || !isFieldTouched) && data.length > 1 ? (
                                        <AppButton
                                            variant="contained"
                                            color="primary"
                                            name={t('next')}
                                            onClick={handleNextClick}
                                        />
                                    ) : resource === 'form' && isFieldTouched ? (
                                        <AppSubmitButton
                                            text={data[currentResourcePage - 1] ? t('update') : t('submitt')}
                                            variant="contained"
                                            color="primary"
                                            loading={submitting}
                                        />
                                    ) : null}
                                    <Box sx={{ flex: 1 }} />
                                    {data?.length === 1 ? null : (
                                        <CompliancePagination
                                            page_={currentResourcePage}
                                            dataLength={data?.length}
                                            noPerPage={1}
                                            onPageChange={goToNextResource}
                                            data={data}
                                            standard={standard}
                                            selectedSubgroup={selectedSubgroup}
                                            pagesAssigned={pagesAssigned}
                                        />
                                    )}
                                </Stack>
                            </Stack>
                        )}
                    </Box>
                </>
            ) : (
                <Stack sx={{ flex: 1 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        component="header"
                        sx={{
                            px: 2,
                            py: 1,
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            height: '100%',
                        }}
                    >
                        <Box
                            component="button"
                            onClick={goBack}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                backgroundColor: 'inherit',
                                border: 'none',
                                outline: 'none',
                            }}
                        >
                            <ChevronLeftRounded
                                sx={{
                                    width: 32,
                                    height: 32,
                                    backgroundColor: theme.palette.gray[50] + '80',
                                    borderRadius: 16,
                                }}
                                color="primary"
                            />
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    color: theme.palette.primary[900],
                                    ml: 1.25,
                                }}
                            >
                                {title}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: theme.palette.primary[700],
                            }}
                        >
                            {groupMetric}
                        </Typography>
                    </Stack>
                    <Box sx={{ flex: 1 }}>{children}</Box>
                    {data?.length > 0 && (
                        <Stack alignItems="flex-end" justifyContent="center" sx={{ px: 2, pb: 4 }}>
                            {data?.length === 1 ? null : (
                                <ComplianceTip
                                    tip={
                                        <>
                                            <Typography
                                                color="primary"
                                                sx={{ fontWeight: 500, fontSize: 13 }}
                                                component="span"
                                            >
                                                {t('submit')}
                                            </Typography>{' '}
                                            {t('before')}
                                        </>
                                    }
                                />
                            )}
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                component="footer"
                                sx={{
                                    backgroundColor: theme.palette.gray[50] + '50',
                                    borderRadius: 1,
                                    p: 1.25,
                                    width: '100%',
                                }}
                            >
                                {(resource === 'doc' || !isFieldTouched) && data.length > 1 ? (
                                    <AppButton
                                        variant="contained"
                                        color="primary"
                                        name={t('next')}
                                        onClick={handleNextClick}
                                    />
                                ) : resource === 'form' && isFieldTouched ? (
                                    <AppSubmitButton
                                        text={data[currentResourcePage - 1] ? t('update') : t('submitt')}
                                        variant="contained"
                                        color="primary"
                                        loading={submitting}
                                    />
                                ) : null}
                                <Box sx={{ flex: 1 }} />
                                {data?.length === 1 ? null : (
                                    <CompliancePagination
                                        page_={currentResourcePage}
                                        dataLength={data?.length}
                                        noPerPage={1}
                                        onPageChange={goToNextResource}
                                        data={data}
                                        standard={standard}
                                        selectedSubgroup={selectedSubgroup}
                                        pagesAssigned={pagesAssigned}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            )}
        </>
    );
};

export default SubgroupView;
