import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { IconButton, Stack, Typography, OutlinedInput } from '@mui/material';
import { Download, Upload, Delete } from '@mui/icons-material';
import AppUserGuide from 'components/new_components/AppUserGuide';
import { steps } from './steps';

// core components
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
import AppButton from 'components/new_components/AppButton';
import AppProgressBar from 'components/new_components/AppProgressBar';
import UploadQuestionnaireModal from './UploadQuestionnaireModal';

// icons
import { ReactComponent as Completed } from 'assets/img/icons/completed.svg';
import { ReactComponent as InProgress } from 'assets/img/icons/inprogress.svg';
import { ReactComponent as Analysed } from 'assets/img/icons/analysed.svg';

// utils
import { formatAddedDate } from '../../utils/utils';
// import { merchantQuestionnaire } from "../../utils/constants";
import { connect } from 'react-redux';

import { UserGuideContext } from 'store/context/UserGuideContextProvider';

import { GetAllUploadedVendorQuestionnaire, DeleteUploadedVendorQuestionnaire } from 'store/actions/vendorActions';
import DeleteQuestionnaireModal from './DeleteQuestionnaireModal';
import { answeredQuestionsPercentage } from 'utils';

//translations
import { useTranslation } from 'react-i18next';

const ActingAsAVendorTable = (props) => {
    const {
        handleViewChange,
        GetAllUploadedVendorQuestionnaire,
        vendor_uploaded_questionnaire,
        currentTab,
        userguide_info,
        DeleteUploadedVendorQuestionnaire,
    } = props;

    const [openUserGuide, setOpenUserGuide] = useState(false);
    const theme = useTheme();

    const stepsTargetRef = useRef([]);

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deletePayload, setDeletePayload] = useState(0);
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    //translation
    const { t } = useTranslation('vendorManagement');

    //function
    const handleDownload = (e, fileURl) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        window.open(fileURl, '_blank');
    };
    const handleDelete = (e, id) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setDeleteModalOpen(true);
        setDeletePayload(id);
    };
    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
        setDeletePayload(0);
    };
    const openUploadModal = () => {
        setModalOpen(true);
    };
    const closeUploadModal = () => {
        setModalOpen(false);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ acting_as_a_vendor: true });
        setToggleUserGuide({ ...toggleUserGuide, actingasavendor: false, vendors: false });
        setOpenUserGuide(false);
    };

    useEffect(() => {
        !userguide_info || toggleUserGuide.actingasavendor ? OpenUserGuide() : null;
    }, [userguide_info, toggleUserGuide.actingasavendor]);

    // const answeredQuestionsPercentage = (answeredQuestionsScore, totalQuestions) => {
    //     let result = Math.floor((answeredQuestionsScore / totalQuestions) * 100);

    //     return result;
    // };
    const columns = [
        {
            title: t('actingAsAVendorTable.column1'),
            render: (row) => (
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    {row.status === 'completed' ? (
                        <Completed />
                    ) : row.status === 'in_progress' ? (
                        <InProgress />
                    ) : (
                        <Analysed />
                    )}
                    <span style={{ wordBreak: 'break-all' }}>{row?.name}</span>
                </Stack>
            ),
        },
        {
            title: t('actingAsAVendorTable.column2'),
            render: (row) => (
                <>
                    {row?.status?.toLowerCase() === 'completed' ? (
                        <AppTag text={t('actingAsAVendorTable.completed')} type="success" />
                    ) : row?.status === 'in_progress' ? (
                        <AppTag text={t('actingAsAVendorTable.inProgress')} type="pending" />
                    ) : row?.status?.toLowerCase() === 'analysing' ? (
                        <AppTag
                            text={
                                <Typography sx={{ fontStyle: 'italic' }}>{t('actingAsAVendorTable.high')}</Typography>
                            }
                            type="primary"
                        />
                    ) : row?.status?.toLowerCase() === 'analysed' ? (
                        <AppTag text={t('actingAsAVendorTable.analysed')} type="primary" />
                    ) : (
                        <AppTag text={row?.status} type="default" />
                    )}
                </>
            ),
        },
        {
            title: t('actingAsAVendorTable.column3'),
            render: (row) => {
                const { date, time } = formatAddedDate(
                    row?.status?.toLowerCase() === 'completed' ? row?.date_updated : row?.date_created
                );
                return (
                    <>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                color: theme.palette.gray[900],
                                fontSize: 14,
                            }}
                        >
                            {date}.
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                color: theme.palette.gray[900],
                                fontSize: 14,
                            }}
                        >
                            ({time})
                        </Typography>
                    </>
                );
            },
        },
        {
            title: '',
            align: 'center',
            render: (row) => {
                const isCompleted = row?.status?.toLowerCase() === 'completed';

                return (
                    <Stack direction="row" spacing={1}>
                        <IconButton disabled={!row?.file} onClick={(e) => handleDownload(e, row?.file)}>
                            <Download color={!isCompleted ? 'disabled' : 'primary'} />
                        </IconButton>
                        <IconButton color="error" onClick={(e) => handleDelete(e, row?.id)}>
                            <Delete color="error" />
                        </IconButton>
                    </Stack>
                );
            },
        },
        {
            title: '',
            align: 'center',
            render: (row) => {
                return (
                    <>
                        <AppProgressBar
                            sx={{
                                width: 130,
                                '&.MuiLinearProgress-colorSuccess': {
                                    backgroundColor: row?.status?.includes('analy')
                                        ? 'initial'
                                        : theme.palette.warning[900],
                                },
                            }}
                            value={answeredQuestionsPercentage(row?.accepted_count, row?.question_count)}
                            color={row?.status?.includes('analy') ? 'primary' : 'success'}
                        />
                    </>
                );
            },
        },
    ];
    const handlePageChange = (page) => {
        setPage(page);
    };

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };
    useEffect(() => {
        const getAllUploadedVendorQuestionnaire = async () => {
            setloading(true);
            const res = await GetAllUploadedVendorQuestionnaire();
            if (!res?.success) {
                toast.error(res?.success);
                setloading(false);
            }
            setloading(false);
        };
        getAllUploadedVendorQuestionnaire();
    }, []);

    const allSortedVendorQuestionaires = useMemo(() => {
        return vendor_uploaded_questionnaire?.length
            ? currentTab === 'all'
                ? vendor_uploaded_questionnaire?.sort((a, b) => b?.id - a?.id)
                : currentTab === 'in_progress'
                ? vendor_uploaded_questionnaire
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.filter((res) => {
                          return (
                              res?.status === 'analysed' ||
                              res?.status === 'pending' ||
                              res?.status === 'analyzing' ||
                              res?.status === 'in_progress'
                          );
                      })
                : currentTab === 'completed'
                ? vendor_uploaded_questionnaire
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.filter((res) => res?.status === currentTab)
                : []
            : [];
    }, [currentTab, vendor_uploaded_questionnaire]);
    useEffect(() => {
        const start = (page - 1) * 4;
        const stop = start + 4;
        if (searchInput) {
            const searchResult = allSortedVendorQuestionaires?.filter((questionnaire) =>
                questionnaire?.name?.toLowerCase().includes(searchInput?.toLowerCase())
            );
            setPageData(searchResult?.slice(start, stop));
        } else {
            setPageData(allSortedVendorQuestionaires?.slice(start, stop));
        }

        return () => setPageData([]);
    }, [page, allSortedVendorQuestionaires, currentTab, searchInput, vendor_uploaded_questionnaire]);
    return (
        <>
            <AppTable
                columns={columns}
                data={pageData}
                loading={loading}
                sorter={(a, b) => b?.id - a?.id}
                title=""
                dataLength={allSortedVendorQuestionaires?.length}
                noPerPage={4}
                onPageChange={handlePageChange}
                actions={[
                    <OutlinedInput
                        id="search"
                        name="search"
                        value={searchInput}
                        onChange={handleInputChange}
                        placeholder={t('actingAsAVendorTable.search')}
                        type="text"
                        key={1}
                        sx={{
                            height: 48,
                            border: '1px solid',
                            borderColor: theme.palette.gray[900],
                            color: theme.palette.gray[900],
                            textTransform: 'capitalize !important',
                            overflow: 'hidden',
                            '&.Mui-disabled': { borderColor: theme.palette.gray[100] },
                            '& fieldset': { border: '0 !important' },
                        }}
                    />,
                    <AppButton
                        icon={<Upload />}
                        name={t('actingAsAVendorTable.upload')}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={openUploadModal}
                        key={2}
                        ref={(el) => (stepsTargetRef.current[0] = el)}
                    />,
                ]}
                rowSpacing={true}
                clickableRow={() => true}
                onRowClick={(row) => handleViewChange(row?.id)}
            />

            <UploadQuestionnaireModal open={modalOpen} handleClose={closeUploadModal} />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />

            <DeleteQuestionnaireModal
                open={deleteModalOpen}
                handleClose={handleCloseDeleteModal}
                DeleteUploadedVendorQuestionnaire={DeleteUploadedVendorQuestionnaire}
                id={deletePayload}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        vendor_uploaded_questionnaire: state?.vendorReducers?.vendor_uploaded_questionnaire,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.acting_as_a_vendor,
    };
};
export default connect(mapStateToProps, { GetAllUploadedVendorQuestionnaire, DeleteUploadedVendorQuestionnaire })(
    ActingAsAVendorTable
);
