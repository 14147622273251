import {
    ALL_AUDIT_CATEGORIES,
    ALL_ACCESSOR_CATEGORIES,
    ALL_AUDIT_ANSWERS,
    ALL_AUDIT_REQUESTS,
    ALL_ADMIN_AUDITORS,
    ALL_QA_REVIEWERS,
    MERCHANT_CERTIFICATE,
    ALL_MERCHANTS_CERTIFICATE,
    CERTIFICATE_VALIDDATE,
    CERTIFICATE_DETAILS,
    ALL_ACCESSOR_ANSWERS,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_AUDIT_CATEGORIES:
            return {
                ...state,
                all_audit_categories: action.payload,
            };
        case ALL_ACCESSOR_CATEGORIES:
            return {
                ...state,
                all_accessor_categories: action.payload,
            };
        case ALL_AUDIT_ANSWERS:
            return {
                ...state,
                all_audit_answers: action.payload,
            };
        case ALL_AUDIT_REQUESTS:
            return {
                ...state,
                all_audit_requests: action.payload,
            };
        case ALL_ADMIN_AUDITORS:
            return {
                ...state,
                all_admin_auditors: action.payload,
            };
        case ALL_QA_REVIEWERS:
            return {
                ...state,
                all_qa_reviewers: action.payload,
            };
        case ALL_MERCHANTS_CERTIFICATE:
            return {
                ...state,
                all_merchants_certificate: action.payload,
            };
        case MERCHANT_CERTIFICATE:
            return {
                ...state,
                merchant_certificate: action.payload,
            };
        case CERTIFICATE_VALIDDATE:
            return {
                ...state,
                valid_certificate: action.payload,
            };
        case CERTIFICATE_DETAILS:
            return {
                ...state,
                certificate_details: action.payload,
            };
        case ALL_ACCESSOR_ANSWERS:
            return {
                ...state,
                all_accessor_answers: action.payload,
            };
        default:
            return state;
    }
};
