import { Drawer } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { Avatar, Stack, IconButton } from '@mui/material';
import LoadingState from 'components/new_components/LoadingState';
import { Topic } from '@mui/icons-material';
import { BsDownload } from 'react-icons/bs';
import moment from 'moment';

// import { toast } from 'react-toastify';

// redux
import { connect } from 'react-redux';
import { GetAllMerchantDocumentResponse } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';

const ViewMerchantResponses = (props) => {
    const { visible, closeDrawer, GetAllMerchantDocumentResponse, all_merchant_docs_responses, selectedDoc } = props;
    const [loading, setLoading] = useState({});

    const getFileName = (url) => {
        const fileName = url?.split('/')?.[4];
        return fileName;
    };

    const filteredDocResponses = useMemo(() => {
        return all_merchant_docs_responses?.filter((docs) => docs?.doc === selectedDoc?.id);
    }, [all_merchant_docs_responses, selectedDoc?.id]);
    console.log(all_merchant_docs_responses);
    // useEffect
    useEffect(() => {
        if (selectedDoc?.merchant) {
            const getAllMerchantDocumentResponse = async () => {
                setLoading({ [selectedDoc?.id]: true });
                const res = await GetAllMerchantDocumentResponse(selectedDoc?.merchant);
                setLoading({});
                if (!res?.success) {
                    toast.error(res?.message);
                }
            };
            getAllMerchantDocumentResponse();
        }
    }, [selectedDoc?.merchant]);

    return (
        <div>
            <Drawer
                title="Document Responses"
                open={visible}
                width="70%"
                getContainer={false}
                onClose={closeDrawer}
                destroyOnClose={true}
                contentWrapperStyle={{
                    overflowY: 'auto',
                    height: '100vh',
                }}
            >
                {!loading?.[selectedDoc?.id] ? (
                    <>
                        {filteredDocResponses?.length > 0 ? (
                            <div className="userdataContainer">
                                {filteredDocResponses?.map((doc) => (
                                    <div className="item" key={doc?.id}>
                                        <Stack flexDirection="row" justifyContent="space-between">
                                            <Stack flexDirection="row" alignItems="flex-start" gap={2}>
                                                <Avatar sx={{ bgcolor: '#F8F8F8' }} variant="square">
                                                    <Topic sx={{ color: '#395BA9' }} />
                                                </Avatar>
                                                <div>
                                                    <p className="text-[14px] font-medium text-[#395BA9]">
                                                        {getFileName(doc?.response_file)}
                                                    </p>
                                                    <p className="text-[12px] font-normal mt-1 text-[#5E5E62]">
                                                        Uploaded{' '}
                                                        {moment(doc?.date_created).format('MMMM Do, YYYY (h:mm a)')}
                                                    </p>
                                                </div>
                                            </Stack>

                                            <Stack flexDirection="row" alignItems="center">
                                                <IconButton
                                                    aria-label="download"
                                                    component="a"
                                                    href={doc?.response_file}
                                                    download
                                                >
                                                    <BsDownload color="#77777A" size="18px" />
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex justify-center items-center">
                                <h1>No Documents Available</h1>
                            </div>
                        )}
                    </>
                ) : (
                    <LoadingState />
                )}
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_docs_responses: state?.merchantReducers?.all_merchant_docs_responses,
    };
};

export default connect(mapStateToProps, { GetAllMerchantDocumentResponse })(ViewMerchantResponses);
