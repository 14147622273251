import React from 'react';
//core
import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AppAgentModal = ({ open, handleClose, title, children, width }) => {
    //states
    // const [uploading, setUploading] = useState(false);
    //theme
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            sx={{
                '& .MuiDialog-container': {
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                },
            }}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    borderRadius: 4,
                    position: 'relative',
                    top: 160,
                    width,
                    maxHeight: 550,
                },
            }}
            scroll="paper"
        >
            {title && (
                <DialogTitle
                    sx={{
                        display: 'flex',
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.gray[900],
                    }}
                >
                    {title}
                </DialogTitle>
            )}
            <DialogContent sx={{ padding: '0 0.5rem' }}>{children}</DialogContent>
        </Dialog>
    );
};

export default AppAgentModal;
