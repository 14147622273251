import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { SearchOutlined } from '@mui/icons-material';
import { Button, Col, Input, Modal, Row, Table, Tag } from 'antd';
import BreadCrumb from 'components/Breadcrumb';

import ViewRequestModal from './components/ViewRequestModal';
import ReviewerModal from './components/ReviewerModal';

import { AUDIT_STATUS } from 'views/merchant/audit/utils';
import useSearch from 'hooks/useSearch';

// redux
import { connect } from 'react-redux';
import { GetAllMerchants } from 'store/actions/adminActions';
import { GetAllAuditRequests, DeleteAuditRequest } from 'store/actions/auditActions';
import ROCPreview from './components/ROCPreview';
import RequestActions from './components/RequestActions';
import UploadRocModal from './components/UploadRocModal';

const Requests = (props) => {
    // props
    const { GetAllMerchants, GetAllAuditRequests, all_audit_requests, all_merchants, DeleteAuditRequest } = props;

    // state
    const [loading, setLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [ROCmodal, setROCmodal] = useState(false);
    const [reviewers, setReviewers] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [data_, setData] = useState({});

    const { data, handleSearch } = useSearch(all_audit_requests, [
        'name',
        'compliance',
        'owner_detail.first_name',
        'owner_detail.last_name',
    ]);

    // functions
    const openViewModal = (data_ = {}) => {
        setViewModal(true);
        setData(data_);
    };
    const closeViewModal = () => {
        setViewModal(false);
        setData({});
    };

    const openROCmodal = (data_ = {}) => {
        setROCmodal(true);
        setData(data_);
    };
    const openReviewersModal = (data_ = {}) => {
        setReviewers(true);
        setData(data_);
    };
    const closeReviewersModal = (data_ = {}) => {
        setReviewers(false);
        setData(data_);
    };
    const closeROCmodal = () => {
        setROCmodal(false);
        setData({});
    };
    const openUploadModal = (mode, data_ = {}) => {
        setUploadModal(mode);
        setData(data_);
    };
    const closeUploadModal = () => {
        setUploadModal(null);
        setData({});
    };

    const getStatusProps = (status) => {
        switch (status) {
            case AUDIT_STATUS.IN_REVIEW:
                return { color: 'warning', text: 'In Review' };
            case AUDIT_STATUS.AUDITING:
                return { color: 'processing', text: 'Auditing' };
            case AUDIT_STATUS.DISAPPROVED:
                return { color: 'error', text: 'Disapproved' };
            case AUDIT_STATUS.COMPLETE:
                return { color: 'success', text: 'Completed' };
            case AUDIT_STATUS.DRAFT:
                return { color: '#9ea9bd30', text: 'Draft' };
            default:
                return {};
        }
    };
    const getAllMerchants = async () => {
        setLoading(true);
        const res = await GetAllMerchants();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllAuditRequests = async () => {
        setLoading(true);
        const res = await GetAllAuditRequests();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const deleteModal = (id) => {
        Modal.confirm({
            title: 'Do you want to delete this ROC?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteAuditRequest(id);
                if (res?.success) {
                    toast.success('Deleted Successfully');
                } else {
                    toast.error(res?.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getAllMerchants();
        getAllAuditRequests();
    }, []);

    // constant
    const columns = [
        {
            title: 'Company Name',
            render: (record) => {
                const merchant = all_merchants?.find((merchant) => merchant?.id === record?.merchant);
                return <>{merchant?.name}</>;
            },
        },
        {
            title: 'Merchant',
            render: (record) => {
                const merchant = all_merchants?.find((merchant) => merchant?.id === record?.merchant);
                return <>{`${merchant?.owner_detail?.first_name} ${merchant?.owner_detail?.last_name}`}</>;
            },
        },
        {
            title: 'Compliance Standard',
            render: (record) => {
                return <span className="uppercase">{record?.compliance}</span>;
            },
        },
        {
            title: 'Status',
            render: (record) => {
                const status = getStatusProps(record?.status);
                return <Tag color={status.color}>{status.text}</Tag>;
            },
        },
        {
            title: 'Effective From',
            render: (record) => {
                if (!record?.effective_from) {
                    return <MinusOutlined />;
                } else {
                    const [start, end] = record.effective_from.split(',');
                    const startDate = new Date(start),
                        endDate = new Date(end);
                    return (
                        <span>
                            {startDate?.toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}{' '}
                            -{' '}
                            {endDate?.toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                        </span>
                    );
                }
            },
        },
        {
            title: 'Actions',
            align: 'right',
            render: (record) => (
                <RequestActions
                    openViewModal={openViewModal}
                    record={record}
                    openROCmodal={openROCmodal}
                    deleteModal={deleteModal}
                    openEditModal={openUploadModal}
                    openReviewersModal={openReviewersModal}
                />
            ),
        },
    ];

    return (
        <div>
            <Row justify="space-between" align="center">
                <Col>
                    <BreadCrumb views={[{ label: 'Audit ROCs' }]} />
                </Col>
                <Col span={12} className="flex items-center">
                    <Input
                        prefix={<SearchOutlined className="text-gray-300" />}
                        placeholder="Search by keyword"
                        onChange={handleSearch}
                        className="flex-1"
                        size="large"
                    />
                    <Button
                        onClick={() => openUploadModal('Add')}
                        type="primary"
                        icon={<PlusOutlined />}
                        className="flex justify-between items-center ml-3"
                    >
                        Upload Document
                    </Button>
                </Col>
            </Row>
            <section className="my-3">
                <Table
                    className="table table-hover table-radius overflow-x-auto"
                    rowKey={(request) => request?.id}
                    loading={loading}
                    columns={columns}
                    dataSource={data?.sort((a, b) => b?.id - a?.id)}
                />
            </section>
            <ViewRequestModal open={viewModal} modalData={data_} handleClose={closeViewModal} />
            <ReviewerModal open={reviewers} modalData={data_} handleClose={closeReviewersModal} />
            <ROCPreview open={ROCmodal} modalData={data_} handleClose={closeROCmodal} />
            <UploadRocModal
                open={Boolean(uploadModal)}
                mode={uploadModal}
                closeModal={closeUploadModal}
                modalData={data_}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    all_merchants: state?.adminReducers?.all_merchants,
    all_audit_requests: state?.auditReducers?.all_audit_requests,
    user_info: state?.generalReducers?.user_info,
});
export default connect(mapStateToProps, { GetAllMerchants, GetAllAuditRequests, DeleteAuditRequest })(Requests);
