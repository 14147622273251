import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
//core components
import AppTable from 'components/new_components/app-table';
import ResponsesTableAction from './ResponsesTableAction';
import ScoreCardModal from './ScoreCardModal';
import AppTag from 'components/new_components/AppTags';

//redux
import { connect } from 'react-redux';
import { GetAllResponses, GetAllVendors } from 'store/actions/vendorActions';

//utils
import { formatDateObjectHandler, GetFromNowDate, truncateText } from 'utils';
import PageHeader from 'components/new_components/PageHeader';
import ReadOnlyVendor from '../VendorsView/ReadOnlyVendor';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 8;

const Responses = (props) => {
    const { GetAllResponses, GetAllVendors, all_responses, all_vendors } = props;
    const theme = useTheme();

    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [scoreModalOpen, setScoreModaOpen] = useState(false);
    const [vendorID, setVendorID] = useState(0);
    const [scaleModalOpen, setScaleModalOpen] = useState(null);
    const [merchantId, setMerchantId] = useState('');
    const [view, setView] = useState(false);

    //translation
    const { t } = useTranslation('vendorManagement');

    // functions
    const columns = [
        {
            title: t('vendorResponseTable.column1'),
            render: (row) => <>{row?.vendor_details?.name}</>,
        },
        {
            title: t('vendorResponseTable.column2'),
            key: 'email',
            render: (row) => <>{row?.vendor_details?.email}</>,
        },
        {
            title: t('vendorResponseTable.column3'),
            key: 'overall_risk_level',
            render: (row) => (
                <>
                    {row?.vendor_details?.overall_risk_level?.toLowerCase() === 'low' ? (
                        <AppTag text="Low" type="success" />
                    ) : row?.vendor_details?.overall_risk_level === 'medium' ? (
                        <AppTag text="Medium" type="pending" />
                    ) : row?.vendor_details?.overall_risk_level?.toLowerCase() === 'high' ? (
                        <AppTag text="High" type="failed" />
                    ) : (
                        <AppTag text={row?.vendor_details?.overall_risk_level?.toUpperCase()} type="default" />
                    )}
                </>
            ),
        },
        {
            title: t('vendorResponseTable.column4'),
            key: 'product',
            render: (row) => <>{truncateText(row?.vendor_details?.products)}</>,
        },
        {
            title: t('vendorResponseTable.column5'),
            key: 'date_created',
            render: (row) => (
                <>
                    <p>{formatDateObjectHandler(row?.date_created, 'MMM Do, YYYY')}</p>
                    <Box
                        component="span"
                        sx={{
                            color: theme.palette.gray[500],
                            ml: 0.2,
                            fontSize: '0.9rem',
                            fontWeight: 400,
                        }}
                    >
                        ({GetFromNowDate(row?.date_created, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')})
                    </Box>
                </>
            ),
        },
        {
            title: t('vendorResponseTable.column6'),
            align: 'center',
            key: 'action',
            render: (row) => {
                return (
                    <ResponsesTableAction
                        row={row}
                        openScoreModal={openScoreModal}
                        handleChangeView={handleChangeView}
                    />
                );
            },
        },
    ];

    //functions
    const handlePageChange = (page) => {
        setPage(page);
    };

    const goBack = () => setView(false);

    const openScoreModal = (id) => {
        setScoreModaOpen(true);
        setVendorID(id);
    };
    const closeScoreModal = () => {
        setScoreModaOpen(false);
        setTimeout(() => setVendorID(0), []);
    };

    const openScaleModal = (event) => {
        setScaleModalOpen(event.currentTarget);
    };

    const closeScaleModal = () => {
        setScaleModalOpen(null);
    };

    const open = Boolean(scaleModalOpen);

    const handleChangeView = (merchantId = '', vendorId = '') => {
        setView(!view);
        setVendorID(vendorId);
        setMerchantId(merchantId);
    };

    useEffect(() => {
        const getAllResponses = async () => {
            setloading(true);
            const res = await GetAllResponses();
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllResponses();
    }, []);
    useEffect(() => {
        const getAllVendors = async () => {
            setloading(true);
            const res = await GetAllVendors();
            setloading(false);
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAllVendors();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        let respondedVendors = all_vendors?.filter((vendor) => vendor?.has_responded);
        let filteredResponses = respondedVendors?.map((vendor) => {
            const response = all_responses?.find((response) => response?.vendor === vendor?.id);
            return response;
        });

        setPageData(filteredResponses ? filteredResponses?.slice(start, stop) : []);

        return () => setPageData([]);
    }, [page, all_responses, all_vendors]);

    return (
        <>
            <div>
                <PageHeader browserTitle="Vendor Responses | Smartcomply" />
                {view ? (
                    <ReadOnlyVendor vendor={vendorID} merchant={merchantId} goBack={goBack} />
                ) : (
                    <AppTable
                        columns={columns}
                        data={pageData}
                        loading={loading}
                        sorter={(a, b) => b?.id - a?.id}
                        title={t('vendorResponseTable.title')}
                        dataLength={pageData?.length}
                        noPerPage={NO_PER_PAGE}
                        page={page}
                        onPageChange={handlePageChange}
                    />
                )}
            </div>

            <ScoreCardModal
                open={scoreModalOpen}
                handleClose={closeScoreModal}
                scaleModalOpen={open}
                openScaleModal={openScaleModal}
                closeScaleModal={closeScaleModal}
                id={vendorID}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_responses: state?.vendorReducers?.all_responses,
        all_vendors: state?.vendorReducers?.all_vendors,
    };
};

export default connect(mapStateToProps, { GetAllResponses, GetAllVendors })(Responses);
