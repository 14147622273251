import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import CircularLoader from 'components/Loaders/CircularLoader';
import { West } from '@mui/icons-material';
import FlutterLogo from 'assets/img/flutter_.png';
// import Paystack from 'assets/img/paystack2.png';
import WhiteLogo from 'assets/img/brand/Logo_blue.svg';
import Signature from 'assets/img/Certificate-signature.png';
import Logo386k from 'assets/img/386k.png';
import QRCode from 'qrcode.react';

import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import AppButton from 'components/new_components/AppButton';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Barcode from 'react-barcode';

// Redux
import { connect } from 'react-redux';
import { GetMerchantCertificate } from 'store/actions/auditActions';
import moment from 'moment';

//Userguide
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const CertificateView = (props) => {
    const { GetMerchantCertificate, merchant_certificates, setView, merchant_info, userguide_info } = props;
    const theme = useTheme();
    const certificateRef = useRef();

    const [loading, setLoading] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);

    const merchantCertificates = async () => {
        setLoading(true);
        const response = await GetMerchantCertificate();
        setLoading(false);
        if (!response?.success) {
            toast.error('Certificate');
        }
    };

    //userguide
    const { updateOneUserGuide } = useContext(UserGuideContext);

    const ref1 = useRef(null);

    const STEPS_LENGTH = 1;

    const steps = [
        {
            title: <UserGuideHeader title="Download" id={1} length={STEPS_LENGTH} />,
            description: 'Download your compliance certificate once the audit process is done.',
            target: () => ref1.current,
            placement: 'leftTop',
        },
    ];

    //UserGuide functions
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ audits_download: true });
        setOpenUserGuide(false);
    };

    useEffect(() => {
        merchantCertificates();
    }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : null;
    }, [userguide_info]);

    // const generateCertificate = () => {
    //     html2canvas(certificateRef.current).then(function (canvas) {
    //         download(canvas);
    //     });
    // };
    // const download = (canvas) => {
    //     var link = document.createElement('a');
    //     link.download = 'certificate.png';
    //     link.href = canvas.toDataURL();
    //     link.click();
    // };

    const generateCertificate = () => {
        const input = document.getElementById('certificate');
        input.style.padding = '50px 0 50px 0';
        document.getElementById('cert_div').style.margin = '0 0 0 2rem';
        document.getElementById('cert_name').style.margin = '-2rem 0 0 0';
        document.getElementById('version').style.width = '65%';
        document.getElementById('version').style.margin = '2rem 0 0 3rem';
        document.getElementById('version_text').style.fontSize = '22px';
        document.getElementById('version_text').style.color = '#434141';
        document.getElementById('version_text').style.fontWeight = '300';
        document.getElementById('customer').style.padding = '0 0 1rem 0';
        document.getElementById('covered').style.padding = '0 0 1.5rem 1rem';
        document.getElementById('ecommerce').style.padding = '0 0 1.5rem 0';
        document.getElementById('implemented').style.padding = '0 0 1.5rem 1rem';
        document.getElementById('smart_logo').style.padding = '0.8rem 0';
        document.getElementById('issue_text').style.width = '46%';
        document.getElementById('issue_date').style.padding = '0 0 1.5rem 0';
        document.getElementById('386k').style.margin = '-2rem 0 1rem 0';
        document.getElementById('sign').style.margin = '-3rem 0 1rem -2.8rem';
        document.getElementById('qr').style.margin = '0 0 0 1rem';
        window.scrollTo(0, 0);
        html2canvas(input).then((canvas) => {
            let imgWidth = 200;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;

            const imgData = canvas.toDataURL('img/png');
            var base64 = imgData.replace(/^data:.+;base64,/, '');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(base64, 'PNG', 5, 5, imgWidth, imgHeight);
            pdf.save('certificate.pdf');
            window.scrollTo(0, document.body.scrollWidth || document.documentElement.scrollWidth);
        });
        // var pdf = new jsPDF();
        // var img = new Image();
        // var canvas = document.createElement('canvas');
        // var ctx = canvas.getContext('2d');
        // img = document.createElement('img');
        // img.src = input;
        // img.onload = function () {
        //     canvas.height = img.height;
        //     canvas.width = img.width;
        //     ctx.drawImage(img, img.width, img.width);
        //     var dataURL = canvas.toDataURL('image/png');
        //     var base64 = dataURL.replace(/^data:.+;base64,/, '');
        //     pdf.text('Hi');
        //     pdf.addImage(base64, 'PNG', 5, 5, 50, 50);
        //     pdf.save('download.pdf');
        // };
        document.getElementById('cert_div').style.margin = 0;
        document.getElementById('cert_name').style.margin = 0;
        document.getElementById('version').style.width = '60%';
        document.getElementById('version').style.margin = '2rem 0 0 0';
        document.getElementById('version_text').style.fontSize = '18px';
        document.getElementById('customer').style.padding = 0;
        document.getElementById('covered').style.padding = '0.5rem 1rem';
        document.getElementById('ecommerce').style.padding = '0.5rem 0';
        document.getElementById('implemented').style.padding = '0.5rem 1rem';
        document.getElementById('smart_logo').style.padding = '0.6rem 0';
        document.getElementById('issue_text').style.width = '48%';
        document.getElementById('issue_date').style.padding = 0;
        document.getElementById('386k').style.margin = '-2rem 0 1rem 0';
        document.getElementById('sign').style.margin = '-3rem 0 2rem -2.8rem';
        document.getElementById('qr').style.margin = '0 0 0 0';
    };

    return (
        <>
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 6 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.primary[900],
                            textDecoration: 'none',
                            gap: 1,
                            m: 2,
                        }}
                        onClick={() => setView('table')}
                    >
                        <West color="primary" />
                        <Typography sx={{ color: theme.palette.primary[900], fontWeight: 700, textDecoration: 'none' }}>
                            Back to Audit
                        </Typography>
                    </Box>
                    <AppButton
                        name="Download Certificate"
                        color="primary"
                        variant="contained"
                        onClick={generateCertificate}
                        ref={ref1}
                    />
                </Stack>
                {loading ? (
                    <CircularLoader />
                ) : (
                    <Box
                        ref={certificateRef}
                        id="certificate"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '70%' }}
                            id="cert_div"
                        >
                            <Box
                                sx={{
                                    border: '3.5px solid #065995',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingLeft: '0.5rem',
                                    width: '50%',
                                    height: '80px',
                                    '& img': {
                                        maxHeight: '95%',
                                    },
                                }}
                            >
                                {merchant_info?.cert_body?.toLowerCase() == 'flutterwave' ? (
                                    <img src={FlutterLogo} alt="Customer's logo" width={'90%'} />
                                ) : merchant_info?.cert_body?.toLowerCase() === '386konsult' ? (
                                    <img src={Logo386k} alt="Customer's logo" width={'80%'} />
                                ) : null}
                            </Box>
                            <Box
                                sx={{
                                    backgroundColor: '#065995',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    width: '50%',
                                    marginLeft: '-1.5rem',
                                    height: '80px',
                                    py: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        letterSpacing: '1px',
                                        fontSize: '30px',
                                        color: '#FFFFFF',
                                        fontWeight: 600,
                                    }}
                                    id="cert_name"
                                >
                                    Certificate
                                </Typography>
                                <Typography
                                    sx={{
                                        letterSpacing: '2px',
                                        fontSize: '18px',
                                        color: '#FFFFFF',
                                        fontWeight: 600,
                                    }}
                                >
                                    OF COMPLIANCE
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 4, ml: 5, width: '60%' }} id="version">
                            <Typography
                                sx={{ color: '#434141', fontSize: '18px', fontWeight: 400, textAlign: 'center' }}
                                id="version_text"
                            >
                                This is to certify that named company below has successfully been assessed against
                                PCIDSS version 4.0 and found to be compliant.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                mt: 4,
                                width: '40%',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#065995',
                                    fontSize: '40px',
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    borderBottom: '2px solid #000000',
                                    textTransform: 'uppercase',
                                }}
                                id="customer"
                            >
                                {merchant_certificates &&
                                    (merchant_certificates[0]?.company_name || merchant_info?.name)}
                            </Typography>

                            <div
                                style={{
                                    backgroundColor: '#000000',
                                }}
                            ></div>
                        </Box>
                        <Box
                            sx={{
                                width: '50%',
                                mt: 4,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    border: '1px solid #395ba9',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: 400,
                                        color: '#28184f',
                                        letterSpacing: '0.5px',
                                        width: '250px',
                                        pl: 2,
                                        py: 1,
                                    }}
                                    id="covered"
                                >
                                    Service Covered
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '60%',
                                    backgroundColor: '#065995',
                                    color: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    px: 2,
                                    [theme.breakpoints.down('xl')]: {
                                        width: '80%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    },
                                }}
                            >
                                <Typography
                                    sx={{ fontSize: '18px', fontWeight: 400, letterSpacing: '0.5px', py: 1.2 }}
                                    id="ecommerce"
                                >
                                    {merchant_info?.service_covered}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '35%',
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <Box
                                sx={{
                                    border: '1px solid #395ba9',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: 400,
                                        color: '#28184f',
                                        letterSpacing: '0.5px',
                                        width: '240px',
                                        py: 1,
                                        pl: 2,
                                    }}
                                    id="implemented"
                                >
                                    Implemented by
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '55%',
                                    borderRight: '1px solid #065995',
                                    borderBottom: '1px solid #065995',
                                    borderTop: '1px solid #065995',
                                    // width: '55%',
                                    backgroundColor: '#065995',
                                    py: 1.15,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    [theme.breakpoints.down('md')]: {
                                        width: '80%',
                                    },
                                }}
                                id="smart_logo"
                            >
                                <img src={WhiteLogo} alt="WhiteLogo" width="120px" />
                            </Box>
                        </Box>
                        <Box sx={{ width: '48%', mt: 8 }} id="issue_text">
                            <Typography
                                sx={{ textAlign: 'center', mb: 3, fontSize: '18px', fontWeight: 300, color: '#434141' }}
                            >
                                This certificate covers a one-year period, beginning from the date of compliance
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'end',
                                mb: 5,
                                width: '90%',
                            }}
                        >
                            <Box id="company">
                                <img
                                    src={Signature}
                                    alt="'Signature"
                                    width="250px"
                                    id="sign"
                                    className="ml-[-2.8rem]"
                                />
                                <Typography sx={{ fontSize: '18px', fontWeight: '700', mb: '0.5rem' }} id="386k">
                                    Issued by:
                                </Typography>
                                <img src={Logo386k} alt="'info" width="150px" />
                            </Box>
                            <Box sx={{ width: '40%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {/* <a
                                    href="https://smartcomply.s3.amazonaws.com/Group.png"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={merchant_certificates && merchant_certificates[0]?.code} alt="'info" />
                                </a> */}
                                    <Barcode
                                        value={merchant_certificates && merchant_certificates[0]?.code}
                                        width={4}
                                        height={200}
                                    />
                                </Box>

                                <Box>
                                    <Typography sx={{ textAlign: 'center', my: 2, fontSize: '22px' }}>
                                        Issue Date:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            backgroundColor: '#065995',
                                            fontSize: '22px',
                                            color: '#FFFFFF',
                                            letterSpacing: '2px',
                                            textAlign: 'center',
                                        }}
                                        id="issue_date"
                                    >
                                        {moment(merchant_certificates && merchant_certificates[0]?.date_created).format(
                                            'MMMM-DD-YYYY'
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        transform: 'rotate(-90deg)',
                                        right: 0,
                                        color: '#000000',
                                        fontSize: '18px',
                                        whiteSpace: 'nowrap',
                                        width: '25px',
                                        marginTop: '7rem',
                                    }}
                                >
                                    Scan to verify
                                </Typography>
                                {/* <img src={merchant_certificates && merchant_certificates[0]?.qrcode} width={'150px'} /> */}
                                <QRCode
                                    id="qr"
                                    value={`https://app.smartcomplyapp.com/verification/${merchant_info?.id}`}
                                    size={150}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_certificates: state?.auditReducers?.merchant_certificate,
        merchant_info: state?.merchantReducers?.merchant_info,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.audits_download,
    };
};

export default connect(mapStateToProps, { GetMerchantCertificate })(CertificateView);
