import React, { useEffect, useState, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import AppUserGuide from 'components/new_components/AppUserGuide';
import { steps } from './steps';

//core components
import { Add, Download, ViewCarousel } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
import AppButton from 'components/new_components/AppButton';
import InfrastructuresAgentModal from './InfrastructuresAgentModal';
import InfrastructureTableActions from './InfrastructureTableActions';
import AddInfrastructureAction from './AddInfrastructureAction';
import AppUploadCSVModal from 'components/new_components/AppUploadCSVModal';
import InfrastructuresModal from './InfrastructuresModal';
import InfrastructureViewModal from './InfrastructureViewModal';
import InfrastructureDeleteModal from './InfrastructureDeleteModal';
import PageHeader from 'components/new_components/PageHeader';
import useSearch from 'hooks/useSearch';
//redux
import { connect } from 'react-redux';
import {
    GetAllInfrastructures,
    UploadMerchantInfrastructureFile,
    GetInfrastructureInfo,
} from 'store/actions/merchantActions';

//Utils
import { truncateText, formatDateObjectHandler, GetFromNowDate } from 'utils';
import { initialInfrastructureValues } from './utils/constants';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

//translations
import { useTranslation } from 'react-i18next';

const NO_PER_PAGE = 8;

const Infrastructures = (props) => {
    //props
    const {
        GetAllInfrastructures,
        GetInfrastructureInfo,
        all_merchant_infrastructures,
        merchant_infrastructure_info,
        UploadMerchantInfrastructureFile,
        userguide_info,
    } = props;
    //states
    const [loading, setloading] = useState({ content: false, delete: false });
    const [agentModalOpen, setAgentModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState();
    const [modalPayload, setModalPayload] = useState(initialInfrastructureValues);
    const [modalPayloadId, setModalPayloadId] = useState(0);
    const [addActionsModalOpen, setAddActionsModalOpen] = useState(false);
    const [uploadCSVModalOpen, setUploadCSVModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [viewPayload, setViewPayload] = useState({});
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [openUserGuide, setOpenUserGuide] = useState(false);

    const stepsTargetRef = useRef([]);

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const { data, handleSearch } = useSearch(all_merchant_infrastructures, [
        'asset_number',
        'department',
        'location',
        'sensitivity',
        'date_updated',
        'ip_type',
    ]);

    //translation
    const { t } = useTranslation('inventory');

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ infrastructures: true });
        setToggleUserGuide({ ...toggleUserGuide, infrastructures: false });
        setOpenUserGuide(false);
    };

    //theme
    const theme = useTheme();

    // const handleSearch = (e) => {
    //     const value = e.target.value;
    //     console.log(value);
    //     const filteredMerchantInfrastructures = all_merchant_infrastructures?.filter((merchant) => {
    //         return (
    //             String(merchant?.asset_number).includes(value) ||
    //             truncateText(merchant?.department).includes(value) ||
    //             truncateText(merchant?.location).includes(value) ||
    //             merchant?.sensitivity.toLowerCase().includes(value) ||
    //             formatDateObjectHandler(merchant.date_updated, 'DD/MM/YYYY').includes(value) ||
    //             merchant?.ip_type.includes(value)
    //         );
    //     });
    //     setFilteredMerchantInfrastructures(filteredMerchantInfrastructures);
    // };

    const columns = [
        {
            title: t('infrastructureTable.column1'),
            key: 'asset',
            render: (row) => <>{row?.asset_number}</>,
        },
        {
            title: t('infrastructureTable.column2'),
            key: 'department',
            render: (row) => <>{truncateText(row?.department)}</>,
        },
        {
            title: t('infrastructureTable.column3'),
            key: 'location',
            render: (row) => <>{truncateText(row?.location)}</>,
        },
        {
            title: t('infrastructureTable.column4'),
            key: 'sensitivity',
            render: (row) => <>{row?.sensitivity?.toLowerCase()}</>,
        },
        {
            title: t('infrastructureTable.column5'),
            key: 'type',
            render: (row) => <>{row?.ip_type}</>,
        },
        {
            title: t('infrastructureTable.column6'),
            key: 'status',
            render: (row) => (
                <>
                    {row?.ip_type === 'public' && !row?.message ? (
                        <AppTag text={t('infrastructureTable.pending')} type="pending" />
                    ) : row?.ip_type === 'public' && row?.message === 'success' ? (
                        <AppTag text={t('infrastructureTable.active')} type="success" />
                    ) : row?.ip_type === 'local' && row?.message === 'success' ? (
                        <AppTag text={t('infrastructureTable.active')} type="success" />
                    ) : row?.ip_type === 'public' && row?.message?.toString()?.length > 8 ? (
                        <AppTag text={t('infrastructureTable.failed')} type="failed" />
                    ) : (
                        <AppTag text={t('infrastructureTable.notAvailable')} type="default" />
                    )}
                </>
            ),
        },
        {
            title: t('infrastructureTable.column7'),
            key: 'date_updated',
            render: (row) => (
                <>
                    {formatDateObjectHandler(row.date_updated, 'MMM Do, YYYY')}
                    <Box
                        component="div"
                        sx={{
                            color: theme.palette.gray[500],
                            ml: 0.2,
                            fontSize: '0.7rem',
                            fontWeight: 400,
                        }}
                    >
                        ({GetFromNowDate(row.date_updated, 'YYYY-MM-DDTHH:mm:ss', 'fromNow')})
                    </Box>
                </>
            ),
        },
        {
            title: t('infrastructureTable.column8'),
            key: 'status',
            render: (row) => (
                <>
                    <InfrastructureTableActions
                        row={row}
                        openViewModal={openViewModal}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openModal}
                    />
                </>
            ),
        },
    ];

    //functions
    const openAgentsModal = () => {
        setAgentModalOpen(true);
    };
    const closeAgentsModal = () => {
        setAgentModalOpen(false);
    };

    const openAddActionsModal = () => {
        setAddActionsModalOpen(true);
    };
    const closeAddActionsModal = () => {
        setAddActionsModalOpen(false);
    };

    const openUploadCSVModal = () => {
        setUploadCSVModalOpen(true);
        closeAddActionsModal();
    };
    const closeUploadCSVModal = () => {
        setUploadCSVModalOpen(false);
    };
    const openModal = (mode, payload = initialInfrastructureValues, id) => {
        setModalOpen(true);
        setModalMode(mode);
        setModalPayload(payload);
        setModalPayloadId(id);
    };
    const closeModal = () => {
        setModalOpen(false);
        setModalMode('');
        setTimeout(() => setModalPayload({}), 1000);
    };
    const openViewModal = (payload) => {
        setViewModalOpen(true);
        setViewPayload(payload);
        getInfrastructureInfo(payload);
    };
    const closeViewModal = () => {
        setViewModalOpen(false);
        setTimeout(() => setViewPayload({}), 1000);
    };
    const openDeleteModal = (id) => {
        setDeleteModalOpen(true);
        setModalPayloadId(id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setModalPayloadId(0);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };

    //Get Infrastructure info
    const getInfrastructureInfo = async (user) => {
        const res = await GetInfrastructureInfo(
            user?.ip_address
            // match?.params?.id
        );
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        const getAllInfrastructures = async () => {
            setloading({ ...loading, content: true });
            const res = await GetAllInfrastructures();
            setloading({ ...loading, content: false });
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getAllInfrastructures();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return data;
    }, [page, data]);

    useEffect(() => {
        !userguide_info || toggleUserGuide.infrastructures ? OpenUserGuide() : null;
    }, [userguide_info, toggleUserGuide.infrastructures]);

    return (
        <>
            <PageHeader browserTitle="Infrastructures | Smartcomply" />
            <AppTable
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading?.content}
                title={t('infrastructureTitle')}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <AppButton
                        icon={<Add />}
                        name={t('addInfrastructure')}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={openAddActionsModal}
                        key={1}
                        ref={(el) => (stepsTargetRef.current[0] = el)}
                    />,
                    <AppButton
                        icon={<Download />}
                        name={t('downloadAgent')}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={openAgentsModal}
                        key={2}
                        ref={(el) => (stepsTargetRef.current[1] = el)}
                    />,
                ]}
                search={true}
                onSearch={handleSearch}
            />
            <InfrastructuresAgentModal
                open={agentModalOpen}
                handleClose={closeAgentsModal}
                title={t('infrastructureAgentModal.title')}
                windowsAgentUrl="https://smartcomply.s3.amazonaws.com/winserver_exe/windows_server.zip"
                linuxAgentUrl="https://smartcomply-prod.s3.amazonaws.com/linuxserver_exe/linuxserver.zip"
            />
            <AddInfrastructureAction
                open={addActionsModalOpen}
                handleClose={closeAddActionsModal}
                handleOpenUploadCSVModal={openUploadCSVModal}
                handleOpenModal={openModal}
            />

            <AppUploadCSVModal
                open={uploadCSVModalOpen}
                handleClose={closeUploadCSVModal}
                title={t('infrastructureUploadCSVModal.title')}
                subheader={t('infrastructureUploadCSVModal.subtitle')}
                templateLink="https://smartcomply-prod.s3.us-west-2.amazonaws.com/infrastructure_template.xlsx"
                uploadCSVAction={UploadMerchantInfrastructureFile}
            />

            <InfrastructuresModal
                open={modalOpen}
                handleClose={closeModal}
                payload={modalPayload}
                isAddModal={modalMode === 'add'}
                id={modalPayloadId}
            />
            <InfrastructureViewModal
                open={viewModalOpen}
                handleClose={closeViewModal}
                title={t('infrastructureViewModal.title')}
                width={500}
                icon={<ViewCarousel />}
                payload={viewPayload}
                merchant_infrastructure_info={merchant_infrastructure_info}
            />

            <InfrastructureDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={modalPayloadId} />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        merchant_infrastructure_info: state?.merchantReducers?.merchant_infrastructure_info,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.infrastructures,
    };
};

export default connect(mapStateToProps, {
    GetAllInfrastructures,
    UploadMerchantInfrastructureFile,
    GetInfrastructureInfo,
})(Infrastructures);
