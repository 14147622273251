import {
    ALL_MERCHANT_DOCUMENTS,
    ALL_KDPA_CATEGORY_METRICS,
    ALL_GDPA_CATEGORY_METRICS,
    ALL_CONTROL_METRICS,
    ALL_ISOGROUP_METRICS,
    ALL_ISOGROUPTWO_METRICS,
    ALL_CONTINUITYGROUPS_METRICS,
    MERCHANT_DOCUMENT_VERSIONS,
    MERCHANT_DOCUMENT_MESSAGES,
    MERCHANT_ANSWERS,
    ANSWER_HISTORY,
    ALL_EXEMPTED_DOCUMENTS,
    ALL_EXEMPTED_FORMS,
    MERCHANT_DOCUMENTS_BY_TAG,
    ALL_CONDITIONAL_ANSWER,
    ALL_CRITERIA_SUMMARY,
    ALL_BLOCKCHAIN_EXCEPTION,
    COMPLIANCE_AI_ANSWERS_BY_SUBREQ_AND_STANDARD,
    ALL_EXEMPTED_CONTROLS,
    ALL_CII_CONDITIONAL_ANSWER,
    ALL_CII_SUMMARY,
    ALL_CII_EXCEPTION,
    ALL_CII_EXCEPTIONS,
    ALL_REQUIREMENTS_METRICS,
    ALL_CATEGORY_METRICS,
    ALL_PCIDSS4POINT0_REQUIREMENTS_METRICS,
    ALL_MERCHANT_EVIDENCES,
    MERCHANT_EVIDENCE_VERSIONS,
    ALL_EVIDENCE_METRICS,
} from '../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case ALL_MERCHANT_DOCUMENTS:
            return {
                ...state,
                all_merchant_documents: action.payload,
            };
        case ALL_MERCHANT_EVIDENCES:
            return {
                ...state,
                all_merchant_evidences: action.payload,
            };
        case ALL_REQUIREMENTS_METRICS:
            return {
                ...state,
                all_requirements_metrics: action.payload,
            };
        case ALL_KDPA_CATEGORY_METRICS:
            return {
                ...state,
                all_kdpa_categories_metrics: action.payload,
            };
        case ALL_CATEGORY_METRICS:
            return {
                ...state,
                all_categories_metrics: action.payload,
            };
        case ALL_GDPA_CATEGORY_METRICS:
            return {
                ...state,
                all_gdpa_categories_metrics: action.payload,
            };
        case ALL_CONTROL_METRICS:
            return {
                ...state,
                all_controls_metrics: action.payload,
            };
        case ALL_EVIDENCE_METRICS:
            return {
                ...state,
                all_evidences_metrics: action.payload,
            };
        case ALL_ISOGROUP_METRICS:
            return {
                ...state,
                all_isogroup_metrics: action.payload,
            };
        case ALL_ISOGROUPTWO_METRICS:
            return {
                ...state,
                all_isogrouptwo_metrics: action.payload,
            };
        case ALL_CONTINUITYGROUPS_METRICS:
            return {
                ...state,
                all_continuitygroups_metrics: action.payload,
            };
        case MERCHANT_DOCUMENT_VERSIONS:
            return {
                ...state,
                merchant_document_versions: action.payload,
            };
        case MERCHANT_EVIDENCE_VERSIONS:
            return {
                ...state,
                merchant_evidence_versions: action.payload,
            };
        case MERCHANT_DOCUMENT_MESSAGES:
            return {
                ...state,
                merchant_document_messages: action.payload,
            };
        case MERCHANT_ANSWERS:
            return {
                ...state,
                merchant_answers: action.payload,
            };
        case ANSWER_HISTORY:
            return {
                ...state,
                answer_history: action.payload,
            };
        case ALL_EXEMPTED_DOCUMENTS:
            return {
                ...state,
                all_exempted_documents: action.payload,
            };
        case ALL_EXEMPTED_FORMS:
            return {
                ...state,
                all_exempted_forms: action.payload,
            };
        case MERCHANT_DOCUMENTS_BY_TAG: {
            return { ...state, merchant_document_version_by_tag: action.payload };
        }
        case ALL_CONDITIONAL_ANSWER: {
            return { ...state, all_condition_answers: action.payload };
        }
        case ALL_CRITERIA_SUMMARY: {
            return { ...state, all_criteria_summary: action.payload };
        }
        case ALL_BLOCKCHAIN_EXCEPTION: {
            return { ...state, all_blockchain_exception: action.payload };
        }
        case ALL_EXEMPTED_CONTROLS: {
            return {
                ...state,
                all_exempted_controls: action.payload,
            };
        }
        case COMPLIANCE_AI_ANSWERS_BY_SUBREQ_AND_STANDARD:
            return { ...state, compliance_ai_answers_by_subreq_and_standard: action.payload };
        case ALL_CII_CONDITIONAL_ANSWER:
            return { ...state, all_cii_condition_answers: action.payload };
        case ALL_CII_SUMMARY:
            return { ...state, all_cii_summary: action.payload };
        case ALL_CII_EXCEPTION:
            return { ...state, all_cii_exceptions: action.payload };
        case ALL_CII_EXCEPTIONS:
            return { ...state, all_cii_exceptions_: action.payload };
        case ALL_PCIDSS4POINT0_REQUIREMENTS_METRICS:
            return {
                ...state,
                all_pcidss4point0_requirements_metrics: action.payload,
            };
        default:
            return state;
    }
};
