import React, { useState } from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Stack, Typography, IconButton, Box } from '@mui/material';
import { AppFormInput } from 'components/new_components/forms';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { AppSubmitButton } from 'components/new_components/forms';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

//form
import { AppForm } from 'components/new_components/forms';
import { runScanValues } from './utils/constant';
import { runScanValidation } from './utils/validation';
import { toast } from 'react-toastify';

//redux
import { connect } from 'react-redux';
import { RequestAPIScans } from 'store/actions/apiComplianceActions';

const RunScanModal = (props) => {
    const [authorization, setAuthorization] = useState(false);
    const [loadingState, setLoadingState] = useState(false);

    const { open, handleClose, RequestAPIScans } = props;

    const theme = useTheme();

    //functions
    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const handleSubmit = async (values) => {
        if (authorization) {
            setLoadingState(true);
            const res = await RequestAPIScans(values);
            setLoadingState(false);
            if (res?.success) {
                toast.success('Scan initiated Successfully');
                handleClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };
    return (
        <AppForm initialValues={runScanValues} onSubmit={handleSubmit} validate={runScanValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                width="464px"
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 6,
                        }}
                    >
                        <AppSubmitButton
                            text="Run Scan"
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '100%', py: 1.5 }}
                        />
                    </Box>
                }
                sx={{ top: '-20%' }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: -1, borderBottom: `1px solid ${theme.palette.gray[90]}` }}
                >
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[30] }}>
                        New Scan
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Stack>
                <AppFormInput type="text" name="link" placeholder="Enter endpoint" sx={{ mt: 1, mb: 1 }} />
                <AppCheckboxInput
                    name="authorization"
                    label="I give my complete consent to this request."
                    onChange={handleAuthorizationCheck}
                    value={authorization}
                    sx={{ mt: 1 }}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestAPIScans })(RunScanModal);
