import React from 'react';
//core components
import { Box, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppViewModal from 'components/new_components/AppViewModal';
import AppTag from 'components/new_components/AppTags';

//translations
import { useTranslation } from 'react-i18next';

const InfrastructureViewModal = (props) => {
    //props
    const { open, handleClose, title, width, icon, payload, merchant_infrastructure_info } = props;
    //theme
    const theme = useTheme();

    //translation
    const { t } = useTranslation('inventory');

    return (
        <AppViewModal open={open} handleClose={handleClose} title={title} width={width} icon={icon}>
            <Box sx={{ marginTop: '1rem' }}>
                <List>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('infrastructureViewModal.assetNo')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.asset_number}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('infrastructureViewModal.department')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.department}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('infrastructureViewModal.location')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.location}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('infrastructureViewModal.sensitivity')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.sensitivity}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {t('infrastructureViewModal.infrastructureType')}:
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.ip_type}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.gray[300]}`,
                            py: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                            {payload?.ip_type === 'public' ? 'Domain Name' : 'IP Address'}
                        </Typography>
                        <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                            {payload?.ip_address}
                        </Typography>
                    </ListItem>
                    {(payload?.ip_type === 'public' && payload?.message === 'success') ||
                    payload?.ip_type === 'local' ? (
                        <>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: `1px solid ${theme.palette.gray[300]}`,
                                    py: 2,
                                }}
                            >
                                <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                                    {t('infrastructureViewModal.antiVirus')}
                                </Typography>
                                <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                                    {merchant_infrastructure_info?.length &&
                                    merchant_infrastructure_info?.[0]?.antivirus ? (
                                        <AppTag text={t('infrastructureViewModal.active')} type="success" />
                                    ) : merchant_infrastructure_info?.length &&
                                      !merchant_infrastructure_info?.[0]?.antivirus ? (
                                        <AppTag text={t('infrastructureViewModal.inactive')} type="failed" />
                                    ) : (
                                        t('infrastructureViewModal.notAvailable')
                                    )}
                                </Typography>
                            </ListItem>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: `1px solid ${theme.palette.gray[300]}`,
                                    py: 2,
                                }}
                            >
                                <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                                    {t('infrastructureViewModal.osModel')}
                                </Typography>
                                <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                                    {merchant_infrastructure_info?.[0]?.model ||
                                        t('infrastructureViewModal.notAvailable')}
                                </Typography>
                            </ListItem>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: `1px solid ${theme.palette.gray[300]}`,
                                    py: 2,
                                }}
                            >
                                <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                                    {t('infrastructureViewModal.osVersion')}
                                </Typography>
                                <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                                    {merchant_infrastructure_info?.[0]?.osversion ||
                                        t('infrastructureViewModal.notAvailable')}
                                </Typography>
                            </ListItem>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: `1px solid ${theme.palette.gray[300]}`,
                                    py: 2,
                                }}
                            >
                                <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                                    {t('infrastructureViewModal.serverSpecification')}
                                </Typography>
                                <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                                    {merchant_infrastructure_info?.[0]?.name ||
                                        t('infrastructureViewModal.notAvailable')}
                                </Typography>
                            </ListItem>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: `1px solid ${theme.palette.gray[300]}`,
                                    py: 2,
                                }}
                            >
                                <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                                    {t('infrastructureViewModal.serialNo')}
                                </Typography>
                                <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                                    {merchant_infrastructure_info?.[0]?.serial ||
                                        t('infrastructureViewModal.notAvailable')}
                                </Typography>
                            </ListItem>
                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: `1px solid ${theme.palette.gray[300]}`,
                                    py: 2,
                                }}
                            >
                                <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>Lock</Typography>
                                <Typography sx={{ fontWeight: '700', color: theme.palette.gray[900] }}>
                                    {merchant_infrastructure_info?.length &&
                                    merchant_infrastructure_info?.[0]?.lock === 'True' ? (
                                        <AppTag text={t('infrastructureViewModal.active')} type="success" />
                                    ) : merchant_infrastructure_info?.length &&
                                      merchant_infrastructure_info?.[0]?.lock === 'False' ? (
                                        <AppTag text={t('infrastructureViewModal.inactive')} type="failed" />
                                    ) : (
                                        t('infrastructureViewModal.notAvailable')
                                    )}
                                </Typography>
                            </ListItem>
                        </>
                    ) : payload?.ip_type === 'public' && payload?.message?.toString()?.length > 8 ? (
                        <ListItem
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderBottom: `1px solid ${theme.palette.gray[300]}`,
                                py: 2,
                            }}
                        >
                            <Typography sx={{ fontWeight: '500', color: theme.palette.gray[900] }}>
                                {t('infrastructureViewModal.scanReport')}:
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: '700',
                                    color: payload?.message ? theme.palette.error[900] : theme.palette.gray[900],
                                }}
                            >
                                {payload?.message ? payload?.message : t('infrastructureViewModal.notAvailable')}
                            </Typography>
                        </ListItem>
                    ) : null}
                </List>
            </Box>
        </AppViewModal>
    );
};

export default InfrastructureViewModal;
