import {
    USER_DETAILS,
    USER_TYPE,
    USER_ID,
    COMPANY_ID,
    USER_INFO,
    USER_PRICING_PLANS,
    COMPANY_DETAILS,
    FIRST_LOGIN,
} from '../constants';
import { baseUrl } from 'config';
import { setToken, setType, endSession, getUserToken, clearBrowserStorage } from 'utils';
import axios from 'axios';
import axiosInstance from '../../utils/https';
import { getType } from 'utils';
import { setDomain } from 'utils';
import { GetAllAuditors } from './adminActions';

export const RegisterUser = (credentials) => async () => {
    try {
        const res = await axios.post(`${baseUrl}/user_mgt/users/`, credentials);
        const data = res.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.response?.data?.message);
        return {
            success: true,
            message: 'You have successfully registered, you will be redirected to the login page now.',
            data: data?.data,
        };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message };
    }
};
export const UpdateUserDetails = (credentials, id) => async () => {
    try {
        const res = await axiosInstance().patch(`${baseUrl}/users/${id}/`, credentials);
        const data = res;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.response?.data?.message);
        return {
            success: true,
            message: 'Updated Successfully.',
        };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message };
    }
};
export const LoginUser = (logindetails) => async (dispatch) => {
    try {
        const res = await axios.post(`${baseUrl}/user_mgt/login/`, logindetails);
        const data = res?.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        setToken(data?.data?.key);
        setType('type', data?.data?.user?.user_type);
        setDomain();
        setType('role', data?.data?.user?.role);
        setType('id', data?.data?.user?.id);
        setType('org_id', data?.data?.user?.organization?.id);
        setType('mfa', data?.data?.user?.mfa);
        setType('plan', data?.data?.user?.organization?.merchant_plan?.tag);
        dispatch({ type: FIRST_LOGIN, payload: data?.first_login });
        dispatch({
            type: USER_TYPE,
            payload: data?.data?.user?.user_type,
        });
        dispatch({ type: USER_ID, payload: data?.data?.user?.id });
        dispatch({
            type: COMPANY_ID,
            payload: data?.data?.user?.organization?.id,
        });
        dispatch({ type: USER_DETAILS, payload: data?.data });
        dispatch({ type: USER_INFO, payload: data?.data?.user });
        dispatch({ type: USER_PRICING_PLANS, payload: data?.data?.user?.organization?.merchant_plan });
        dispatch({
            type: COMPANY_DETAILS,
            payload: data?.data?.user?.organization,
        });
        return {
            success: true,
            message: 'User Login Successfully',
            role: data?.data?.user?.role,
            user_type: data?.data?.user?.user_type,
            mfa: data?.data?.user?.mfa,
            mfa_type: data?.data?.user?.mfa_type,
            email: data?.data?.user?.email,
            first_login: data?.first_login,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const ConfirmOtp = (credentials) => async () => {
    try {
        const res = await axios.post(`${baseUrl}/user_mgt/mfa/`, credentials);
        const data = res.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        return {
            success: true,
            message: 'Token Confirmed Successfully',
        };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message };
    }
};
export const LogoutUser = () => async () => {
    try {
        // const res = await axios.get(`${baseUrl}${window.localStorage.i18nextLng?.includes('en') ? 'en' : window.localStorage.i18nextLng}/api/user_mgt/logout/`, { Authorization: `Token ${getToken()}` });
        // const data = res.data;
        // if (data.status.toLowerCase() !== "success") throw new Error(data?.message);
        endSession(
            getUserToken('token'),
            getType('type'),
            getType('role'),
            getType('id'),
            getType('org_id'),
            getType('mfa')
        );
        clearBrowserStorage();
        return {
            success: true,
            // message: data?.message,
            // user_type: data?.data?.user?.user_type,
        };
    } catch (err) {
        console.log(err);
        return { success: false, message: err.message };
    }
};
export const PasswordReset = (credentials) => async () => {
    try {
        const res = await axios.post(`${baseUrl}/user_mgt/reset_password/`, credentials);
        const data = res.data;
        if (data?.status?.toLowerCase() !== 'success') throw new Error(data?.message);
        return {
            success: true,
            message: data?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetSocToken = () => async (_, getState) => {
    const company_id = getState().authReducers?.company_id;
    const user_id = getState().authReducers?.user_id;
    try {
        const res = await axiosInstance().post(`/scans/soctoken/`, { merchant: company_id, user_id });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        return {
            success: true,
            message: res.message,
            token: res?.data?.token,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const CreateAuditorUser = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`${baseUrl}/user_mgt/aud_admin/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.response?.data?.message);
        dispatch(GetAllAuditors());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return { success: false, message: err?.response?.data?.message };
    }
};
