import React from 'react';
import { Box, Typography } from '@mui/material';
// import CheckDone from 'assets/img/dashboard/CheckDone.svg';
// import ClipIcon from 'assets/img/dashboard/CliqIcon.svg';
// import QuestionIcon from 'assets/img/dashboard/QuestionClipIcon.svg';
// import { Progress } from 'antd';
import { IoIosCheckmarkCircle } from 'react-icons/io';

const CisoReportCard = ({ details }) => {
    return (
        <Box
            sx={{
                px: '10px',
                py: '8px',
                border: '0.6px solid #ACAAAF',
                borderRadius: '5px',
                width: '100%',
                height: '75px',
                display: 'flex',
                justifyContent: 'space-between !important',
                alignItems: 'center',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                <div className="flex">
                    <Box sx={{ width: '10px', mt: '5px' }}>
                        <Box
                            sx={{
                                width: '5px',
                                height: '5px',
                                borderRadius: '50%',
                                backgroundColor: `${details?.stepCount === 100 ? '#06B877' : '#EB4141'}`,
                            }}
                        />
                    </Box>
                    <Typography
                        sx={{
                            fontSize: '10px',
                            fontWeight: 500,
                            color: '#0E2C66',
                            pl: '5px',
                            width: '100%',
                        }}
                    >
                        {details?.title}
                    </Typography>
                </div>
                <div className="flex justify-self-end pl-3 pt-2">
                    <div className="flex items-end">
                        {/* <img className="ml-2" width={10} src={ClipIcon} alt="ClipIcon" /> */}
                        <Typography component="span" sx={{ fontSize: '9px', fontWeight: 700, color: '#0E2C66' }}>
                            Documents:
                        </Typography>
                        <Typography
                            component="span"
                            sx={{ fontSize: '9px', fontWeight: 500, color: '#0E2C66', pl: '5px' }}
                        >
                            {details?.user_doc}/{details?.total_doc}
                        </Typography>
                    </div>
                    <div className="flex items-end pl-2">
                        {/* <img className="ml-2" width={11} src={QuestionIcon} alt="QuestionIcon" /> */}
                        <Typography component="span" sx={{ fontSize: '9px', fontWeight: 700, color: '#0E2C66' }}>
                            Audit Questions:
                        </Typography>
                        <Typography
                            component="span"
                            sx={{ fontSize: '9px', fontWeight: 500, color: '#0E2C66', pl: '5px' }}
                        >
                            {details?.user_form}/{details?.total_form}
                        </Typography>
                    </div>
                </div>
            </Box>
            <div>
                {details?.stepCount === 100 ? (
                    <IoIosCheckmarkCircle color="#06B877" size={25} />
                ) : (
                    <Box sx={{ display: 'flex', height: '50px', alignItems: 'flex-end' }}>
                        {/* <Progress
                            type="circle"
                            strokeLinecap="butt"
                            width={33}
                            percent={details?.stepCount}
                            strokeWidth={12}
                            strokeColor={'#0E2C66'}
                        /> */}
                        <Typography
                            sx={{
                                fontSize: '8px',
                                fontWeight: 700,
                                color: '#0E2C66',
                                lineHeight: '7.26px',
                                // pl: '5px'
                            }}
                        >
                            {details?.stepCount}% Completed
                        </Typography>
                    </Box>
                )}
            </div>
        </Box>
    );
};

export default CisoReportCard;
