import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import FileUnCheck from 'assets/img/FileUncheck.svg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { connect } from 'react-redux';

const Evidences = (props) => {
    const {
        control,
        setDoc_id,
        all_merchant_evidences,
        setControl,
        req_id,
        openEvidenceHistoryModal,
        evidence_type,
        setControlModal,
    } = props;

    const selectedEvidence = useMemo(() => {
        return all_merchant_evidences?.find((doc) => doc?.evidence_id === control?.id);
    }, [control, all_merchant_evidences]);

    const filtered_doc_numb = (
        selectedEvidence?.data?.find((itm, index) => itm?.[`segment_${index + 1}`])
            ? Object?.values(selectedEvidence?.data?.find((itm, index) => itm?.[`segment_${index + 1}`]))
            : []
    )?.reduce((prev, current) => {
        const reduced_items = prev + current?.length;
        return reduced_items;
    }, 0);

    const expected_doc_number = (evidence_type === 'soc2' ? control?.segments?.slice(0, 1) : control?.segments)
        ?.map((segment, index) => segment?.[`segment_${index + 1}`])
        ?.reduce((prev, current) => {
            const reduced_items = prev + current;
            return reduced_items;
        }, 0);
    return (
        <Box key={control?.id}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '50%',
                        }}
                    >
                        <img src={FileUnCheck} alt="fileunckeck" />{' '}
                        <Typography
                            sx={{
                                ml: 2,
                                color: '#395BA9',
                                fontSize: '13px',
                                fontWeight: 500,
                            }}
                        >
                            {control?.soc2_title}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {selectedEvidence ? (
                            <>
                                {filtered_doc_numb > expected_doc_number ? (
                                    <Button
                                        sx={{
                                            textTransform: 'inherit',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            backgroundColor: '#F8FAFC',
                                            border: '1px solid #E2E8F0',
                                            px: 1,
                                            color:
                                                filtered_doc_numb === expected_doc_number ||
                                                filtered_doc_numb > expected_doc_number
                                                    ? '#395BA9'
                                                    : '#64748B',
                                            ml: 1,
                                            '&:hover': {
                                                backgroundColor: '#F8FAFC !important',
                                                border: '1px solid #E2E8F0',
                                            },
                                        }}
                                        onClick={() => {
                                            setDoc_id(control?.id);
                                            setControl(req_id);
                                            setControlModal(control);
                                            openEvidenceHistoryModal();
                                        }}
                                        variant="outlined"
                                        startIcon={
                                            <FiberManualRecordIcon
                                                sx={{
                                                    width: 10,
                                                    height: 10,
                                                    color:
                                                        filtered_doc_numb === expected_doc_number ||
                                                        filtered_doc_numb > expected_doc_number
                                                            ? '#55BE8B'
                                                            : '#64748B',
                                                }}
                                            />
                                        }
                                    >
                                        Update Evidence ({filtered_doc_numb})
                                    </Button>
                                ) : (
                                    <Button
                                        sx={{
                                            textTransform: 'inherit',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                            px: 1,
                                            backgroundColor: '#F8FAFC',
                                            border: '1px solid #E2E8F0',
                                            color:
                                                filtered_doc_numb === expected_doc_number ||
                                                filtered_doc_numb > expected_doc_number
                                                    ? '#395BA9'
                                                    : '#64748B',
                                            ml: 1,
                                            '&:hover': {
                                                backgroundColor: '#F8FAFC !important',
                                                border: '1px solid #E2E8F0',
                                            },
                                        }}
                                        onClick={() => {
                                            setDoc_id(control?.id);
                                            setControl(req_id);
                                            setControlModal(control);
                                            openEvidenceHistoryModal();
                                        }}
                                        variant="outlined"
                                        startIcon={
                                            <FiberManualRecordIcon
                                                sx={{
                                                    width: 10,
                                                    height: 10,
                                                    color:
                                                        filtered_doc_numb === expected_doc_number ||
                                                        filtered_doc_numb > expected_doc_number
                                                            ? '#55BE8B'
                                                            : '#64748B',
                                                }}
                                            />
                                        }
                                    >
                                        {filtered_doc_numb === expected_doc_number ||
                                        filtered_doc_numb > expected_doc_number
                                            ? 'Update Evidence'
                                            : 'Upload Evidence'}{' '}
                                        ({filtered_doc_numb} / {expected_doc_number})
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button
                                sx={{
                                    textTransform: 'inherit',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                    color: '#64748B',
                                    backgroundColor: '#F8FAFC',
                                    border: '1px solid #E2E8F0',
                                    ml: 1,
                                    '&:hover': {
                                        backgroundColor: '#F8FAFC',
                                        border: '1px solid #E2E8F0',
                                    },
                                }}
                                onClick={() => {
                                    if (!selectedEvidence) {
                                        setDoc_id(control?.id);
                                        setControl(req_id);
                                        setControlModal(control);
                                        openEvidenceHistoryModal();
                                    }
                                }}
                                variant="outlined"
                                startIcon={
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            color: '#64748B',
                                        }}
                                    />
                                }
                            >
                                Upload Evidence
                            </Button>
                        )}
                    </Box>
                </Box>
                <Divider sx={{ color: '#F1F5F9', mx: '-2rem' }} />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
        documents_by_control: state?.adminReducers?.all_admin_docs_by_common_control,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
        evidences_metrics: state?.complianceReducers?.all_evidences_metrics,
    };
};
export default connect(mapStateToProps, {})(Evidences);
