import React from 'react';
import { Box, Typography } from '@mui/material';

const ContainerWithHeader = ({ header, children, sx }) => {
    return (
        <Box
            sx={{
                border: '0.6px solid #C7C6CA',
                borderRadius: '4.44444px',
                // height: '370px',
                // background: '#fff',
                ...sx,
            }}
        >
            <Typography
                sx={{
                    color: '#183366',
                    fontWeight: 700,
                    borderBottom: '0.6px solid #C7C6CA',
                    p: 2,
                    fontSize: '13.3333px',
                    lineHeight: '16px',
                    textTransform: 'capitalize',
                }}
            >
                {header}
            </Typography>
            {children}
        </Box>
    );
};

export default ContainerWithHeader;
