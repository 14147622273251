import React, { useEffect, useState } from 'react';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Stack, Typography, IconButton, Box } from '@mui/material';
import { AppFormInput } from 'components/new_components/forms';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { AppSubmitButton } from 'components/new_components/forms';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

//form
import { AppForm } from 'components/new_components/forms';
// import { runScanValues } from './utils/constant';
import { runScanValidation } from './utils/validation';
import { toast } from 'react-toastify';

//redux
import { connect } from 'react-redux';
import { RequestCodeReviewcans, UpdateRequestCodeReviewcans } from 'store/actions/apiComplianceActions';

const RunScanModal = (props) => {
    const [authorization, setAuthorization] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [data, setData] = useState({
        url: '',
        token: '',
    });

    const { open, handleClose, RequestCodeReviewcans, UpdateRequestCodeReviewcans, payload } = props;
    const theme = useTheme();

    //functions
    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const onClose = () => {
        handleClose();
        setData({
            url: '',
            token: '',
        });
    };

    const handleSubmit = async (values) => {
        if (authorization) {
            setLoadingState(true);
            const res = payload?.url
                ? await UpdateRequestCodeReviewcans(values, payload?.id)
                : await RequestCodeReviewcans(values);
            setLoadingState(false);
            if (res?.success) {
                toast.success(res?.message);
                onClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };

    useEffect(() => {
        if (payload) {
            setData({
                url: payload?.url,
                token: payload?.token,
            });
        }
    }, [payload]);
    return (
        <AppForm
            initialValues={{
                url: data?.url,
                token: data?.token,
            }}
            onSubmit={handleSubmit}
            validate={runScanValidation}
        >
            <AppCenteredModal
                open={open}
                handleClose={onClose}
                width="464px"
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 6,
                        }}
                    >
                        <AppSubmitButton
                            text={payload?.url ? 'Update Code Reviev' : 'Run Code Review'}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '100%', py: 1.5 }}
                        />
                    </Box>
                }
                sx={{ top: '-20%' }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mt: -1, borderBottom: `1px solid ${theme.palette.gray[90]}` }}
                >
                    <Typography sx={{ fontSize: 18, fontWeight: 700, color: theme.palette.gray[30] }}>
                        {payload?.url ? 'Update Code Reiew' : 'New Code Review'}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
                <Typography
                    sx={{
                        mt: 5,
                        py: 1,
                        pl: 0.5,
                        backgroundColor: '#F8F8F8',
                        color: '#46464A',
                        fontSize: '10px',
                        fontWeight: 500,
                    }}
                >
                    <strong>Note:</strong> Only Github repo is allowed at the moment
                </Typography>
                <AppFormInput
                    type="text"
                    name="url"
                    placeholder="Enter repo link"
                    sx={{ mb: 1 }}
                    disabled={payload?.url}
                />
                <AppFormInput type="text" name="token" placeholder="Enter github token" sx={{ mb: 1 }} />
                <AppCheckboxInput
                    name="authorization"
                    label="I give my complete consent to this request."
                    onChange={handleAuthorizationCheck}
                    value={authorization}
                    sx={{ mt: 1 }}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestCodeReviewcans, UpdateRequestCodeReviewcans })(RunScanModal);
