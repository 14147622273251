import React from 'react';
import { ListItem, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ViewResponse = (props) => {
    const theme = useTheme();
    const { name, answer } = props;
    let bulletedName = name.substr(name.indexOf(' ') + 1);

    return (
        <div>
            <ListItem
                sx={{
                    display: 'list-item',
                    pt: 2,
                    pb: 1,
                    pl: '30px',
                    pr: '10px',
                    fontWeight: '500',
                    fontSize: { xs: '13px', md: '16px' },
                    color: theme.palette.gray[30],
                }}
            >
                {bulletedName}
            </ListItem>
            <Typography
                variant="h3"
                noWrap
                component="h3"
                sx={{
                    m: 0,
                    pl: { xs: '33px', md: '52px' },
                    pr: 2,
                    pt: 1,
                    fontSize: { xs: '12px', md: '14px' },
                    color: ' #919094',
                    fontWeight: '600',
                }}
            >
                {answer?.includes('https:') ? (
                    <Box
                        sx={{
                            color: theme.palette.primary[900],
                            textDecoration: 'underline',
                            paddingRight: '5px',
                            cursor: 'pointer',
                        }}
                        component="a"
                        href={answer}
                        download
                    >
                        Click here to download
                    </Box>
                ) : (
                    answer
                )}
            </Typography>
        </div>
    );
};

export default ViewResponse;
