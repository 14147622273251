import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const STEPS_LENGTH = 2;

export const steps = [
    {
        title: <UserGuideHeader title="Request Scan" id={1} length={STEPS_LENGTH} />,
        description: 'Here you can run PCI/ASV scan for PCI DSS framework',
        placement: 'leftTop',
    },
    {
        title: <UserGuideHeader title="Upload Report" id={2} length={STEPS_LENGTH} />,
        description: 'You can Upload scan report if you already performed the scan elsewhere',
        placement: 'leftTop',
    },
];
