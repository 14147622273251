import React, { useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CisoReportCard from './CisoReportCard';
import { connect } from 'react-redux';
import { GetMerchantKDPAGeneralSummary, GetMerchantKDPASummaryByCategory } from 'store/actions/dashboardActions';

const KDPAReport = ({ GetMerchantKDPAGeneralSummary, GetMerchantKDPASummaryByCategory, kdpaSummary }) => {
    const toListEntry = Object.entries(kdpaSummary || {});
    const newList = toListEntry?.map((res) => {
        const { user_doc, user_form, total_form, total_doc } = res?.[1] || {};
        const steps = ((user_doc + user_form) * 100) / (total_doc + total_form) || 0;
        return {
            title: res?.[0],
            ...res?.[1],
            stepCount: parseInt(steps.toString()),
        };
    });

    useEffect(() => {
        const getKdpaSummary = async () => {
            await GetMerchantKDPAGeneralSummary();
            await GetMerchantKDPASummaryByCategory();
        };
        getKdpaSummary();
    }, []);
    return (
        <>
            <Box sx={{ my: '25px', display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#0E2C66', mr: '10px' }}>KDPA</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#46464A' }}>
                    Implementation Phase Completion
                </Typography>
            </Box>
            <Grid container spacing={1}>
                {newList?.map((res, index) => (
                    <Grid key={index} item xs={12} md={6} lg={6}>
                        <CisoReportCard details={res} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        kdpaSummary: state?.dashboardReducers?.kdpaSummary,
    };
};
export default connect(mapStateToProps, { GetMerchantKDPAGeneralSummary, GetMerchantKDPASummaryByCategory })(
    KDPAReport
);
