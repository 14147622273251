import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { ErrorOutline, PlayCircle } from '@mui/icons-material/';

import AppButton from 'components/new_components/AppButton';
import AppCard from 'components/new_components/AppCard';
import EmptyComponent from 'components/EmptyComponents/EmptyComponent';
import { formatDateObjectHandler } from 'utils';

import PlayIcon from 'assets/img/PlayIcon.svg';
import calendarIcon from 'assets/img/calendarIcon.svg';
import VideoModal from '../VideoModal';

const SecurityAwarenessTraining = (props) => {
    const { loading, check, id, handleVideoFinish, videos } = props;
    const theme = useTheme();

    const [openVideo, setOpenVideo] = useState({});

    const openVideoModal = (index) => {
        setOpenVideo({ [index]: true });
    };
    const closeVideoModal = (index) => {
        setOpenVideo({ [index]: false });
    };

    return (
        <>
            <Grid item md={7} lg={7}>
                <Stack>
                    <Box
                        sx={{
                            fontSize: '12px',
                            color: theme.palette.gray[40],
                            fontWeight: 400,
                            border: '0.3px solid #C7C6CA',
                            p: 0.5,
                            mx: 1,
                            my: 1,
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ErrorOutline color="#77777A" fontSize="small" />
                        <Typography sx={{ fontSize: '12px', color: '#77777A', marginLeft: '10px' }}>
                            Watch the video, download and read the document, and acknowledge them all at once by
                            clicking the acknowledge <br /> button below of this section. You can only acknowledge these
                            policies once.
                        </Typography>
                    </Box>
                    {videos?.find((video) => !video?.is_deactivated) ? (
                        videos
                            ?.filter((video) => !video?.is_deactivated)
                            ?.map((video, index) => {
                                return (
                                    <React.Fragment key={video?.id}>
                                        <AppCard sx={{ mt: '50px', p: 3, display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ mr: '25px' }}>
                                                <img src={PlayIcon} alt="Play Icon" />
                                            </Box>
                                            <Stack>
                                                <Typography
                                                    sx={{
                                                        color: theme.palette.primary[900],
                                                        fontSize: '18px',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {video?.name}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: '#77777A',
                                                        fontSize: '12px',
                                                        fontWeight: 500,
                                                        mt: '15px',
                                                        mb: '55px',
                                                    }}
                                                >
                                                    {video?.description}
                                                </Typography>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={calendarIcon} alt="calendar icon" />
                                                        <Typography
                                                            sx={{
                                                                color: '#77777A',
                                                                fontSize: '12px',
                                                                fontWeight: 400,
                                                                ml: '8px',
                                                                mr: '30px',
                                                            }}
                                                        >
                                                            {formatDateObjectHandler(video?.date_created, 'DD-MM-YYYY')}
                                                        </Typography>
                                                    </Box>
                                                    <AppButton
                                                        icon={<PlayCircle />}
                                                        name="Watch Video"
                                                        variant="contained"
                                                        color="white"
                                                        onClick={() => openVideoModal(index)}
                                                        sx={{
                                                            height: '20px',
                                                            border: '1px solid #C7C6CA',
                                                            borderRadius: '20px',
                                                            p: 2,
                                                            bacgroundColor: '#ffffff',
                                                            color: '#77777A',
                                                            boxShadow: '0 0 0 0 !important',
                                                        }}
                                                    />
                                                </Box>
                                            </Stack>
                                        </AppCard>
                                        <VideoModal
                                            open={openVideo?.[index]}
                                            handleClose={() => closeVideoModal(index)}
                                            videos={videos}
                                            videoIndex={index}
                                        />
                                    </React.Fragment>
                                );
                            })
                    ) : (
                        <EmptyComponent text="No policy document have been uploaded yet. Check again later." />
                    )}
                    <AppButton
                        name="Acknowledge"
                        color="primary"
                        variant="contained"
                        onClick={() => handleVideoFinish({ training: true })}
                        loading={loading}
                        disabled={check || id?.toString()?.length <= 4}
                        sx={{
                            width: '215px',
                            height: '55px',
                            mt: '24px',
                        }}
                    />
                </Stack>
            </Grid>
        </>
    );
};

export default SecurityAwarenessTraining;
