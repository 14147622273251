import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';

//core components
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import TabLayout from 'components/new_components/tabs/TabLayout';
import Vendors from './MyVendors/Vendors';
import ActingAsAVendorLayout from './ActingAsAVendor';
import PageHeader from 'components/new_components/PageHeader';
import SubscriptionAlert from '../../../../components/Pricing/SubscriptionAlert';
import { connect } from 'react-redux';
import { GetRemaining } from '../../../../components/Pricing/usePricingHook';

//translations
import { useTranslation } from 'react-i18next';

//userguide
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const VendorLayout = (props) => {
    const { pricing_plan, all_vendors, userguide_info, all_article_header } = props;
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const reff = useRef(null);

    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="My Vendors" id={1} length={STEPS_LENGTH} />,
            description: 'This displays all vendors that you’ve added',
            target: () => ref1.current,
            placement: 'leftTop',
        },
        {
            title: <UserGuideHeader title="Add Vendors" id={2} length={STEPS_LENGTH} />,
            description: 'Here you can add new vendors',
            target: () => reff.current,
            placement: 'rightTop',
        },
    ];

    //translation
    const { t } = useTranslation('vendorManagement');

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Vendor Management'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ vendors: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, vendors: false });
        setOpenArticle(false);
    };

    // constants
    // const TABS = [
    //     { name: 'My Vendors', id: 0 },
    //     { name: 'Acting as a Vendor', id: 1 },
    // ];

    const { reminderCount, showReminder } = GetRemaining(pricing_plan?.vendor_mgt, all_vendors?.length);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.vendors ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.vendors]);

    return (
        <>
            <PageHeader browserTitle="Vendors | Smartcomply" />
            {showReminder && <SubscriptionAlert isDashboardPage={false} limitedNumber={`${reminderCount} vendor`} />}
            <TabLayout
                onTabChange={handleTabChange}
                tab={currentTab}
                tabs={t('tabs', { returnObjects: true })}
                ref1={ref1}
                ref2={ref2}
            >
                <AppTabPanel value={currentTab} index={0}>
                    <Vendors reff={reff} />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <ActingAsAVendorLayout />
                </AppTabPanel>
            </TabLayout>
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Vendors"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_vendors: state?.vendorReducers?.all_vendors,
        pricing_plan: state?.authReducers.pricing_plan,
        organization: state?.generalReducers?.user_info?.organization,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.vendors,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps, {})(VendorLayout);
