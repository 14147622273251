import React from 'react';
import { useTheme } from '@mui/styles';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Stack, Box, Typography } from '@mui/material';
import ContainerWithHeader from '../../commons/ContainerWithHeader';
// import LabeledChipTag from '../../commons/LabeledChipTag';

const RiskAssessmentCard = (props) => {
    const { dashboardResult } = props;

    const theme = useTheme();
    //charts paremeters
    //5 x 5 chart data
    const fiveByFiveInherentData = {
        labels: ['Very High', 'High', 'Medium', 'Low', 'Very Low'],
        // : ['High', 'Medium', 'Low'],
        datasets: [
            {
                label: '',
                data: dashboardResult?.risk_assessment?.['5x5']?.inherent,
                // : dashboardResult?.risk_assessment?.['3x3']?.inherent,
                backgroundColor: `${theme.palette.primary.main}`,
                barThickness: 30,
            },
        ],
    };
    const fiveByFiveResidualData = {
        labels: ['Very High', 'High', 'Medium', 'Low', 'Very Low'],
        // : ['High', 'Medium', 'Low'],
        datasets: [
            {
                label: '',
                data: dashboardResult?.risk_assessment?.['5x5']?.residual,
                backgroundColor: `${theme.palette.primary.main}`,
                barThickness: 30,
            },
        ],
    };

    const threeByThreeResidualData = {
        labels: ['High', 'Medium', 'Low'],
        datasets: [
            {
                label: '',
                data: dashboardResult?.risk_assessment?.['3x3']?.residual,
                backgroundColor: `${theme.palette.primary.main}`,
                barThickness: 30,
            },
        ],
    };
    const threeByThreeInherentData = {
        labels: ['High', 'Medium', 'Low'],
        datasets: [
            {
                label: '',
                data: dashboardResult?.risk_assessment?.['3x3']?.inherent,
                backgroundColor: `${theme.palette.primary.main}`,
                barThickness: 30,
            },
        ],
    };
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

    const option = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                display: true,
                color: 'black',
                formatter: Math.round,
                anchor: 'end',
                offset: -20,
                align: 'start',
            },
        },
        aspectRatio: 5 / 2,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                min: 0,
                max: 100,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                barThickness: 6,
                grid: {
                    display: false,
                },
            },
        },
    };
    const option2 = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                display: true,
                color: 'black',
                formatter: Math.round,
                anchor: 'end',
                offset: -20,
                align: 'start',
            },
        },
        aspectRatio: 4 / 1,
        scales: {
            x: {
                stacked: true,
                barThickness: 6,
                min: 0,
                max: 100,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                barThickness: 6,
                grid: {
                    display: false,
                },
            },
        },
    };

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    // const criticalRisks = [
    //     { id: 1, name: 'Infrastructures', value: risk_assessment_counts },
    //     { id: 2, name: 'Computers', value: 1 },
    // ];
    // const highRisks = [
    //     {
    //         id: 1,
    //         name: 'Infrastructures',
    //         value: high_risk?.filter((risk) => risk?.assets?.toString()?.length === 4).length,
    //     },
    //     { id: 2, name: 'Computers', value: high_risk?.filter((risk) => risk?.assets?.toString()?.length > 4).length },
    // ];
    // const mediumRisks = [
    //     {
    //         id: 1,
    //         name: 'Infrastructures',
    //         value: medium_risk?.filter((risk) => risk?.assets?.toString()?.length === 4).length,
    //     },
    //     {
    //         id: 2,
    //         name: 'Computers',
    //         value: medium_risk?.filter((risk) => risk?.assets?.toString()?.length > 4).length,
    //     },
    // ];
    // const lowRisks = [
    //     {
    //         id: 1,
    //         name: 'Infrastructures',
    //         value: low_risk?.filter((risk) => risk?.assets?.toString()?.length === 4).length,
    //     },
    //     { id: 2, name: 'Computers', value: low_risk?.filter((risk) => risk?.assets?.toString()?.length > 4).length },
    // ];
    return (
        <ContainerWithHeader header="risk assessment" sx={{ mt: 4, height: '580px' }}>
            <Box sx={{ mt: 1 }}>
                <Typography sx={{ textAlign: 'center', color: theme.palette.primary[20] }}>5 x 5 Matrix</Typography>
                <Stack direction="row" spacing={1} sx={{ height: '50%', mt: 3, pl: 2 }}>
                    <div className="w-[48%] h-[fit-content]">
                        <Typography
                            sx={{
                                color: theme.palette.primary[20],
                                fontSize: '14px',
                                fontWeight: 700,
                                letterSpacing: '0.1px',
                                mb: 2,
                            }}
                        >
                            Inherent
                        </Typography>
                        <Bar options={option} data={fiveByFiveInherentData} width={100} height={140} />
                    </div>
                    <div className="w-1/2 border-l-[0.7px] border-l-[#E1E2EC] pl-4 h-[fit-content]">
                        <Typography
                            sx={{
                                color: theme.palette.primary[20],
                                fontSize: '14px',
                                fontWeight: 700,
                                letterSpacing: '0.1px',
                                // ml: 1.5,
                                mb: 2,
                            }}
                        >
                            Residual
                        </Typography>
                        <Bar options={option} data={fiveByFiveResidualData} width={100} height={140} />
                    </div>
                </Stack>
            </Box>

            <Box sx={{ mt: 1 }}>
                <Typography sx={{ textAlign: 'center', color: theme.palette.primary[20] }}>3 x 3 Matrix</Typography>
                <Stack direction="row" spacing={1} sx={{ height: '50%', mt: 1.5, pl: 2 }}>
                    <div className="w-[48%] h-[fit-content]">
                        <Typography
                            sx={{
                                color: theme.palette.primary[20],
                                fontSize: '14px',
                                fontWeight: 700,
                                letterSpacing: '0.1px',
                                mb: 2,
                            }}
                        >
                            Inherent
                        </Typography>
                        <Bar options={option2} data={threeByThreeInherentData} width={100} height={100} />
                    </div>
                    <div className="w-1/2 border-l-[0.7px] border-l-[#E1E2EC] pl-4 h-[fit-content]">
                        <Typography
                            sx={{
                                color: theme.palette.primary[20],
                                fontSize: '14px',
                                fontWeight: 700,
                                letterSpacing: '0.1px',
                                // ml: 1.5,
                                mb: 2,
                            }}
                        >
                            Residual
                        </Typography>
                        <Bar options={option2} data={threeByThreeResidualData} width={100} height={100} />
                    </div>
                </Stack>
            </Box>
        </ContainerWithHeader>
    );
};

export default RiskAssessmentCard;
