import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Button, Empty, Card, Typography, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BiUnlink } from 'react-icons/bi';
import { PlusOutlined, EditOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

// redux
import { connect } from 'react-redux';
import {
    GetDocumentsBySubcontrol,
    GetAllDocumentsByAltTag,
    EditDocument,
    DeleteDocument,
} from 'store/actions/adminActions';
import AddDocumentModal from '../Operations/AddDocumentModal';
import EditDocumentModal from '../Operations/EditDocumentModal';

// sub-components
const { Title } = Typography;
const { confirm } = Modal;

const Soc2DocumentsView = (props) => {
    const {
        GetDocumentsBySubcontrol,
        subcontrol,
        all_admin_documents_by_control,
        GetAllDocumentsByAltTag,
        EditDocument,
        DeleteDocument,
    } = props;

    // state
    const [modal, setModal] = useState({ add: false, edit: false });
    const [oneDoc, setOneDoc] = useState({});
    const [loading, setLoading] = useState({ content: false });

    // functions
    const openAddDocumentModal = () => setModal({ ...modal, add: true });
    const closeAddDocumentModal = () => setModal({ ...modal, add: false });
    const openEditDocumentModal = (doc) => {
        setModal({ ...modal, edit: true });
        setOneDoc(doc);
    };
    const closeEditDocumentModal = () => setModal({ ...modal, edit: false });

    const getDocumentsBySubcontrol = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetDocumentsBySubcontrol(subcontrol?.title);
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast?.error(res?.message);
        }
    };
    const getDocumentsInOtherStandard = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllDocumentsByAltTag('soc2');
        setLoading({ ...loading, content: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    //Actions Array
    const documentActions = (document) => {
        let actions = [
            <Tooltip title="Download this Document" color="blue">
                <a key="preview" href={document?.default} target="_blank" rel="noreferrer">
                    <DownloadOutlined />
                </a>
            </Tooltip>,
            <Tooltip title="Edit this Document" color="blue">
                <EditOutlined key="edit" title="Edit this Document" onClick={() => openEditDocumentModal(document)} />
            </Tooltip>,
            <Tooltip title="Delete this Document" color="red">
                <DeleteOutlined key="delete" title="Delete this document" onClick={() => deleteModal(document)} />
            </Tooltip>,
        ];
        if (document?.tags?.split(',').length > 1 && document?.tags?.split(',').includes('soc2')) {
            actions.push(
                <Tooltip title="Unlink this Document" color="blue">
                    <BiUnlink key="unlink" title="Unlink this document" onClick={() => unlinkModal(document)} />
                </Tooltip>
            );
        }
        return actions;
    };
    const deleteModal = (document) => {
        confirm({
            title: 'Do you want to delete this document?',
            content:
                'If this document is linked to other standards, you will be deleting it across all standards by clicking yes. If you intend to remove it from this standard alone and it is linked, just unlink it to avoid loss of data.',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const res = await DeleteDocument(document?.id, document?.sub_control, 'soc2');
                if (res.success) {
                    toast.success('Document Deleted Successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };
    const unlinkModal = (document) => {
        confirm({
            title: 'Do you want to unlink this document?',
            icon: <ExclamationCircleOutlined />,
            content: 'Unlinking this document will detach this document from this compliance standard',
            okText: 'Yes',
            cancelText: 'No',
            async onOk() {
                const newTags = document.tags
                    .split(',')
                    .filter((tag) => tag !== 'soc2')
                    .join();
                const formData = new FormData();
                formData.append('tags', newTags);
                const res = await EditDocument(formData, document?.id, 'soc2', document?.sub_control);
                if (res.success) {
                    toast.success('Document unlinked successfully');
                } else {
                    toast.error(res.message);
                }
            },
        });
    };

    // useEffect
    useEffect(() => {
        getDocumentsBySubcontrol();
        getDocumentsInOtherStandard();
    }, []);
    return (
        <>
            <Row justify="end">
                <Button type="primary" icon={<PlusOutlined />} onClick={openAddDocumentModal}>
                    Add Document
                </Button>
            </Row>
            <section className="my-3">
                {loading.content ? (
                    'loading'
                ) : (
                    <>
                        {all_admin_documents_by_control && all_admin_documents_by_control?.length > 0 ? (
                            <Row gutter={16}>
                                {all_admin_documents_by_control
                                    ?.sort((a, b) => a?.id - b?.id)
                                    .map((document) => {
                                        return (
                                            <Col
                                                xs={24}
                                                sm={12}
                                                md={8}
                                                lg={6}
                                                style={{ marginBottom: '1rem' }}
                                                key={document.id}
                                            >
                                                <Card loading={false} actions={documentActions(document)}>
                                                    <Title level={5} style={{ textAlign: 'center' }}>
                                                        {document.soc2_title}
                                                    </Title>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        ) : (
                            <Row justify="center">
                                <Col>
                                    <Empty
                                        description={
                                            <div>
                                                <h3>No Document</h3>
                                                <span>Add a new document </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </section>
            {modal?.add && (
                <AddDocumentModal
                    open={modal.add}
                    handleClose={closeAddDocumentModal}
                    tag="soc2"
                    payload={{
                        sub_control: subcontrol?.title,
                        common_control: subcontrol?.parent_control,
                    }}
                    titleLabel="soc2_title"
                    descLabel="soc2_description"
                    groupIdLabel="sub_control"
                />
            )}
            {modal.edit && (
                <EditDocumentModal
                    open={modal.edit}
                    handleClose={closeEditDocumentModal}
                    one_document={oneDoc}
                    tag="soc2"
                    payload={{
                        sub_control: subcontrol?.title,
                        common_control: subcontrol?.parent_control,
                    }}
                    titleLabel="soc2_title"
                    descLabel="soc2_description"
                    groupIdLabel="sub_control"
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_admin_documents_by_control: state?.adminReducers?.all_admin_documents_by_control,
    };
};
export default connect(mapStateToProps, {
    GetDocumentsBySubcontrol,
    GetAllDocumentsByAltTag,
    EditDocument,
    DeleteDocument,
})(Soc2DocumentsView);
