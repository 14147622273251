import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import Hello from '../../../assets/img/Hello.svg';
import SmilingFace from '../../../assets/img/SmilingFace.svg';
import ProgressBolt from '../../../assets/img/icons/ProgressBolt.svg';
import AppLoadingButton from '../../../components/new_components/AppLoadingButton';
import ReadDocumentation from './components/ReadDocumentation';
import CheckMark from 'assets/img/Check.png';

const Welcome = (props) => {
    const { loading, handleRequest, all_approved_user } = props;
    const theme = useTheme();
    return (
        <Box sx={{ mb: 5, mt: '-1rem' }}>
            <Typography sx={{ color: '#395BA9', fontWeight: 600, fontSize: '16px' }}>
                {' '}
                Introducing
                <Typography
                    component={'span'}
                    sx={{
                        color: '#395BA9',
                        fontWeight: 600,
                        fontSize: '16px',
                        ml: 1,
                        borderBottom: `3px solid ${theme.palette.warning[700]}`,
                    }}
                >
                    Fraud guardian API !
                </Typography>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <Typography
                    sx={{
                        width: '70%',
                        color: theme.palette.gray[30],
                        fontWeight: 400,
                        fontSize: '13px',
                    }}
                >
                    Fraud Guardian API is your trusted partner in the world of Know Your Customer (KYC) solutions.
                    Designed as a three-way KYC API, our platform is committed to guarding against fraudulent activities
                    and ensuring secure and reliable user verification.
                </Typography>
                <img src={Hello} alt="Hello" />
            </Box>
            <Divider />
            <Box sx={{ mt: 2 }}>
                <Typography sx={{ color: '#395BA9', fontWeight: 600, fontSize: '16px', mb: 2 }}>
                    Ready to explore
                </Typography>
                <Typography
                    sx={{
                        width: '70%',
                        color: theme.palette.gray[30],
                        fontWeight: 400,
                        fontSize: '13px',
                        mb: 2.5,
                        [theme.breakpoints.down('xl')]: {
                            width: '100%',
                        },
                    }}
                >
                    For those seeking a deeper understanding of the capabilities and possibilities of our API, the API
                    Reference is your treasure trove of information. Discover the full potential of Fraud Guardian API
                    and unlock new horizons for your KYC needs.
                </Typography>
                {all_approved_user?.merchant === null && (
                    <AppLoadingButton
                        text="Request for API"
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        sx={{ width: '200px', height: '45px', mb: 2 }}
                        loading={loading}
                        type="submit"
                        onClick={handleRequest}
                    />
                )}

                {all_approved_user?.merchant && !all_approved_user?.enabled && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <img src={ProgressBolt} alt="ProgressBolt" />
                        <Typography
                            sx={{ fontSize: '14px', fontWeight: 500, color: '#6E8EDF', fontStyle: 'italic', ml: 1 }}
                        >
                            API request in progress...
                        </Typography>
                    </Box>
                )}
                {all_approved_user?.enabled && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <img src={CheckMark} alt="ProgressBolt" />
                        <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#395BA9', ml: 1 }}>
                            API token ready
                        </Typography>
                    </Box>
                )}
            </Box>
            <Divider />
            <Box sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', mb: 2 }}>
                    <Typography sx={{ mr: 1 }}>Hey developer</Typography>
                    <img src={SmilingFace} alt="SmilingFace" />
                </Box>
                <Typography
                    sx={{
                        width: '70%',
                        color: theme.palette.gray[30],
                        fontWeight: 400,
                        fontSize: '13px',
                        mb: 2.5,
                        [theme.breakpoints.down('xl')]: {
                            width: '100%',
                        },
                    }}
                >
                    If you're raring to go, take a quick leap into the Quick Start Docs. They'll guide you through the
                    process of making your first request, ensuring a smooth onboarding experience.
                </Typography>
            </Box>
            <ReadDocumentation width="50%" />
        </Box>
    );
};
export default Welcome;
