import React from 'react';
//core components
import AppModal from 'components/new_components/AppModal';
import CheckIcon from '../../../../assets/img/check_circle.svg';
import { Box, Typography } from '@mui/material';

const NotificationModal = (props) => {
    const { open, handleClose } = props;
    return (
        <AppModal open={open} handleClose={handleClose} width={300}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <img src={CheckIcon} width="80px" />
                </Box>
                <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '14px' }}>
                    Your Infrastructure(s) record have been created successfully. Please hold on for few minutes while
                    we run a scan on your infrastructure. You can view the scan report once the scan is completed.
                </Typography>
            </Box>
        </AppModal>
    );
};

export default NotificationModal;
