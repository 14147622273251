import React, { useMemo } from 'react';

// core components
import EmptyState from 'components/new_components/EmptyState';
import ExceptionHeader from './ExceptionHeader';
import CompliancePagination from '../CompliancePagination';

// redux
import { connect } from 'react-redux';
import ExceptionItem from './ExceptionItem';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';

//translation
import { useTranslation } from 'react-i18next';

const ResourceAside = (props) => {
    const {
        selectedSubgroup,
        resource,
        subgroupIdKey,
        exempted_documents,
        exempted_forms,
        subgroupTitle,
        standard,
        onExempted,
    } = props;

    // state
    const [page, setPage] = useState(1);

    //translation
    const { t } = useTranslation('compliance');

    // functions
    const handlePageChange = (page) => {
        setPage(page);
    };

    // memos
    const exceptionsBySubgroup = useMemo(() => {
        const resources = resource === 'doc' ? exempted_documents : exempted_forms;
        return resources?.filter((res) => {
            return (
                selectedSubgroup &&
                res?.[resource === 'doc' ? 'document_detail' : 'form_detail']?.[subgroupIdKey] === selectedSubgroup
            );
        });
    }, [selectedSubgroup, resource, exempted_documents, exempted_forms]);

    // useEffect
    useEffect(() => {
        setPage(1);
    }, [selectedSubgroup]);

    return exceptionsBySubgroup && exceptionsBySubgroup.length ? (
        <Stack sx={{ m: -2, flex: 1 }}>
            <ExceptionHeader
                count={exceptionsBySubgroup.length}
                title={subgroupTitle}
                titleFontSize={['pcidss', 'soc2', 'card4'].includes(standard) ? 24 : 20}
            />
            <ExceptionItem
                item={exceptionsBySubgroup?.[page - 1]}
                resource={resource}
                standard={standard}
                onExempted={onExempted}
            />
            <Stack sx={{ flex: 1, justifyContent: 'flex-end', pb: 4 }}>
                <CompliancePagination
                    page={page}
                    dataLength={exceptionsBySubgroup.length}
                    noPerPage={1}
                    onPageChange={handlePageChange}
                    sx={{
                        root: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    }}
                />
            </Stack>
        </Stack>
    ) : (
        <EmptyState description={t('noSub')} />
    );
};

const mapStateToProps = (state) => {
    return {
        exempted_documents: state?.complianceReducers?.all_exempted_documents,
        exempted_forms: state?.complianceReducers?.all_exempted_forms,
    };
};

export default connect(mapStateToProps, {})(ResourceAside);
